// Generated by ReScript, PLEASE EDIT WITH CARE


var defaultId = 1;

export {
  defaultId ,
  
}
/* No side effect */
