// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Modal$Ucidata from "../../../core/modals/Modal.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as CompartimentCash_Columns$Ucidata from "./CompartimentCash_Columns.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import * as CompartimentCashAccounts_ItemDetails$Ucidata from "./CompartimentCashAccounts_ItemDetails.bs.js";

function Compartiment_Cash(Props) {
  var uci = Props.uci;
  var codes = Codes$Ucidata.useCodes(undefined);
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var fetchItems = match[1];
  Client$Ucidata.useClient(undefined);
  var match$1 = React.useState(function () {
        
      });
  var setSelectedAccount = match$1[1];
  var selectedAccount = match$1[0];
  React.useState(function () {
        return false;
      });
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowItemDetailsModal = match$2[1];
  var editModalContent;
  if (selectedAccount !== undefined) {
    var tmp = {
      uciId: Belt_Option.getWithDefault(uci.id, 0),
      onCancel: (function (param) {
          return Curry._1(setShowItemDetailsModal, (function (_prev) {
                        return false;
                      }));
        }),
      onSave: (function (param) {
          Curry._1(fetchItems, undefined);
          return Curry._1(setShowItemDetailsModal, (function (_prev) {
                        return false;
                      }));
        })
    };
    if (selectedAccount !== undefined) {
      tmp.account = Caml_option.valFromOption(selectedAccount);
    }
    editModalContent = React.createElement(Modal$Ucidata.make, {
          isOpen: match$2[0],
          setOpen: setShowItemDetailsModal,
          closeOnOutsideClick: true,
          closeOnEsc: true,
          size: /* Large */1,
          children: React.createElement(CompartimentCashAccounts_ItemDetails$Ucidata.make, tmp)
        });
  } else {
    editModalContent = null;
  }
  var events_onEditAccountClick = function (account) {
    Curry._1(setSelectedAccount, (function (_prev) {
            return account;
          }));
    return Curry._1(setShowItemDetailsModal, (function (_prev) {
                  return true;
                }));
  };
  var events_onDeleteAccountClick = function (account) {
    console.log("Delete Account Clicked: ", account);
    
  };
  var events = {
    onEditAccountClick: events_onEditAccountClick,
    onDeleteAccountClick: events_onDeleteAccountClick
  };
  var columns = React.useMemo((function () {
          return Belt_Option.map(codes, (function (codes) {
                        return CompartimentCash_Columns$Ucidata.make(codes, events);
                      }));
        }), [
        codes,
        uci
      ]);
  var uciId = uci.id;
  var match$3 = uciId !== undefined ? Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci/" + String(uciId) + "/cash_accounts") : [
      /* NoData */0,
      (function (param) {
          
        })
    ];
  var itemsState = match$3[0];
  var content;
  var exit = 0;
  var exit$1 = 0;
  if (columns !== undefined) {
    if (typeof itemsState === "number") {
      exit = 1;
    } else {
      switch (itemsState.TAG | 0) {
        case /* Loading */0 :
        case /* Error */1 :
            exit$1 = 2;
            break;
        case /* Data */2 :
            content = React.createElement(CompartimentCash_Columns$Ucidata.Table.make, {
                  columns: columns,
                  data: itemsState._0,
                  dataKey: (function (account) {
                      return Belt_Option.getWithDefault(Belt_Option.map(account.id, (function (prim) {
                                        return String(prim);
                                      })), "0");
                    }),
                  className: "table-auto lg:table-fixed whitespace-nowrap border-separate rounded border text-xs text-center",
                  overflowContainerClassName: "w-full",
                  tableExtraClass: "w-full rounded-l-none",
                  fixedTableExtraClass: "border-r-8 rounded-r-none",
                  containerClassName: "border-r rounded border-dashed",
                  thClassName: "py-4 px-2 font-medium",
                  tdClassName: "py-4 px-2",
                  headTrClassName: "bg-gray-100 uppercase",
                  bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
                  paginationLimit: 20
                });
            break;
        
      }
    }
  } else {
    exit$1 = 2;
  }
  if (exit$1 === 2) {
    if (typeof itemsState === "number") {
      exit = 1;
    } else {
      switch (itemsState.TAG | 0) {
        case /* Loading */0 :
            content = React.createElement(Text$Ucidata.make, {
                  children: "Loading cash accounts..."
                });
            break;
        case /* Error */1 :
            content = React.createElement(Text$Ucidata.make, {
                  children: "Failed to load cash accounts."
                });
            break;
        case /* Data */2 :
            exit = 1;
            break;
        
      }
    }
  }
  if (exit === 1) {
    content = React.createElement(Text$Ucidata.make, {
          children: "No cash accounts available."
        });
  }
  return React.createElement(Section$Ucidata.make, {
              title: React.createElement(Text$Ucidata.make, {
                    children: "Cash Monitoring"
                  }),
              className: "space-y-8",
              icon: ProRegularSvgIcons.faMonitorHeartRate,
              children: null
            }, content, editModalContent);
}

var Columns;

var ItemDetails;

var make = Compartiment_Cash;

export {
  Columns ,
  ItemDetails ,
  make ,
  
}
/* react Not a pure module */
