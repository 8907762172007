// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as UciItem$Ucidata from "../../../../models/UciItem.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Input_Text$Ucidata from "../../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../../core/validation/Validation.bs.js";
import * as Input_Number$Ucidata from "../../../core/input/Input_Number.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Input_Textarea$Ucidata from "../../../core/input/Input_Textarea.bs.js";
import * as Validation_Rules$Ucidata from "../../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../../core/validation/Validation_Context.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function CompartimentTransactions_ItemDetails(Props) {
  var uciId = Props.uciId;
  var item = Props.item;
  var onCancel = Props.onCancel;
  var onSave = Props.onSave;
  var itemTypeOpt = Props.itemType;
  var itemType = itemTypeOpt !== undefined ? itemTypeOpt : "1";
  var client = Client$Ucidata.useClient(undefined);
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(item, UciItem$Ucidata.empty(itemType === "2", uciId));
      });
  var setItem = match[1];
  var item$1 = match[0];
  var editionMode = item$1.id !== undefined;
  var codes = Codes$Ucidata.useCodes(undefined);
  var uciTypes = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci/" + String(uciId) + "/types")[0]);
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveError = match$1[2];
  var setSaveError = match$1[1];
  var saveError = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSaving = match$2[1];
  var saving = match$2[0];
  var codesType = React.useMemo((function () {
          if (uciTypes !== undefined) {
            if (codes !== undefined) {
              return Belt_Array.keep(codes.codesType, (function (codeType) {
                            return Belt_Array.some(uciTypes.types, (function (uciType) {
                                          return uciType.id === codeType.id;
                                        }));
                          }));
            } else {
              return [];
            }
          } else {
            return [];
          }
        }), [
        uciTypes,
        codes
      ]);
  var codesSubType = React.useMemo((function () {
          if (codes === undefined) {
            return [];
          }
          if (uciTypes === undefined) {
            return [];
          }
          var codesSubtype = codes.codesSubtype;
          return Belt_Option.mapWithDefault(Belt_Array.getBy(uciTypes.types, (function (uciType) {
                            return uciType.id === item$1.code_type_id;
                          })), [], (function (selectedType) {
                        return Belt_Array.keep(codesSubtype, (function (param) {
                                      return selectedType.code_subtypes.includes(param.id);
                                    }));
                      }));
        }), [
        item$1.code_type_id,
        uciTypes,
        Belt_Option.map(codes, (function (codes) {
                return codes.codesSubtype;
              }))
      ]);
  var codesEvent = React.useMemo((function () {
          return Belt_Option.mapWithDefault(codes, [], (function (codes) {
                        return Belt_Array.keep(codes.codesEvent, (function (elt) {
                                      if (elt.code_uci_item_type_id === item$1.code_uci_item_type_id) {
                                        if (editionMode) {
                                          return true;
                                        } else {
                                          return !elt.name.startsWith("Capital Call");
                                        }
                                      } else {
                                        return false;
                                      }
                                    }));
                      }));
        }), [
        item$1,
        codes
      ]);
  var codesStatus = React.useMemo((function () {
          return Belt_Option.mapWithDefault(codes, [], (function (param) {
                        return param.codesStatus;
                      }));
        }), [codes]);
  var saveItem = function (param) {
    Curry._1(clearSaveError, undefined);
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(UciItem$Ucidata.save(client, item$1), (function (item) {
                              return Belt_Option.forEach(onSave, (function (onSave) {
                                            return Curry._1(onSave, item);
                                          }));
                            })), setSaveError), (function (param) {
                      return Curry._1(setSaving, (function (param) {
                                    return false;
                                  }));
                    })));
  };
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Loader$Ucidata.make, {
                  className: "text-6xl my-10",
                  children: Belt_Option.map(codes, (function (codes) {
                          return React.createElement(Validation$Ucidata.make, {
                                      render: (function (validation) {
                                          var tmp = {};
                                          if (saveError !== undefined) {
                                            tmp.error = Caml_option.valFromOption(saveError);
                                          }
                                          var tmp$1 = {
                                            name: "transaction",
                                            options: codesEvent,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: item.cadaster_nb,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: item.code_type_id,
                                                                                    code_event_id: option.id,
                                                                                    code_subtype_id: item.code_subtype_id,
                                                                                    code_asset_type_id: item.code_asset_type_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: item.status_id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Transaction"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            isDisabled: editionMode,
                                            isOptionDisabled: (function (option) {
                                                return option.is_sell;
                                              }),
                                            getOptionLabel: (function (codeEvent) {
                                                return codeEvent.name;
                                              }),
                                            getOptionValue: (function (codeEvent) {
                                                return codeEvent.id;
                                              })
                                          };
                                          var tmp$2 = Belt_Array.getBy(codesEvent, (function (elt) {
                                                  return elt.id === item$1.code_event_id;
                                                }));
                                          if (tmp$2 !== undefined) {
                                            tmp$1.value = Caml_option.valFromOption(tmp$2);
                                          }
                                          var tmp$3 = {
                                            name: "status",
                                            options: codesStatus,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: item.cadaster_nb,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: item.code_type_id,
                                                                                    code_event_id: item.code_event_id,
                                                                                    code_subtype_id: item.code_subtype_id,
                                                                                    code_asset_type_id: item.code_asset_type_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: option.id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Status"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (codeStatus) {
                                                return codeStatus.name;
                                              }),
                                            getOptionValue: (function (codeStatus) {
                                                return codeStatus.id;
                                              })
                                          };
                                          var tmp$4 = Belt_Array.getBy(codesStatus, (function (elt) {
                                                  return elt.id === Belt_Option.getWithDefault(item$1.status_id, 0);
                                                }));
                                          if (tmp$4 !== undefined) {
                                            tmp$3.value = Caml_option.valFromOption(tmp$4);
                                          }
                                          var tmp$5 = {
                                            name: "code-type",
                                            options: codesType,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: option.id === 1 ? item.cadaster_nb : undefined,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: option.id,
                                                                                    code_event_id: item.code_event_id,
                                                                                    code_subtype_id: item.code_subtype_id,
                                                                                    code_asset_type_id: item.code_asset_type_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: item.status_id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Transaction Type"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (codeType) {
                                                return codeType.name;
                                              }),
                                            getOptionValue: (function (codeType) {
                                                return codeType.id;
                                              })
                                          };
                                          var tmp$6 = Belt_Array.getBy(codesType, (function (elt) {
                                                  return elt.id === item$1.code_type_id;
                                                }));
                                          if (tmp$6 !== undefined) {
                                            tmp$5.value = Caml_option.valFromOption(tmp$6);
                                          }
                                          var tmp$7 = {
                                            name: "code-subtype",
                                            options: codesSubType,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: item.cadaster_nb,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: item.code_type_id,
                                                                                    code_event_id: item.code_event_id,
                                                                                    code_subtype_id: option.id,
                                                                                    code_asset_type_id: item.code_asset_type_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: item.status_id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Transaction Category"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (codeSubType) {
                                                return codeSubType.name;
                                              }),
                                            getOptionValue: (function (codeSubType) {
                                                return codeSubType.id;
                                              })
                                          };
                                          var tmp$8 = Belt_Array.getBy(codesSubType, (function (elt) {
                                                  return elt.id === item$1.code_subtype_id;
                                                }));
                                          if (tmp$8 !== undefined) {
                                            tmp$7.value = Caml_option.valFromOption(tmp$8);
                                          }
                                          var tmp$9 = {
                                            name: "asset-type",
                                            options: codes.codesAssetType,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: item.cadaster_nb,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: item.code_type_id,
                                                                                    code_event_id: item.code_event_id,
                                                                                    code_subtype_id: item.code_subtype_id,
                                                                                    code_asset_type_id: option.id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: item.status_id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Asset Type"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (assetType) {
                                                return assetType.name;
                                              }),
                                            getOptionValue: (function (assetType) {
                                                return assetType.id;
                                              })
                                          };
                                          var tmp$10 = Belt_Array.getBy(codes.codesAssetType, (function (elt) {
                                                  return elt.id === item$1.code_asset_type_id;
                                                }));
                                          if (tmp$10 !== undefined) {
                                            tmp$9.value = Caml_option.valFromOption(tmp$10);
                                          }
                                          var tmp$11 = {
                                            name: "code-denom",
                                            options: codes.codesDenom,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: item.cadaster_nb,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: item.code_type_id,
                                                                                    code_event_id: item.code_event_id,
                                                                                    code_subtype_id: item.code_subtype_id,
                                                                                    code_asset_type_id: item.code_asset_type_id,
                                                                                    code_denom_id: option.id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: item.status_id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Denomination"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (denom) {
                                                return denom.name;
                                              }),
                                            getOptionValue: (function (denom) {
                                                return denom.id;
                                              })
                                          };
                                          var tmp$12 = Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                                  return elt.id === item$1.code_denom_id;
                                                }));
                                          if (tmp$12 !== undefined) {
                                            tmp$11.value = Caml_option.valFromOption(tmp$12);
                                          }
                                          var tmp$13 = {
                                            name: "code-currency",
                                            options: codes.codesCcy,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_group_id: item.uci_item_group_id,
                                                                                    uci_id: item.uci_id,
                                                                                    name: item.name,
                                                                                    cadaster_nb: item.cadaster_nb,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    price: item.price,
                                                                                    code_type_id: item.code_type_id,
                                                                                    code_event_id: item.code_event_id,
                                                                                    code_subtype_id: item.code_subtype_id,
                                                                                    code_asset_type_id: item.code_asset_type_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: option.id,
                                                                                    code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                    status_id: item.status_id,
                                                                                    holding_structure: item.holding_structure,
                                                                                    status_hs: item.status_hs,
                                                                                    notes: item.notes
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Currency"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (ccy) {
                                                return ccy.name;
                                              }),
                                            getOptionValue: (function (ccy) {
                                                return ccy.id;
                                              })
                                          };
                                          var tmp$14 = Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                                  return elt.id === item$1.code_ccy_id;
                                                }));
                                          if (tmp$14 !== undefined) {
                                            tmp$13.value = Caml_option.valFromOption(tmp$14);
                                          }
                                          return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                                          unstyled: true,
                                                          children: React.createElement(Layout$Ucidata.make, {
                                                                style: /* FlexBetween */3,
                                                                children: null
                                                              }, React.createElement(Font_Div$Ucidata.make, {
                                                                    style: /* Title */1,
                                                                    children: editionMode ? React.createElement(Icon$Ucidata.make, {
                                                                            text: item$1.name,
                                                                            icon: ProSolidSvgIcons.faEdit,
                                                                            className: "mr-2"
                                                                          }) : React.createElement(Icon$Ucidata.make, {
                                                                            text: "Create Asset",
                                                                            icon: ProLightSvgIcons.faPlus,
                                                                            className: "mr-2"
                                                                          })
                                                                  }), React.createElement("div", {
                                                                    className: "flex items-center justify-center space-x-4"
                                                                  }, React.createElement(OptionMap$Ucidata.make, {
                                                                        option: onCancel,
                                                                        render: (function (onCancel) {
                                                                            return React.createElement(Button$Ucidata.make, {
                                                                                        onClick: (function (param) {
                                                                                            return Curry._1(onCancel, undefined);
                                                                                          }),
                                                                                        children: React.createElement(Text$Ucidata.make, {
                                                                                              children: "Cancel"
                                                                                            })
                                                                                      });
                                                                          })
                                                                      }), React.createElement(Button$Ucidata.make, {
                                                                        style: /* Primary */0,
                                                                        disabled: Validation_Context$Ucidata.invalid(validation),
                                                                        icon: ProRegularSvgIcons.faSave,
                                                                        spinner: saving,
                                                                        onClick: saveItem,
                                                                        children: editionMode ? React.createElement(Text$Ucidata.make, {
                                                                                children: "Update"
                                                                              }) : React.createElement(Text$Ucidata.make, {
                                                                                children: "Create"
                                                                              })
                                                                      })))
                                                        }), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Section$Ucidata.make, {
                                                          children: React.createElement("div", {
                                                                className: "space-y-4"
                                                              }, React.createElement("div", {
                                                                    className: "border-b border-dashed pb-8 mb-8 space-y-4"
                                                                  }, React.createElement("div", undefined, React.createElement(Input_Select$Ucidata.make, tmp$1)), React.createElement(If$Ucidata.make, {
                                                                        condition: editionMode && Belt_Option.isSome(item$1.status_id),
                                                                        children: React.createElement("div", undefined, React.createElement(Input_Select$Ucidata.make, tmp$3))
                                                                      })), React.createElement(Layout$Ucidata.make, {
                                                                    style: /* Grid2Cols */0,
                                                                    children: null
                                                                  }, React.createElement(Input_Select$Ucidata.make, tmp$5), React.createElement(Input_Select$Ucidata.make, tmp$7), React.createElement(Input_Select$Ucidata.make, tmp$9)), React.createElement(Layout$Ucidata.make, {
                                                                    style: /* Grid2Cols */0,
                                                                    children: null
                                                                  }, React.createElement(Input_Text$Ucidata.make, {
                                                                        id: "name",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Name"
                                                                            }),
                                                                        value: item$1.name,
                                                                        validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                        onChange: (function (name) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_group_id: item.uci_item_group_id,
                                                                                                  uci_id: item.uci_id,
                                                                                                  name: name,
                                                                                                  cadaster_nb: item.cadaster_nb,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: item.units,
                                                                                                  price: item.price,
                                                                                                  code_type_id: item.code_type_id,
                                                                                                  code_event_id: item.code_event_id,
                                                                                                  code_subtype_id: item.code_subtype_id,
                                                                                                  code_asset_type_id: item.code_asset_type_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                                  status_id: item.status_id,
                                                                                                  holding_structure: item.holding_structure,
                                                                                                  status_hs: item.status_hs,
                                                                                                  notes: item.notes
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Name"
                                                                      }), React.createElement(If$Ucidata.make, {
                                                                        condition: item$1.code_type_id === 1,
                                                                        children: React.createElement(Input_Text$Ucidata.make, {
                                                                              id: "cadaster",
                                                                              label: React.createElement(Text$Ucidata.make, {
                                                                                    children: "Cadaster"
                                                                                  }),
                                                                              value: Belt_Option.getWithDefault(item$1.cadaster_nb, ""),
                                                                              validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                              onChange: (function (cadaster_nb) {
                                                                                  return Curry._1(setItem, (function (item) {
                                                                                                return {
                                                                                                        id: item.id,
                                                                                                        uci_item_group_id: item.uci_item_group_id,
                                                                                                        uci_id: item.uci_id,
                                                                                                        name: item.name,
                                                                                                        cadaster_nb: cadaster_nb,
                                                                                                        internal_ref: item.internal_ref,
                                                                                                        units: item.units,
                                                                                                        price: item.price,
                                                                                                        code_type_id: item.code_type_id,
                                                                                                        code_event_id: item.code_event_id,
                                                                                                        code_subtype_id: item.code_subtype_id,
                                                                                                        code_asset_type_id: item.code_asset_type_id,
                                                                                                        code_denom_id: item.code_denom_id,
                                                                                                        code_ccy_id: item.code_ccy_id,
                                                                                                        code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                                        status_id: item.status_id,
                                                                                                        holding_structure: item.holding_structure,
                                                                                                        status_hs: item.status_hs,
                                                                                                        notes: item.notes
                                                                                                      };
                                                                                              }));
                                                                                }),
                                                                              placeholder: "Cadaster Number"
                                                                            })
                                                                      }), React.createElement(Input_Text$Ucidata.make, {
                                                                        id: "internal-ref",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Reference"
                                                                            }),
                                                                        value: item$1.internal_ref,
                                                                        validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                        onChange: (function (internal_ref) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_group_id: item.uci_item_group_id,
                                                                                                  uci_id: item.uci_id,
                                                                                                  name: item.name,
                                                                                                  cadaster_nb: item.cadaster_nb,
                                                                                                  internal_ref: internal_ref,
                                                                                                  units: item.units,
                                                                                                  price: item.price,
                                                                                                  code_type_id: item.code_type_id,
                                                                                                  code_event_id: item.code_event_id,
                                                                                                  code_subtype_id: item.code_subtype_id,
                                                                                                  code_asset_type_id: item.code_asset_type_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                                  status_id: item.status_id,
                                                                                                  holding_structure: item.holding_structure,
                                                                                                  status_hs: item.status_hs,
                                                                                                  notes: item.notes
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Internal Reference"
                                                                      })), React.createElement(Layout$Ucidata.make, {
                                                                    style: /* Grid2Cols */0,
                                                                    children: null
                                                                  }, React.createElement(Input_Number$Ucidata.make, {
                                                                        id: "units",
                                                                        value: item$1.units,
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Units"
                                                                            }),
                                                                        onChange: (function (units) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_group_id: item.uci_item_group_id,
                                                                                                  uci_id: item.uci_id,
                                                                                                  name: item.name,
                                                                                                  cadaster_nb: item.cadaster_nb,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: units,
                                                                                                  price: item.price,
                                                                                                  code_type_id: item.code_type_id,
                                                                                                  code_event_id: item.code_event_id,
                                                                                                  code_subtype_id: item.code_subtype_id,
                                                                                                  code_asset_type_id: item.code_asset_type_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                                  status_id: item.status_id,
                                                                                                  holding_structure: item.holding_structure,
                                                                                                  status_hs: item.status_hs,
                                                                                                  notes: item.notes
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Units"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$11), React.createElement(Input_Number$Ucidata.make, {
                                                                        id: "total-cost",
                                                                        value: item$1.price,
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Cash"
                                                                            }),
                                                                        validate: Validation_Rules$Ucidata.Int.min(undefined, 1),
                                                                        onChange: (function (price) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_group_id: item.uci_item_group_id,
                                                                                                  uci_id: item.uci_id,
                                                                                                  name: item.name,
                                                                                                  cadaster_nb: item.cadaster_nb,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: item.units,
                                                                                                  price: price,
                                                                                                  code_type_id: item.code_type_id,
                                                                                                  code_event_id: item.code_event_id,
                                                                                                  code_subtype_id: item.code_subtype_id,
                                                                                                  code_asset_type_id: item.code_asset_type_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                                  status_id: item.status_id,
                                                                                                  holding_structure: item.holding_structure,
                                                                                                  status_hs: item.status_hs,
                                                                                                  notes: item.notes
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Cash"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$13)), React.createElement("div", undefined, React.createElement(Input_Textarea$Ucidata.make, {
                                                                        id: "notes",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "notes"
                                                                            }),
                                                                        value: item$1.internal_ref,
                                                                        onChange: (function (notes) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_group_id: item.uci_item_group_id,
                                                                                                  uci_id: item.uci_id,
                                                                                                  name: item.name,
                                                                                                  cadaster_nb: item.cadaster_nb,
                                                                                                  internal_ref: notes,
                                                                                                  units: item.units,
                                                                                                  price: item.price,
                                                                                                  code_type_id: item.code_type_id,
                                                                                                  code_event_id: item.code_event_id,
                                                                                                  code_subtype_id: item.code_subtype_id,
                                                                                                  code_asset_type_id: item.code_asset_type_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_uci_item_type_id: item.code_uci_item_type_id,
                                                                                                  status_id: item.status_id,
                                                                                                  holding_structure: item.holding_structure,
                                                                                                  status_hs: item.status_hs,
                                                                                                  notes: item.notes
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Notes"
                                                                      })))
                                                        }));
                                        })
                                    });
                        }))
                }));
}

var make = React.memo(CompartimentTransactions_ItemDetails);

var realEstateId = 1;

export {
  realEstateId ,
  make ,
  
}
/* make Not a pure module */
