// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as UserContext$Ucidata from "../../UserContext.bs.js";

function getUserRoleCode(role) {
  return role + 1 | 0;
}

function check(user, roles) {
  return Belt_Option.mapWithDefault(user.user_roles, false, (function (userRoles) {
                return Belt_Array.some(userRoles, (function (userRole) {
                              return Belt_Array.some(roles, (function (role) {
                                            return userRole === (role + 1 | 0);
                                          }));
                            }));
              }));
}

function IfUserRole(Props) {
  var role = Props.role;
  var rolesOpt = Props.roles;
  var renderElse = Props.renderElse;
  var children = Props.children;
  var roles = rolesOpt !== undefined ? rolesOpt : [];
  var user = UserContext$Ucidata.useUser(undefined);
  var roles$1 = Belt_Array.concat(Belt_Option.mapWithDefault(role, [], (function (role) {
              return [role];
            })), roles);
  var authorized = user !== undefined ? check(user, roles$1) : false;
  if (authorized) {
    return children;
  } else {
    return Curry._1(Belt_Option.getWithDefault(renderElse, (function (param) {
                      return null;
                    })), undefined);
  }
}

var make = React.memo(IfUserRole);

export {
  getUserRoleCode ,
  check ,
  make ,
  
}
/* make Not a pure module */
