// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function empty(uciStructId) {
  return {
          id: undefined,
          uci_item_id: uciStructId,
          name: "",
          internal_ref: "",
          units: 0,
          cost: 0,
          holdings: 0,
          code_nature_id: 0,
          code_country_id: 0,
          code_denom_id: 0,
          code_ccy_id: 0,
          code_level_id: 0
        };
}

function create(client, item) {
  return client.url("/uci_struct_item").post(item).json();
}

function update(client, item) {
  return client.url("/uci_struct_item/" + String(Belt_Option.getExn(item.id))).put(item).json();
}

function save(client, item) {
  var match = item.id;
  if (match !== undefined) {
    return update(client, item);
  } else {
    return create(client, item);
  }
}

function toItem(detailed) {
  return {
          id: detailed.id,
          uci_item_id: detailed.uci_item_id,
          name: detailed.name,
          internal_ref: detailed.internal_ref,
          units: detailed.units,
          cost: detailed.cost,
          holdings: detailed.holdings,
          code_nature_id: detailed.code_nature_id,
          code_country_id: detailed.code_country_id,
          code_denom_id: detailed.code_denom_id,
          code_ccy_id: detailed.code_ccy_id,
          code_level_id: detailed.code_level_id
        };
}

var Detailed = {
  toItem: toItem
};

function $$fetch(client, id) {
  return client.url("/api/uci_struct_item/" + id).get().json();
}

export {
  empty ,
  create ,
  update ,
  save ,
  Detailed ,
  $$fetch ,
  
}
/* No side effect */
