// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as CodeUciRole$Ucidata from "./CodeUciRole.bs.js";

var UciUserRole = {};

var empty_uci_user_roles = [];

var empty_types = [];

var empty_doc_opts = [];

var empty_cash_accounts = [];

var empty = {
  id: undefined,
  uci_name: "",
  comp_name: "",
  cssf_code: "",
  account_nb: "",
  uci_user_roles: empty_uci_user_roles,
  types: empty_types,
  doc_opts: empty_doc_opts,
  uci_group_id: undefined,
  capital_ccy_id: undefined,
  cash_accounts: empty_cash_accounts
};

function create(client, uci) {
  return client.url("/uci").post(uci).json();
}

function update(client, uci) {
  return client.url("/uci/" + String(Belt_Option.getExn(uci.id))).patch(uci).json();
}

function save(client, uci) {
  var match = uci.id;
  if (match !== undefined) {
    return update(client, uci);
  } else {
    return create(client, uci);
  }
}

function setData(uci, dispatch) {
  return Curry._1(dispatch, {
              TAG: /* SetUci */6,
              _0: uci
            });
}

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* AddUser */0 :
        var user = action._0;
        return Belt_Option.map(state, (function (state) {
                      var alreadyAdded = Belt_Array.some(state.uci_user_roles, (function (param) {
                              if (param.user_id === user.id) {
                                return param.code_uci_role_id === CodeUciRole$Ucidata.defaultId;
                              } else {
                                return false;
                              }
                            }));
                      if (alreadyAdded) {
                        return state;
                      } else {
                        return {
                                id: state.id,
                                uci_name: state.uci_name,
                                comp_name: state.comp_name,
                                cssf_code: state.cssf_code,
                                account_nb: state.account_nb,
                                uci_user_roles: Belt_Array.concat(state.uci_user_roles, [{
                                        user_id: user.id,
                                        code_uci_role_id: CodeUciRole$Ucidata.defaultId
                                      }]),
                                types: state.types,
                                doc_opts: state.doc_opts,
                                uci_group_id: state.uci_group_id,
                                capital_ccy_id: state.capital_ccy_id,
                                cash_accounts: state.cash_accounts
                              };
                      }
                    }));
    case /* RemoveUser */1 :
        var codeUciRoleId = action._1;
        var userId = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: state.comp_name,
                              cssf_code: state.cssf_code,
                              account_nb: state.account_nb,
                              uci_user_roles: Belt_Array.keep(state.uci_user_roles, (function (role) {
                                      return !(role.user_id === userId && role.code_uci_role_id === codeUciRoleId);
                                    })),
                              types: state.types,
                              doc_opts: state.doc_opts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetAccountNb */2 :
        var accountNb = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: state.comp_name,
                              cssf_code: state.cssf_code,
                              account_nb: accountNb,
                              uci_user_roles: state.uci_user_roles,
                              types: state.types,
                              doc_opts: state.doc_opts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetDocumentsCompulsory */3 :
        var compulsory = action._1;
        var documentIds = action._0;
        return Belt_Option.map(state, (function (state) {
                      var docOpts = Belt_Array.reduce(documentIds, state.doc_opts.slice(0), (function (ids, documentId) {
                              if (compulsory) {
                                return Belt_Array.keep(ids, (function (id) {
                                              return id !== documentId;
                                            }));
                              } else {
                                return Belt_Array.concat([documentId], ids);
                              }
                            }));
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: state.comp_name,
                              cssf_code: state.cssf_code,
                              account_nb: state.account_nb,
                              uci_user_roles: state.uci_user_roles,
                              types: state.types,
                              doc_opts: docOpts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetName */4 :
        var compName = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: compName,
                              cssf_code: state.cssf_code,
                              account_nb: state.account_nb,
                              uci_user_roles: state.uci_user_roles,
                              types: state.types,
                              doc_opts: state.doc_opts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetTypes */5 :
        var documents = action._1;
        var types = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: state.comp_name,
                              cssf_code: state.cssf_code,
                              account_nb: state.account_nb,
                              uci_user_roles: state.uci_user_roles,
                              types: types,
                              doc_opts: Belt_Array.keep(state.doc_opts, (function (doc_id) {
                                      return Belt_Option.getWithDefault(Belt_Option.map(documents, (function (docs) {
                                                        return Belt_Array.some(docs, (function (d) {
                                                                      if (d.id === doc_id) {
                                                                        return Belt_Array.some(types, (function (t) {
                                                                                      return t === d.code_type_id;
                                                                                    }));
                                                                      } else {
                                                                        return false;
                                                                      }
                                                                    }));
                                                      })), false);
                                    })),
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetUci */6 :
        return action._0;
    case /* SetUciGroup */7 :
        var cssfCode = action._1;
        var uciName = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: uciName,
                              comp_name: state.comp_name,
                              cssf_code: cssfCode,
                              account_nb: state.account_nb,
                              uci_user_roles: state.uci_user_roles,
                              types: state.types,
                              doc_opts: state.doc_opts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetUserRole */8 :
        var oldCodeRoleId = action._2;
        var codeRoleId = action._1;
        var userId$1 = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: state.comp_name,
                              cssf_code: state.cssf_code,
                              account_nb: state.account_nb,
                              uci_user_roles: Belt_Array.map(state.uci_user_roles, (function (userRole) {
                                      var alreadyAdded = Belt_Array.some(state.uci_user_roles, (function (param) {
                                              if (param.user_id === userId$1) {
                                                return param.code_uci_role_id === codeRoleId;
                                              } else {
                                                return false;
                                              }
                                            }));
                                      if (userRole.user_id === userId$1 && userRole.code_uci_role_id === oldCodeRoleId && !alreadyAdded) {
                                        return {
                                                user_id: userRole.user_id,
                                                code_uci_role_id: codeRoleId
                                              };
                                      } else {
                                        return userRole;
                                      }
                                    })),
                              types: state.types,
                              doc_opts: state.doc_opts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: state.capital_ccy_id,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    case /* SetCCY */9 :
        var ccy = action._0;
        return Belt_Option.map(state, (function (state) {
                      return {
                              id: state.id,
                              uci_name: state.uci_name,
                              comp_name: state.comp_name,
                              cssf_code: state.cssf_code,
                              account_nb: state.account_nb,
                              uci_user_roles: state.uci_user_roles,
                              types: state.types,
                              doc_opts: state.doc_opts,
                              uci_group_id: state.uci_group_id,
                              capital_ccy_id: ccy,
                              cash_accounts: state.cash_accounts
                            };
                    }));
    
  }
}

var Reducer = {
  setData: setData,
  reducer: reducer
};

export {
  UciUserRole ,
  empty ,
  create ,
  update ,
  save ,
  Reducer ,
  
}
/* No side effect */
