// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as If$Ucidata from "../conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../Icon.bs.js";
import * as OptionMap$Ucidata from "../conditionals/OptionMap.bs.js";
import * as Validation$Ucidata from "../validation/Validation.bs.js";
import * as Validation_Errors$Ucidata from "../validation/Validation_Errors.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import ReactDatetimerangePicker from "@wojtekmaj/react-datetimerange-picker";

function Input_DateRange(Props) {
  var id = Props.id;
  var label = Props.label;
  var sizeOpt = Props.size;
  var validate = Props.validate;
  var validateDependency = Props.validateDependency;
  var showValidationErrorsOpt = Props.showValidationErrors;
  var amPmAriaLabel = Props.amPmAriaLabel;
  var autoFocus = Props.autoFocus;
  var calendarAriaLabel = Props.calendarAriaLabel;
  var calendarClassName = Props.calendarClassName;
  var className = Props.className;
  var clearAriaLabel = Props.clearAriaLabel;
  var clockClassName = Props.clockClassName;
  var closeWidgets = Props.closeWidgets;
  var dayAriaLabel = Props.dayAriaLabel;
  var disabled = Props.disabled;
  var disableCalendar = Props.disableCalendar;
  var disableClock = Props.disableClock;
  var format = Props.format;
  var hourAriaLabel = Props.hourAriaLabel;
  var isCalendarOpen = Props.isCalendarOpen;
  var isClockOpen = Props.isClockOpen;
  var locale = Props.locale;
  var maxDate = Props.maxDate;
  var maxDetail = Props.maxDetail;
  var minDate = Props.minDate;
  var minDetail = Props.minDetail;
  var minuteAriaLabel = Props.minuteAriaLabel;
  var monthAriaLabel = Props.monthAriaLabel;
  var name = Props.name;
  var nativeInputAriaLabel = Props.nativeInputAriaLabel;
  var onCalendarClose = Props.onCalendarClose;
  var onCalendarOpen = Props.onCalendarOpen;
  var onChange = Props.onChange;
  var onClockClose = Props.onClockClose;
  var onClockOpen = Props.onClockOpen;
  var required = Props.required;
  var secondAriaLabel = Props.secondAriaLabel;
  var showLeadingZeros = Props.showLeadingZeros;
  var value = Props.value;
  var yearAriaLabel = Props.yearAriaLabel;
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var showValidationErrors = showValidationErrorsOpt !== undefined ? showValidationErrorsOpt : true;
  var match = Validation$Ucidata.useValidation(id, value, validate, validateDependency, undefined);
  var errors = match.errors;
  var dirty = match.dirty;
  var isDisabled = Belt_Option.getWithDefault(disabled, false);
  var labelCn = Cn.$plus("block uppercase tracking-wide text-gray-500 text-xs font-medium absolute -mt-6 w-full", Cn.on("text-red-500", dirty && errors.length !== 0));
  var inputCn = Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("flex items-center justify-center appearance-none block w-full border-gray-300 border rounded leading-tight", Cn.on("text-gray-500", !isDisabled)), Cn.on("cursor-not-allowed bg-gray-100 text-gray-400", isDisabled)), Cn.on("border-red-500", dirty && errors.length !== 0)), size ? "h-11 px-4" : "h-8 px-2");
  var tmp = {
    calendarIcon: React.createElement(Icon$Ucidata.make, {
          icon: ProRegularSvgIcons.faCalendar,
          className: "hover:text-black"
        }),
    className: inputCn,
    clearIcon: React.createElement(Icon$Ucidata.make, {
          icon: ProSolidSvgIcons.faTimes,
          className: "hover:text-black"
        }),
    onChange: onChange,
    rangeDivider: "",
    value: value
  };
  if (amPmAriaLabel !== undefined) {
    tmp.amPmAriaLabel = amPmAriaLabel;
  }
  if (autoFocus !== undefined) {
    tmp.autoFocus = autoFocus;
  }
  if (calendarAriaLabel !== undefined) {
    tmp.calendarAriaLabel = calendarAriaLabel;
  }
  if (calendarClassName !== undefined) {
    tmp.calendarClassName = calendarClassName;
  }
  if (clearAriaLabel !== undefined) {
    tmp.clearAriaLabel = clearAriaLabel;
  }
  if (clockClassName !== undefined) {
    tmp.clockClassName = clockClassName;
  }
  if (closeWidgets !== undefined) {
    tmp.closeWidgets = closeWidgets;
  }
  if (dayAriaLabel !== undefined) {
    tmp.dayAriaLabel = dayAriaLabel;
  }
  if (disabled !== undefined) {
    tmp.disabled = disabled;
  }
  if (disableCalendar !== undefined) {
    tmp.disableCalendar = disableCalendar;
  }
  if (disableClock !== undefined) {
    tmp.disableClock = disableClock;
  }
  if (format !== undefined) {
    tmp.format = format;
  }
  if (hourAriaLabel !== undefined) {
    tmp.hourAriaLabel = hourAriaLabel;
  }
  if (isCalendarOpen !== undefined) {
    tmp.isCalendarOpen = isCalendarOpen;
  }
  if (isClockOpen !== undefined) {
    tmp.isClockOpen = isClockOpen;
  }
  if (locale !== undefined) {
    tmp.locale = locale;
  }
  if (maxDate !== undefined) {
    tmp.maxDate = Caml_option.valFromOption(maxDate);
  }
  if (maxDetail !== undefined) {
    tmp.maxDetail = maxDetail;
  }
  if (minDate !== undefined) {
    tmp.minDate = Caml_option.valFromOption(minDate);
  }
  if (minDetail !== undefined) {
    tmp.minDetail = minDetail;
  }
  if (minuteAriaLabel !== undefined) {
    tmp.minuteAriaLabel = minuteAriaLabel;
  }
  if (monthAriaLabel !== undefined) {
    tmp.monthAriaLabel = monthAriaLabel;
  }
  if (name !== undefined) {
    tmp.name = name;
  }
  if (nativeInputAriaLabel !== undefined) {
    tmp.nativeInputAriaLabel = nativeInputAriaLabel;
  }
  if (onCalendarClose !== undefined) {
    tmp.onCalendarClose = Caml_option.valFromOption(onCalendarClose);
  }
  if (onCalendarOpen !== undefined) {
    tmp.onCalendarOpen = Caml_option.valFromOption(onCalendarOpen);
  }
  if (onClockClose !== undefined) {
    tmp.onClockClose = Caml_option.valFromOption(onClockClose);
  }
  if (onClockOpen !== undefined) {
    tmp.onClockOpen = Caml_option.valFromOption(onClockOpen);
  }
  if (required !== undefined) {
    tmp.required = required;
  }
  if (secondAriaLabel !== undefined) {
    tmp.secondAriaLabel = secondAriaLabel;
  }
  if (showLeadingZeros !== undefined) {
    tmp.showLeadingZeros = showLeadingZeros;
  }
  if (yearAriaLabel !== undefined) {
    tmp.yearAriaLabel = yearAriaLabel;
  }
  return React.createElement("div", {
              className: Cn.$plus(Cn.$plus("relative", Cn.onSome("pt-6", label)), Cn.take(className)),
              id: id
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: label,
                  render: (function (label) {
                      return React.createElement("label", {
                                  className: labelCn,
                                  htmlFor: id
                                }, label);
                    })
                }), React.createElement(ReactDatetimerangePicker, tmp), React.createElement(If$Ucidata.make, {
                  condition: showValidationErrors,
                  children: React.createElement(Validation_Errors$Ucidata.make, {
                        errors: errors,
                        dirty: dirty
                      })
                }));
}

var make = Input_DateRange;

export {
  make ,
  
}
/* react Not a pure module */
