// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as If$Ucidata from "../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../core/loader/Loader.bs.js";
import * as Font_H1$Ucidata from "../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as Text_Span$Ucidata from "../../core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "../../core/conditionals/IfUserRole.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as Assets_Export$Ucidata from "./Assets_Export.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Assets_UciGroupsList$Ucidata from "./Assets_UciGroupsList.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Assets(Props) {
  var pageWrapperOpt = Props.pageWrapper;
  var showExportsOpt = Props.showExports;
  var pageWrapper = pageWrapperOpt !== undefined ? pageWrapperOpt : true;
  var showExports = showExportsOpt !== undefined ? showExportsOpt : true;
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_group");
  var uciGroupsState = match[0];
  var tmp;
  if (typeof uciGroupsState === "number") {
    tmp = undefined;
  } else {
    switch (uciGroupsState.TAG | 0) {
      case /* Loading */0 :
          tmp = undefined;
          break;
      case /* Error */1 :
          tmp = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: uciGroupsState._0
                  }));
          break;
      case /* Data */2 :
          tmp = Caml_option.some(React.createElement(Assets_UciGroupsList$Ucidata.make, {
                    uciGroups: uciGroupsState._0
                  }));
          break;
      
    }
  }
  var contents = React.createElement(React.Fragment, undefined, React.createElement(If$Ucidata.make, {
            condition: showExports,
            children: React.createElement(IfUserRole$Ucidata.make, {
                  roles: [
                    /* AccountAdmin */1,
                    /* HeadBackOffice */2,
                    /* BackOffice */3
                  ],
                  children: React.createElement(Section$Ucidata.make, {
                        children: React.createElement(Collapsible$Ucidata.make, {
                              contentClassName: "mt-4 p-4 space-y-8",
                              children: React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
                                        children: "Select one or more filters, pick the export format and download the data."
                                      }), React.createElement(Assets_Export$Ucidata.make, {
                                        uciGroups: Belt_Option.getWithDefault(Client$Ucidata.data(undefined, uciGroupsState), [])
                                      })),
                              render: (function (param) {
                                  return React.createElement(Font_Div$Ucidata.make, {
                                              style: /* Subtitle */2,
                                              children: React.createElement(Icon$Ucidata.make, {
                                                    text: "Export",
                                                    icon: ProLightSvgIcons.faFileExport,
                                                    className: "mx-2"
                                                  })
                                            });
                                })
                            })
                      })
                })
          }), React.createElement(Section$Ucidata.make, {
            title: React.createElement(Icon$Ucidata.make, {
                  text: "Uci List",
                  icon: ProLightSvgIcons.faList,
                  className: "mr-2"
                }),
            children: React.createElement("div", {
                  className: "space-y-8"
                }, React.createElement(Loader$Ucidata.make, {
                      className: "text-4xl",
                      children: tmp
                    }))
          }));
  if (pageWrapper) {
    return React.createElement(Layout$Ucidata.make, {
                style: /* Page */4,
                children: null
              }, React.createElement(Section$Ucidata.make, {
                    unstyled: true,
                    children: React.createElement(Font_H1$Ucidata.make, {
                          style: /* Title */1,
                          children: React.createElement(Icon$Ucidata.make, {
                                text: "Assets",
                                icon: ProRegularSvgIcons.faCoins,
                                className: "mr-2"
                              })
                        })
                  }), contents);
  } else {
    return contents;
  }
}

var make = React.memo(Assets);

var UciGroupsList;

var Export;

export {
  UciGroupsList ,
  Export ,
  make ,
  
}
/* make Not a pure module */
