// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

var ResponseChain = {};

var WretcherError = {};

function make(fn) {
  return fn;
}

var Middleware = {
  make: make
};

var Exn = {};

function $$then(promise, fn) {
  return promise.then(function (value) {
              return Promise.resolve(Curry._1(fn, value));
            });
}

function map(promise, fn) {
  return promise.then(Curry.__1(fn));
}

function $$catch(promise, fn) {
  return promise.catch(function (error) {
              return Promise.resolve(Curry._1(fn, error));
            });
}

function ignore(promise) {
  $$catch(promise, (function (param) {
          
        }));
  
}

export {
  ResponseChain ,
  WretcherError ,
  Middleware ,
  Exn ,
  $$then ,
  map ,
  $$catch ,
  ignore ,
  
}
/* No side effect */
