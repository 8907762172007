// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Render$Ucidata from "../../Render.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as Oversight_Helpers$Ucidata from "./Oversight_Helpers.bs.js";
import * as Oversight_Category$Ucidata from "./Oversight_Category.bs.js";

function Oversight_Categories(Props) {
  var uciOvs = Props.uciOvs;
  var codesOversight = Props.codesOversight;
  var codesOversightSubtype = Props.codesOversightSubtype;
  var codeOversightDoc = Props.codeOversightDoc;
  var codeDocOvsStatus = Props.codeDocOvsStatus;
  var oversightData = React.useMemo((function () {
          var data = Belt_Array.reduce(codesOversight, {}, (function (acc, elt) {
                  var config = Belt_Array.getBy(uciOvs.uci_ovs_configs, (function (conf) {
                          return conf.code_oversight_id === elt.id;
                        }));
                  if (Belt_Option.mapWithDefault(config, false, (function (config) {
                            return config.is_active;
                          }))) {
                    acc[String(elt.id)] = {
                      id: elt.id,
                      name: elt.name,
                      subtypes: []
                    };
                  }
                  return acc;
                }));
          var dataSubtypes = Belt_Array.reduce(codesOversightSubtype, {}, (function (acc, elt) {
                  var subtype_id = elt.id;
                  var subtype_name = elt.name;
                  var subtype_docs = [];
                  var subtype = {
                    id: subtype_id,
                    name: subtype_name,
                    docs: subtype_docs
                  };
                  if (subtype_id !== Oversight_Helpers$Ucidata.externalValuerSubtypeId || uciOvs.is_ext_valuer) {
                    acc[String(elt.id)] = subtype;
                    Belt_Option.forEach(Js_dict.get(data, String(elt.code_oversight_id)), (function (datum) {
                            datum.subtypes.push(subtype);
                            
                          }));
                  }
                  return acc;
                }));
          Belt_Array.forEach(codeOversightDoc, (function (doc) {
                  return Belt_Option.forEach(Js_dict.get(dataSubtypes, String(doc.code_oversight_subtype_id)), (function (subtype) {
                                subtype.docs.push(doc);
                                
                              }));
                }));
          return Js_dict.values(data);
        }), [
        codesOversight,
        codesOversightSubtype,
        codeOversightDoc
      ]);
  return React.createElement("div", {
              className: "space-y-4"
            }, Render$Ucidata.array(undefined, oversightData, (function (datum, param) {
                    return React.createElement("div", {
                                key: String(datum.id),
                                className: "space-y-4"
                              }, React.createElement(Font_Div$Ucidata.make, {
                                    style: /* Large */3,
                                    className: "bg-gray-600 text-gray-50 px-4 py-1 rounded",
                                    children: React.createElement(Text$Ucidata.make, {
                                          children: datum.name
                                        })
                                  }), React.createElement("div", {
                                    className: "ml-4"
                                  }, Render$Ucidata.array(undefined, datum.subtypes, (function (subtype, param) {
                                          return React.createElement("div", {
                                                      key: String(subtype.id),
                                                      className: "pb-2"
                                                    }, React.createElement(Collapsible$Ucidata.make, {
                                                          openedByDefault: true,
                                                          className: "bg-gray-500 text-gray-50 rounded py-1 mb-2 font-normal",
                                                          children: React.createElement(Oversight_Category$Ucidata.make, {
                                                                uciId: String(uciOvs.uci_id),
                                                                subtype: subtype,
                                                                codeDocOvsStatus: codeDocOvsStatus
                                                              }),
                                                          render: (function (param) {
                                                              return React.createElement(Text$Ucidata.make, {
                                                                          children: subtype.name
                                                                        });
                                                            })
                                                        }));
                                        }))));
                  })));
}

var make = React.memo(Oversight_Categories);

var Helpers;

var Category;

export {
  Helpers ,
  Category ,
  make ,
  
}
/* make Not a pure module */
