// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$Ucidata from "../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../Hooks.bs.js";
import * as Button$Ucidata from "../core/Button.bs.js";
import * as Client$Ucidata from "../../Client.bs.js";
import * as Footer$Ucidata from "../Footer.bs.js";
import * as Wretch$Ucidata from "../../libs/Wretch.bs.js";
import * as Font_H1$Ucidata from "../core/font/Font_H1.bs.js";
import * as Font_H2$Ucidata from "../core/font/Font_H2.bs.js";
import * as Input_Text$Ucidata from "../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../core/validation/Validation.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Input_Toggle$Ucidata from "../core/input/Input_Toggle.bs.js";
import * as Validation_Rules$Ucidata from "../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../core/validation/Validation_Context.bs.js";

function LoginPage(Props) {
  var fetchUser = Props.fetchUser;
  var match = React.useState(function () {
        return "";
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPassword = match$1[1];
  var password = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setRemember = match$2[1];
  var remember = match$2[0];
  var match$3 = Hooks$Ucidata.useErrorState(undefined);
  var clearError = match$3[2];
  var setError = match$3[1];
  var login = function (param) {
    Curry._1(clearError, undefined);
    var query = function (w) {
      if (remember) {
        return w.query({
                    set_cookie: true
                  });
      } else {
        return w.query({
                    set_session_cookie: true
                  });
      }
    };
    return Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(Wretch$Ucidata.$$then(query(Client$Ucidata.base.url("/sign_ins")).post({
                              email: email,
                              password: password
                            }).res(), (function (param) {
                          return Curry._1(fetchUser, undefined);
                        })), (function (param) {
                      return RescriptReactRouter.push("/");
                    })), setError);
  };
  var tmp = {};
  var tmp$1 = match$3[0];
  if (tmp$1 !== undefined) {
    tmp.error = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "min-h-screen flex flex-col items-center justify-center bg-gray-800"
                }, React.createElement("div", {
                      className: "sm:rounded-xl sm:max-w-md w-full space-y-8 bg-white p-12 h-screen sm:h-auto flex flex-col justify-center"
                    }, React.createElement("div", {
                          className: "text-center"
                        }, React.createElement(Font_H1$Ucidata.make, {
                              style: /* Hero */0,
                              children: React.createElement(Text$Ucidata.make, {
                                    children: "UciData"
                                  })
                            }), React.createElement(Font_H2$Ucidata.make, {
                              style: /* Subtitle */2,
                              children: React.createElement(Text$Ucidata.make, {
                                    children: "Please enter your credentials"
                                  })
                            })), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement("form", {
                          onSubmit: (function (e) {
                              e.preventDefault();
                              login(undefined);
                              
                            })
                        }, React.createElement(Validation$Ucidata.make, {
                              render: (function (validation) {
                                  var partial_arg = [Validation_Rules$Ucidata.$$String.email(undefined, undefined)];
                                  var partial_arg$1 = [Validation_Rules$Ucidata.$$String.required("Please enter your password.", undefined)];
                                  return React.createElement(React.Fragment, undefined, React.createElement(Input_Text$Ucidata.make, {
                                                  id: "email",
                                                  label: React.createElement(Text$Ucidata.make, {
                                                        children: "Email"
                                                      }),
                                                  value: email,
                                                  className: "mb-4",
                                                  validate: (function (param) {
                                                      return Validation_Rules$Ucidata.compose(partial_arg, param);
                                                    }),
                                                  onChange: (function (email) {
                                                      return Curry._1(setEmail, (function (param) {
                                                                    return email;
                                                                  }));
                                                    }),
                                                  type_: "email",
                                                  placeholder: "john@doe.com"
                                                }), React.createElement(Input_Text$Ucidata.make, {
                                                  id: "password",
                                                  label: React.createElement(Text$Ucidata.make, {
                                                        children: "Password"
                                                      }),
                                                  value: password,
                                                  className: "mb-4",
                                                  validate: (function (param) {
                                                      return Validation_Rules$Ucidata.compose(partial_arg$1, param);
                                                    }),
                                                  onChange: (function (pwd) {
                                                      return Curry._1(setPassword, (function (param) {
                                                                    return pwd;
                                                                  }));
                                                    }),
                                                  type_: "password",
                                                  placeholder: "*********"
                                                }), React.createElement("div", {
                                                  className: "mb-8"
                                                }, React.createElement("label", {
                                                      className: "inline-flex items-center cursor-pointer"
                                                    }, React.createElement(Input_Toggle$Ucidata.make, {
                                                          checked: remember,
                                                          setChecked: (function (checked) {
                                                              return Curry._1(setRemember, (function (param) {
                                                                            return checked;
                                                                          }));
                                                            })
                                                        }), React.createElement("span", {
                                                          className: "ml-4 text-sm text-gray-500"
                                                        }, React.createElement(Text$Ucidata.make, {
                                                              children: "Remember me"
                                                            })))), React.createElement(Button$Ucidata.make, {
                                                  style: /* Primary */0,
                                                  disabled: Validation_Context$Ucidata.invalid(validation),
                                                  className: "w-full",
                                                  children: React.createElement(Text$Ucidata.make, {
                                                        children: "Login"
                                                      })
                                                }));
                                })
                            })))), React.createElement(Footer$Ucidata.make, {
                  className: "bg-gray-700 text-white",
                  logoClassName: "bg-gray-200 rounded p-1"
                }));
}

var make = LoginPage;

export {
  make ,
  
}
/* react Not a pure module */
