// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as IfOvs$Ucidata from "../../core/conditionals/IfOvs.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Wretch$Ucidata from "../../../libs/Wretch.bs.js";
import * as ArrayMap$Ucidata from "../../core/conditionals/ArrayMap.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as UciGroup$Ucidata from "../../../models/UciGroup.bs.js";
import * as Text_Span$Ucidata from "../../core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "../../core/conditionals/IfUserRole.bs.js";
import * as Input_Text$Ucidata from "../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../core/validation/Validation.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as UciGroupOvs$Ucidata from "../../../models/UciGroupOvs.bs.js";
import * as Validation_Rules$Ucidata from "../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../core/validation/Validation_Context.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Config_UciGroupElement(Props) {
  var uciGroup = Props.uciGroup;
  var setUciGroup = Props.setUciGroup;
  var forceOpenOpt = Props.forceOpen;
  var forceOpen = forceOpenOpt !== undefined ? forceOpenOpt : false;
  var client = Client$Ucidata.useClient(undefined);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setHover = match[1];
  var hover = match[0];
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearError = match$1[2];
  var setError = match$1[1];
  var error = match$1[0];
  var match$2 = React.useState(function () {
        return UciGroup$Ucidata.empty;
      });
  var setEditableGroup = match$2[1];
  var editableGroup = match$2[0];
  var match$3 = React.useState(function () {
        return UciGroupOvs$Ucidata.empty;
      });
  var setEditableGroupOvs = match$3[1];
  var editableGroupOvs = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setEditing = match$4[1];
  var uciGroupKey = String(Belt_Option.getExn(uciGroup.id));
  var updateUciGroup = function (param) {
    return Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(client.url("/uci_group/" + uciGroupKey).put(editableGroup).json(function (updatedGroup) {
                        Curry._1(setUciGroup, updatedGroup);
                        return client.url("/uci_group_ovs/" + uciGroupKey).put(editableGroupOvs).json(function (updatedGroupOvs) {
                                    return Curry._1(setUciGroup, {
                                                id: updatedGroup.id,
                                                name: updatedGroup.name,
                                                cssf_code: updatedGroup.cssf_code,
                                                aifm: updatedGroupOvs.aifm,
                                                central_admin: updatedGroupOvs.central_admin,
                                                ucis: updatedGroup.ucis
                                              });
                                  });
                      }), (function (param) {
                      Curry._1(setEditing, (function (param) {
                              return false;
                            }));
                      return Curry._1(clearError, undefined);
                    })), setError);
  };
  var collapsibleBarRef = Hooks$Ucidata.Events.useHoverListener(function (hovered) {
        return Curry._1(setHover, (function (param) {
                      return hovered;
                    }));
      });
  var stopClickPropagation = function (e) {
    e.stopPropagation();
    
  };
  var collapsibleDetails = match$4[0] ? React.createElement(Validation$Ucidata.make, {
          render: (function (validation) {
              var tmp = {
                mode: /* Light */0,
                className: "flex-none w-full mt-4"
              };
              if (error !== undefined) {
                tmp.error = Caml_option.valFromOption(error);
              }
              return React.createElement("div", {
                          className: "flex flex-wrap w-full py-2",
                          onClick: stopClickPropagation
                        }, React.createElement("div", {
                              className: "flex flex-auto items-center space-x-1"
                            }, React.createElement(Input_Text$Ucidata.make, {
                                  id: "ucigroup-" + uciGroupKey + "-name",
                                  value: editableGroup.name,
                                  className: "min-w-200px",
                                  size: /* Small */0,
                                  validate: Validation_Rules$Ucidata.$$String.required("Name field is mandatory.", undefined),
                                  showValidationErrors: false,
                                  onChange: (function (name) {
                                      return Curry._1(setEditableGroup, (function (group) {
                                                    return {
                                                            id: group.id,
                                                            name: name,
                                                            cssf_code: group.cssf_code,
                                                            aifm: group.aifm,
                                                            central_admin: group.central_admin,
                                                            ucis: group.ucis
                                                          };
                                                  }));
                                    })
                                }), React.createElement(Input_Text$Ucidata.make, {
                                  id: "ucigroup-" + uciGroupKey + "-cssf-code",
                                  value: editableGroup.cssf_code,
                                  className: "min-w-200px w-1/4",
                                  size: /* Small */0,
                                  validate: Validation_Rules$Ucidata.$$String.required("Cssf Code field is mandatory.", undefined),
                                  showValidationErrors: false,
                                  onChange: (function (name) {
                                      return Curry._1(setEditableGroup, (function (group) {
                                                    return {
                                                            id: group.id,
                                                            name: group.name,
                                                            cssf_code: name,
                                                            aifm: group.aifm,
                                                            central_admin: group.central_admin,
                                                            ucis: group.ucis
                                                          };
                                                  }));
                                    })
                                }), React.createElement(IfOvs$Ucidata.make, {
                                  children: (function (param) {
                                      return React.createElement(React.Fragment, undefined, React.createElement(Input_Text$Ucidata.make, {
                                                      id: "ucigroup-" + uciGroupKey + "-aifm",
                                                      value: editableGroupOvs.aifm,
                                                      className: "min-w-200px w-1/4",
                                                      size: /* Small */0,
                                                      validate: Validation_Rules$Ucidata.$$String.required("AIFM field is mandatory.", undefined),
                                                      showValidationErrors: false,
                                                      onChange: (function (aifm) {
                                                          return Curry._1(setEditableGroupOvs, (function (group) {
                                                                        return {
                                                                                aifm: aifm,
                                                                                central_admin: group.central_admin,
                                                                                uci_group_id: group.uci_group_id
                                                                              };
                                                                      }));
                                                        })
                                                    }), React.createElement(Input_Text$Ucidata.make, {
                                                      id: "ucigroup-" + uciGroupKey + "-central-admin",
                                                      value: editableGroupOvs.central_admin,
                                                      className: "min-w-200px w-1/4",
                                                      size: /* Small */0,
                                                      validate: Validation_Rules$Ucidata.$$String.required("Central Admin field is mandatory.", undefined),
                                                      showValidationErrors: false,
                                                      onChange: (function (centralAdmin) {
                                                          return Curry._1(setEditableGroupOvs, (function (group) {
                                                                        return {
                                                                                aifm: group.aifm,
                                                                                central_admin: centralAdmin,
                                                                                uci_group_id: group.uci_group_id
                                                                              };
                                                                      }));
                                                        })
                                                    }));
                                    })
                                }), React.createElement("div", {
                                  className: "flex items-center space-x-2"
                                }, React.createElement(Button$Ucidata.make, {
                                      style: /* Primary */0,
                                      size: /* Slim */3,
                                      disabled: Validation_Context$Ucidata.invalid(validation),
                                      className: "text-xs font-light",
                                      onClick: (function (param) {
                                          updateUciGroup(undefined);
                                          
                                        }),
                                      children: React.createElement(Icon$Ucidata.make, {
                                            text: "Save",
                                            icon: ProSolidSvgIcons.faCheck
                                          })
                                    }), React.createElement(Button$Ucidata.make, {
                                      style: /* Secondary */2,
                                      size: /* Slim */3,
                                      className: "text-xs font-light",
                                      onClick: (function ($$event) {
                                          return Curry._1(setEditing, (function (param) {
                                                        return false;
                                                      }));
                                        }),
                                      children: React.createElement(Icon$Ucidata.make, {
                                            text: "Cancel",
                                            icon: ProSolidSvgIcons.faTimes
                                          })
                                    }))), React.createElement(DisplayServerError$Ucidata.make, tmp));
            })
        }) : React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
              className: "min-w-200px font-medium p-2",
              children: uciGroup.name
            }), React.createElement(Text_Span$Ucidata.make, {
              className: "min-w-200px w-1/4 text-gray-600",
              children: uciGroup.cssf_code
            }), React.createElement(IfOvs$Ucidata.make, {
              children: (function (param) {
                  return React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
                                  className: "w-1/4 text-gray-600",
                                  children: Belt_Option.getWithDefault(uciGroup.aifm, "")
                                }), React.createElement(Text_Span$Ucidata.make, {
                                  className: "w-1/4 text-gray-600",
                                  children: Belt_Option.getWithDefault(uciGroup.central_admin, "")
                                }));
                })
            }), React.createElement(IfUserRole$Ucidata.make, {
              role: /* AccountAdmin */1,
              children: React.createElement(Button$Ucidata.make, {
                    style: /* Secondary */2,
                    size: /* Slim */3,
                    className: Cn.$plus(Cn.$plus("text-xs font-light", Cn.on("block", hover)), Cn.on("hidden", !hover)),
                    onClick: (function ($$event) {
                        $$event.stopPropagation();
                        Curry._1(setEditableGroup, (function (param) {
                                return uciGroup;
                              }));
                        Curry._1(setEditableGroupOvs, (function (param) {
                                return {
                                        aifm: Belt_Option.getWithDefault(uciGroup.aifm, ""),
                                        central_admin: Belt_Option.getWithDefault(uciGroup.central_admin, ""),
                                        uci_group_id: uciGroup.id
                                      };
                              }));
                        Curry._1(setEditing, (function (param) {
                                return true;
                              }));
                        return Curry._1(clearError, undefined);
                      }),
                    children: React.createElement(Icon$Ucidata.make, {
                          text: "Edit",
                          icon: ProSolidSvgIcons.faPencil
                        })
                  })
            }));
  return React.createElement(Collapsible$Ucidata.make, {
              openedByDefault: url.hash === uciGroupKey,
              forceOpen: forceOpen,
              className: "p-1 px-2",
              onChange: (function (opened) {
                  if (opened) {
                    return RescriptReactRouter.replace("#" + uciGroupKey);
                  }
                  
                }),
              disabled: forceOpen || uciGroup.ucis.length === 0,
              children: React.createElement("div", {
                    className: "ml-14"
                  }, React.createElement("div", {
                        className: "pb-4 border-b border-dashed"
                      }, React.createElement(Font_Div$Ucidata.make, {
                            style: /* Italic */4,
                            children: React.createElement(Text$Ucidata.make, {
                                  children: String(uciGroup.ucis.length) + " compartiment(s)"
                                })
                          })), React.createElement("div", {
                        className: "divide-y divide-dashed"
                      }, React.createElement(ArrayMap$Ucidata.make, {
                            array: uciGroup.ucis,
                            render: (function (uci) {
                                var uciKey = String(uci.uci_id);
                                return React.createElement("div", {
                                            key: uciKey,
                                            className: "flex items-center space-x-2 py-4"
                                          }, React.createElement("div", {
                                                className: "font-normal"
                                              }, React.createElement(Link$Ucidata.make, {
                                                    href: "/config/compartiment/" + uciKey,
                                                    children: React.createElement(Text$Ucidata.make, {
                                                          children: uci.comp_name
                                                        })
                                                  })));
                              })
                          }))),
              render: (function (param) {
                  return React.createElement("div", {
                              ref: collapsibleBarRef,
                              className: "flex items-baseline flex-auto"
                            }, collapsibleDetails);
                })
            });
}

var make = React.memo(Config_UciGroupElement);

export {
  make ,
  
}
/* make Not a pure module */
