// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hyperactiv$Ucidata from "../libs/Hyperactiv.bs.js";
import * as React from "hyperactiv/src/react";

var M = Hyperactiv$Ucidata.Make({});

var include = M.React;

var $$default = {
  uploads: {
    data: {},
    working: false
  }
};

function make(param) {
  return React.store($$default, {
              ignore: (function (_key, _value) {
                  return (_value instanceof File);
                })
            });
}

var observe = M.observe;

var computed = M.computed;

var dispose = M.dispose;

var React$1 = M.React;

var Provider = include.Provider;

var Watch = include.Watch;

export {
  M ,
  observe ,
  computed ,
  dispose ,
  React$1 as React,
  Provider ,
  Watch ,
  $$default ,
  $$default as default,
  make ,
  
}
/* M Not a pure module */
