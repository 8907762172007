// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function ArrayMapi(Props) {
  var render = Props.render;
  var array = Props.array;
  return array.map(Curry.__2(render));
}

var make = ArrayMapi;

export {
  make ,
  
}
/* No side effect */
