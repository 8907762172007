// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as UserContext$Ucidata from "../../../UserContext.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as CompartimentTransactions_History_Columns$Ucidata from "./CompartimentTransactions_History_Columns.bs.js";

function CompartimentTransactions_History(Props) {
  var item = Props.item;
  var uciId = Props.uciId;
  var onClose = Props.onClose;
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci_item_hist?p_uci_item_id=" + String(item.id));
  var uciUciItemHistState = match$1[0];
  var user = UserContext$Ucidata.useUser(undefined);
  var codes = Codes$Ucidata.useCodes(undefined);
  var match$2 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci/" + uciId);
  var uci = Client$Ucidata.data(undefined, match$2[0]);
  var columns = React.useMemo((function () {
          return Belt_Option.map(codes, (function (codes) {
                        return CompartimentTransactions_History_Columns$Ucidata.make(codes, uci, user);
                      }));
        }), [
        codes,
        uci,
        user
      ]);
  var uploadedUciItemHistsRender;
  var exit = 0;
  if (typeof uciUciItemHistState === "number") {
    exit = 1;
  } else {
    switch (uciUciItemHistState.TAG | 0) {
      case /* Loading */0 :
          uploadedUciItemHistsRender = React.createElement("div", {
                className: "w-20 text-xl p-4"
              }, React.createElement(Loader$Ucidata.make, {
                    children: undefined
                  }));
          break;
      case /* Error */1 :
          uploadedUciItemHistsRender = React.createElement("div", {
                className: "p-4"
              }, React.createElement(DisplayServerError$Ucidata.make, {
                    error: uciUciItemHistState._0,
                    mode: /* Light */0
                  }));
          break;
      case /* Data */2 :
          var items = uciUciItemHistState._0;
          if (items !== undefined && items.length !== 0) {
            uploadedUciItemHistsRender = React.createElement(OptionMap$Ucidata.make, {
                  option: columns,
                  render: (function (columns) {
                      return React.createElement(CompartimentTransactions_History_Columns$Ucidata.Table.make, {
                                  columns: columns,
                                  data: items,
                                  dataKey: (function (item) {
                                      return String(item.id);
                                    }),
                                  className: "table-auto whitespace-nowrap border-separate rounded border text-xs text-center",
                                  overflowContainerClassName: "w-full",
                                  tableExtraClass: "rounded-l-none w-full",
                                  fixedTableExtraClass: "border-r-8 rounded-r-none",
                                  containerClassName: "border-r rounded border-dashed",
                                  thClassName: "py-4 px-2 font-medium",
                                  tdClassName: "py-4 px-2",
                                  headTrClassName: "bg-gray-100 uppercase",
                                  bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
                                  paginationLimit: 20
                                });
                    })
                });
          } else {
            exit = 1;
          }
          break;
      
    }
  }
  if (exit === 1) {
    uploadedUciItemHistsRender = React.createElement(Font_Div$Ucidata.make, {
          style: /* Italic */4,
          className: "p-4 text-sm",
          children: React.createElement(Text$Ucidata.make, {
                children: "No history found"
              })
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  className: "mb-4",
                  children: React.createElement(Layout$Ucidata.make, {
                        style: /* FlexBetween */3,
                        children: null
                      }, React.createElement(Font_Div$Ucidata.make, {
                            style: /* Title */1,
                            children: React.createElement(Icon$Ucidata.make, {
                                  text: item.name,
                                  icon: ProSolidSvgIcons.faHistory,
                                  className: "mr-2"
                                })
                          }), React.createElement("div", {
                            className: "flex items-center justify-center space-x-4"
                          }, React.createElement(OptionMap$Ucidata.make, {
                                option: onClose,
                                render: (function (onClose) {
                                    return React.createElement(Button$Ucidata.make, {
                                                style: /* Regular */3,
                                                onClick: (function (param) {
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                children: React.createElement(Text$Ucidata.make, {
                                                      children: "Close"
                                                    })
                                              });
                                  })
                              })))
                }), uploadedUciItemHistsRender);
}

var make = React.memo(CompartimentTransactions_History);

var Columns;

export {
  Columns ,
  make ,
  
}
/* make Not a pure module */
