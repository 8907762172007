// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as ArrayMap$Ucidata from "../../core/conditionals/ArrayMap.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as Text_Span$Ucidata from "../../core/text/Text_Span.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Input_Search$Ucidata from "../../core/input/Input_Search.bs.js";

function Assets_UciGroupsList(Props) {
  var uciGroups = Props.uciGroups;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        return "";
      });
  var setSearch = match[1];
  var search = match[0];
  var searching = search.length !== 0;
  var uciGroups$1 = React.useMemo((function () {
          if (searching) {
            return Belt_Array.keep(uciGroups, (function (elt) {
                          if (elt.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                            return true;
                          } else {
                            return Belt_Array.some(elt.ucis, (function (uci) {
                                          return uci.comp_name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
                                        }));
                          }
                        }));
          } else {
            return uciGroups;
          }
        }), [
        uciGroups,
        search
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(Input_Search$Ucidata.make, {
                      id: "search-name",
                      value: search,
                      onChange: (function (v) {
                          return Curry._1(setSearch, (function (param) {
                                        return v;
                                      }));
                        }),
                      placeholder: "Uci or Compartiment name"
                    })), React.createElement("div", {
                  className: "border rounded overflow-x-auto bg-white text-xs border-b"
                }, React.createElement("div", {
                      className: "divide-y divide-gray-300"
                    }, React.createElement(Collapsible$Ucidata.make, {
                          className: "p-2 font-semibold bg-gray-100 uppercase",
                          disabled: true,
                          render: (function (param) {
                              return React.createElement("div", {
                                          className: "flex items-baseline flex-auto"
                                        }, React.createElement(Text_Span$Ucidata.make, {
                                              className: "min-w-200px p-2",
                                              children: "Name"
                                            }), React.createElement(Text_Span$Ucidata.make, {
                                              children: "Cssf Code"
                                            }));
                            })
                        }), React.createElement(ArrayMap$Ucidata.make, {
                          array: uciGroups$1,
                          render: (function (uciGroup) {
                              var uciGroupKey = String(Belt_Option.getExn(uciGroup.id));
                              return React.createElement(Collapsible$Ucidata.make, {
                                          openedByDefault: url.hash === uciGroupKey,
                                          forceOpen: searching,
                                          className: "p-1 px-2",
                                          onChange: (function (opened) {
                                              if (opened) {
                                                return RescriptReactRouter.replace("#" + uciGroupKey);
                                              }
                                              
                                            }),
                                          disabled: searching || uciGroup.ucis.length === 0,
                                          children: React.createElement("div", {
                                                className: "ml-14"
                                              }, React.createElement("div", {
                                                    className: "pb-4 border-b border-dashed"
                                                  }, React.createElement(Font_Div$Ucidata.make, {
                                                        style: /* Italic */4,
                                                        children: React.createElement(Text$Ucidata.make, {
                                                              children: String(uciGroup.ucis.length) + " compartiment(s)"
                                                            })
                                                      })), React.createElement("div", {
                                                    className: "divide-y divide-dashed"
                                                  }, React.createElement(ArrayMap$Ucidata.make, {
                                                        array: uciGroup.ucis,
                                                        render: (function (uci) {
                                                            var uciKey = String(uci.uci_id);
                                                            return React.createElement("div", {
                                                                        key: uciKey,
                                                                        className: "flex items-center space-x-2 py-4"
                                                                      }, React.createElement("div", {
                                                                            className: "font-normal"
                                                                          }, React.createElement(Link$Ucidata.make, {
                                                                                href: "/assets/compartiment/" + uciKey + "/transactions?" + url.search,
                                                                                children: React.createElement(Text$Ucidata.make, {
                                                                                      children: uci.comp_name
                                                                                    })
                                                                              })));
                                                          })
                                                      }))),
                                          render: (function (param) {
                                              return React.createElement("div", {
                                                          className: "flex items-baseline flex-auto",
                                                          id: uciGroupKey
                                                        }, React.createElement(Text_Span$Ucidata.make, {
                                                              className: "min-w-200px font-medium p-2",
                                                              children: uciGroup.name
                                                            }), React.createElement(Text_Span$Ucidata.make, {
                                                              className: "flex-auto text-gray-600",
                                                              children: uciGroup.cssf_code
                                                            }));
                                            }),
                                          key: uciGroupKey
                                        });
                            })
                        }))));
}

var make = React.memo(Assets_UciGroupsList);

export {
  make ,
  
}
/* make Not a pure module */
