// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils$Ucidata from "../../Utils.bs.js";
import * as Island$Ucidata from "./Island.bs.js";
import * as OptionMap$Ucidata from "./conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "./text/Text_Span.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function DisplayServerError(Props) {
  var error = Props.error;
  var modeOpt = Props.mode;
  var className = Props.className;
  var mode = modeOpt !== undefined ? modeOpt : /* Regular */1;
  return React.createElement(OptionMap$Ucidata.make, {
              option: error,
              render: (function (error) {
                  var title = mode === /* Light */0 ? undefined : "Error(s)";
                  var icon = Caml_option.some(ProSolidSvgIcons.faBomb);
                  var tmp = {
                    style: /* Error */2,
                    children: React.createElement(Text_Span$Ucidata.make, {
                          children: Utils$Ucidata.getErrorMessage(error)
                        })
                  };
                  if (className !== undefined) {
                    tmp.className = Caml_option.valFromOption(className);
                  }
                  if (title !== undefined) {
                    tmp.title = Caml_option.valFromOption(title);
                  }
                  if (icon !== undefined) {
                    tmp.icon = Caml_option.valFromOption(icon);
                  }
                  return React.createElement(Island$Ucidata.make, tmp);
                })
            });
}

var make = DisplayServerError;

export {
  make ,
  
}
/* react Not a pure module */
