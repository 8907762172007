// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Layout$Ucidata from "../Layout.bs.js";
import * as Loader$Ucidata from "./Loader.bs.js";

function PageLoader(Props) {
  var className = Props.className;
  var iconClassName = Props.iconClassName;
  var childrenOpt = Props.children;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : undefined;
  var tmp = {
    className: Cn.$plus("text-6xl p-10", Cn.take(className)),
    children: children
  };
  if (iconClassName !== undefined) {
    tmp.iconClassName = Caml_option.valFromOption(iconClassName);
  }
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: React.createElement(Loader$Ucidata.make, tmp)
            });
}

var make = React.memo(PageLoader);

export {
  make ,
  
}
/* make Not a pure module */
