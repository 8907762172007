// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Input_Text$Ucidata from "./Input_Text.bs.js";

function adapter(fn) {
  return Belt_Option.map(fn, (function (fn, value) {
                var value$1 = value.replace(/\s/g, "");
                return Curry._1(fn, Belt_Option.getWithDefault(Belt_Int.fromString(value$1), 0));
              }));
}

function Input_Number(Props) {
  var id = Props.id;
  var value = Props.value;
  var label = Props.label;
  var className = Props.className;
  var size = Props.size;
  var validate = Props.validate;
  var validateDependency = Props.validateDependency;
  var showValidationErrors = Props.showValidationErrors;
  var onChange = Props.onChange;
  var autoComplete = Props.autoComplete;
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var min = Props.min;
  var max = Props.max;
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  var value$1 = String(value);
  var value$2 = match[0] ? value$1 : value$1.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  var tmp = {
    id: id,
    value: value$2,
    pattern: "[0-9 ]+",
    onFocus: (function (param) {
        return Curry._1(setFocused, (function (param) {
                      return true;
                    }));
      }),
    onBlur: (function (param) {
        return Curry._1(setFocused, (function (param) {
                      return false;
                    }));
      })
  };
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  var tmp$1 = adapter(validate);
  if (tmp$1 !== undefined) {
    tmp.validate = Caml_option.valFromOption(tmp$1);
  }
  if (validateDependency !== undefined) {
    tmp.validateDependency = Caml_option.valFromOption(validateDependency);
  }
  if (showValidationErrors !== undefined) {
    tmp.showValidationErrors = Caml_option.valFromOption(showValidationErrors);
  }
  var tmp$2 = adapter(onChange);
  if (tmp$2 !== undefined) {
    tmp.onChange = Caml_option.valFromOption(tmp$2);
  }
  if (autoComplete !== undefined) {
    tmp.autoComplete = Caml_option.valFromOption(autoComplete);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (min !== undefined) {
    tmp.min = Caml_option.valFromOption(min);
  }
  if (max !== undefined) {
    tmp.max = Caml_option.valFromOption(max);
  }
  return React.createElement(Input_Text$Ucidata.make, tmp);
}

var make = React.memo(Input_Number);

export {
  adapter ,
  make ,
  
}
/* make Not a pure module */
