// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as If$Ucidata from "../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Assets$Ucidata from "../assets/Assets.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as IfUserRole$Ucidata from "../../core/conditionals/IfUserRole.bs.js";
import * as PageLoader$Ucidata from "../../core/loader/PageLoader.bs.js";
import * as UserContext$Ucidata from "../../UserContext.bs.js";
import * as Home_Controller$Ucidata from "./controller/Home_Controller.bs.js";
import * as Home_BackOffice_Loader$Ucidata from "./backoffice/Home_BackOffice_Loader.bs.js";

function showSection(user, roles) {
  return Belt_Option.mapWithDefault(user, false, (function (user) {
                return IfUserRole$Ucidata.check(user, roles);
              }));
}

function Home(Props) {
  var user = UserContext$Ucidata.useUser(undefined);
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: null
            }, React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: React.createElement(Font_Div$Ucidata.make, {
                        style: /* Title */1,
                        className: "mb-2",
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Dashboard"
                            })
                      })
                }), React.createElement(If$Ucidata.make, {
                  condition: showSection(user, [
                        /* AccountAdmin */1,
                        /* HeadBackOffice */2,
                        /* BackOffice */3
                      ]),
                  children: React.createElement(Home_BackOffice_Loader$Ucidata.make, {
                        loading: React.createElement(PageLoader$Ucidata.make, {}),
                        children: (function (M) {
                            return React.createElement(M.make, {});
                          })
                      })
                }), React.createElement(If$Ucidata.make, {
                  condition: showSection(user, [/* Controller */5]),
                  children: React.createElement(Home_Controller$Ucidata.make, {})
                }), React.createElement(If$Ucidata.make, {
                  condition: showSection(user, [/* Manager */4]),
                  children: React.createElement(Assets$Ucidata.make, {
                        pageWrapper: false,
                        showExports: false
                      })
                }));
}

var make = React.memo(Home);

export {
  showSection ,
  make ,
  
}
/* make Not a pure module */
