// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Modal$Ucidata from "../../../core/modals/Modal.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Font_H1$Ucidata from "../../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as PopupMenu$Ucidata from "../../../core/PopupMenu.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "../../../core/conditionals/IfUserRole.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as Assets_UciGroupsList$Ucidata from "../Assets_UciGroupsList.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import * as CompartimentPositions_Columns$Ucidata from "./CompartimentPositions_Columns.bs.js";
import * as CompartimentPositions_Operation$Ucidata from "./CompartimentPositions_Operation.bs.js";

function CompartimentPositions(Props) {
  var uciId = Props.uciId;
  Client$Ucidata.useClient(undefined);
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci/" + uciId);
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_group");
  var match$2 = Hooks$Ucidata.useFixedReference(undefined);
  var fetchPositions = match$2[1];
  var match$3 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match$2[0]], undefined, "/uci/" + uciId + "/portfolio");
  var positionsState = match$3[0];
  var codes = Codes$Ucidata.useCodes(undefined);
  var uci = Client$Ucidata.data(undefined, match[0]);
  var uciGroups = Client$Ucidata.data(undefined, match$1[0]);
  var match$4 = React.useState(function () {
        return false;
      });
  var setShowOperationModal = match$4[1];
  var showOperationModal = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setSelectedPositionId = match$5[1];
  var selectedPositionId = match$5[0];
  var columns = React.useMemo((function () {
          return Belt_Option.map(codes, (function (codes) {
                        return CompartimentPositions_Columns$Ucidata.make(codes, uci, {
                                    onShowTransactions: (function (position) {
                                        return RescriptReactRouter.push("/assets/compartiment/" + String(position.uci_id) + "/transactions?positionId=" + String(position.id));
                                      }),
                                    onPerformOperation: (function (position) {
                                        Curry._1(setSelectedPositionId, (function (param) {
                                                return position.id;
                                              }));
                                        return Curry._1(setShowOperationModal, (function (param) {
                                                      return true;
                                                    }));
                                      }),
                                    onShowHoldingStructure: (function (position) {
                                        return RescriptReactRouter.push("/assets/compartiment/" + String(position.uci_id) + "/" + String(position.id) + "/holding");
                                      })
                                  });
                      }));
        }), [
        codes,
        uci
      ]);
  var innerContent;
  var exit = 0;
  var positions;
  var columns$1;
  if (typeof positionsState === "number") {
    innerContent = Caml_option.some(React.createElement(Text$Ucidata.make, {
              children: "No positions."
            }));
  } else {
    switch (positionsState.TAG | 0) {
      case /* Loading */0 :
          var positions$1 = positionsState._0;
          if (positions$1 !== undefined && columns !== undefined) {
            positions = positions$1;
            columns$1 = columns;
            exit = 1;
          } else {
            innerContent = undefined;
          }
          break;
      case /* Error */1 :
          innerContent = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: positionsState._0
                  }));
          break;
      case /* Data */2 :
          if (columns !== undefined) {
            positions = positionsState._0;
            columns$1 = columns;
            exit = 1;
          } else {
            innerContent = undefined;
          }
          break;
      
    }
  }
  if (exit === 1) {
    innerContent = Caml_option.some(React.createElement(CompartimentPositions_Columns$Ucidata.Table.make, {
              columns: columns$1,
              data: positions,
              dataKey: (function (position) {
                  return String(position.id);
                }),
              className: "table-auto lg:table-fixed whitespace-nowrap border-separate rounded border text-xs text-center",
              overflowContainerClassName: "w-full",
              tableExtraClass: "w-full rounded-l-none",
              fixedTableExtraClass: "border-r-8 rounded-r-none",
              containerClassName: "border-r rounded border-dashed",
              thClassName: "py-4 px-2 font-medium",
              tdClassName: "py-4 px-2",
              headTrClassName: "bg-gray-100 uppercase",
              bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
              paginationLimit: 20
            }));
  }
  var match$6 = Client$Ucidata.data(undefined, positionsState);
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: null
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: selectedPositionId !== undefined && match$6 !== undefined ? Belt_Array.getBy(match$6, (function (p) {
                            return p.id === selectedPositionId;
                          })) : undefined,
                  render: (function (position) {
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showOperationModal,
                                  setOpen: setShowOperationModal,
                                  closeOnOutsideClick: true,
                                  closeOnEsc: true,
                                  size: /* Large */1,
                                  children: React.createElement(CompartimentPositions_Operation$Ucidata.make, {
                                        position: position,
                                        onClose: (function (param) {
                                            return Curry._1(setShowOperationModal, (function (param) {
                                                          return false;
                                                        }));
                                          }),
                                        onOperation: (function (param) {
                                            Curry._1(setShowOperationModal, (function (param) {
                                                    return false;
                                                  }));
                                            return Curry._1(fetchPositions, undefined);
                                          })
                                      })
                                });
                    })
                }), React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: null
                }, React.createElement(Font_H1$Ucidata.make, {
                      style: /* Title */1,
                      children: null
                    }, React.createElement(Icon$Ucidata.make, {
                          icon: ProRegularSvgIcons.faBalanceScale,
                          className: "mr-4"
                        }), React.createElement(Text_Span$Ucidata.make, {
                          className: "mr-4",
                          children: "Positions"
                        }), React.createElement(Font_Div$Ucidata.make, {
                          style: /* Subtitle */2,
                          className: "flex-auto",
                          children: React.createElement(OptionMap$Ucidata.make, {
                                option: uci,
                                render: (function (uci) {
                                    return React.createElement(IfUserRole$Ucidata.make, {
                                                roles: [
                                                  /* BackOffice */3,
                                                  /* AccountAdmin */1
                                                ],
                                                renderElse: (function (param) {
                                                    return React.createElement(Text$Ucidata.make, {
                                                                children: uci.comp_name
                                                              });
                                                  }),
                                                children: React.createElement(Link$Ucidata.make, {
                                                      href: "/config/compartiment/" + String(Belt_Option.getWithDefault(uci.id, 0)),
                                                      children: React.createElement(Text$Ucidata.make, {
                                                            children: uci.comp_name
                                                          })
                                                    })
                                              });
                                  })
                              })
                        }), React.createElement(OptionMap$Ucidata.make, {
                          option: uciGroups,
                          render: (function (uciGroups) {
                              return React.createElement(PopupMenu$Ucidata.make, {
                                          render: (function (param) {
                                              return React.createElement(Section$Ucidata.make, {
                                                          className: "text-base space-y-4 max-h-3/4 overflow-y-scroll",
                                                          children: React.createElement(Assets_UciGroupsList$Ucidata.make, {
                                                                uciGroups: uciGroups
                                                              })
                                                        });
                                            }),
                                          children: React.createElement(Button$Ucidata.make, {
                                                style: /* Unstyled */4,
                                                children: React.createElement(Icon$Ucidata.make, {
                                                      icon: ProRegularSvgIcons.faListAlt
                                                    })
                                              })
                                        });
                            })
                        })), React.createElement("div", {
                      className: "space-y-2 italic text-sm font-normal"
                    }, React.createElement("div", undefined, React.createElement(Link$Ucidata.make, {
                              href: "/assets/compartiment/" + uciId + "/transactions",
                              children: React.createElement(Icon$Ucidata.make, {
                                    containerClassName: "flex items-center",
                                    text: "Compartiment Transactions",
                                    icon: ProRegularSvgIcons.faLink,
                                    fixedWidth: true
                                  })
                            })))), React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Layout$Ucidata.make, {
                        style: /* FlexBetween */3,
                        children: null
                      }, React.createElement("div", {
                            className: "text-center md:text-left"
                          }, React.createElement(Icon$Ucidata.make, {
                                text: "Positions List",
                                icon: ProLightSvgIcons.faList,
                                className: "mr-2"
                              })), React.createElement("div", {
                            className: "flex items-center space-x-4"
                          }, React.createElement(PopupMenu$Ucidata.make, {
                                render: (function (setShowMenu) {
                                    return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                    onClick: (function (param) {
                                                        var items = Belt_Option.getWithDefault(Client$Ucidata.data(undefined, positionsState), []);
                                                        return Belt_Option.forEach(columns, (function (columns) {
                                                                      var csv = Curry._3(CompartimentPositions_Columns$Ucidata.Table.csvExport, undefined, columns, items);
                                                                      return Utils$Ucidata.downloadStringData(Belt_Option.mapWithDefault(uci, "", (function (param) {
                                                                                        return param.uci_name;
                                                                                      })) + "_positions_" + new Date().toISOString() + ".csv", csv, "text/csv");
                                                                    }));
                                                      }),
                                                    children: React.createElement(Icon$Ucidata.make, {
                                                          text: "Export to CSV",
                                                          icon: ProRegularSvgIcons.faFileExport,
                                                          fixedWidth: true
                                                        }),
                                                    key: "csv-export"
                                                  }));
                                  }),
                                children: React.createElement(Button$Ucidata.make, {
                                      style: /* Unstyled */4,
                                      className: "px-2",
                                      children: React.createElement(Icon$Ucidata.make, {
                                            icon: ProSolidSvgIcons.faEllipsisV,
                                            className: "text-base"
                                          })
                                    })
                              }))),
                  children: React.createElement("div", {
                        className: "space-y-8"
                      }, React.createElement(Loader$Ucidata.make, {
                            className: "text-4xl",
                            children: innerContent
                          }))
                }));
}

var make = React.memo(CompartimentPositions);

var Columns;

var Operation;

export {
  Columns ,
  Operation ,
  make ,
  
}
/* make Not a pure module */
