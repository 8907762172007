// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Modal$Ucidata from "../../../core/modals/Modal.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Font_H1$Ucidata from "../../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as UciItem$Ucidata from "../../../../models/UciItem.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "../../../core/conditionals/IfUserRole.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import * as CompartimentHoldingStructureItem$Ucidata from "./CompartimentHoldingStructureItem.bs.js";
import * as Compartiment_HS_Positions_Columns$Ucidata from "./Compartiment_HS_Positions_Columns.bs.js";
import * as CompartimentHoldingStructure_Columns$Ucidata from "./CompartimentHoldingStructure_Columns.bs.js";
import * as CompartimentHoldingStructure_Documents$Ucidata from "./CompartimentHoldingStructure_Documents.bs.js";

function CompartimentHoldingStructure(Props) {
  var uciId = Props.uciId;
  var uciItemId = Props.uciItemId;
  var client = Client$Ucidata.useClient(undefined);
  var codes = Codes$Ucidata.useCodes(undefined);
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci/" + uciId);
  var uci = Client$Ucidata.data(undefined, match[0]);
  var match$1 = React.useState(function () {
        
      });
  var setSelectedItem = match$1[1];
  var selectedItem = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowItemDetailsModal = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowDocumentsModal = match$3[1];
  var showDocumentsModal = match$3[0];
  var match$4 = Hooks$Ucidata.useFixedReference(undefined);
  var fetchItems = match$4[1];
  var match$5 = Hooks$Ucidata.useFixedReference(undefined);
  var fetchPositions = match$5[1];
  var match$6 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match$5[0]], undefined, "/uci/" + uciId + "/portfolio/?p_uci_item_id=" + uciItemId);
  var positionsState = match$6[0];
  var match$7 = React.useState(function () {
        return false;
      });
  var setValidating = match$7[1];
  var validateItem = function (param) {
    Curry._1(setValidating, (function (param) {
            return true;
          }));
    Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(UciItem$Ucidata.updateStructStatus(client, uciItemId, 1), (function (param) {
                return Curry._1(setValidating, (function (param) {
                              return false;
                            }));
              })));
    return Curry._1(fetchPositions, undefined);
  };
  var match$8 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match$4[0]], undefined, "/uci_struct_item?p_uci_item_id=" + uciItemId);
  var uciStructState = match$8[0];
  var columns = React.useMemo((function () {
          return Belt_Option.map(codes, (function (codes) {
                        return CompartimentHoldingStructure_Columns$Ucidata.make(codes, uci, {
                                    onDocumentsClick: (function (item) {
                                        Curry._1(setShowDocumentsModal, (function (param) {
                                                return true;
                                              }));
                                        return Curry._1(setSelectedItem, (function (param) {
                                                      return item;
                                                    }));
                                      }),
                                    onEditItemClick: (function (item) {
                                        Curry._1(setShowItemDetailsModal, (function (param) {
                                                return true;
                                              }));
                                        return Curry._1(setSelectedItem, (function (param) {
                                                      return item;
                                                    }));
                                      })
                                  });
                      }));
        }), [
        codes,
        uci
      ]);
  var innerContent;
  var exit = 0;
  var uciStructs;
  var columns$1;
  if (typeof uciStructState === "number") {
    innerContent = Caml_option.some(React.createElement(Text$Ucidata.make, {
              children: "No Holding Structure defined"
            }));
  } else {
    switch (uciStructState.TAG | 0) {
      case /* Loading */0 :
          var uciStructs$1 = uciStructState._0;
          if (uciStructs$1 !== undefined && columns !== undefined) {
            uciStructs = uciStructs$1;
            columns$1 = columns;
            exit = 1;
          } else {
            innerContent = undefined;
          }
          break;
      case /* Error */1 :
          innerContent = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: uciStructState._0
                  }));
          break;
      case /* Data */2 :
          if (columns !== undefined) {
            uciStructs = uciStructState._0;
            columns$1 = columns;
            exit = 1;
          } else {
            innerContent = undefined;
          }
          break;
      
    }
  }
  if (exit === 1) {
    innerContent = Caml_option.some(React.createElement(CompartimentHoldingStructure_Columns$Ucidata.Table.make, {
              columns: columns$1,
              data: uciStructs,
              dataKey: (function (uciStruct) {
                  return String(Belt_Option.getWithDefault(uciStruct.id, 0));
                }),
              className: "table-auto lg:table-fixed whitespace-nowrap border-separate rounded border text-xs text-center",
              overflowContainerClassName: "w-full",
              tableExtraClass: "w-full rounded-l-none",
              fixedTableExtraClass: "border-r-8 rounded-r-none",
              containerClassName: "border-r rounded border-dashed",
              thClassName: "py-4 px-2 font-medium",
              tdClassName: "py-4 px-2",
              headTrClassName: "bg-gray-100 uppercase",
              bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
              paginationLimit: 20
            }));
  }
  var columns$2 = React.useMemo((function () {
          return Belt_Option.map(codes, Compartiment_HS_Positions_Columns$Ucidata.make);
        }), [
        codes,
        uci
      ]);
  var innerContentPos;
  var exit$1 = 0;
  var positions;
  var columns$3;
  if (typeof positionsState === "number") {
    innerContentPos = Caml_option.some(React.createElement(Text$Ucidata.make, {
              children: "No positions."
            }));
  } else {
    switch (positionsState.TAG | 0) {
      case /* Loading */0 :
          var positions$1 = positionsState._0;
          if (positions$1 !== undefined && columns$2 !== undefined) {
            positions = positions$1;
            columns$3 = columns$2;
            exit$1 = 1;
          } else {
            innerContentPos = undefined;
          }
          break;
      case /* Error */1 :
          innerContentPos = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: positionsState._0
                  }));
          break;
      case /* Data */2 :
          if (columns$2 !== undefined) {
            positions = positionsState._0;
            columns$3 = columns$2;
            exit$1 = 1;
          } else {
            innerContentPos = undefined;
          }
          break;
      
    }
  }
  if (exit$1 === 1) {
    innerContentPos = Caml_option.some(React.createElement(Compartiment_HS_Positions_Columns$Ucidata.Table.make, {
              columns: columns$3,
              data: positions,
              dataKey: (function (position) {
                  return String(position.id);
                }),
              className: "table-auto lg:table-fixed whitespace-nowrap border-separate rounded border text-xs text-center",
              overflowContainerClassName: "w-full",
              tableExtraClass: "w-full rounded-l-none",
              fixedTableExtraClass: "border-r-8 rounded-r-none",
              containerClassName: "border-r rounded border-dashed",
              thClassName: "py-4 px-2 font-medium",
              tdClassName: "py-4 px-2",
              headTrClassName: "bg-gray-100 uppercase",
              bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
              paginationLimit: 20
            }));
  }
  var tmp = {
    uciItemId: Belt_Option.getExn(Belt_Int.fromString(uciItemId)),
    onCancel: (function (param) {
        return Curry._1(setShowItemDetailsModal, (function (param) {
                      return false;
                    }));
      }),
    onSave: (function (param) {
        Curry._1(fetchItems, undefined);
        return Curry._1(setShowItemDetailsModal, (function (param) {
                      return false;
                    }));
      })
  };
  if (selectedItem !== undefined) {
    tmp.item = Caml_option.valFromOption(selectedItem);
  }
  var tmp$1 = {
    role: /* Manager */0,
    children: null
  };
  if (uci !== undefined) {
    tmp$1.uci = Caml_option.valFromOption(uci);
  }
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: null
            }, React.createElement(Modal$Ucidata.make, {
                  isOpen: match$2[0],
                  setOpen: setShowItemDetailsModal,
                  closeOnOutsideClick: true,
                  closeOnEsc: true,
                  size: /* Large */1,
                  children: React.createElement(CompartimentHoldingStructureItem$Ucidata.make, tmp)
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: selectedItem,
                  render: (function (item) {
                      var tmp = {
                        item: item,
                        onClose: (function (param) {
                            return Curry._1(setShowDocumentsModal, (function (param) {
                                          return false;
                                        }));
                          })
                      };
                      if (uci !== undefined) {
                        tmp.uci = Caml_option.valFromOption(uci);
                      }
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showDocumentsModal,
                                  setOpen: setShowDocumentsModal,
                                  closeOnEsc: true,
                                  size: /* Fullscreen */0,
                                  children: React.createElement(CompartimentHoldingStructure_Documents$Ucidata.make, tmp)
                                });
                    })
                }), React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: null
                }, React.createElement(Font_H1$Ucidata.make, {
                      style: /* Title */1,
                      children: null
                    }, React.createElement(Icon$Ucidata.make, {
                          icon: ProRegularSvgIcons.faSitemap,
                          className: "mr-4"
                        }), React.createElement(Text_Span$Ucidata.make, {
                          className: "mr-4",
                          children: "Holding Structure"
                        }), React.createElement(Font_Div$Ucidata.make, {
                          style: /* Subtitle */2,
                          className: "flex-auto",
                          children: React.createElement(OptionMap$Ucidata.make, {
                                option: uci,
                                render: (function (uci) {
                                    return React.createElement(IfUserRole$Ucidata.make, {
                                                roles: [
                                                  /* BackOffice */3,
                                                  /* AccountAdmin */1
                                                ],
                                                renderElse: (function (param) {
                                                    return React.createElement(Text$Ucidata.make, {
                                                                children: uci.comp_name
                                                              });
                                                  }),
                                                children: React.createElement(Link$Ucidata.make, {
                                                      href: "/config/compartiment/" + String(Belt_Option.getWithDefault(uci.id, 0)),
                                                      children: React.createElement(Text$Ucidata.make, {
                                                            children: uci.comp_name
                                                          })
                                                    })
                                              });
                                  })
                              })
                        })), React.createElement("div", {
                      className: "space-y-2 italic text-sm font-normal"
                    }, React.createElement("div", undefined, React.createElement(Link$Ucidata.make, {
                              href: "/assets/compartiment/" + uciId + "/positions",
                              children: React.createElement(Icon$Ucidata.make, {
                                    containerClassName: "flex items-center",
                                    text: "Compartiment Positions",
                                    icon: ProRegularSvgIcons.faLink,
                                    fixedWidth: true
                                  })
                            })))), React.createElement("div", {
                  className: "space-y-8"
                }, React.createElement(Loader$Ucidata.make, {
                      className: "text-4xl",
                      children: innerContentPos
                    })), React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Layout$Ucidata.make, {
                        style: /* FlexBetween */3,
                        children: null
                      }, React.createElement("div", {
                            className: "text-center md:text-left"
                          }, React.createElement(Icon$Ucidata.make, {
                                text: "Structure",
                                icon: ProSolidSvgIcons.faList,
                                className: "mr-2"
                              })), React.createElement("div", {
                            className: "flex items-center space-x-4"
                          }, React.createElement(IfUciRole$Ucidata.make, tmp$1, React.createElement(Button$Ucidata.make, {
                                    style: /* Primary */0,
                                    className: "text-base w-full md:w-auto",
                                    onClick: validateItem,
                                    children: React.createElement(Text$Ucidata.make, {
                                          children: "Validate"
                                        })
                                  }), React.createElement(Button$Ucidata.make, {
                                    style: /* Primary */0,
                                    className: "text-base w-full md:w-auto",
                                    onClick: (function (param) {
                                        Curry._1(setSelectedItem, (function (param) {
                                                
                                              }));
                                        return Curry._1(setShowItemDetailsModal, (function (param) {
                                                      return true;
                                                    }));
                                      }),
                                    children: React.createElement(Icon$Ucidata.make, {
                                          text: "New Item",
                                          icon: ProRegularSvgIcons.faPlus
                                        })
                                  })))),
                  children: React.createElement("div", {
                        className: "space-y-8"
                      }, React.createElement(Loader$Ucidata.make, {
                            className: "text-4xl",
                            children: innerContent
                          }))
                }));
}

var make = React.memo(CompartimentHoldingStructure);

var Columns;

var ColumnsPos;

var ItemDetails;

var Documents;

export {
  Columns ,
  ColumnsPos ,
  ItemDetails ,
  Documents ,
  make ,
  
}
/* make Not a pure module */
