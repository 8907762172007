// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function Unless(Props) {
  var condition = Props.condition;
  var renderElse = Props.renderElse;
  var children = Props.children;
  if (condition) {
    return Curry._1(Belt_Option.getWithDefault(renderElse, (function (param) {
                      return null;
                    })), undefined);
  } else {
    return children;
  }
}

var make = Unless;

export {
  make ,
  
}
/* No side effect */
