// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Font_Component$Ucidata from "./Font_Component.bs.js";

var css = Font_Component$Ucidata.css;

var Div;

var H1;

var H2;

var make = Font_Component$Ucidata.make;

export {
  css ,
  Div ,
  H1 ,
  H2 ,
  make ,
  
}
/* Font_Component-Ucidata Not a pure module */
