// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text_Span$Ucidata from "./text/Text_Span.bs.js";
import * as ReactFontawesome from "@fortawesome/react-fontawesome";

function Icon(Props) {
  var containerClassName = Props.containerClassName;
  var text = Props.text;
  var textPositionOpt = Props.textPosition;
  var icon = Props.icon;
  var className = Props.className;
  var size = Props.size;
  var fixedWidth = Props.fixedWidth;
  var inverse = Props.inverse;
  var listItem = Props.listItem;
  var rotation = Props.rotation;
  var flip = Props.flip;
  var spin = Props.spin;
  var pulse = Props.pulse;
  var border = Props.border;
  var pull = Props.pull;
  var swapOpacity = Props.swapOpacity;
  var transform = Props.transform;
  var mask = Props.mask;
  var symbol = Props.symbol;
  var title = Props.title;
  var textPosition = textPositionOpt !== undefined ? textPositionOpt : /* Right */1;
  var tmp = {
    icon: icon
  };
  if (className !== undefined) {
    tmp.className = className;
  }
  if (size !== undefined) {
    tmp.size = (function () {
          switch (size) {
            case "xs" :
                return "xs";
            case "sm" :
                return "sm";
            case "lg" :
                return "lg";
            case "x2" :
                return "2x";
            case "x3" :
                return "3x";
            case "x4" :
                return "4x";
            case "x5" :
                return "5x";
            case "x6" :
                return "6x";
            case "x7" :
                return "7x";
            case "x8" :
                return "8x";
            case "x9" :
                return "9x";
            case "x10" :
                return "10x";
            
          }
        })();
  }
  if (fixedWidth !== undefined) {
    tmp.fixedWidth = fixedWidth;
  }
  if (inverse !== undefined) {
    tmp.inverse = inverse;
  }
  if (listItem !== undefined) {
    tmp.listItem = listItem;
  }
  if (rotation !== undefined) {
    tmp.rotation = (function () {
          switch (rotation) {
            case "deg90" :
                return 90;
            case "deg180" :
                return 180;
            case "deg240" :
                return 240;
            
          }
        })();
  }
  if (flip !== undefined) {
    tmp.flip = Caml_option.valFromOption(flip);
  }
  if (spin !== undefined) {
    tmp.spin = spin;
  }
  if (pulse !== undefined) {
    tmp.pulse = pulse;
  }
  if (border !== undefined) {
    tmp.border = border;
  }
  if (pull !== undefined) {
    tmp.pull = Caml_option.valFromOption(pull);
  }
  if (swapOpacity !== undefined) {
    tmp.swapOpacity = swapOpacity;
  }
  if (transform !== undefined) {
    tmp.transform = transform;
  }
  if (mask !== undefined) {
    tmp.mask = Caml_option.valFromOption(mask);
  }
  if (symbol !== undefined) {
    tmp.symbol = symbol;
  }
  if (title !== undefined) {
    tmp.title = title;
  }
  var iconElement = React.createElement(ReactFontawesome.FontAwesomeIcon, tmp);
  return Belt_Option.mapWithDefault(text, iconElement, (function (text) {
                var textElement = React.createElement(Text_Span$Ucidata.make, {
                      children: text
                    });
                return React.createElement("div", {
                            className: Cn.$plus("flex items-center space-x-2", Cn.take(containerClassName))
                          }, React.createElement(If$Ucidata.make, {
                                condition: textPosition === /* Left */0,
                                children: textElement
                              }), iconElement, React.createElement(If$Ucidata.make, {
                                condition: textPosition === /* Right */1,
                                children: textElement
                              }));
              }));
}

var make = React.memo(Icon);

var Regular;

var Solid;

var Light;

var Duotone;

var Brands;

var $$default = Icon;

export {
  Regular ,
  Solid ,
  Light ,
  Duotone ,
  Brands ,
  $$default ,
  $$default as default,
  make ,
  
}
/* make Not a pure module */
