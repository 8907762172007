// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function Text_Component(Props) {
  var element = Props.element;
  var className = Props.className;
  var children = Props.children;
  if (element === undefined) {
    return children;
  } else {
    return React.createElement(element, {
                className: Belt_Option.getWithDefault(className, "")
              }, children);
  }
}

var make = React.memo(Text_Component);

export {
  make ,
  
}
/* make Not a pure module */
