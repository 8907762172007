// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Render$Ucidata from "../../Render.bs.js";
import * as Font_H1$Ucidata from "../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as StateLoader$Ucidata from "../../core/StateLoader.bs.js";
import * as Input_Search$Ucidata from "../../core/input/Input_Search.bs.js";
import * as OversightFlat_Item$Ucidata from "./OversightFlat_Item.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var httpCache = Client$Ucidata.useCachingClient(undefined);

function OversightFlat(Props) {
  var pageWrapperOpt = Props.pageWrapper;
  var pageWrapper = pageWrapperOpt !== undefined ? pageWrapperOpt : true;
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_oversight");
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_oversight_subtype");
  var match$2 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_doc_ovs_status");
  var match$3 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_ovs_items?param_not_validated=true&param_flat=true");
  var setPendingItems = match$3[1];
  var match$4 = React.useState(function () {
        return "";
      });
  var setSearch = match$4[1];
  var search = match$4[0];
  var searching = search.length !== 0;
  var pendingItems = Belt_Option.getWithDefault(Client$Ucidata.data(undefined, match$3[0]), []);
  var pendingItems2 = React.useMemo((function () {
          if (searching) {
            return Belt_Array.keep(pendingItems, (function (elt) {
                          if (Belt_Option.getWithDefault(elt.comp_name, "").toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                            return true;
                          } else {
                            return Belt_Option.getWithDefault(elt.uci_group_name, "").toLocaleLowerCase().includes(search.toLocaleLowerCase());
                          }
                        }));
          } else {
            return pendingItems;
          }
        }), [
        pendingItems,
        search
      ]);
  var contents = React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(Input_Search$Ucidata.make, {
                id: "search-name",
                value: search,
                onChange: (function (v) {
                    return Curry._1(setSearch, (function (param) {
                                  return v;
                                }));
                  }),
                placeholder: "Uci or Compartiment name"
              })), React.createElement("div", {
            className: "space-y-2"
          }, React.createElement("div", {
                className: "border rounded-lg bg-white text-sm"
              }, React.createElement("div", {
                    className: "px-4 py-2 border-b bg-gray-200"
                  }, React.createElement(Icon$Ucidata.make, {
                        text: "Pending",
                        icon: ProRegularSvgIcons.faClock
                      })), React.createElement("div", {
                    className: "grid items-center justify-center gap-x-8 px-4",
                    style: {
                      gridTemplateColumns: "1fr 1fr 1fr auto auto auto"
                    }
                  }, React.createElement(StateLoader$Ucidata.make, {
                        className: "text-6xl",
                        state: Client$Ucidata.compose3(match[0], match$2[0], match$1[0]),
                        render: (function (param) {
                            var codeOversightSubtype = param[2];
                            var codeDocOvsStatus = param[1];
                            var codeOversight = param[0];
                            return Render$Ucidata.array((function (param) {
                                          return React.createElement("div", {
                                                      className: "p-4"
                                                    }, React.createElement(Text$Ucidata.make, {
                                                          children: "There are no pending reviews."
                                                        }));
                                        }), pendingItems2, (function (item, param) {
                                          return React.createElement(React.Fragment, undefined, React.createElement(OversightFlat_Item$Ucidata.make, {
                                                          item: item,
                                                          codeOversight: codeOversight,
                                                          codeDocOvsStatus: codeDocOvsStatus,
                                                          codeOversightSubtype: codeOversightSubtype,
                                                          setItems: setPendingItems,
                                                          key: String(item.id)
                                                        }), React.createElement("div", {
                                                          key: "divider",
                                                          className: "border-b border-dashed",
                                                          style: {
                                                            gridColumn: "1/7"
                                                          }
                                                        }));
                                        }));
                          })
                      })))));
  if (pageWrapper) {
    return React.createElement(Layout$Ucidata.make, {
                style: /* Page */4,
                children: null
              }, React.createElement(Section$Ucidata.make, {
                    unstyled: true,
                    children: React.createElement(Layout$Ucidata.make, {
                          style: /* FlexBetween */3,
                          children: null
                        }, React.createElement(Font_H1$Ucidata.make, {
                              style: /* Title */1,
                              children: React.createElement(Icon$Ucidata.make, {
                                    text: "Oversight Control List",
                                    icon: ProRegularSvgIcons.faMonitorHeartRate,
                                    className: "mr-2"
                                  })
                            }), React.createElement("div", undefined, React.createElement(Link$Ucidata.make, {
                                  href: "/oversight",
                                  children: React.createElement(Button$Ucidata.make, {
                                        style: /* Primary */0,
                                        icon: ProLightSvgIcons.faList,
                                        className: "mr-2 w-full md:w-auto",
                                        children: React.createElement(Text$Ucidata.make, {
                                              children: "Groups"
                                            })
                                      })
                                })))
                  }), contents);
  } else {
    return contents;
  }
}

var make = React.memo(OversightFlat);

var Helpers;

var Item;

export {
  Helpers ,
  Item ,
  httpCache ,
  make ,
  
}
/* httpCache Not a pure module */
