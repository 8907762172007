// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import ReactSelect from "react-select";
import * as OptionMap$Ucidata from "../conditionals/OptionMap.bs.js";
import * as Validation$Ucidata from "../validation/Validation.bs.js";
import * as Validation_Errors$Ucidata from "../validation/Validation_Errors.bs.js";

function Input_Select(Props) {
  var name = Props.name;
  var options = Props.options;
  var onChange = Props.onChange;
  var value = Props.value;
  var label = Props.label;
  var className = Props.className;
  var validate = Props.validate;
  var validateDependency = Props.validateDependency;
  var isMulti = Props.isMulti;
  var isSearchable = Props.isSearchable;
  var isClearable = Props.isClearable;
  var isDisabled = Props.isDisabled;
  var isOptionDisabled = Props.isOptionDisabled;
  var isLoading = Props.isLoading;
  var getOptionLabel = Props.getOptionLabel;
  var getOptionValue = Props.getOptionValue;
  var sizeOpt = Props.size;
  var menuPortalTarget = Props.menuPortalTarget;
  var placeholder = Props.placeholder;
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var match = Validation$Ucidata.useValidation(name, value, validate, validateDependency, undefined);
  var errors = match.errors;
  var dirty = match.dirty;
  var tmp = {
    options: options,
    classNamePrefix: "react-select",
    value: Js_null.fromOption(value),
    onChange: (function (nullableValue) {
        return Curry._1(onChange, (nullableValue == null) ? undefined : Caml_option.some(nullableValue));
      })
  };
  if (isMulti !== undefined) {
    tmp.isMulti = isMulti;
  }
  if (isOptionDisabled !== undefined) {
    tmp.isOptionDisabled = Caml_option.valFromOption(isOptionDisabled);
  }
  if (menuPortalTarget !== undefined) {
    tmp.menuPortalTarget = Caml_option.valFromOption(menuPortalTarget);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (isSearchable !== undefined) {
    tmp.isSearchable = isSearchable;
  }
  if (isClearable !== undefined) {
    tmp.isClearable = isClearable;
  }
  if (isDisabled !== undefined) {
    tmp.isDisabled = isDisabled;
  }
  if (isLoading !== undefined) {
    tmp.isLoading = isLoading;
  }
  if (getOptionLabel !== undefined) {
    tmp.getOptionLabel = Caml_option.valFromOption(getOptionLabel);
  }
  if (getOptionValue !== undefined) {
    tmp.getOptionValue = Caml_option.valFromOption(getOptionValue);
  }
  return React.createElement("div", {
              className: Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("react-select relative min-w-200px", Cn.take(className)), Cn.on("small", size === /* Small */0)), Cn.on("tiny", size === /* Tiny */2)), Cn.onSome("pt-6", label)), Cn.on("react-select--multi", Belt_Option.getWithDefault(isMulti, false)))
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: label,
                  render: (function (label) {
                      return React.createElement("label", {
                                  className: Cn.$plus(Cn.$plus("block tracking-wide text-gray-500 border-gray-300 text-xs font-medium absolute -mt-6 w-full", Cn.on("uppercase", size === /* Normal */1)), Cn.on("text-red-500", dirty && errors.length !== 0))
                                }, label);
                    })
                }), React.createElement(ReactSelect, tmp), React.createElement(Validation_Errors$Ucidata.make, {
                  errors: errors,
                  dirty: dirty
                }));
}

var make = React.memo(Input_Select);

export {
  make ,
  
}
/* make Not a pure module */
