// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ComponentLoader$Ucidata from "../../../core/ComponentLoader.bs.js";

function loader(param) {
  return import("./Home_BackOffice_Component.bs.js");
}

var HomeSig = {
  loader: loader
};

var include = ComponentLoader$Ucidata.Make(HomeSig);

var make = include.make;

export {
  HomeSig ,
  make ,
  
}
/* include Not a pure module */
