// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";

function css(style) {
  switch (style) {
    case /* Hero */0 :
        return "text-4xl text-gray-700 font-semibold leading-normal";
    case /* Title */1 :
        return "text-3xl font-medium text-gray-700 flex flex-wrap items-baseline md:justify-start justify-center md:my-4 my-2";
    case /* Subtitle */2 :
        return "text-xl text-gray-500 font-normal";
    case /* Large */3 :
        return "text-lg font-normal";
    case /* Italic */4 :
        return "italic text-gray-400";
    case /* Uppercase */5 :
        return "uppercase font-normal";
    case /* UppercaseLabel */6 :
        return "uppercase tracking-wide text-gray-500 font-medium";
    
  }
}

function Font_Component(Props) {
  var style = Props.style;
  var elementOpt = Props.element;
  var className = Props.className;
  var children = Props.children;
  var element = elementOpt !== undefined ? elementOpt : "span";
  var className$1 = Cn.$plus(css(style), Cn.take(className));
  return React.createElement(element, {
              className: className$1
            }, children);
}

var make = React.memo(Font_Component);

export {
  css ,
  make ,
  
}
/* make Not a pure module */
