// Generated by ReScript, PLEASE EDIT WITH CARE

import Src from "hyperactiv/src";

function Make(T) {
  var partial_arg = Src;
  var observe = function (param) {
    return partial_arg.observe(param);
  };
  var partial_arg$1 = Src;
  var computed = function (param) {
    return partial_arg$1.computed(param);
  };
  var partial_arg$2 = Src;
  var dispose = function (param) {
    partial_arg$2.dispose(param);
    
  };
  var Provider = {};
  var Watch = {};
  var React = {
    Provider: Provider,
    Watch: Watch
  };
  return {
          observe: observe,
          computed: computed,
          dispose: dispose,
          React: React
        };
}

export {
  Make ,
  
}
/* hyperactiv/src Not a pure module */
