// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

var defaultStyles = "text-gray-600 border-gray-600 hover:text-blue-500 hover:border-blue-600 transition-colors duration-200 visited:text-blue-900 visited:border-blue-900";

function Link(Props) {
  var href = Props.href;
  var target = Props.target;
  var replace = Props.replace;
  var rel = Props.rel;
  var vanillaOpt = Props.vanilla;
  var className = Props.className;
  var extraClass = Props.extraClass;
  var unstyledOpt = Props.unstyled;
  var onClick = Props.onClick;
  var title = Props.title;
  var children = Props.children;
  var vanilla = vanillaOpt !== undefined ? vanillaOpt : false;
  var unstyled = unstyledOpt !== undefined ? unstyledOpt : false;
  var tmp = {
    className: Cn.$plus(Belt_Option.getWithDefault(className, Cn.on(defaultStyles, !unstyled)), Cn.take(extraClass)),
    href: href,
    onClick: (function ($$event) {
        if (!vanilla) {
          $$event.preventDefault();
          var fn = Belt_Option.getWithDefault(replace, false) ? RescriptReactRouter.replace : RescriptReactRouter.push;
          Curry._1(fn, href);
        }
        return Belt_Option.forEach(onClick, (function (onClick) {
                      return Curry._1(onClick, $$event);
                    }));
      })
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (rel !== undefined) {
    tmp.rel = Caml_option.valFromOption(rel);
  }
  if (target !== undefined) {
    tmp.target = Caml_option.valFromOption(target);
  }
  return React.createElement("a", tmp, children);
}

var make = React.memo(Link);

export {
  defaultStyles ,
  make ,
  
}
/* make Not a pure module */
