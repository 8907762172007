// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactPortal from "react-portal";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Transition$Ucidata from "../Transition.bs.js";
import * as Webapi__Dom__Document from "bs-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js";

var make = React.memo(function (Props) {
      var isOpenOpt = Props.isOpen;
      var setOpen = Props.setOpen;
      var closeOnOutsideClickOpt = Props.closeOnOutsideClick;
      var closeOnEscOpt = Props.closeOnEsc;
      var sizeOpt = Props.size;
      var preventScrollOpt = Props.preventScroll;
      var children = Props.children;
      var isOpen = isOpenOpt !== undefined ? isOpenOpt : true;
      var closeOnOutsideClick = closeOnOutsideClickOpt !== undefined ? closeOnOutsideClickOpt : false;
      var closeOnEsc = closeOnEscOpt !== undefined ? closeOnEscOpt : false;
      var size = sizeOpt !== undefined ? sizeOpt : /* Auto */4;
      var preventScroll = preventScrollOpt !== undefined ? preventScrollOpt : true;
      Hooks$Ucidata.Events.useDocumentEventListener1("keydown", (function ($$event) {
              if (closeOnEsc && $$event.key === "Escape") {
                return Curry._1(setOpen, (function (param) {
                              return false;
                            }));
              }
              
            }), [closeOnEsc]);
      React.useEffect((function () {
              if (preventScroll) {
                Belt_Option.map(Belt_Option.flatMap(Webapi__Dom__Document.asHtmlDocument(document), (function (doc) {
                            return Caml_option.nullable_to_opt(doc.body);
                          })), (function (body) {
                        return (
                                  isOpen ? (function (param) {
                                        param.add("scrollblock");
                                        
                                      }) : (function (param) {
                                        param.remove("scrollblock");
                                        
                                      })
                                )(body.classList);
                      }));
              }
              
            }), [
            isOpen,
            preventScroll
          ]);
      var sizeCss;
      switch (size) {
        case /* Fullscreen */0 :
            sizeCss = "w-screen min-h-screen";
            break;
        case /* Large */1 :
            sizeCss = "w-full max-w-screen-lg shadow lg:rounded-lg";
            break;
        case /* Medium */2 :
            sizeCss = "w-full max-w-screen-md shadow md:rounded-lg";
            break;
        case /* Small */3 :
            sizeCss = "w-full max-w-screen-sm shadow sm:rounded-lg";
            break;
        case /* Auto */4 :
            sizeCss = "shadow rounded-lg";
            break;
        
      }
      return React.createElement(ReactPortal.Portal, {
                  children: React.createElement(Transition$Ucidata.make, {
                        show: isOpen,
                        transitionIn: "opacity-1",
                        transitionOut: "opacity-0",
                        className: "fixed flex justify-center items-center fixed top-0 left-0 h-screen w-screen transition-opacity duration-300",
                        children: null
                      }, React.createElement("div", {
                            className: "absolute opacity-70 bg-black w-full h-full",
                            onClick: (function (param) {
                                if (closeOnOutsideClick) {
                                  return Curry._1(setOpen, (function (param) {
                                                return false;
                                              }));
                                }
                                
                              })
                          }), React.createElement(Transition$Ucidata.make, {
                            show: isOpen,
                            transitionIn: "translate-y-0",
                            transitionOut: "translate-y-10",
                            className: Cn.$plus(Cn.$plus("transition-transform duration-300 transform-gpu", "bg-white text-black z-10 sm:p-2 md:p-4 lg:p-8 overflow-x-scroll max-h-screen"), sizeCss),
                            children: children
                          }))
                });
    });

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
