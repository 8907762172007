// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$Ucidata from "../../../Utils.bs.js";
import * as Validation_Defs$Ucidata from "./Validation_Defs.bs.js";
import * as Validation_Context$Ucidata from "./Validation_Context.bs.js";
import * as Validation_Provider$Ucidata from "./Validation_Provider.bs.js";

function useState(param) {
  return React.useState(function () {
              return {};
            });
}

function Validation(Props) {
  var resetDependency = Props.resetDependency;
  var render = Props.render;
  var match = React.useState(function () {
        return {};
      });
  var setValidation = match[1];
  var validation = match[0];
  var firstRender = React.useRef(true);
  React.useEffect((function () {
          if (firstRender.current) {
            firstRender.current = false;
          } else {
            Curry._1(setValidation, (function (param) {
                    return {};
                  }));
          }
          
        }), [resetDependency]);
  var providerValue = React.useMemo((function () {
          return {
                  validation: validation,
                  setValidation: (function (key, errors) {
                      return Curry._1(setValidation, (function (state) {
                                    var copy = Utils$Ucidata.cloneDict(state);
                                    copy[key] = {
                                      dirty: Js_dict.get(state, key) !== undefined,
                                      errors: errors
                                    };
                                    return copy;
                                  }));
                    })
                };
        }), [
        validation,
        setValidation
      ]);
  return React.createElement(Validation_Provider$Ucidata.make, Validation_Provider$Ucidata.makeProps(providerValue, Curry._1(render, validation), undefined));
}

function useValidation(label, value, validate, validateDependency, param) {
  var match = React.useContext(Validation_Context$Ucidata.context);
  var setValidation = match.setValidation;
  var validation = match.validation;
  if (label.length === 0 && validate !== undefined) {
    Js_exn.raiseError("Label is falsy.");
  }
  var validationWrapper = function (value) {
    return Belt_Option.forEach(validate, (function (validate) {
                  return Curry._2(setValidation, label, Validation_Defs$Ucidata.toExceptionList(Curry._1(validate, value)));
                }));
  };
  React.useEffect((function () {
          return (function (param) {
                    return Curry._2(setValidation, label, []);
                  });
        }), []);
  React.useEffect((function () {
          validationWrapper(value);
          
        }), [
        value,
        validateDependency
      ]);
  return {
          dirty: Belt_Option.mapWithDefault(Js_dict.get(validation, label), false, (function (elt) {
                  return elt.dirty;
                })),
          errors: Belt_Option.mapWithDefault(Js_dict.get(validation, label), [], (function (elt) {
                  return elt.errors;
                }))
        };
}

var Context;

var Errors;

var Provider;

var Rules;

var make = Validation;

export {
  Context ,
  Errors ,
  Provider ,
  Rules ,
  useState ,
  make ,
  useValidation ,
  
}
/* react Not a pure module */
