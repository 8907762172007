// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Render$Ucidata from "../../Render.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as StateLoader$Ucidata from "../../core/StateLoader.bs.js";
import * as Oversight_Item$Ucidata from "./Oversight_Item.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Oversight_Category$ItemsHistory(Props) {
  var uciId = Props.uciId;
  var subtype = Props.subtype;
  var codeDocOvsStatus = Props.codeDocOvsStatus;
  var fetchItemsRef = Props.fetchItemsRef;
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [fetchItemsRef], undefined, "/uci_ovs_items?param_not_validated=false&param_uci_id=" + uciId + "&param_code_oversight_subtype=" + String(subtype.id));
  var setItems = match[1];
  return React.createElement(StateLoader$Ucidata.make, {
              className: "p-2 text-xl",
              stale: true,
              state: match[0],
              render: (function (items) {
                  return Render$Ucidata.array((function (param) {
                                return React.createElement("div", {
                                            className: "p-4"
                                          }, React.createElement(Text$Ucidata.make, {
                                                children: "There are no previous reviews."
                                              }));
                              }), items, (function (item, param) {
                                return React.createElement(Oversight_Item$Ucidata.make, {
                                            item: item,
                                            subtype: subtype,
                                            codeDocOvsStatus: codeDocOvsStatus,
                                            editable: false,
                                            setItems: setItems,
                                            key: String(item.id)
                                          });
                              }));
                })
            });
}

var make = React.memo(Oversight_Category$ItemsHistory);

var ItemsHistory = {
  make: make
};

function Oversight_Category(Props) {
  var uciId = Props.uciId;
  var subtype = Props.subtype;
  var codeDocOvsStatus = Props.codeDocOvsStatus;
  var editableOpt = Props.editable;
  var editable = editableOpt !== undefined ? editableOpt : true;
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var fetchItems = match[1];
  var fetchItemsRef = match[0];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [fetchItemsRef], undefined, "/uci_ovs_items?param_not_validated=true&param_uci_id=" + uciId + "&param_code_oversight_subtype=" + String(subtype.id));
  var setPendingItems = match$1[1];
  return React.createElement("div", {
              className: "space-y-2"
            }, React.createElement(StateLoader$Ucidata.make, {
                  className: "p-2 text-xl",
                  stale: true,
                  state: match$1[0],
                  render: (function (pendingItems) {
                      return React.createElement("div", {
                                  className: "border rounded-lg bg-white text-sm"
                                }, React.createElement("div", {
                                      className: "px-4 py-2 border-b bg-gray-200"
                                    }, React.createElement(Icon$Ucidata.make, {
                                          text: "Pending",
                                          icon: ProRegularSvgIcons.faClock
                                        })), Render$Ucidata.array((function (param) {
                                        return React.createElement("div", {
                                                    className: "p-4"
                                                  }, React.createElement(Text$Ucidata.make, {
                                                        children: "There are no pending reviews."
                                                      }));
                                      }), pendingItems, (function (item, param) {
                                        return React.createElement(Oversight_Item$Ucidata.make, {
                                                    item: item,
                                                    subtype: subtype,
                                                    codeDocOvsStatus: codeDocOvsStatus,
                                                    editable: editable,
                                                    setItems: setPendingItems,
                                                    fetchItems: fetchItems,
                                                    key: String(item.id)
                                                  });
                                      })));
                    })
                }), React.createElement("div", {
                  className: "border rounded-lg bg-white text-sm"
                }, React.createElement(Collapsible$Ucidata.make, {
                      className: "p-2 bg-gray-200",
                      contentClassName: "divide-y",
                      shallow: true,
                      children: React.createElement(make, {
                            uciId: uciId,
                            subtype: subtype,
                            codeDocOvsStatus: codeDocOvsStatus,
                            fetchItemsRef: fetchItemsRef
                          }),
                      render: (function (param) {
                          return React.createElement("div", undefined, React.createElement(Icon$Ucidata.make, {
                                          text: "History",
                                          icon: ProRegularSvgIcons.faHourglass
                                        }));
                        })
                    })));
}

var make$1 = React.memo(Oversight_Category);

var Helpers;

var Item;

export {
  Helpers ,
  Item ,
  ItemsHistory ,
  make$1 as make,
  
}
/* make Not a pure module */
