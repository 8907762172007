// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Text$Ucidata from "./text/Text.bs.js";
import * as Island$Ucidata from "./Island.bs.js";
import * as Loader$Ucidata from "./loader/Loader.bs.js";

function Make(Component) {
  var ComponentLoader$Make = function (Props) {
    var loading = Props.loading;
    var error = Props.error;
    var children = Props.children;
    var match = React.useState(function () {
          return /* Loading */0;
        });
    var setComponentState = match[1];
    var componentState = match[0];
    React.useEffect((function () {
            Curry._1(Component.loader, undefined).then(function (component) {
                    return Promise.resolve(Curry._1(setComponentState, (function (param) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: component
                                            };
                                    })));
                  }).catch(function (error) {
                  return Promise.resolve(Curry._1(setComponentState, (function (param) {
                                    return {
                                            TAG: /* Error */1,
                                            _0: error
                                          };
                                  })));
                });
            
          }), []);
    if (typeof componentState === "number") {
      return Belt_Option.getWithDefault(loading, React.createElement(Loader$Ucidata.make, {
                      className: "text-3xl"
                    }));
    } else if (componentState.TAG === /* Ok */0) {
      return Curry._1(children, componentState._0);
    } else {
      return Belt_Option.getWithDefault(error, React.createElement(Island$Ucidata.make, {
                      style: /* Error */2,
                      children: React.createElement(Text$Ucidata.make, {
                            children: "Something went wrong…"
                          })
                    }));
    }
  };
  return {
          make: ComponentLoader$Make
        };
}

export {
  Make ,
  
}
/* react Not a pure module */
