// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Text_Component$Ucidata from "./Text_Component.bs.js";

var make = Text_Component$Ucidata.make;

var Span;

var Paragraph;

export {
  make ,
  Span ,
  Paragraph ,
  
}
/* Text_Component-Ucidata Not a pure module */
