// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

function useDebouncedEffect(effect, delay, deps) {
  var callback = React.useCallback(Curry.__1(effect), deps);
  React.useEffect((function () {
          var handler = setTimeout(callback, delay);
          return (function (param) {
                    clearTimeout(handler);
                    
                  });
        }), [
        callback,
        delay
      ]);
  
}

function useFixedReference(param) {
  var match = React.useState(function () {
        return {};
      });
  var setRef = match[1];
  var updateRef = React.useCallback((function (param) {
          return Curry._1(setRef, (function (param) {
                        return {};
                      }));
        }), [setRef]);
  return [
          match[0],
          updateRef
        ];
}

function useErrorState(param) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  return [
          match[0],
          (function (err) {
              return Curry._1(setError, (function (param) {
                            return Caml_option.some(err);
                          }));
            }),
          (function (param) {
              return Curry._1(setError, (function (param) {
                            
                          }));
            })
        ];
}

function documentEventListenerCallback(_type, fn) {
  var listener = Curry.__1(fn);
  document.addEventListener(_type, listener);
  return (function (param) {
            document.removeEventListener(_type, listener);
            
          });
}

function useDocumentEventListener(_type, fn) {
  React.useEffect((function () {
          return documentEventListenerCallback(_type, fn);
        }), []);
  
}

function useDocumentEventListener1(_type, fn, dependency) {
  React.useEffect((function () {
          return documentEventListenerCallback(_type, fn);
        }), dependency);
  
}

function windowEventListenerCallback(_type, fn) {
  var listener = Curry.__1(fn);
  window.addEventListener(_type, listener);
  return (function (param) {
            window.removeEventListener(_type, listener);
            
          });
}

function useWindowEventListener(_type, fn) {
  React.useEffect((function () {
          return windowEventListenerCallback(_type, fn);
        }), []);
  
}

function useWindowEventListener1(_type, fn, dependency) {
  React.useEffect((function () {
          return windowEventListenerCallback(_type, fn);
        }), dependency);
  
}

function useClickListenerContainer(onClick) {
  var elementRef = React.useRef(null);
  React.useEffect((function () {
          var listener = function (e) {
            var target = e.target;
            var container = elementRef.current;
            Js_null_undefined.iter(container, (function (container) {
                    return Curry._1(onClick, container.contains(target));
                  }));
            
          };
          document.addEventListener("click", listener, true);
          return (function (param) {
                    document.removeEventListener("click", listener);
                    
                  });
        }), []);
  return elementRef;
}

function useMouseOverListener(fn) {
  var elementRef = React.useRef(null);
  React.useEffect((function () {
          return Belt_Option.map(Caml_option.nullable_to_opt(elementRef.current), (function (ref) {
                        ref.addEventListener("mouseover", fn);
                        return function (param) {
                          ref.removeEventListener("mouseover", fn);
                          
                        };
                      }));
        }), [elementRef]);
  return elementRef;
}

function useMouseOutListener(fn) {
  var elementRef = React.useRef(null);
  React.useEffect((function () {
          return Belt_Option.map(Caml_option.nullable_to_opt(elementRef.current), (function (ref) {
                        ref.addEventListener("mouseout", fn);
                        return function (param) {
                          ref.removeEventListener("mouseout", fn);
                          
                        };
                      }));
        }), [elementRef]);
  return elementRef;
}

function useHoverListener(onHover) {
  var elementRef = React.useRef(null);
  React.useEffect((function () {
          var listener = function (e) {
            var target = e.target;
            var container = elementRef.current;
            return Js_null_undefined.iter(container, (function (container) {
                          return Curry._1(onHover, container.contains(target));
                        }));
          };
          document.addEventListener("mouseover", listener);
          return (function (param) {
                    document.removeEventListener("mouseover", listener);
                    
                  });
        }), []);
  return elementRef;
}

function useEventListener(_type, fn) {
  var elementRef = React.useRef(null);
  React.useEffect((function () {
          var listener = function (e) {
            return fn(e, elementRef);
          };
          return Belt_Option.map(Caml_option.nullable_to_opt(elementRef.current), (function (ref) {
                        ref.addEventListener(_type, listener);
                        return function (param) {
                          ref.removeEventListener(_type, listener);
                          
                        };
                      }));
        }), [elementRef]);
  return elementRef;
}

var Events = {
  documentEventListenerCallback: documentEventListenerCallback,
  useDocumentEventListener: useDocumentEventListener,
  useDocumentEventListener1: useDocumentEventListener1,
  windowEventListenerCallback: windowEventListenerCallback,
  useWindowEventListener: useWindowEventListener,
  useWindowEventListener1: useWindowEventListener1,
  useClickListenerContainer: useClickListenerContainer,
  useMouseOverListener: useMouseOverListener,
  useMouseOutListener: useMouseOutListener,
  useHoverListener: useHoverListener,
  useEventListener: useEventListener
};

export {
  useDebouncedEffect ,
  useFixedReference ,
  useErrorState ,
  Events ,
  
}
/* react Not a pure module */
