// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Text$Ucidata from "./text/Text.bs.js";
import * as Font_H2$Ucidata from "./font/Font_H2.bs.js";
import * as OptionMap$Ucidata from "./conditionals/OptionMap.bs.js";

function Section(Props) {
  var title = Props.title;
  var unstyledOpt = Props.unstyled;
  var className = Props.className;
  var icon = Props.icon;
  var description = Props.description;
  var children = Props.children;
  var unstyled = unstyledOpt !== undefined ? unstyledOpt : false;
  return React.createElement("section", {
              className: Cn.$plus(Cn.on("bg-gray-50 text-gray-500 rounded relative p-8", !unstyled), Cn.take(className))
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: title,
                  render: (function (title) {
                      return React.createElement(Font_H2$Ucidata.make, {
                                  style: /* Subtitle */2,
                                  className: Cn.on("mb-8", description === undefined),
                                  children: null
                                }, React.createElement(OptionMap$Ucidata.make, {
                                      option: icon,
                                      render: (function (icon) {
                                          return React.createElement(Icon$Ucidata.make, {
                                                      icon: icon,
                                                      className: "mr-4 align-center"
                                                    });
                                        })
                                    }), title);
                    })
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: description,
                  render: (function (description) {
                      return React.createElement("div", {
                                  className: "my-8 font-normal"
                                }, React.createElement(Text$Ucidata.make, {
                                      children: description
                                    }));
                    })
                }), children);
}

var make = React.memo(Section);

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
