// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Unless$Ucidata from "./conditionals/Unless.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Button(Props) {
  var styleOpt = Props.style;
  var sizeOpt = Props.size;
  var borderOpt = Props.border;
  var disabledOpt = Props.disabled;
  var icon = Props.icon;
  var spinnerOpt = Props.spinner;
  var className = Props.className;
  var noPaddingOpt = Props.noPadding;
  var onClick = Props.onClick;
  var href = Props.href;
  var replace = Props.replace;
  var title = Props.title;
  var type_ = Props.type_;
  var children = Props.children;
  var id = Props.id;
  var role = Props.role;
  var style = styleOpt !== undefined ? styleOpt : /* Regular */3;
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var border = borderOpt !== undefined ? borderOpt : /* Normal */0;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var spinner = spinnerOpt !== undefined ? spinnerOpt : false;
  var noPadding = noPaddingOpt !== undefined ? noPaddingOpt : false;
  var disabled$1 = disabled || spinner;
  var tmp;
  switch (style) {
    case /* Primary */0 :
        tmp = Cn.$plus(Cn.$plus("rounded transition-colors", Cn.on("text-white bg-blue-400 border-blue-400 hover:bg-blue-500 hover:border-blue-500", !disabled$1)), Cn.on("text-gray-400 bg-gray-300 border-gray-300", disabled$1));
        break;
    case /* PrimaryRed */1 :
        tmp = Cn.$plus(Cn.$plus("rounded transition-colors", Cn.on("text-white bg-red-400 border-red-400 hover:bg-red-500 hover:border-red-500", !disabled$1)), Cn.on("text-gray-400 bg-gray-300 border-gray-300", disabled$1));
        break;
    case /* Secondary */2 :
        tmp = Cn.$plus(Cn.$plus("rounded transition-colors", Cn.on("border-blue-400 text-blue-400 hover:border-blue-500 hover:text-blue-500", !disabled$1)), Cn.on("border-gray-300 text-gray-300", disabled$1));
        break;
    case /* Regular */3 :
        tmp = Cn.$plus(Cn.$plus("rounded transition-colors", Cn.on("border-gray-400 text-gray-400 hover:border-black hover:text-black", !disabled$1)), Cn.on("text-gray-300 border-gray-300", disabled$1));
        break;
    case /* Unstyled */4 :
        tmp = "";
        break;
    
  }
  var tmp$1;
  switch (size) {
    case /* Small */0 :
        tmp$1 = Cn.$plus("text-sm font-normal", Cn.on("py-1 px-3", !noPadding));
        break;
    case /* Normal */1 :
        tmp$1 = Cn.$plus("font-semibold", Cn.on("py-1.5 px-4", !noPadding));
        break;
    case /* Large */2 :
        tmp$1 = Cn.$plus("font-semibold", Cn.on("py-2 px-6", !noPadding));
        break;
    case /* Slim */3 :
        tmp$1 = Cn.$plus("text-xs font-normal", Cn.on("py-0.5 px-2", !noPadding));
        break;
    
  }
  var tmp$2;
  switch (border) {
    case /* Normal */0 :
        tmp$2 = "border";
        break;
    case /* Thick */1 :
        tmp$2 = "border-2";
        break;
    case /* None */2 :
        tmp$2 = "";
        break;
    
  }
  var cssClasses = Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(tmp, Cn.on(tmp$1, style !== /* Unstyled */4)), Cn.on(tmp$2, style !== /* Unstyled */4)), Cn.on("cursor-not-allowed", disabled$1)), Cn.take(className));
  var tmp$3 = {
    className: cssClasses,
    disabled: disabled$1
  };
  if (id !== undefined) {
    tmp$3.id = Caml_option.valFromOption(id);
  }
  if (role !== undefined) {
    tmp$3.role = Caml_option.valFromOption(role);
  }
  if (title !== undefined) {
    tmp$3.title = Caml_option.valFromOption(title);
  }
  if (type_ !== undefined) {
    tmp$3.type = Caml_option.valFromOption(type_);
  }
  var tmp$4 = Belt_Option.mapWithDefault(href, onClick, (function (href) {
          return (function (param) {
                    param.preventDefault();
                    var fn = Belt_Option.getWithDefault(replace, false) ? RescriptReactRouter.replace : RescriptReactRouter.push;
                    return Curry._1(fn, href);
                  });
        }));
  if (tmp$4 !== undefined) {
    tmp$3.onClick = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement("button", tmp$3, React.createElement(If$Ucidata.make, {
                  condition: spinner,
                  children: React.createElement(Icon$Ucidata.make, {
                        icon: ProSolidSvgIcons.faSpinner,
                        spin: true
                      })
                }), React.createElement(Unless$Ucidata.make, {
                  condition: spinner,
                  children: null
                }, Belt_Option.mapWithDefault(icon, null, (function (icon) {
                        return React.createElement(Icon$Ucidata.make, {
                                    icon: icon,
                                    className: "mr-2"
                                  });
                      })), Belt_Option.getWithDefault(children, null)));
}

var make = React.memo(Button);

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
