// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Compartiment_Users_Role(Props) {
  var userId = Props.userId;
  var codeUciRoleId = Props.codeUciRoleId;
  var dispatchUci = Props.dispatchUci;
  var isEditable = Props.isEditable;
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Client$Ucidata.useData([], undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_uci_role");
  var codeRoleState = match[0];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/user/" + String(userId));
  var userState = match$1[0];
  if (typeof codeRoleState === "number") {
    return null;
  }
  if (codeRoleState.TAG !== /* Data */2) {
    return null;
  }
  if (typeof userState === "number") {
    return null;
  }
  if (userState.TAG !== /* Data */2) {
    return null;
  }
  var user = userState._0;
  var roleOptions = Belt_Array.map(codeRoleState._0, (function (role) {
          return {
                  value: role.id,
                  label: role.name
                };
        }));
  var selectedOption = Belt_Array.getBy(roleOptions, (function (param) {
          return param.value === codeUciRoleId;
        }));
  var tmp = {
    name: "User Role",
    options: roleOptions,
    onChange: (function (option) {
        if (option !== undefined) {
          return Curry._1(dispatchUci, {
                      TAG: /* SetUserRole */8,
                      _0: userId,
                      _1: option.value,
                      _2: selectedOption.value
                    });
        }
        
      }),
    className: "w-64",
    isDisabled: !isEditable,
    size: /* Small */0
  };
  if (selectedOption !== undefined) {
    tmp.value = Caml_option.valFromOption(selectedOption);
  }
  return React.createElement(Layout$Ucidata.make, {
              style: /* FlexBetween */3,
              className: "text-sm hover:bg-blue-50 px-2",
              children: null
            }, React.createElement("div", {
                  className: "space-x-4"
                }, React.createElement(If$Ucidata.make, {
                      condition: isEditable,
                      children: React.createElement(Button$Ucidata.make, {
                            style: /* Unstyled */4,
                            className: "text-red-500 text-base",
                            onClick: (function (param) {
                                return Curry._1(dispatchUci, {
                                            TAG: /* RemoveUser */1,
                                            _0: userId,
                                            _1: codeUciRoleId
                                          });
                              }),
                            children: React.createElement(Icon$Ucidata.make, {
                                  icon: ProSolidSvgIcons.faTimes
                                })
                          })
                    }), React.createElement("span", {
                      className: "text-gray-700"
                    }, React.createElement(Text_Span$Ucidata.make, {
                          children: user.first_name + " " + user.last_name
                        }), React.createElement(Font$Ucidata.make, {
                          style: /* Italic */4,
                          children: React.createElement(Text$Ucidata.make, {
                                children: " (" + user.email + ")"
                              })
                        }))), React.createElement(Input_Select$Ucidata.make, tmp));
}

var make = React.memo(Compartiment_Users_Role);

export {
  make ,
  
}
/* make Not a pure module */
