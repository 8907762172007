// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as UciItem$Ucidata from "../../../../models/UciItem.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as Position$Ucidata from "../../../../models/Position.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Validation$Ucidata from "../../../core/validation/Validation.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as Input_Number$Ucidata from "../../../core/input/Input_Number.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Webapi__Dom__Document from "bs-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js";
import * as Validation_Rules$Ucidata from "../../../core/validation/Validation_Rules.bs.js";
import * as Validation_Context$Ucidata from "../../../core/validation/Validation_Context.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function CompartimentPositions_Operation(Props) {
  var position = Props.position;
  var onClose = Props.onClose;
  var onOperation = Props.onOperation;
  var client = Client$Ucidata.useClient(undefined);
  var codes = Codes$Ucidata.useCodes(undefined);
  var match = React.useState(function () {
        
      });
  var setOperationType = match[1];
  var operationType = match[0];
  var match$1 = React.useState(function () {
        return 1;
      });
  var setOperationAmount = match$1[1];
  var operationAmount = match$1[0];
  var match$2 = React.useState(function () {
        return 1;
      });
  var setOperationPrice = match$2[1];
  var operationPrice = match$2[0];
  var match$3 = Client$Ucidata.useRequest(undefined, undefined, (function (param) {
          var codeEvent = Belt_Option.getExn(operationType).id;
          var init = Position$Ucidata.toUciItem(Position$Ucidata.Detailed.toPos(position), codeEvent);
          return Wretch$Ucidata.$$then(UciItem$Ucidata.create(client, {
                          id: init.id,
                          uci_item_group_id: init.uci_item_group_id,
                          uci_id: init.uci_id,
                          name: init.name,
                          cadaster_nb: init.cadaster_nb,
                          internal_ref: init.internal_ref,
                          units: operationAmount,
                          price: operationPrice,
                          code_type_id: init.code_type_id,
                          code_event_id: init.code_event_id,
                          code_subtype_id: init.code_subtype_id,
                          code_asset_type_id: init.code_asset_type_id,
                          code_denom_id: init.code_denom_id,
                          code_ccy_id: init.code_ccy_id,
                          code_uci_item_type_id: init.code_uci_item_type_id,
                          status_id: init.status_id,
                          holding_structure: init.holding_structure,
                          status_hs: init.status_hs,
                          notes: init.notes
                        }), (function (param) {
                        return Belt_Option.forEach(onOperation, (function (onOperation) {
                                      return Curry._1(onOperation, undefined);
                                    }));
                      }));
        }));
  var performOperation = match$3[1];
  var operationState = match$3[0];
  var selling = Belt_Option.mapWithDefault(operationType, false, (function (param) {
          return param.is_sell;
        }));
  var committing = Belt_Option.mapWithDefault(operationType, false, (function (param) {
          return param.code_uci_item_type_id === 2;
        }));
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Loader$Ucidata.make, {
                  className: "text-6xl my-10",
                  children: Belt_Option.map(codes, (function (codes) {
                          var currency = Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                  return elt.id === position.code_ccy_id;
                                }));
                          var tmp = {
                            name: "code-denom",
                            options: codes.codesDenom,
                            onChange: (function (param) {
                                
                              }),
                            label: React.createElement(Text$Ucidata.make, {
                                  children: "Denomination"
                                }),
                            isDisabled: true,
                            getOptionLabel: (function (denom) {
                                return denom.name;
                              }),
                            getOptionValue: (function (denom) {
                                return denom.id;
                              })
                          };
                          var tmp$1 = Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                  return elt.id === position.code_denom_id;
                                }));
                          if (tmp$1 !== undefined) {
                            tmp.value = Caml_option.valFromOption(tmp$1);
                          }
                          var tmp$2 = {
                            name: "code-currency",
                            options: codes.codesCcy,
                            onChange: (function (param) {
                                
                              }),
                            label: React.createElement(Text$Ucidata.make, {
                                  children: "Currency"
                                }),
                            isDisabled: true,
                            getOptionLabel: (function (ccy) {
                                return ccy.name;
                              }),
                            getOptionValue: (function (ccy) {
                                return ccy.id;
                              })
                          };
                          if (currency !== undefined) {
                            tmp$2.value = Caml_option.valFromOption(currency);
                          }
                          return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                          unstyled: true,
                                          children: React.createElement(Layout$Ucidata.make, {
                                                style: /* FlexBetween */3,
                                                children: null
                                              }, React.createElement(Font_Div$Ucidata.make, {
                                                    style: /* Title */1,
                                                    children: React.createElement(Icon$Ucidata.make, {
                                                          text: position.name,
                                                          icon: ProSolidSvgIcons.faBalanceScale,
                                                          className: "mr-2"
                                                        })
                                                  }), React.createElement("div", {
                                                    className: "flex items-center justify-center space-x-4"
                                                  }, React.createElement(OptionMap$Ucidata.make, {
                                                        option: onClose,
                                                        render: (function (onClose) {
                                                            return React.createElement(Button$Ucidata.make, {
                                                                        onClick: (function (param) {
                                                                            return Curry._1(onClose, undefined);
                                                                          }),
                                                                        children: React.createElement(Text$Ucidata.make, {
                                                                              children: "Close"
                                                                            })
                                                                      });
                                                          })
                                                      })))
                                        }), React.createElement(Section$Ucidata.make, {
                                          children: React.createElement(Collapsible$Ucidata.make, {
                                                openedByDefault: true,
                                                openedClassName: "mb-8",
                                                children: React.createElement("div", {
                                                      className: "space-y-4"
                                                    }, React.createElement(Layout$Ucidata.make, {
                                                          style: /* Grid2Cols */0,
                                                          children: null
                                                        }, React.createElement(Input_Number$Ucidata.make, {
                                                              id: "units",
                                                              value: position.units,
                                                              label: React.createElement(Text$Ucidata.make, {
                                                                    children: "Units"
                                                                  }),
                                                              disabled: true
                                                            }), React.createElement(Input_Select$Ucidata.make, tmp), React.createElement(Input_Number$Ucidata.make, {
                                                              id: "initial-comm-units",
                                                              value: position.initial_comm,
                                                              label: React.createElement(Text$Ucidata.make, {
                                                                    children: "Initial Commitment"
                                                                  }),
                                                              disabled: true
                                                            }), React.createElement(Input_Number$Ucidata.make, {
                                                              id: "open-comm-units",
                                                              value: position.opn_comm_units,
                                                              label: React.createElement(Text$Ucidata.make, {
                                                                    children: "Open Committment"
                                                                  }),
                                                              disabled: true
                                                            })), React.createElement(Layout$Ucidata.make, {
                                                          style: /* Grid2Cols */0,
                                                          children: null
                                                        }, React.createElement(Input_Number$Ucidata.make, {
                                                              id: "price",
                                                              value: position.price,
                                                              label: React.createElement(Text$Ucidata.make, {
                                                                    children: "Price"
                                                                  }),
                                                              placeholder: "Price",
                                                              disabled: true
                                                            }), React.createElement(Input_Select$Ucidata.make, tmp$2))),
                                                render: (function (param) {
                                                    return React.createElement(Font_Div$Ucidata.make, {
                                                                style: /* Subtitle */2,
                                                                children: React.createElement(Text$Ucidata.make, {
                                                                      children: "Details"
                                                                    })
                                                              });
                                                  })
                                              })
                                        }), React.createElement(Section$Ucidata.make, {
                                          title: React.createElement(Text$Ucidata.make, {
                                                children: "Operation"
                                              }),
                                          children: React.createElement(Validation$Ucidata.make, {
                                                render: (function (validation) {
                                                    var tmp = {
                                                      name: "operation-type",
                                                      options: Belt_Array.keep(codes.codesEvent, (function (elt) {
                                                              return elt.name !== "Capital Call A";
                                                            })),
                                                      onChange: (function (value) {
                                                          return Curry._1(setOperationType, (function (param) {
                                                                        return value;
                                                                      }));
                                                        }),
                                                      label: React.createElement(Text$Ucidata.make, {
                                                            children: "Operation Type"
                                                          }),
                                                      validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                                      getOptionLabel: (function (codeEvent) {
                                                          return codeEvent.name;
                                                        }),
                                                      getOptionValue: (function (codeEvent) {
                                                          return codeEvent.id;
                                                        })
                                                    };
                                                    var tmp$1 = Belt_Array.getBy(codes.codesEvent, (function (elt) {
                                                            return elt.id === Belt_Option.mapWithDefault(operationType, 0, (function (t) {
                                                                          return t.id;
                                                                        }));
                                                          }));
                                                    if (tmp$1 !== undefined) {
                                                      tmp.value = Caml_option.valFromOption(tmp$1);
                                                    }
                                                    var tmp$2 = Belt_Option.getExn(Belt_Option.map(Webapi__Dom__Document.asHtmlDocument(document), (function ($$document) {
                                                                return Caml_option.nullable_to_opt($$document.body);
                                                              })));
                                                    if (tmp$2 !== undefined) {
                                                      tmp.menuPortalTarget = Caml_option.valFromOption(tmp$2);
                                                    }
                                                    var units = committing ? position.opn_comm_units : position.units;
                                                    var partial_arg = selling ? [
                                                        Validation_Rules$Ucidata.Int.min(undefined, 1),
                                                        Validation_Rules$Ucidata.Int.max(undefined, units)
                                                      ] : [Validation_Rules$Ucidata.Int.min(undefined, 1)];
                                                    var tmp$3 = {
                                                      id: "amount",
                                                      value: operationAmount,
                                                      label: React.createElement("div", {
                                                            className: "inline-flex space-x-2"
                                                          }, React.createElement(Text_Span$Ucidata.make, {
                                                                children: committing ? "Amount" : "Units"
                                                              }), React.createElement(Font_Div$Ucidata.make, {
                                                                style: /* Italic */4,
                                                                children: React.createElement(Text$Ucidata.make, {
                                                                      children: "(" + (
                                                                        committing ? "committed" : "available"
                                                                      ) + ": " + String(units) + ")"
                                                                    })
                                                              })),
                                                      validate: (function (param) {
                                                          return Validation_Rules$Ucidata.compose(partial_arg, param);
                                                        }),
                                                      onChange: (function (value) {
                                                          return Curry._1(setOperationAmount, (function (param) {
                                                                        return value;
                                                                      }));
                                                        }),
                                                      placeholder: "Amount",
                                                      min: "1",
                                                      max: selling ? String(units) : ""
                                                    };
                                                    if (operationType !== undefined) {
                                                      tmp$3.validateDependency = Caml_option.valFromOption(operationType);
                                                    }
                                                    return React.createElement("div", {
                                                                className: "space-y-4"
                                                              }, React.createElement("div", undefined, React.createElement(Input_Select$Ucidata.make, tmp)), React.createElement("div", undefined, React.createElement(Input_Number$Ucidata.make, tmp$3)), React.createElement("div", undefined, React.createElement(Input_Number$Ucidata.make, {
                                                                        id: "price",
                                                                        value: operationPrice,
                                                                        label: React.createElement("div", {
                                                                              className: "inline-flex space-x-2"
                                                                            }, React.createElement(Text_Span$Ucidata.make, {
                                                                                  children: "Price"
                                                                                }), React.createElement(Font_Div$Ucidata.make, {
                                                                                  style: /* Italic */4,
                                                                                  children: React.createElement(Text$Ucidata.make, {
                                                                                        children: Belt_Option.mapWithDefault(currency, "", (function (param) {
                                                                                                return "(" + param.name + ")";
                                                                                              }))
                                                                                      })
                                                                                })),
                                                                        validate: Validation_Rules$Ucidata.Int.min("Minimum price is 1.", 1),
                                                                        onChange: (function (value) {
                                                                            return Curry._1(setOperationPrice, (function (param) {
                                                                                          return value;
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Price",
                                                                        min: "1"
                                                                      })), React.createElement("div", undefined, React.createElement(Button$Ucidata.make, {
                                                                        style: /* Primary */0,
                                                                        disabled: Validation_Context$Ucidata.invalid(validation) || operationState === /* Pending */1,
                                                                        spinner: operationState === /* Pending */1,
                                                                        onClick: (function (param) {
                                                                            Curry._1(performOperation, undefined);
                                                                            
                                                                          }),
                                                                        children: React.createElement(Text$Ucidata.make, {
                                                                              children: "Perform Operation"
                                                                            })
                                                                      })));
                                                  })
                                              })
                                        }));
                        }))
                }));
}

var make = React.memo(CompartimentPositions_Operation);

export {
  make ,
  
}
/* make Not a pure module */
