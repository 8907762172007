// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Table$Ucidata from "../../../core/Table.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as PopupMenu$Ucidata from "../../../core/PopupMenu.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var Table = Table$Ucidata.Make({});

function make(codes, uci, events) {
  return Curry._1(Table.Column.make, [
              {
                label: "Name",
                extract: (function (item) {
                    return item.name;
                  }),
                options: Curry._7(Table.Column.Options.make, true, true, (function (label, param) {
                        return React.createElement(Text_Span$Ucidata.make, {
                                    className: "mx-2",
                                    children: label
                                  });
                      }), undefined, undefined, undefined, undefined)
              },
              {
                label: "",
                extract: (function (param) {
                    return "";
                  }),
                options: Curry._7(Table.Column.Options.make, true, undefined, (function (param, item) {
                        return React.createElement(PopupMenu$Ucidata.make, {
                                    render: (function (setShowMenu) {
                                        var tmp = {
                                          roles: [
                                            /* Manager */0,
                                            /* BackOffice */2
                                          ],
                                          bypassWhenUserRoles: [
                                            /* AccountAdmin */1,
                                            /* HeadBackOffice */2
                                          ],
                                          children: React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                onClick: (function (param) {
                                                    Curry._1(events.onEditItemClick, item);
                                                    return Curry._1(setShowMenu, (function (param) {
                                                                  return false;
                                                                }));
                                                  }),
                                                children: React.createElement(Icon$Ucidata.make, {
                                                      text: "Edit",
                                                      icon: ProRegularSvgIcons.faEdit,
                                                      fixedWidth: true
                                                    }),
                                                key: "edit-item"
                                              })
                                        };
                                        if (uci !== undefined) {
                                          tmp.uci = Caml_option.valFromOption(uci);
                                        }
                                        return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                        onClick: (function (param) {
                                                            Curry._1(events.onDocumentsClick, item);
                                                            return Curry._1(setShowMenu, (function (param) {
                                                                          return false;
                                                                        }));
                                                          }),
                                                        children: React.createElement(Icon$Ucidata.make, {
                                                              text: "Documents",
                                                              icon: ProRegularSvgIcons.faFileAlt,
                                                              fixedWidth: true
                                                            }),
                                                        key: "show-documents"
                                                      }), React.createElement(IfUciRole$Ucidata.make, tmp));
                                      }),
                                    children: React.createElement(Button$Ucidata.make, {
                                          style: /* Unstyled */4,
                                          className: "h-8 px-2",
                                          children: React.createElement(Icon$Ucidata.make, {
                                                icon: ProRegularSvgIcons.faEllipsisH,
                                                className: "text-base"
                                              })
                                        })
                                  });
                      }), undefined, "", undefined, undefined)
              },
              {
                label: "Internal Ref.",
                extract: (function (item) {
                    return item.internal_ref;
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Nature",
                extract: (function (item) {
                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesNature, (function (elt) {
                                      return elt.id === item.code_nature_id;
                                    })), "", (function (elt) {
                                  return elt.name;
                                }));
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Country",
                extract: (function (item) {
                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesCountry, (function (elt) {
                                      return elt.id === item.code_country_id;
                                    })), "", (function (elt) {
                                  return elt.name;
                                }));
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Units",
                extract: (function (item) {
                    return item.units.toLocaleString();
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Denom.",
                extract: (function (item) {
                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                      return elt.id === item.code_denom_id;
                                    })), "", (function (elt) {
                                  return elt.name;
                                }));
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Cost",
                extract: (function (item) {
                    return item.cost.toLocaleString();
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Curr.",
                extract: (function (item) {
                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                      return elt.id === item.code_ccy_id;
                                    })), "", (function (elt) {
                                  return elt.name;
                                }));
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Holding %",
                extract: (function (item) {
                    return item.holdings.toLocaleString();
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              },
              {
                label: "Level",
                extract: (function (item) {
                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesLevel, (function (elt) {
                                      return elt.id === item.code_level_id;
                                    })), "", (function (elt) {
                                  return elt.name;
                                }));
                  }),
                options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
              }
            ]);
}

var Column;

export {
  Table ,
  Column ,
  make ,
  
}
/* Table Not a pure module */
