// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function flatten(r1, r2) {
  if (r1 === undefined) {
    if (r2 !== undefined) {
      return r2;
    } else {
      return ;
    }
  }
  if (r2 === undefined) {
    return r1;
  }
  var tmp;
  if (r1.TAG === /* Error */0) {
    var e1 = r1._0;
    tmp = r2.TAG === /* Error */0 ? [
        e1,
        r2._0
      ] : Belt_Array.concat(r2._0, [e1]);
  } else {
    var a1 = r1._0;
    tmp = r2.TAG === /* Error */0 ? Belt_Array.concat(a1, [r2._0]) : Belt_Array.concat(a1, r2._0);
  }
  return {
          TAG: /* Errors */1,
          _0: tmp
        };
}

function toExceptionList(result) {
  if (result === undefined) {
    return [];
  }
  var tmp;
  tmp = result.TAG === /* Error */0 ? [result._0] : result._0;
  return Belt_Array.map(tmp, (function (elt) {
                if (elt.TAG === /* Error */0) {
                  return elt._0;
                } else {
                  return new Error(elt._0);
                }
              }));
}

function errorFromMessage(message) {
  return {
          TAG: /* Error */0,
          _0: {
            TAG: /* String */1,
            _0: message
          }
        };
}

export {
  flatten ,
  toExceptionList ,
  errorFromMessage ,
  
}
/* No side effect */
