// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Unless$Ucidata from "./conditionals/Unless.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";

function Collapsible(Props) {
  var openedByDefaultOpt = Props.openedByDefault;
  var forceOpenOpt = Props.forceOpen;
  var className = Props.className;
  var iconClassName = Props.iconClassName;
  var onChange = Props.onChange;
  var openedClassName = Props.openedClassName;
  var openedContainerClassName = Props.openedContainerClassName;
  var containerClassName = Props.containerClassName;
  var contentClassName = Props.contentClassName;
  var disabledOpt = Props.disabled;
  var iconPositionOpt = Props.iconPosition;
  var iconCenteredOpt = Props.iconCentered;
  var shallowOpt = Props.shallow;
  var children = Props.children;
  var render = Props.render;
  var openedByDefault = openedByDefaultOpt !== undefined ? openedByDefaultOpt : false;
  var forceOpen = forceOpenOpt !== undefined ? forceOpenOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var iconPosition = iconPositionOpt !== undefined ? iconPositionOpt : /* Left */1;
  var iconCentered = iconCenteredOpt !== undefined ? iconCenteredOpt : true;
  var shallow = shallowOpt !== undefined ? shallowOpt : false;
  var match = React.useState(function () {
        return openedByDefault;
      });
  var setOpened = match[1];
  var opened = forceOpen || match[0];
  var icon = React.createElement("div", {
        className: Cn.$plus(Cn.$plus(Cn.$plus("flex justify-center", Cn.on("w-2.5em", iconCentered)), Cn.on("mr-2", !iconCentered && iconPosition === /* Left */1)), Cn.on("ml-2", !iconCentered && iconPosition === /* Right */0))
      }, React.createElement(Unless$Ucidata.make, {
            condition: disabled,
            children: React.createElement(Icon$Ucidata.make, {
                  icon: ProLightSvgIcons.faChevronUp,
                  className: Cn.$plus(Cn.$plus("transition-transform duration-200", Cn.on("fa-rotate-180", opened)), Cn.take(iconClassName))
                })
          }));
  var tmp;
  if (shallow) {
    var tmp$1 = {};
    if (contentClassName !== undefined) {
      tmp$1.className = Caml_option.valFromOption(contentClassName);
    }
    tmp = React.createElement(If$Ucidata.make, {
          condition: opened,
          children: React.createElement("div", tmp$1, Belt_Option.getWithDefault(children, null))
        });
  } else {
    var tmp$2 = {
      style: {
        display: opened ? "block" : "none"
      }
    };
    if (contentClassName !== undefined) {
      tmp$2.className = Caml_option.valFromOption(contentClassName);
    }
    tmp = React.createElement("div", tmp$2, Belt_Option.getWithDefault(children, null));
  }
  return React.createElement("div", {
              className: Cn.$plus(Cn.take(containerClassName), Cn.on(Cn.take(openedContainerClassName), opened))
            }, React.createElement("div", {
                  className: Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("flex items-center", Cn.on("cursor-pointer", !disabled)), Cn.take(className)), Cn.on(Cn.take(openedClassName), opened)), Cn.on("justify-between", iconPosition === /* Right */0)),
                  onClick: (function (param) {
                      if (!disabled) {
                        return Curry._1(setOpened, (function (o) {
                                      Belt_Option.forEach(onChange, (function (onChange) {
                                              return Curry._1(onChange, !o);
                                            }));
                                      return !o;
                                    }));
                      }
                      
                    })
                }, React.createElement(If$Ucidata.make, {
                      condition: iconPosition === /* Left */1,
                      children: icon
                    }), Curry._1(render, undefined), React.createElement(If$Ucidata.make, {
                      condition: iconPosition === /* Right */0,
                      children: icon
                    })), tmp);
}

var make = React.memo(Collapsible);

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
