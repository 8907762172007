// Generated by ReScript, PLEASE EDIT WITH CARE


var empty = {
  aifm: "",
  central_admin: "",
  uci_group_id: undefined
};

export {
  empty ,
  
}
/* No side effect */
