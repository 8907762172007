// Generated by ReScript, PLEASE EDIT WITH CARE


function upload(client, item, codeOversightDoc, file) {
  return client.url("/uci_ovs_docfile/" + String(item.id) + "/" + String(codeOversightDoc.id)).formData({
                  file: file
                }, undefined).post(undefined).json();
}

export {
  upload ,
  
}
/* No side effect */
