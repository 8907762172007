// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Validation_Context$Ucidata from "./Validation_Context.bs.js";

var make = Validation_Context$Ucidata.context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

export {
  make ,
  makeProps ,
  
}
/* make Not a pure module */
