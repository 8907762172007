// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as User$Ucidata from "../../../models/User.bs.js";
import * as Codes$Ucidata from "../../Codes.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Unless$Ucidata from "../../core/conditionals/Unless.bs.js";
import * as Wretch$Ucidata from "../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../core/conditionals/OptionMap.bs.js";
import * as Input_Text$Ucidata from "../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../core/validation/Validation.bs.js";
import * as Input_Select$Ucidata from "../../core/input/Input_Select.bs.js";
import * as Validation_Rules$Ucidata from "../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../core/validation/Validation_Context.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Admin_UserDetails(Props) {
  var user = Props.user;
  var onSave = Props.onSave;
  var onCancel = Props.onCancel;
  var client = Client$Ucidata.useClient(undefined);
  var codes = Codes$Ucidata.useCodes(undefined);
  var editionMode = Belt_Option.isSome(user);
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(user, User$Ucidata.empty(undefined));
      });
  var setUser = match[1];
  var user$1 = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPwd = match$1[1];
  var pwd = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setPwdConfirmation = match$2[1];
  var pwdConfirmation = match$2[0];
  var match$3 = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveError = match$3[2];
  var setSaveError = match$3[1];
  var saveError = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setSaving = match$4[1];
  var saving = match$4[0];
  var saveUser = function (param) {
    Curry._1(clearSaveError, undefined);
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    if (editionMode) {
      return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(User$Ucidata.update(client, user$1), (function (user) {
                                return Belt_Option.forEach(onSave, (function (onSave) {
                                              return Curry._1(onSave, user);
                                            }));
                              })), setSaveError), (function (param) {
                        return Curry._1(setSaving, (function (param) {
                                      return false;
                                    }));
                      })));
    } else {
      return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(Wretch$Ucidata.map(User$Ucidata.signup(client, user$1, pwd), (function (param) {
                                    return User$Ucidata.set_roles(client, param.id, Belt_Option.getWithDefault(user$1.user_roles, []));
                                  })), (function (user) {
                                return Belt_Option.forEach(onSave, (function (onSave) {
                                              return Curry._1(onSave, user);
                                            }));
                              })), setSaveError), (function (param) {
                        return Curry._1(setSaving, (function (param) {
                                      return false;
                                    }));
                      })));
    }
  };
  var userRoles = React.useMemo((function () {
          return Belt_Option.mapWithDefault(codes, [], (function (codes) {
                        return codes.codesRole;
                      }));
        }), [codes]);
  return React.createElement(Validation$Ucidata.make, {
              render: (function (validation) {
                  var tmp = {};
                  if (saveError !== undefined) {
                    tmp.error = Caml_option.valFromOption(saveError);
                  }
                  var tmp$1 = {
                    name: "user-roles",
                    options: userRoles,
                    onChange: (function (roles) {
                        return Curry._1(setUser, (function (user) {
                                      return {
                                              id: user.id,
                                              first_name: user.first_name,
                                              last_name: user.last_name,
                                              identifier: user.identifier,
                                              email: user.email,
                                              user_roles: Belt_Array.map(roles, (function (param) {
                                                      return param.id;
                                                    }))
                                            };
                                    }));
                      }),
                    label: React.createElement(Text$Ucidata.make, {
                          children: "User Roles"
                        }),
                    isMulti: true,
                    getOptionLabel: (function (userRole) {
                        return userRole.name;
                      }),
                    getOptionValue: (function (userRole) {
                        return userRole.id;
                      })
                  };
                  var tmp$2 = Belt_Option.map(codes, (function (codes) {
                          return Belt_Array.keep(codes.codesRole, (function (codeRole) {
                                        return Belt_Option.getWithDefault(user$1.user_roles, []).includes(codeRole.id);
                                      }));
                        }));
                  if (tmp$2 !== undefined) {
                    tmp$1.value = Caml_option.valFromOption(tmp$2);
                  }
                  var partial_arg = [
                    Validation_Rules$Ucidata.$$String.required("Please confirm the user password.", undefined),
                    Validation_Rules$Ucidata.$$String.equal(pwd, "The confirmation does not match the password.")
                  ];
                  return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                  unstyled: true,
                                  children: React.createElement(Layout$Ucidata.make, {
                                        style: /* FlexBetween */3,
                                        children: null
                                      }, React.createElement(Font_Div$Ucidata.make, {
                                            style: /* Title */1,
                                            children: editionMode ? React.createElement(Icon$Ucidata.make, {
                                                    text: user$1.identifier,
                                                    icon: ProSolidSvgIcons.faEdit,
                                                    className: "mr-2"
                                                  }) : React.createElement(Icon$Ucidata.make, {
                                                    text: "Create User",
                                                    icon: ProLightSvgIcons.faPlus,
                                                    className: "mr-2"
                                                  })
                                          }), React.createElement("div", {
                                            className: "flex items-center justify-center space-x-4"
                                          }, React.createElement(OptionMap$Ucidata.make, {
                                                option: onCancel,
                                                render: (function (onCancel) {
                                                    return React.createElement(Button$Ucidata.make, {
                                                                onClick: (function (param) {
                                                                    return Curry._1(onCancel, undefined);
                                                                  }),
                                                                children: React.createElement(Text$Ucidata.make, {
                                                                      children: "Cancel"
                                                                    })
                                                              });
                                                  })
                                              }), React.createElement(Button$Ucidata.make, {
                                                style: /* Primary */0,
                                                disabled: Validation_Context$Ucidata.invalid(validation),
                                                icon: ProRegularSvgIcons.faSave,
                                                spinner: saving,
                                                onClick: saveUser,
                                                children: editionMode ? React.createElement(Text$Ucidata.make, {
                                                        children: "Update"
                                                      }) : React.createElement(Text$Ucidata.make, {
                                                        children: "Create"
                                                      })
                                              })))
                                }), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Section$Ucidata.make, {
                                  className: "space-y-4",
                                  children: null
                                }, React.createElement("div", undefined, React.createElement(Input_Select$Ucidata.make, tmp$1)), React.createElement(Layout$Ucidata.make, {
                                      style: /* Grid2Cols */0,
                                      children: null
                                    }, React.createElement(Input_Text$Ucidata.make, {
                                          id: "first-name",
                                          label: React.createElement(Text$Ucidata.make, {
                                                children: "First Name"
                                              }),
                                          value: user$1.first_name,
                                          validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                          onChange: (function (first_name) {
                                              return Curry._1(setUser, (function (user) {
                                                            return {
                                                                    id: user.id,
                                                                    first_name: first_name,
                                                                    last_name: user.last_name,
                                                                    identifier: user.identifier,
                                                                    email: user.email,
                                                                    user_roles: user.user_roles
                                                                  };
                                                          }));
                                            }),
                                          placeholder: "First Name"
                                        }), React.createElement(Input_Text$Ucidata.make, {
                                          id: "last-name",
                                          label: React.createElement(Text$Ucidata.make, {
                                                children: "Last Name"
                                              }),
                                          value: user$1.last_name,
                                          validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                          onChange: (function (last_name) {
                                              return Curry._1(setUser, (function (user) {
                                                            return {
                                                                    id: user.id,
                                                                    first_name: user.first_name,
                                                                    last_name: last_name,
                                                                    identifier: user.identifier,
                                                                    email: user.email,
                                                                    user_roles: user.user_roles
                                                                  };
                                                          }));
                                            }),
                                          placeholder: "Last Name"
                                        }), React.createElement(Input_Text$Ucidata.make, {
                                          id: "email",
                                          label: React.createElement(Text$Ucidata.make, {
                                                children: "Email"
                                              }),
                                          value: user$1.email,
                                          validate: Validation_Rules$Ucidata.$$String.email(undefined, undefined),
                                          onChange: (function (email) {
                                              return Curry._1(setUser, (function (user) {
                                                            return {
                                                                    id: user.id,
                                                                    first_name: user.first_name,
                                                                    last_name: user.last_name,
                                                                    identifier: user.identifier,
                                                                    email: email,
                                                                    user_roles: user.user_roles
                                                                  };
                                                          }));
                                            }),
                                          type_: "email",
                                          placeholder: "john@doe.com"
                                        }), React.createElement(Input_Text$Ucidata.make, {
                                          id: "identifier",
                                          label: React.createElement(Text$Ucidata.make, {
                                                children: "Identifier"
                                              }),
                                          value: user$1.identifier,
                                          validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                          onChange: (function (identifier) {
                                              return Curry._1(setUser, (function (user) {
                                                            return {
                                                                    id: user.id,
                                                                    first_name: user.first_name,
                                                                    last_name: user.last_name,
                                                                    identifier: identifier,
                                                                    email: user.email,
                                                                    user_roles: user.user_roles
                                                                  };
                                                          }));
                                            }),
                                          placeholder: "Identifier"
                                        }), React.createElement(Unless$Ucidata.make, {
                                          condition: editionMode,
                                          children: null
                                        }, React.createElement(Input_Text$Ucidata.make, {
                                              id: "pwd",
                                              label: React.createElement(Text$Ucidata.make, {
                                                    children: "Password"
                                                  }),
                                              value: pwd,
                                              validate: Validation_Rules$Ucidata.$$String.required("Please enter user the password.", undefined),
                                              onChange: (function (value) {
                                                  return Curry._1(setPwd, (function (param) {
                                                                return value;
                                                              }));
                                                }),
                                              type_: "password",
                                              placeholder: "*********"
                                            }), React.createElement(Input_Text$Ucidata.make, {
                                              id: "pwd-confirmation",
                                              label: React.createElement(Text$Ucidata.make, {
                                                    children: "Confirm Password"
                                                  }),
                                              value: pwdConfirmation,
                                              validate: (function (param) {
                                                  return Validation_Rules$Ucidata.compose(partial_arg, param);
                                                }),
                                              validateDependency: pwd,
                                              onChange: (function (value) {
                                                  return Curry._1(setPwdConfirmation, (function (param) {
                                                                return value;
                                                              }));
                                                }),
                                              type_: "password",
                                              placeholder: "*********"
                                            })))));
                })
            });
}

var make = Admin_UserDetails;

export {
  make ,
  
}
/* react Not a pure module */
