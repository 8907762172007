// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as ArrayMap$Ucidata from "../../../core/conditionals/ArrayMap.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as Input_Toggle$Ucidata from "../../../core/input/Input_Toggle.bs.js";

function Compartiment_Documents_Element$SubTypeDocuments(Props) {
  var subType = Props.subType;
  var docs = Props.docs;
  var optionalDocumentIds = Props.optionalDocumentIds;
  var dispatchUci = Props.dispatchUci;
  var isEditable = Props.isEditable;
  var optionalDocsCounter = Belt_Array.reduce(docs, 0, (function (count, doc) {
          if (optionalDocumentIds.includes(doc.id)) {
            return count + 1 | 0;
          } else {
            return count;
          }
        }));
  var checked = optionalDocsCounter === 0 ? true : (
      optionalDocsCounter === docs.length ? false : undefined
    );
  return React.createElement(Collapsible$Ucidata.make, {
              className: "text-left flex items-center p-2 px-4",
              containerClassName: "flex-auto border bg-white m-2",
              children: React.createElement("div", {
                    className: "px-4 mb-2 border-t w-full text-xs"
                  }, React.createElement("table", {
                        className: "table-auto w-full"
                      }, React.createElement("thead", {
                            className: "text-gray-700 border-b text-sm"
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "py-4 text-left text-xs"
                                  }, React.createElement(Text$Ucidata.make, {
                                        children: "Document Name"
                                      })), React.createElement("th", {
                                    className: "text-right text-xs"
                                  }, React.createElement(Text$Ucidata.make, {
                                        children: "Compulsory"
                                      })))), React.createElement("tbody", {
                            className: "divide-y"
                          }, React.createElement(ArrayMap$Ucidata.make, {
                                array: docs,
                                render: (function (doc) {
                                    return React.createElement("tr", {
                                                key: String(doc.id),
                                                className: "hover:bg-blue-50 transition-colors duration-200"
                                              }, React.createElement("td", {
                                                    className: "py-2"
                                                  }, React.createElement(Text$Ucidata.make, {
                                                        children: doc.code_doc
                                                      })), React.createElement("td", {
                                                    className: "py-2 text-right"
                                                  }, React.createElement(Input_Toggle$Ucidata.make, {
                                                        checked: !optionalDocumentIds.includes(doc.id),
                                                        setChecked: (function (checked) {
                                                            return Curry._1(dispatchUci, {
                                                                        TAG: /* SetDocumentsCompulsory */3,
                                                                        _0: [doc.id],
                                                                        _1: checked
                                                                      });
                                                          }),
                                                        disabled: !isEditable
                                                      })));
                                  })
                              })))),
              render: (function (param) {
                  var tmp = {
                    setChecked: (function (checked) {
                        return Curry._1(dispatchUci, {
                                    TAG: /* SetDocumentsCompulsory */3,
                                    _0: Belt_Array.map(docs, (function (doc) {
                                            return doc.id;
                                          })),
                                    _1: checked
                                  });
                      }),
                    disabled: !isEditable
                  };
                  if (checked !== undefined) {
                    tmp.checked = checked;
                  }
                  return React.createElement("div", {
                              className: "inline-flex items-center justify-between space-x-4 flex-auto"
                            }, React.createElement(Text_Span$Ucidata.make, {
                                  children: subType
                                }), React.createElement(Input_Toggle$Ucidata.make, tmp));
                }),
              key: subType
            });
}

var make = React.memo(Compartiment_Documents_Element$SubTypeDocuments);

var SubTypeDocuments = {
  make: make
};

function Compartiment_Documents_Element(Props) {
  var codeTypeId = Props.codeTypeId;
  var optionalDocumentIds = Props.optionalDocumentIds;
  var dispatchUci = Props.dispatchUci;
  var isEditable = Props.isEditable;
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_type");
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_type_doc");
  var codeTypes = Client$Ucidata.data(undefined, match[0]);
  var documents = Client$Ucidata.data(undefined, match$1[0]);
  var codeTypeOpt = React.useMemo((function () {
          return Belt_Option.flatMap(codeTypes, (function (codeTypes) {
                        return Belt_Array.getBy(codeTypes, (function (c) {
                                      return c.id === codeTypeId;
                                    }));
                      }));
        }), [codeTypes]);
  var sortedTypeDocsOpt = React.useMemo((function () {
          return Belt_Option.map(documents, (function (documents) {
                        return Belt_Array.reduce(documents, {}, (function (acc, curr) {
                                      if (curr.code_type_id !== codeTypeId) {
                                        return acc;
                                      }
                                      if (Js_dict.get(acc, curr.code_event) === undefined) {
                                        acc[curr.code_event] = {};
                                      }
                                      var innerDict = acc[curr.code_event];
                                      if (Js_dict.get(innerDict, curr.code_subtype) === undefined) {
                                        innerDict[curr.code_subtype] = [];
                                      }
                                      innerDict[curr.code_subtype].push(curr);
                                      return acc;
                                    }));
                      }));
        }), [
        codeTypeOpt,
        documents
      ]);
  if (codeTypeOpt !== undefined && sortedTypeDocsOpt !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement(Font_Div$Ucidata.make, {
                    style: /* Large */3,
                    className: "pb-2 border-b-2 text-gray-700 border-gray-700",
                    children: React.createElement(Text$Ucidata.make, {
                          children: codeTypeOpt.name
                        })
                  }), React.createElement("div", {
                    className: "ml-4 space-y-4"
                  }, React.createElement(ArrayMap$Ucidata.make, {
                        array: Js_dict.entries(Caml_option.valFromOption(sortedTypeDocsOpt)),
                        render: (function (param) {
                            var $$event = param[0];
                            return React.createElement(Collapsible$Ucidata.make, {
                                        className: "border-b w-full pb-2 text-left",
                                        children: React.createElement("div", {
                                              className: "md:flex flex-wrap items-start my-2 text-sm"
                                            }, React.createElement(ArrayMap$Ucidata.make, {
                                                  array: Js_dict.entries(param[1]),
                                                  render: (function (param) {
                                                      var subType = param[0];
                                                      return React.createElement(make, {
                                                                  subType: subType,
                                                                  docs: param[1],
                                                                  optionalDocumentIds: optionalDocumentIds,
                                                                  dispatchUci: dispatchUci,
                                                                  isEditable: isEditable,
                                                                  key: subType
                                                                });
                                                    })
                                                })),
                                        render: (function (param) {
                                            return React.createElement(Text$Ucidata.make, {
                                                        children: $$event
                                                      });
                                          }),
                                        key: $$event
                                      });
                          })
                      })));
  } else {
    return null;
  }
}

var make$1 = React.memo(Compartiment_Documents_Element);

export {
  SubTypeDocuments ,
  make$1 as make,
  
}
/* make Not a pure module */
