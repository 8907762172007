// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function OptionMap(Props) {
  var render = Props.render;
  var option = Props.option;
  return Belt_Option.mapWithDefault(option, null, render);
}

var make = OptionMap;

export {
  make ,
  
}
/* No side effect */
