// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";

function on(condition, element) {
  if (condition) {
    return element;
  } else {
    return null;
  }
}

function onLazy(condition, element) {
  if (condition) {
    return CamlinternalLazy.force(element);
  } else {
    return null;
  }
}

function unless(condition) {
  var partial_arg = !condition;
  return function (param) {
    return on(partial_arg, param);
  };
}

function onSome(option, element) {
  return on(option !== undefined, element);
}

function map(opt, fn) {
  return Belt_Option.mapWithDefault(opt, null, fn);
}

function map2(param, fn) {
  var opt2 = param[1];
  var opt = param[0];
  if (opt !== undefined && opt2 !== undefined) {
    return Curry._2(fn, Caml_option.valFromOption(opt), Caml_option.valFromOption(opt2));
  } else {
    return null;
  }
}

function take(opt) {
  return Belt_Option.getWithDefault(opt, null);
}

function array(renderEmptyOpt, a, fn) {
  var renderEmpty = renderEmptyOpt !== undefined ? renderEmptyOpt : (function (param) {
        return null;
      });
  if (a.length !== 0) {
    return a.map(Curry.__2(fn));
  } else {
    return Curry._1(renderEmpty, undefined);
  }
}

export {
  on ,
  onLazy ,
  unless ,
  onSome ,
  map ,
  map2 ,
  take ,
  array ,
  
}
/* No side effect */
