// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Hooks$Ucidata from "../Hooks.bs.js";
import * as Button$Ucidata from "./Button.bs.js";

function PopupMenu$MenuElement(Props) {
  var onClick = Props.onClick;
  var className = Props.className;
  var children = Props.children;
  var className$1 = Cn.$plus("px-4 py-3 w-full text-left text-sm", Cn.take(className));
  if (onClick === undefined) {
    return React.createElement("div", {
                className: className$1,
                role: "menuitem"
              }, children);
  }
  var tmp = {
    style: /* Unstyled */4,
    className: Cn.$plus(className$1, "hover:bg-gray-100 block"),
    children: children,
    role: "menuitem"
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(Button$Ucidata.make, tmp);
}

var make = React.memo(PopupMenu$MenuElement);

var MenuElement = {
  make: make
};

function PopupMenu(Props) {
  var render = Props.render;
  var positionOpt = Props.position;
  var className = Props.className;
  var containerClassName = Props.containerClassName;
  var children = Props.children;
  var position = positionOpt !== undefined ? positionOpt : /* BottomRightAlign */6;
  var match = React.useState(function () {
        return false;
      });
  var setShowMenu = match[1];
  var showMenu = match[0];
  var container = Hooks$Ucidata.Events.useClickListenerContainer(function (insideContainer) {
        return Curry._1(setShowMenu, (function (param) {
                      return insideContainer;
                    }));
      });
  var positionStyle;
  switch (position) {
    case /* LeftTopAlign */0 :
        positionStyle = "right-full top-0 mr-2";
        break;
    case /* LeftBottomAlign */1 :
        positionStyle = "right-full bottom-0 mr-2";
        break;
    case /* RightTopAlign */2 :
        positionStyle = "left-full top-0 ml-2";
        break;
    case /* RightBottomAlign */3 :
        positionStyle = "left-full bottom-0 ml-2";
        break;
    case /* TopRightAlign */4 :
        positionStyle = "bottom-full right-0 mb-2";
        break;
    case /* TopLeftAlign */5 :
        positionStyle = "bottom-full left-0 mb-2";
        break;
    case /* BottomRightAlign */6 :
        positionStyle = "top-full right-0 mt-2";
        break;
    case /* BottomLeftAlign */7 :
        positionStyle = "top-full left-0 mt-2";
        break;
    case /* TopLeftCorner */8 :
        positionStyle = "bottom-full right-full";
        break;
    case /* TopRightCorner */9 :
        positionStyle = "bottom-full left-full";
        break;
    case /* BottomLeftCorner */10 :
        positionStyle = "top-full right-full";
        break;
    case /* BottomRightCorner */11 :
        positionStyle = "top-full left-full";
        break;
    
  }
  return React.createElement("div", {
              ref: container,
              className: Cn.$plus(Cn.$plus("relative", Cn.on("z-10", showMenu)), Cn.take(containerClassName))
            }, React.createElement(React.Fragment, undefined, children), React.createElement(If$Ucidata.make, {
                  condition: showMenu,
                  children: React.createElement("div", {
                        "aria-labelledby": "user-menu",
                        className: Cn.$plus(Cn.$plus("origin-top-right absolute rounded-md shadow-lg overflow-hidden bg-white ring-1 ring-black ring-opacity-5 whitespace-nowrap", positionStyle), Cn.take(className)),
                        role: "menu"
                      }, Curry._1(render, setShowMenu))
                }));
}

var make$1 = React.memo(PopupMenu);

var $$default = make$1;

export {
  MenuElement ,
  make$1 as make,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
