// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as User$Ucidata from "../models/User.bs.js";
import * as Store$Ucidata from "../store/Store.bs.js";
import * as Client$Ucidata from "../Client.bs.js";
import * as Loader$Ucidata from "./core/loader/Loader.bs.js";
import * as Router$Ucidata from "./core/router/Router.bs.js";
import * as Wretch$Ucidata from "../libs/Wretch.bs.js";
import * as LoginPage$Ucidata from "./pages/LoginPage.bs.js";
import * as PageShell$Ucidata from "./PageShell.bs.js";
import * as Breadcrumbs$Ucidata from "./Breadcrumbs.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as UserContext$Ucidata from "./UserContext.bs.js";
import * as React$1 from "hyperactiv/src/react";

function Main(Props) {
  var client = Client$Ucidata.useClient(undefined);
  var match = React.useState(function () {
        
      });
  var setUser = match[1];
  var user = match[0];
  var match$1 = React.useState(function () {
        return true;
      });
  var setFirstUserLoad = match$1[1];
  var loggedIn = user !== undefined;
  var fetchUser = React.useCallback((function (param) {
          return Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(User$Ucidata.me(client), (function (user) {
                            return Curry._1(setUser, (function (param) {
                                          return user;
                                        }));
                          })), (function (param) {
                        return Curry._1(setUser, (function (param) {
                                      
                                    }));
                      }));
        }), []);
  var logout = React.useCallback((function (param) {
          return Wretch$Ucidata.ignore(Client$Ucidata.cached.options({
                                clearCache: true,
                                skipCache: true
                              }).url("/sign_out").delete().res(function (param) {
                          Curry._1(setUser, (function (param) {
                                  
                                }));
                          return RescriptReactRouter.push("/");
                        }));
        }), []);
  React.useEffect((function () {
          Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Curry._1(fetchUser, undefined), (function (param) {
                      return Curry._1(setFirstUserLoad, (function (param) {
                                    
                                  }));
                    })));
          
        }), []);
  var jsx = loggedIn ? React.createElement(PageShell$Ucidata.make, {
          header: React.createElement(Breadcrumbs$Ucidata.make, {}),
          logout: logout,
          children: React.createElement(Router$Ucidata.make, {})
        }) : React.createElement(LoginPage$Ucidata.make, {
          fetchUser: fetchUser
        });
  return React.createElement(UserContext$Ucidata.Provider.make, UserContext$Ucidata.Provider.makeProps(user, React.createElement(React$1.HyperactivProvider, {
                      store: Store$Ucidata.make(undefined),
                      children: React.createElement(Loader$Ucidata.make, {
                            className: "w-screen h-screen text-8xl bg-gray-800 text-white",
                            children: match$1[0] === undefined ? Caml_option.some(jsx) : undefined
                          })
                    }), undefined));
}

var make = Main;

export {
  make ,
  
}
/* react Not a pure module */
