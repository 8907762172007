// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function empty(uciId) {
  return {
          id: undefined,
          uci_id: uciId,
          bank_name: "",
          iban: "",
          balance: 0,
          code_ccy_id: 0,
          code_monitor_freq: 0,
          code_toggle: 0,
          code_monitor_acc: 0
        };
}

function create(client, item) {
  return client.url("/cash_account").post(item).json();
}

function update(client, item) {
  return client.url("/cash_account/" + String(Belt_Option.getExn(item.id))).put(item).json();
}

function save(client, item) {
  var match = item.id;
  if (match !== undefined) {
    return update(client, item);
  } else {
    return create(client, item);
  }
}

function toItem(detailed) {
  return {
          id: detailed.id,
          uci_id: detailed.uci_id,
          bank_name: detailed.bank_name,
          iban: detailed.iban,
          balance: detailed.balance,
          code_ccy_id: detailed.code_ccy_id,
          code_monitor_freq: detailed.code_monitor_freq,
          code_toggle: detailed.code_toggle,
          code_monitor_acc: detailed.code_monitor_acc
        };
}

var Detailed = {
  toItem: toItem
};

function $$fetch(client, id) {
  return client.url("/api/cash_account/" + id).get().json();
}

export {
  empty ,
  create ,
  update ,
  save ,
  Detailed ,
  $$fetch ,
  
}
/* No side effect */
