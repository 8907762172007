// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Modal$Ucidata from "../../../core/modals/Modal.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as PageLoader$Ucidata from "../../../core/loader/PageLoader.bs.js";
import * as Home_Controller_Columns$Ucidata from "./Home_Controller_Columns.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import * as CompartimentTransactions_Documents$Ucidata from "../../assets/transactions/CompartimentTransactions_Documents.bs.js";
import * as CompartimentTransactions_ItemPosition$Ucidata from "../../assets/transactions/CompartimentTransactions_ItemPosition.bs.js";

var uploadedValidationStatus = "1";

function Home_Controller(Props) {
  var codes = Codes$Ucidata.useCodes(undefined);
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var fetchItems = match[1];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci_item/status/" + uploadedValidationStatus);
  var itemsState = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedItem = match$2[1];
  var selectedItem = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowDocumentsModal = match$3[1];
  var showDocumentsModal = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setShowPositionModal = match$4[1];
  var showPositionModal = match$4[0];
  var content;
  var exit = 0;
  if (typeof itemsState === "number") {
    content = React.createElement(Island$Ucidata.make, {
          style: /* Error */2,
          title: "Error",
          icon: ProSolidSvgIcons.faBomb,
          children: React.createElement(Text$Ucidata.make, {
                children: "An error occured while retrieving the items list."
              })
        });
  } else {
    switch (itemsState.TAG | 0) {
      case /* Loading */0 :
          exit = 1;
          break;
      case /* Error */1 :
          content = React.createElement(Island$Ucidata.make, {
                style: /* Error */2,
                title: "Error",
                icon: ProSolidSvgIcons.faBomb,
                children: React.createElement(Text$Ucidata.make, {
                      children: Utils$Ucidata.getErrorMessage(itemsState._0)
                    })
              });
          break;
      case /* Data */2 :
          if (itemsState._0.length !== 0) {
            if (codes !== undefined) {
              var columns = Home_Controller_Columns$Ucidata.make(codes, {
                    onDocumentsClick: (function (item) {
                        Curry._1(setShowDocumentsModal, (function (param) {
                                return true;
                              }));
                        return Curry._1(setSelectedItem, (function (param) {
                                      return item;
                                    }));
                      }),
                    onShowPosition: (function (item) {
                        Curry._1(setShowPositionModal, (function (param) {
                                return true;
                              }));
                        return Curry._1(setSelectedItem, (function (param) {
                                      return item;
                                    }));
                      })
                  });
              content = React.createElement(Home_Controller_Columns$Ucidata.Table.make, {
                    columns: columns,
                    data: itemsState._0,
                    dataKey: (function (item) {
                        return String(item.id);
                      }),
                    className: "table-auto whitespace-nowrap border-separate rounded border text-xs text-center",
                    tableExtraClass: "rounded-l-none",
                    fixedTableExtraClass: "border-r-8 rounded-r-none",
                    containerClassName: "border-r rounded border-dashed",
                    thClassName: "py-4 px-2 font-medium",
                    tdClassName: "py-4 px-2",
                    headTrClassName: "bg-gray-100 uppercase",
                    bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
                    paginationLimit: 20
                  });
            } else {
              exit = 1;
            }
          } else {
            content = React.createElement(Text$Ucidata.make, {
                  children: "No items to show."
                });
          }
          break;
      
    }
  }
  if (exit === 1) {
    content = React.createElement(PageLoader$Ucidata.make, {});
  }
  return React.createElement(React.Fragment, undefined, React.createElement(OptionMap$Ucidata.make, {
                  option: selectedItem,
                  render: (function (item) {
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showDocumentsModal,
                                  setOpen: setShowDocumentsModal,
                                  closeOnEsc: true,
                                  size: /* Fullscreen */0,
                                  children: React.createElement(CompartimentTransactions_Documents$Ucidata.make, {
                                        item: item,
                                        onClose: (function (param) {
                                            return Curry._1(setShowDocumentsModal, (function (param) {
                                                          return false;
                                                        }));
                                          }),
                                        onSave: (function (param) {
                                            return Curry._1(fetchItems, undefined);
                                          })
                                      })
                                });
                    })
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: selectedItem,
                  render: (function (item) {
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showPositionModal,
                                  setOpen: setShowPositionModal,
                                  closeOnOutsideClick: true,
                                  closeOnEsc: true,
                                  size: /* Large */1,
                                  children: React.createElement(CompartimentTransactions_ItemPosition$Ucidata.make, {
                                        itemId: String(item.id),
                                        onClose: (function (param) {
                                            return Curry._1(setShowPositionModal, (function (param) {
                                                          return false;
                                                        }));
                                          }),
                                        onOperation: fetchItems
                                      })
                                });
                    })
                }), React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Text$Ucidata.make, {
                        children: "Items to review"
                      }),
                  icon: ProRegularSvgIcons.faHourglass,
                  children: content
                }));
}

var make = React.memo(Home_Controller);

var Columns;

var Documents;

var ItemPosition;

export {
  uploadedValidationStatus ,
  Columns ,
  Documents ,
  ItemPosition ,
  make ,
  
}
/* make Not a pure module */
