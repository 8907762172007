// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as Compartiment_Documents_Element$Ucidata from "./Compartiment_Documents_Element.bs.js";

function Compartiment_Documents(Props) {
  var uci = Props.uci;
  var dispatchUci = Props.dispatchUci;
  var isEditable = Props.isEditable;
  var match = uci.types.length;
  var tmp = {
    title: React.createElement(Text$Ucidata.make, {
          children: "Documents"
        }),
    icon: ProLightSvgIcons.faFile,
    children: match !== 0 ? React.createElement("div", {
            className: "space-y-6"
          }, React.createElement(ArrayMapi$Ucidata.make, {
                array: uci.types,
                render: (function (uciType, idx) {
                    return React.createElement(Compartiment_Documents_Element$Ucidata.make, {
                                codeTypeId: uciType,
                                optionalDocumentIds: uci.doc_opts,
                                dispatchUci: dispatchUci,
                                isEditable: isEditable,
                                key: String(idx)
                              });
                  })
              })) : React.createElement(Island$Ucidata.make, {
            icon: ProSolidSvgIcons.faInfoCircle,
            children: React.createElement(Text$Ucidata.make, {
                  children: isEditable ? "Select one or more compartiment types to configure the associated documents." : "The compartiment has no types."
                })
          })
  };
  var tmp$1 = Utils$Ucidata.$$Option.fromBool(isEditable, "Toggle which documents should be mandatory for this compartiment, classified by type.");
  if (tmp$1 !== undefined) {
    tmp.description = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Section$Ucidata.make, tmp);
}

var make = React.memo(Compartiment_Documents);

var $$Element;

export {
  $$Element ,
  make ,
  
}
/* make Not a pure module */
