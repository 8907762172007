// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font_Component$Ucidata from "./Font_Component.bs.js";

function Font_Div(Props) {
  var style = Props.style;
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    style: style,
    element: "div",
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Font_Component$Ucidata.make, tmp);
}

var make = React.memo(Font_Div);

export {
  make ,
  
}
/* make Not a pure module */
