// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as If$Ucidata from "../conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../Icon.bs.js";
import * as Text$Ucidata from "../text/Text.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Input_Text$Ucidata from "./Input_Text.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Input_Search$Element(Props) {
  var className = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  return React.createElement("li", {
              className: Cn.$plus("cursor-pointer hover:text-black p-1", Cn.take(className)),
              onClick: (function (e) {
                  return Belt_Option.forEach(onClick, (function (f) {
                                return Curry._1(f, e);
                              }));
                })
            }, children);
}

var make = React.memo(Input_Search$Element);

var $$Element = {
  make: make
};

function Input_Search(Props) {
  var id = Props.id;
  var value = Props.value;
  var labelOpt = Props.label;
  var onChange = Props.onChange;
  var loadingOpt = Props.loading;
  var menuElements = Props.menuElements;
  var className = Props.className;
  var containerClassName = Props.containerClassName;
  var validate = Props.validate;
  var placeholder = Props.placeholder;
  var hideWhenNoResultsOpt = Props.hideWhenNoResults;
  var disabled = Props.disabled;
  var size = Props.size;
  var label = labelOpt !== undefined ? labelOpt : "Search";
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var hideWhenNoResults = hideWhenNoResultsOpt !== undefined ? hideWhenNoResultsOpt : false;
  var match = React.useState(function () {
        return false;
      });
  var setShowMenu = match[1];
  var showMenu = match[0];
  var closeMenu = function (param) {
    return Curry._1(setShowMenu, (function (param) {
                  return false;
                }));
  };
  var menuElements$1 = Belt_Option.map(menuElements, (function (menuElements) {
          return Curry._1(menuElements, closeMenu);
        }));
  var container = Hooks$Ucidata.Events.useClickListenerContainer(function (insideContainer) {
        return Curry._1(setShowMenu, (function (param) {
                      return insideContainer;
                    }));
      });
  var showMenu$1 = Belt_Option.mapWithDefault(menuElements$1, false, (function (menuElements) {
          if (showMenu && value.length !== 0) {
            if (hideWhenNoResults) {
              return menuElements.length !== 0;
            } else {
              return true;
            }
          } else {
            return false;
          }
        }));
  var tmp = {
    id: id,
    label: React.createElement(Icon$Ucidata.make, {
          text: label,
          icon: ProSolidSvgIcons.faSearch
        }),
    value: value,
    onChange: (function (value) {
        Curry._1(setShowMenu, (function (param) {
                return value.length !== 0;
              }));
        return Curry._1(onChange, value);
      }),
    type_: "search",
    autoComplete: "off"
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (validate !== undefined) {
    tmp.validate = Caml_option.valFromOption(validate);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  var input = React.createElement(Input_Text$Ucidata.make, tmp);
  return Belt_Option.mapWithDefault(menuElements$1, input, (function (menuElements) {
                return React.createElement("div", {
                            ref: container,
                            className: Cn.$plus("relative", Cn.take(containerClassName))
                          }, input, React.createElement(If$Ucidata.make, {
                                condition: showMenu$1,
                                children: React.createElement("div", {
                                      className: "absolute w-full p-2 border rounded mt-1 border-gray-300 text-sm text-gray-700 bg-white z-10"
                                    }, loading ? React.createElement("div", {
                                            className: "p-1"
                                          }, React.createElement(Icon$Ucidata.make, {
                                                icon: ProSolidSvgIcons.faSpinner,
                                                className: "mr-2",
                                                spin: true
                                              }), React.createElement(Text$Ucidata.make, {
                                                children: "Searching…"
                                              })) : (
                                        menuElements.length === 0 ? React.createElement("div", {
                                                className: "p-1"
                                              }, React.createElement(Text$Ucidata.make, {
                                                    children: "No results."
                                                  })) : React.createElement("ul", undefined, menuElements)
                                      ))
                              }));
              }));
}

var make$1 = React.memo(Input_Search);

export {
  $$Element ,
  make$1 as make,
  
}
/* make Not a pure module */
