// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var UciOvsConfig = {};

var empty_uci_ovs_configs = [
  {
    code_oversight_id: 1,
    is_active: false
  },
  {
    code_oversight_id: 2,
    is_active: false
  },
  {
    code_oversight_id: 3,
    is_active: false
  },
  {
    code_oversight_id: 4,
    is_active: false
  },
  {
    code_oversight_id: 5,
    is_active: false
  }
];

var empty = {
  activ_date: undefined,
  is_ext_valuer: false,
  uci_id: 0,
  nav_freq_id: undefined,
  proc_freq_id: undefined,
  inv_restr_freq_id: undefined,
  time_settle_freq_id: undefined,
  code_status_id: undefined,
  uci_ovs_configs: empty_uci_ovs_configs
};

function setData(uciOvs, dispatch) {
  return Curry._1(dispatch, {
              TAG: /* SetUciOvs */0,
              _0: uciOvs
            });
}

function reducer(state, action) {
  if (action.TAG === /* SetUciOvs */0) {
    return action._0;
  } else {
    return Belt_Option.map(state, (function (state) {
                  switch (action.TAG | 0) {
                    case /* SetUciOvs */0 :
                        return state;
                    case /* SetActivationDate */1 :
                        return {
                                activ_date: action._0,
                                is_ext_valuer: state.is_ext_valuer,
                                uci_id: state.uci_id,
                                nav_freq_id: state.nav_freq_id,
                                proc_freq_id: state.proc_freq_id,
                                inv_restr_freq_id: state.inv_restr_freq_id,
                                time_settle_freq_id: state.time_settle_freq_id,
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: state.uci_ovs_configs
                              };
                    case /* SetNavFrequency */2 :
                        return {
                                activ_date: state.activ_date,
                                is_ext_valuer: state.is_ext_valuer,
                                uci_id: state.uci_id,
                                nav_freq_id: Belt_Option.map(action._0, (function (nav) {
                                        return nav.id;
                                      })),
                                proc_freq_id: state.proc_freq_id,
                                inv_restr_freq_id: state.inv_restr_freq_id,
                                time_settle_freq_id: state.time_settle_freq_id,
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: state.uci_ovs_configs
                              };
                    case /* SetProcFrequency */3 :
                        return {
                                activ_date: state.activ_date,
                                is_ext_valuer: state.is_ext_valuer,
                                uci_id: state.uci_id,
                                nav_freq_id: state.nav_freq_id,
                                proc_freq_id: Belt_Option.map(action._0, (function (proc) {
                                        return proc.id;
                                      })),
                                inv_restr_freq_id: state.inv_restr_freq_id,
                                time_settle_freq_id: state.time_settle_freq_id,
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: state.uci_ovs_configs
                              };
                    case /* SetResctrictionFrequency */4 :
                        return {
                                activ_date: state.activ_date,
                                is_ext_valuer: state.is_ext_valuer,
                                uci_id: state.uci_id,
                                nav_freq_id: state.nav_freq_id,
                                proc_freq_id: state.proc_freq_id,
                                inv_restr_freq_id: Belt_Option.map(action._0, (function (restr) {
                                        return restr.id;
                                      })),
                                time_settle_freq_id: state.time_settle_freq_id,
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: state.uci_ovs_configs
                              };
                    case /* SetSettlementFrequency */5 :
                        return {
                                activ_date: state.activ_date,
                                is_ext_valuer: state.is_ext_valuer,
                                uci_id: state.uci_id,
                                nav_freq_id: state.nav_freq_id,
                                proc_freq_id: state.proc_freq_id,
                                inv_restr_freq_id: state.inv_restr_freq_id,
                                time_settle_freq_id: Belt_Option.map(action._0, (function (settle) {
                                        return settle.id;
                                      })),
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: state.uci_ovs_configs
                              };
                    case /* SetExternalValuer */6 :
                        return {
                                activ_date: state.activ_date,
                                is_ext_valuer: action._0,
                                uci_id: state.uci_id,
                                nav_freq_id: state.nav_freq_id,
                                proc_freq_id: state.proc_freq_id,
                                inv_restr_freq_id: state.inv_restr_freq_id,
                                time_settle_freq_id: state.time_settle_freq_id,
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: state.uci_ovs_configs
                              };
                    case /* SetOvsConfigItem */7 :
                        var active = action._1;
                        var id = action._0;
                        return {
                                activ_date: state.activ_date,
                                is_ext_valuer: state.is_ext_valuer,
                                uci_id: state.uci_id,
                                nav_freq_id: state.nav_freq_id,
                                proc_freq_id: state.proc_freq_id,
                                inv_restr_freq_id: state.inv_restr_freq_id,
                                time_settle_freq_id: state.time_settle_freq_id,
                                code_status_id: state.code_status_id,
                                uci_ovs_configs: Belt_Array.mapWithIndex(state.uci_ovs_configs, (function (idx, config) {
                                        if (idx === id) {
                                          return {
                                                  code_oversight_id: config.code_oversight_id,
                                                  is_active: active
                                                };
                                        } else {
                                          return config;
                                        }
                                      }))
                              };
                    
                  }
                }));
  }
}

var Reducer = {
  setData: setData,
  reducer: reducer
};

export {
  UciOvsConfig ,
  empty ,
  Reducer ,
  
}
/* No side effect */
