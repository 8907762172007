// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Modal$Ucidata from "../../core/modals/Modal.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as OptionMap$Ucidata from "../../core/conditionals/OptionMap.bs.js";
import * as StateLoader$Ucidata from "../../core/StateLoader.bs.js";
import * as Input_Calendar$Ucidata from "../../core/input/Input_Calendar.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as CompartimentTransactions_History$Ucidata from "../assets/transactions/CompartimentTransactions_History.bs.js";

function OversightFlat_Item$HistoryModalContents(Props) {
  var uciItemId = Props.uciItemId;
  var uciId = Props.uciId;
  var setShowHistoryModal = Props.setShowHistoryModal;
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_item/" + uciItemId);
  return React.createElement(StateLoader$Ucidata.make, {
              className: "text-6xl",
              state: match[0],
              render: (function (item) {
                  return React.createElement(CompartimentTransactions_History$Ucidata.make, {
                              item: item,
                              uciId: uciId,
                              onClose: (function (param) {
                                  return Curry._1(setShowHistoryModal, (function (param) {
                                                return false;
                                              }));
                                })
                            });
                })
            });
}

var HistoryModalContents = {
  make: OversightFlat_Item$HistoryModalContents
};

function OversightFlat_Item(Props) {
  var item = Props.item;
  var codeOversight = Props.codeOversight;
  var codeDocOvsStatus = Props.codeDocOvsStatus;
  var codeOversightSubtype = Props.codeOversightSubtype;
  var setItems = Props.setItems;
  var itemStatus = Belt_Array.getBy(codeDocOvsStatus, (function (status) {
          return status.id === item.status_id;
        }));
  var itemOversight = Belt_Array.getBy(codeOversight, (function (oversight) {
          return oversight.id === item.code_oversight_id;
        }));
  var title = React.useMemo((function () {
          return Belt_Option.mapWithDefault(itemStatus, "", (function (status) {
                        return status.name;
                      }));
        }), [
        codeDocOvsStatus,
        item
      ]);
  var oversight = React.useMemo((function () {
          return Belt_Option.mapWithDefault(itemOversight, "", (function (oversight) {
                        return oversight.name;
                      }));
        }), [
        codeOversight,
        item
      ]);
  var itemOversightSubtype = Belt_Array.getBy(codeOversightSubtype, (function (oversightSubtype) {
          return oversightSubtype.id === item.code_oversight_subtype_id;
        }));
  var oversightSubtype = React.useMemo((function () {
          return Belt_Option.mapWithDefault(itemOversightSubtype, "", (function (oversightSubtype) {
                        return oversightSubtype.name;
                      }));
        }), [
        codeOversightSubtype,
        item
      ]);
  var match = item.status_id;
  var colors;
  switch (match) {
    case 1 :
        colors = "bg-blue-400 text-white";
        break;
    case 2 :
        colors = "bg-green-400 text-black";
        break;
    case 3 :
        colors = "bg-yellow-400 text-black";
        break;
    case 4 :
    case 5 :
        colors = "bg-red-400 text-black";
        break;
    default:
      colors = undefined;
  }
  var uciItemId = Belt_Option.getWithDefault(item.note, "");
  var uciId = String(item.uci_id);
  var checkNote = uciItemId !== "";
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowHistoryModal = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex items-baseline p-2"
                }, React.createElement(Text$Ucidata.make, {
                      children: Belt_Option.getWithDefault(item.uci_group_name, "")
                    })), React.createElement("div", {
                  className: "flex items-baseline p-2"
                }, React.createElement(Link$Ucidata.make, {
                      href: "/oversight/compartiment/" + uciId,
                      children: React.createElement(Text$Ucidata.make, {
                            children: Belt_Option.getWithDefault(item.comp_name, "")
                          })
                    })), React.createElement("div", undefined, React.createElement(Text$Ucidata.make, {
                      children: oversight
                    })), React.createElement("div", undefined, React.createElement(Text$Ucidata.make, {
                      children: oversightSubtype
                    })), React.createElement(OptionMap$Ucidata.make, {
                  option: colors,
                  render: (function (colors) {
                      return React.createElement("div", {
                                  className: colors + " capitalize text-xs py-0.5 px-2 inline-flex items-center justify-center rounded italic",
                                  title: title
                                }, React.createElement(Text$Ucidata.make, {
                                      children: title
                                    }));
                    })
                }), React.createElement("div", {
                  className: "flex items-baseline"
                }, React.createElement(Font_Div$Ucidata.make, {
                      style: /* UppercaseLabel */6,
                      className: "text-xs",
                      children: React.createElement(Text$Ucidata.make, {
                            children: "Date:"
                          })
                    }), React.createElement(Input_Calendar$Ucidata.make, {
                      id: "next-control",
                      size: /* Tiny */2,
                      noBorders: true,
                      disabled: true,
                      onChange: (function (date) {
                          var cb = function (it) {
                            return {
                                    code_oversight_id: it.code_oversight_id,
                                    code_oversight_subtype_id: it.code_oversight_subtype_id,
                                    id: it.id,
                                    next_control: Belt_Option.map((date == null) ? undefined : Caml_option.some(date), (function (prim) {
                                            return prim.toISOString();
                                          })),
                                    controlled_on: it.controlled_on,
                                    controlled_by_id: it.controlled_by_id,
                                    status_id: it.status_id,
                                    uci_id: it.uci_id,
                                    note: it.note,
                                    comp_name: it.comp_name,
                                    uci_group_name: it.uci_group_name
                                  };
                          };
                          return Curry._1(setItems, (function (items) {
                                        return Belt_Option.map(items, (function (items) {
                                                      return Belt_Array.map(items, (function (it) {
                                                                    if (item.id === it.id) {
                                                                      return Curry._1(cb, it);
                                                                    } else {
                                                                      return it;
                                                                    }
                                                                  }));
                                                    }));
                                      }));
                        }),
                      value: Js_null_undefined.fromOption(Belt_Option.map(item.next_control, (function (prim) {
                                  return new Date(prim);
                                })))
                    })), React.createElement("div", {
                  className: "flex items-baseline"
                }, React.createElement(If$Ucidata.make, {
                      condition: checkNote,
                      children: null
                    }, React.createElement(Modal$Ucidata.make, {
                          isOpen: match$1[0],
                          setOpen: setShowHistoryModal,
                          closeOnOutsideClick: true,
                          closeOnEsc: true,
                          size: /* Fullscreen */0,
                          children: React.createElement(OversightFlat_Item$HistoryModalContents, {
                                uciItemId: uciItemId,
                                uciId: uciId,
                                setShowHistoryModal: setShowHistoryModal
                              })
                        }), React.createElement(Button$Ucidata.make, {
                          style: /* Secondary */2,
                          size: /* Slim */3,
                          onClick: (function (param) {
                              return Curry._1(setShowHistoryModal, (function (param) {
                                            return true;
                                          }));
                            }),
                          children: React.createElement(Icon$Ucidata.make, {
                                text: "Trx",
                                icon: ProLightSvgIcons.faLink
                              })
                        }))));
}

var make = React.memo(OversightFlat_Item);

var Helpers;

var Documents;

var $$History;

export {
  Helpers ,
  Documents ,
  $$History ,
  HistoryModalContents ,
  make ,
  
}
/* make Not a pure module */
