// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as IfOvs$Ucidata from "../../core/conditionals/IfOvs.bs.js";
import * as Loader$Ucidata from "../../core/loader/Loader.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as ArrayMapi$Ucidata from "../../core/conditionals/ArrayMapi.bs.js";
import * as Text_Span$Ucidata from "../../core/text/Text_Span.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as Input_Search$Ucidata from "../../core/input/Input_Search.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Config_UciGroupElement$Ucidata from "./Config_UciGroupElement.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";

function Config_UciGroupList$GroupList(Props) {
  var uciGroups = Props.uciGroups;
  var setUciGroup = Props.setUciGroup;
  var match = React.useState(function () {
        return "";
      });
  var setSearch = match[1];
  var search = match[0];
  var searching = search.length !== 0;
  var uciGroups$1 = React.useMemo((function () {
          if (searching) {
            return Belt_Array.keep(uciGroups, (function (elt) {
                          if (elt.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
                            return true;
                          } else {
                            return Belt_Array.some(elt.ucis, (function (uci) {
                                          return uci.comp_name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
                                        }));
                          }
                        }));
          } else {
            return uciGroups;
          }
        }), [
        uciGroups,
        search
      ]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement(Input_Search$Ucidata.make, {
                      id: "search-name",
                      value: search,
                      onChange: (function (v) {
                          return Curry._1(setSearch, (function (param) {
                                        return v;
                                      }));
                        }),
                      placeholder: "Uci or Compartiment name"
                    })), React.createElement("div", {
                  className: "border rounded overflow-hidden bg-white text-xs border-b"
                }, React.createElement("div", {
                      className: "divide-y divide-gray-300"
                    }, React.createElement(Collapsible$Ucidata.make, {
                          className: "p-2 font-semibold bg-gray-100 uppercase",
                          disabled: true,
                          render: (function (param) {
                              return React.createElement("div", {
                                          className: "flex items-baseline flex-auto"
                                        }, React.createElement(Text_Span$Ucidata.make, {
                                              className: "min-w-200px p-2",
                                              children: "Name"
                                            }), React.createElement(Text_Span$Ucidata.make, {
                                              className: "min-w-200px w-1/4",
                                              children: "Cssf Code"
                                            }), React.createElement(IfOvs$Ucidata.make, {
                                              children: (function (param) {
                                                  return React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
                                                                  className: "w-1/4",
                                                                  children: "AIFM"
                                                                }), React.createElement(Text_Span$Ucidata.make, {
                                                                  className: "w-1/4",
                                                                  children: "Central Admin"
                                                                }));
                                                })
                                            }));
                            })
                        }), React.createElement(ArrayMapi$Ucidata.make, {
                          array: uciGroups$1,
                          render: (function (uciGroup, idx) {
                              return React.createElement(Config_UciGroupElement$Ucidata.make, {
                                          uciGroup: uciGroup,
                                          setUciGroup: setUciGroup,
                                          forceOpen: searching,
                                          key: String(Belt_Option.getWithDefault(uciGroup.id, idx))
                                        });
                            })
                        }))));
}

var make = React.memo(Config_UciGroupList$GroupList);

var GroupList = {
  make: make
};

function Config_UciGroupList(Props) {
  var uciGroupsState = Props.uciGroupsState;
  var setUciGroup = Props.setUciGroup;
  var content;
  if (typeof uciGroupsState === "number") {
    content = undefined;
  } else {
    switch (uciGroupsState.TAG | 0) {
      case /* Loading */0 :
          content = undefined;
          break;
      case /* Error */1 :
          content = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: uciGroupsState._0
                  }));
          break;
      case /* Data */2 :
          content = Caml_option.some(React.createElement(make, {
                    uciGroups: uciGroupsState._0,
                    setUciGroup: setUciGroup
                  }));
          break;
      
    }
  }
  return React.createElement(Section$Ucidata.make, {
              title: React.createElement(Icon$Ucidata.make, {
                    text: "Uci List",
                    icon: ProLightSvgIcons.faList,
                    className: "mr-2"
                  }),
              children: React.createElement("div", {
                    className: "space-y-8"
                  }, React.createElement(Loader$Ucidata.make, {
                        className: "text-4xl",
                        children: content
                      }))
            });
}

var make$1 = React.memo(Config_UciGroupList);

var UciGroupElement;

export {
  UciGroupElement ,
  GroupList ,
  make$1 as make,
  
}
/* make Not a pure module */
