// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Text$Ucidata from "./text/Text.bs.js";
import * as Button$Ucidata from "./Button.bs.js";
import * as Text_Span$Ucidata from "./text/Text_Span.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Pagination$PaginationButton(Props) {
  var page = Props.page;
  var currentPage = Props.currentPage;
  var pageCount = Props.pageCount;
  var onChange = Props.onChange;
  var className = Props.className;
  var title = Props.title;
  var hide = Props.hide;
  var children = Props.children;
  var hide$1 = Belt_Option.getWithDefault(hide, false);
  if (hide$1) {
    return null;
  }
  var tmp = {
    style: /* Unstyled */4,
    disabled: currentPage === page || page > pageCount || page <= 0,
    className: Cn.$plus(Cn.$plus("px-2 w-8", Cn.on("font-bold text-blue-900", currentPage === page)), Cn.take(className)),
    onClick: (function (param) {
        return Curry._1(onChange, page);
      }),
    children: Belt_Option.getWithDefault(children, React.createElement(Text$Ucidata.make, {
              children: String(page)
            }))
  };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  return React.createElement(Button$Ucidata.make, tmp);
}

var make = React.memo(Pagination$PaginationButton);

var PaginationButton = {
  make: make
};

function computations(limit, offset, numberOfPagesToShow, pageCount) {
  var currentPage = Caml_int32.div(offset, limit) + 1 | 0;
  var sidePagesToShow = (numberOfPagesToShow - 1 | 0) / 2 | 0;
  var previousPagesLimit = {
    contents: Math.max(currentPage - sidePagesToShow | 0, 1)
  };
  var nextPagesLimit = {
    contents: Math.min(currentPage + sidePagesToShow | 0, pageCount)
  };
  var numberOfLeftPages = currentPage - previousPagesLimit.contents | 0;
  var numberOfRightPages = nextPagesLimit.contents - currentPage | 0;
  var missingPages = 0;
  if (numberOfLeftPages < sidePagesToShow) {
    missingPages = sidePagesToShow - numberOfLeftPages | 0;
    nextPagesLimit.contents = Math.min((currentPage + sidePagesToShow | 0) + missingPages | 0, pageCount);
  }
  if (numberOfRightPages < sidePagesToShow) {
    missingPages = sidePagesToShow - numberOfRightPages | 0;
    previousPagesLimit.contents = Math.max((currentPage - sidePagesToShow | 0) - missingPages | 0, 1);
  }
  if (previousPagesLimit.contents <= 2) {
    nextPagesLimit.contents = Math.min(nextPagesLimit.contents + (
          previousPagesLimit.contents === 2 ? 1 : 2
        ) | 0, pageCount);
  }
  if (nextPagesLimit.contents >= (pageCount - 1 | 0)) {
    previousPagesLimit.contents = Math.max(previousPagesLimit.contents - (
          nextPagesLimit.contents === (pageCount - 1 | 0) ? 1 : 2
        ) | 0, 1);
  }
  return [
          currentPage,
          previousPagesLimit,
          nextPagesLimit
        ];
}

function Pagination(Props) {
  var offset = Props.offset;
  var limitOpt = Props.limit;
  var total = Props.total;
  var numberOfPagesToShowOpt = Props.numberOfPagesToShow;
  var onChange = Props.onChange;
  var limit = limitOpt !== undefined ? limitOpt : 30;
  var numberOfPagesToShow = numberOfPagesToShowOpt !== undefined ? numberOfPagesToShowOpt : 5;
  var pageCount = Js_math.ceil(total / limit);
  if (pageCount < 2) {
    return null;
  }
  var match = computations(limit, offset, numberOfPagesToShow, pageCount);
  var nextPagesLimit = match[2];
  var previousPagesLimit = match[1];
  var currentPage = match[0];
  var onChange$1 = function (page) {
    return Curry._1(onChange, Math.imul(page - 1 | 0, limit));
  };
  var previousPagesButtons = [];
  var nextPagesButtons = [];
  for(var i = currentPage - 1 | 0 ,i_finish = previousPagesLimit.contents; i >= i_finish; --i){
    previousPagesButtons.unshift(React.createElement(make, {
              page: i,
              currentPage: currentPage,
              pageCount: pageCount,
              onChange: onChange$1,
              key: String(i)
            }));
  }
  for(var i$1 = currentPage + 1 | 0 ,i_finish$1 = nextPagesLimit.contents; i$1 <= i_finish$1; ++i$1){
    nextPagesButtons.push(React.createElement(make, {
              page: i$1,
              currentPage: currentPage,
              pageCount: pageCount,
              onChange: onChange$1,
              key: String(i$1)
            }));
  }
  return React.createElement("div", {
              className: "max-w-lg mx-auto bg-white border p-2"
            }, React.createElement("div", {
                  className: "flex items-center justify-between w-full"
                }, React.createElement("div", undefined, React.createElement(make, {
                          page: currentPage - 1 | 0,
                          currentPage: currentPage,
                          pageCount: pageCount,
                          onChange: onChange$1,
                          children: React.createElement(Icon$Ucidata.make, {
                                icon: ProSolidSvgIcons.faCaretLeft
                              })
                        })), React.createElement("div", undefined, React.createElement(make, {
                          page: 1,
                          currentPage: currentPage,
                          pageCount: pageCount,
                          onChange: onChange$1,
                          hide: previousPagesLimit.contents < 2
                        }), React.createElement(If$Ucidata.make, {
                          condition: previousPagesLimit.contents > 2,
                          children: React.createElement(Text_Span$Ucidata.make, {
                                className: "px-2 w-8",
                                children: "…"
                              })
                        }), previousPagesButtons, React.createElement(make, {
                          page: currentPage,
                          currentPage: currentPage,
                          pageCount: pageCount,
                          onChange: onChange$1
                        }), nextPagesButtons, React.createElement(If$Ucidata.make, {
                          condition: nextPagesLimit.contents < (pageCount - 1 | 0),
                          children: React.createElement(Text_Span$Ucidata.make, {
                                className: "px-2 w-8",
                                children: "…"
                              })
                        }), React.createElement(make, {
                          page: pageCount,
                          currentPage: currentPage,
                          pageCount: pageCount,
                          onChange: onChange$1,
                          hide: nextPagesLimit.contents >= pageCount
                        })), React.createElement("div", undefined, React.createElement(make, {
                          page: currentPage + 1 | 0,
                          currentPage: currentPage,
                          pageCount: pageCount,
                          onChange: onChange$1,
                          title: currentPage >= pageCount ? "Last page already active" : "",
                          children: React.createElement(Icon$Ucidata.make, {
                                icon: ProSolidSvgIcons.faCaretRight
                              })
                        }))));
}

var make$1 = React.memo(Pagination);

var $$default = make$1;

export {
  PaginationButton ,
  computations ,
  make$1 as make,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
