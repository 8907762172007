// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as UciItem$Ucidata from "../../../../models/UciItem.bs.js";
import * as ArrayMap$Ucidata from "../../../core/conditionals/ArrayMap.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as TextArea$Ucidata from "../../../core/TextArea.bs.js";
import * as Uploader$Ucidata from "../../../../store/Uploader.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as UserContext$Ucidata from "../../../UserContext.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as React$1 from "hyperactiv/src/react";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function CompartimentTransactions_Documents$SectionDocuments(Props) {
  var item = Props.item;
  var doc = Props.doc;
  var setItem = Props.setItem;
  var showAddDocuments = Props.showAddDocuments;
  var unmounted = React.useRef(false);
  var client = Client$Ucidata.useClient(undefined);
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var fetchDocFiles = match[1];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci_doc_file/" + String(item.id) + "/" + String(doc.id));
  var uciDocFilesState = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setFiles = match$2[1];
  var files = match$2[0];
  var store = React$1.useStore();
  var itemId = String(item.id);
  var docId = String(doc.id);
  var storedFiles = Uploader$Ucidata.getFiles(store, itemId, docId);
  var mandatoryDocument = Belt_Option.isNone(doc.uci_doc_opts_id);
  React.useEffect((function () {
          return (function (param) {
                    unmounted.current = false;
                    
                  });
        }), []);
  var uploadedDocumentsRender;
  var exit = 0;
  if (typeof uciDocFilesState === "number") {
    exit = 1;
  } else {
    switch (uciDocFilesState.TAG | 0) {
      case /* Loading */0 :
          uploadedDocumentsRender = React.createElement("div", {
                className: "w-20 text-xl p-4"
              }, React.createElement(Loader$Ucidata.make, {
                    children: undefined
                  }));
          break;
      case /* Error */1 :
          uploadedDocumentsRender = React.createElement("div", {
                className: "p-4"
              }, React.createElement(DisplayServerError$Ucidata.make, {
                    error: uciDocFilesState._0,
                    mode: /* Light */0
                  }));
          break;
      case /* Data */2 :
          var files$1 = uciDocFilesState._0;
          if (files$1 !== undefined && files$1.length !== 0) {
            uploadedDocumentsRender = React.createElement("div", {
                  className: "space-y-4 p-4 text-sm"
                }, React.createElement(ArrayMap$Ucidata.make, {
                      array: files$1,
                      render: (function (file) {
                          var link = "/api/uci_doc_file/" + String(file.id);
                          var match = Utils$Ucidata.getFileSize(undefined, file.size);
                          var unit = match[1];
                          var fileSize = match[0];
                          return React.createElement(Collapsible$Ucidata.make, {
                                      iconClassName: "text-sm",
                                      shallow: true,
                                      children: React.createElement("div", {
                                            className: "py-4"
                                          }, React.createElement("iframe", {
                                                className: "w-full min-h-3/4",
                                                src: link
                                              })),
                                      render: (function (param) {
                                          return React.createElement("div", {
                                                      className: "space-x-4 flex items-center"
                                                    }, React.createElement(Link$Ucidata.make, {
                                                          href: link + "?download=true",
                                                          vanilla: true,
                                                          className: "transition-colors hover:text-gray-800 hover:bg-blue-300 hover:border-blue-300 text-sm border-2 rounded-lg w-8 h-8 p-1 inline-flex items-center justify-center",
                                                          unstyled: true,
                                                          onClick: (function (prim) {
                                                              prim.stopPropagation();
                                                              
                                                            }),
                                                          title: "Download",
                                                          children: React.createElement(Icon$Ucidata.make, {
                                                                icon: ProLightSvgIcons.faDownload
                                                              })
                                                        }), React.createElement(Text_Span$Ucidata.make, {
                                                          children: file.descn
                                                        }), React.createElement(Font$Ucidata.make, {
                                                          style: /* Italic */4,
                                                          children: React.createElement(Text_Span$Ucidata.make, {
                                                                children: "(" + fileSize + " " + unit + ")"
                                                              })
                                                        }));
                                        }),
                                      key: String(file.id)
                                    });
                        })
                    }));
          } else {
            exit = 1;
          }
          break;
      
    }
  }
  if (exit === 1) {
    uploadedDocumentsRender = React.createElement(Font_Div$Ucidata.make, {
          style: /* Italic */4,
          className: "p-4 text-sm",
          children: React.createElement(Text$Ucidata.make, {
                children: "No documents."
              })
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: Cn.$plus("p-2 px-4 text-sm space-x-4", mandatoryDocument ? "bg-red-50" : "bg-gray-100")
                }, React.createElement(Text_Span$Ucidata.make, {
                      className: "font-normal",
                      children: doc.code_doc
                    }), React.createElement(If$Ucidata.make, {
                      condition: Belt_Option.isNone(doc.uci_doc_opts_id),
                      children: React.createElement(Text_Span$Ucidata.make, {
                            className: "text-2xs text-red-500 italic",
                            children: "(Mandatory)"
                          })
                    })), React.createElement("div", undefined, uploadedDocumentsRender), React.createElement(If$Ucidata.make, {
                  condition: storedFiles.errored.length !== 0,
                  children: React.createElement(Island$Ucidata.make, {
                        style: /* Error */2,
                        icon: ProDuotoneSvgIcons.faBomb,
                        children: React.createElement(Text$Ucidata.make, {
                              children: "An error occured while trying to upload some of the documents. Please try again."
                            })
                      })
                }), React.createElement(If$Ucidata.make, {
                  condition: storedFiles.uploading.length !== 0,
                  children: React.createElement(Island$Ucidata.make, {
                        icon: ProDuotoneSvgIcons.faSpinnerThird,
                        iconSpin: true,
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Uploading documents…"
                            })
                      })
                }), React.createElement(If$Ucidata.make, {
                  condition: showAddDocuments,
                  children: React.createElement("div", {
                        className: "p-4 text-sm border-t space-y-4"
                      }, React.createElement("div", {
                            className: "space-x-4 inline-flex items-center"
                          }, React.createElement(Button$Ucidata.make, {
                                size: /* Slim */3,
                                noPadding: true,
                                children: React.createElement("label", {
                                      className: "block cursor-pointer space-x-2 py-1 px-2"
                                    }, React.createElement(Icon$Ucidata.make, {
                                          text: "Add",
                                          icon: ProLightSvgIcons.faPlus
                                        }), React.createElement("input", {
                                          className: "hidden",
                                          multiple: true,
                                          type: "file",
                                          onChange: (function (e) {
                                              var target = e.target;
                                              var newFiles = Array.from(target.files);
                                              Curry._1(setFiles, (function (files) {
                                                      return Belt_Array.concat(files, newFiles);
                                                    }));
                                              target.value = "";
                                              
                                            })
                                        }))
                              }), React.createElement(If$Ucidata.make, {
                                condition: files.length !== 0,
                                children: React.createElement(Button$Ucidata.make, {
                                      style: /* Primary */0,
                                      size: /* Slim */3,
                                      icon: ProSolidSvgIcons.faSave,
                                      className: "space-x-2 py-1 px-2",
                                      noPadding: true,
                                      onClick: (function (param) {
                                          Belt_Array.forEach(Uploader$Ucidata.uploadFiles(store, itemId, docId, files), (function (promise) {
                                                  return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(promise, (function (param) {
                                                                    if (!unmounted.current) {
                                                                      Curry._1(fetchDocFiles, undefined);
                                                                      return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(UciItem$Ucidata.$$fetch(client, String(item.id)), (function (item) {
                                                                                        return Curry._1(setItem, (function (param) {
                                                                                                      return item;
                                                                                                    }));
                                                                                      })));
                                                                    }
                                                                    
                                                                  })));
                                                }));
                                          return Curry._1(setFiles, (function (param) {
                                                        return [];
                                                      }));
                                        }),
                                      children: React.createElement(Text$Ucidata.make, {
                                            children: "Save"
                                          })
                                    })
                              })), React.createElement(If$Ucidata.make, {
                            condition: files.length !== 0,
                            children: null
                          }, React.createElement(Font_Div$Ucidata.make, {
                                style: /* Italic */4,
                                children: React.createElement(Text$Ucidata.make, {
                                      children: "By uploading you confirm that all documents are true copies or as defined in the OP Memorandum of the UCI"
                                    })
                              }), React.createElement("div", {
                                className: "flex flex-wrap text-xs"
                              }, React.createElement(ArrayMapi$Ucidata.make, {
                                    array: files,
                                    render: (function (file, index) {
                                        var match = Utils$Ucidata.getFileSize(undefined, file.size);
                                        return React.createElement("div", {
                                                    key: String(index),
                                                    className: "border rounded p-1 px-2 mr-2 mt-1 space-x-2"
                                                  }, React.createElement(Text_Span$Ucidata.make, {
                                                        children: file.name
                                                      }), React.createElement(Font$Ucidata.make, {
                                                        style: /* Italic */4,
                                                        children: React.createElement(Text_Span$Ucidata.make, {
                                                              children: "(" + match[0] + " " + match[1] + ")"
                                                            })
                                                      }), React.createElement(Button$Ucidata.make, {
                                                        style: /* Unstyled */4,
                                                        onClick: (function (param) {
                                                            return Curry._1(setFiles, (function (files) {
                                                                          return Belt_Array.keep(files, (function (f) {
                                                                                        return f !== file;
                                                                                      }));
                                                                        }));
                                                          }),
                                                        children: React.createElement(Icon$Ucidata.make, {
                                                              icon: ProSolidSvgIcons.faTimes,
                                                              className: "text-red-400 hover:text-red-500"
                                                            })
                                                      }));
                                      })
                                  }))))
                }));
}

var make = React.memo(React$1.watch(CompartimentTransactions_Documents$SectionDocuments));

var SectionDocuments = {
  make: make
};

function docStatusColor(codeId) {
  switch (codeId) {
    case 1 :
        return "bg-yellow-50";
    case 2 :
        return "bg-green-50";
    case 3 :
    case 4 :
        return "bg-red-50";
    case 5 :
        return "bg-gray-50";
    default:
      return "bg-red-50";
  }
}

function CompartimentTransactions_Documents$ItemSection(Props) {
  var item = Props.item;
  var setItem = Props.setItem;
  var section = Props.section;
  var codes = Props.codes;
  var showAddDocuments = Props.showAddDocuments;
  var showEditableSectionStatus = Props.showEditableSectionStatus;
  var onSave = Props.onSave;
  var client = Client$Ucidata.useClient(undefined);
  var match = React.useState(function () {
        return false;
      });
  var setUpdating = match[1];
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearUpdateError = match$1[2];
  var setUpdateError = match$1[1];
  var sectionStatus = Belt_Array.getBy(item.sections_status, (function (status) {
          return status.code_section_id === section.id;
        }));
  var sectionStatusCode = Belt_Option.mapWithDefault(sectionStatus, 0, (function (param) {
          return param.code_doc_status_id;
        }));
  var rejectReason = Belt_Option.flatMap(Belt_Option.map(sectionStatus, (function (param) {
              return param.rej_reas;
            })), (function (prim) {
          if (prim == null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  var codesDocStatus = Belt_Option.mapWithDefault(codes, [], (function (codes) {
          return codes.codesDocStatus;
        }));
  var codeDocStatusLabel = codes !== undefined && sectionStatus !== undefined ? Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDocStatus, (function (docStatus) {
                return docStatus.id === sectionStatus.code_doc_status_id;
              })), "", (function (param) {
            return $$String.capitalize_ascii(param.name);
          })) : "";
  var match$2 = React.useState(function () {
        return sectionStatusCode;
      });
  var setSelectedStatus = match$2[1];
  var selectedStatus = match$2[0];
  var match$3 = React.useState(function () {
        return Belt_Option.mapWithDefault(sectionStatus, "", (function (param) {
                      return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(param.rej_reas), "");
                    }));
      });
  var setReason = match$3[1];
  var reason = match$3[0];
  var color = docStatusColor(showEditableSectionStatus ? selectedStatus : sectionStatusCode);
  var showRejectReason = selectedStatus > 2 && selectedStatus !== 5;
  var tmp = {
    name: "documents-status",
    options: codesDocStatus,
    onChange: (function (option) {
        Curry._1(setSelectedStatus, (function (param) {
                return option.id;
              }));
        if (option.id < 2) {
          return Curry._1(setReason, (function (param) {
                        return "";
                      }));
        }
        
      }),
    label: React.createElement(Text$Ucidata.make, {
          children: "Documents Status"
        }),
    getOptionLabel: (function (codeDocStatus) {
        return codeDocStatus.name;
      }),
    getOptionValue: (function (codeDocStatus) {
        return codeDocStatus.id;
      }),
    size: /* Small */0
  };
  var tmp$1 = Belt_Array.getBy(codesDocStatus, (function (elt) {
          return elt.id === selectedStatus;
        }));
  if (tmp$1 !== undefined) {
    tmp.value = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {};
  var tmp$3 = match$1[0];
  if (tmp$3 !== undefined) {
    tmp$2.error = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement("div", {
              key: String(section.id)
            }, React.createElement("div", {
                  className: "md:rounded-lg md:border md:mb-4"
                }, React.createElement("div", {
                      className: "space-x-2 p-4 bg-gray-600 text-white"
                    }, React.createElement(Text_Span$Ucidata.make, {
                          className: "font-semibold",
                          children: section.name
                        }), React.createElement(Font$Ucidata.make, {
                          style: /* Italic */4,
                          children: React.createElement(Text$Ucidata.make, {
                                children: "(" + section.abbreviation + ")"
                              })
                        })), React.createElement("div", {
                      className: Cn.$plus(Cn.$plus(Cn.$plus(color, "p-4 text-gray-600 space-y-2"), Cn.on("text-xs", !showEditableSectionStatus)), Cn.on("text-sm", showEditableSectionStatus))
                    }, React.createElement(If$Ucidata.make, {
                          condition: showEditableSectionStatus,
                          renderElse: (function (param) {
                              return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                              className: "font-semibold"
                                            }, React.createElement(Text$Ucidata.make, {
                                                  children: codeDocStatusLabel
                                                })), React.createElement(OptionMap$Ucidata.make, {
                                              option: rejectReason,
                                              render: (function (reason) {
                                                  return React.createElement("div", {
                                                              className: "italic text-gray-500 text-xs"
                                                            }, React.createElement(Text$Ucidata.make, {
                                                                  children: reason
                                                                }));
                                                })
                                            }));
                            }),
                          children: null
                        }, React.createElement(Layout$Ucidata.make, {
                              style: showRejectReason ? /* Grid2Cols */0 : /* None */5,
                              children: null
                            }, React.createElement(Input_Select$Ucidata.make, tmp), React.createElement(If$Ucidata.make, {
                                  condition: showRejectReason,
                                  children: React.createElement(TextArea$Ucidata.make, {
                                        id: "reason-text",
                                        label: React.createElement(Text$Ucidata.make, {
                                              children: "Reason"
                                            }),
                                        value: reason,
                                        onChange: (function (value) {
                                            return Curry._1(setReason, (function (param) {
                                                          return value;
                                                        }));
                                          }),
                                        rows: 2
                                      })
                                })), React.createElement(DisplayServerError$Ucidata.make, tmp$2), React.createElement(Button$Ucidata.make, {
                              style: /* Primary */0,
                              size: /* Small */0,
                              icon: ProSolidSvgIcons.faSave,
                              spinner: match[0],
                              onClick: (function (param) {
                                  return Belt_Option.forEach(sectionStatus, (function (sectionStatus) {
                                                Curry._1(setUpdating, (function (param) {
                                                        return true;
                                                      }));
                                                Curry._1(clearUpdateError, undefined);
                                                return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(UciItem$Ucidata.updateStatus(client, String(item.id), {
                                                                            code_section_id: sectionStatus.code_section_id,
                                                                            code_doc_status_id: selectedStatus,
                                                                            rej_reas: reason
                                                                          }), (function (json) {
                                                                          return Belt_Option.forEach(onSave, (function (onSave) {
                                                                                        return Curry._1(onSave, json);
                                                                                      }));
                                                                        })), setUpdateError), (function (param) {
                                                                  return Curry._1(setUpdating, (function (param) {
                                                                                return false;
                                                                              }));
                                                                })));
                                              }));
                                }),
                              children: React.createElement(Text$Ucidata.make, {
                                    children: "Save"
                                  })
                            })))), React.createElement("div", {
                  className: "md:ml-4 md:rounded-lg md:overflow-hidden md:border md:mb-4"
                }, React.createElement(ArrayMap$Ucidata.make, {
                      array: section.docs,
                      render: (function (doc) {
                          return React.createElement(make, {
                                      item: item,
                                      doc: doc,
                                      setItem: setItem,
                                      showAddDocuments: showAddDocuments,
                                      key: String(doc.id)
                                    });
                        })
                    })));
}

var make$1 = React.memo(CompartimentTransactions_Documents$ItemSection);

var ItemSection = {
  docStatusColor: docStatusColor,
  make: make$1
};

function CompartimentTransactions_Documents(Props) {
  var item = Props.item;
  var onClose = Props.onClose;
  var uci = Props.uci;
  var onSave = Props.onSave;
  var user = UserContext$Ucidata.useUser(undefined);
  var match = React.useState(function () {
        return item;
      });
  var setItem = match[1];
  var item$1 = match[0];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_item/" + String(item$1.id) + "/docs");
  var match$2 = Client$Ucidata.useData(uci, undefined, undefined, Belt_Option.isSome(uci), undefined, undefined, undefined, "/uci/" + String(item$1.uci_id));
  var uci$1 = Client$Ucidata.data(undefined, match$2[0]);
  var codes = Codes$Ucidata.useCodes(undefined);
  var itemDocs = Client$Ucidata.data(undefined, match$1[0]);
  var isSale = Belt_Option.mapWithDefault(codes, true, (function (codes) {
          return Belt_Array.some(codes.codesEvent, (function (codeEvent) {
                        if (codeEvent.id === item$1.code_event_id) {
                          return codeEvent.is_sell;
                        } else {
                          return false;
                        }
                      }));
        }));
  var showAddDocuments = uci$1 !== undefined && user !== undefined ? IfUciRole$Ucidata.check(undefined, user, uci$1, isSale ? [
            /* Manager */0,
            /* BackOffice */2
          ] : [/* Manager */0]) : false;
  var showEditableSectionStatus = uci$1 !== undefined && user !== undefined ? IfUciRole$Ucidata.check(undefined, user, uci$1, [
          /* Controller */1,
          /* BackOffice */2
        ]) : false;
  var docsBySection = React.useMemo((function () {
          if (itemDocs !== undefined) {
            if (codes !== undefined) {
              return Belt_Array.reduce(itemDocs, [], (function (acc, doc) {
                            var codeSection = Belt_Array.getBy(codes.codesSection, (function (section) {
                                    return section.id === doc.code_section_id;
                                  }));
                            if (codeSection === undefined) {
                              return acc;
                            }
                            var sectionGroup = Belt_Array.getBy(acc, (function (elt) {
                                    return elt.id === doc.code_section_id;
                                  }));
                            var sectionGroup$1;
                            if (sectionGroup !== undefined) {
                              sectionGroup$1 = sectionGroup;
                            } else {
                              var newGroup_id = doc.code_section_id;
                              var newGroup_name = codeSection.name;
                              var newGroup_abbreviation = codeSection.abbreviation;
                              var newGroup_docs = [];
                              var newGroup = {
                                id: newGroup_id,
                                name: newGroup_name,
                                abbreviation: newGroup_abbreviation,
                                docs: newGroup_docs
                              };
                              acc.push(newGroup);
                              sectionGroup$1 = newGroup;
                            }
                            sectionGroup$1.docs.push(doc);
                            return acc;
                          }));
            } else {
              return [];
            }
          } else {
            return [];
          }
        }), [
        itemDocs,
        codes
      ]);
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: React.createElement(Layout$Ucidata.make, {
                        style: /* FlexBetween */3,
                        children: null
                      }, React.createElement(Font_Div$Ucidata.make, {
                            style: /* Title */1,
                            children: React.createElement(Icon$Ucidata.make, {
                                  text: item$1.name,
                                  icon: ProLightSvgIcons.faFileAlt,
                                  className: "mr-2"
                                })
                          }), React.createElement("div", {
                            className: "flex items-center justify-center space-x-4"
                          }, React.createElement(OptionMap$Ucidata.make, {
                                option: onClose,
                                render: (function (onClose) {
                                    return React.createElement(Button$Ucidata.make, {
                                                style: /* Regular */3,
                                                onClick: (function (param) {
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                children: React.createElement(Text$Ucidata.make, {
                                                      children: "Close"
                                                    })
                                              });
                                  })
                              })))
                }), React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Icon$Ucidata.make, {
                        text: "Attached documents",
                        icon: ProRegularSvgIcons.faPaperclip
                      }),
                  unstyled: true,
                  className: "text-gray-500",
                  children: React.createElement("div", {
                        className: "md:space-y-4"
                      }, React.createElement(ArrayMap$Ucidata.make, {
                            array: docsBySection,
                            render: (function (section) {
                                var tmp = {
                                  item: item$1,
                                  setItem: setItem,
                                  section: section,
                                  codes: codes,
                                  showAddDocuments: showAddDocuments,
                                  showEditableSectionStatus: showEditableSectionStatus,
                                  key: String(section.id)
                                };
                                if (onSave !== undefined) {
                                  tmp.onSave = Caml_option.valFromOption(onSave);
                                }
                                return React.createElement(make$1, tmp);
                              })
                          }))
                }));
}

var make$2 = React.memo(CompartimentTransactions_Documents);

export {
  SectionDocuments ,
  ItemSection ,
  make$2 as make,
  
}
/* make Not a pure module */
