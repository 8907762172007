// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";

function Layout(Props) {
  var elementOpt = Props.element;
  var style = Props.style;
  var className = Props.className;
  var children = Props.children;
  var element = elementOpt !== undefined ? elementOpt : "div";
  var tmp;
  switch (style) {
    case /* Grid2Cols */0 :
        tmp = "md:grid space-y-4 md:space-y-0 grid-cols-2 gap-4";
        break;
    case /* Grid3Cols */1 :
        tmp = "md:grid space-y-4 md:space-y-0 grid-cols-3 gap-4";
        break;
    case /* Grid4Cols */2 :
        tmp = "md:grid space-y-4 md:space-y-0 grid-cols-4 gap-4";
        break;
    case /* FlexBetween */3 :
        tmp = "md:flex flex-wrap items-center justify-between md:space-y-0 space-y-4";
        break;
    case /* Page */4 :
        tmp = "max-w-screen-2xl mx-auto p-6 sm:px-6 lg:px-8 bg-white shadow space-y-8 sm:rounded-md";
        break;
    case /* None */5 :
        tmp = "";
        break;
    
  }
  var className$1 = Cn.$plus(tmp, Cn.take(className));
  return React.createElement(element, {
              className: className$1
            }, children);
}

var make = React.memo(Layout);

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
