// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as IfOvs$Ucidata from "../../core/conditionals/IfOvs.bs.js";
import * as Utils$Ucidata from "../../../Utils.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Wretch$Ucidata from "../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as UciGroup$Ucidata from "../../../models/UciGroup.bs.js";
import * as Input_Text$Ucidata from "../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../core/validation/Validation.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as UciGroupOvs$Ucidata from "../../../models/UciGroupOvs.bs.js";
import * as Validation_Rules$Ucidata from "../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../core/validation/Validation_Context.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Config_CreateUci(Props) {
  var refetchGroups = Props.refetchGroups;
  var client = Client$Ucidata.useClient(undefined);
  var match = React.useState(function () {
        return UciGroup$Ucidata.empty;
      });
  var setNewUciGroup = match[1];
  var newUciGroup = match[0];
  var match$1 = React.useState(function () {
        return UciGroupOvs$Ucidata.empty;
      });
  var setUciGroupOvs = match$1[1];
  var uciGroupOvs = match$1[0];
  var match$2 = Hooks$Ucidata.useErrorState(undefined);
  var clearError = match$2[2];
  var setError = match$2[1];
  var error = match$2[0];
  var match$3 = Hooks$Ucidata.useFixedReference(undefined);
  var resetValidation = match$3[1];
  var createUciGroup = function (param) {
    Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(client.url("/uci_group").post(newUciGroup).json(function (uciGroup) {
                  Curry._1(setNewUciGroup, (function (param) {
                          return UciGroup$Ucidata.empty;
                        }));
                  Curry._1(refetchGroups, undefined);
                  if (Utils$Ucidata.ovs) {
                    return client.url("/uci_group_ovs").post({
                                  aifm: uciGroupOvs.aifm,
                                  central_admin: uciGroupOvs.central_admin,
                                  uci_group_id: uciGroup.id
                                }).json(function (param) {
                                
                              });
                  } else {
                    return Promise.resolve(undefined);
                  }
                }), (function (param) {
                if (Utils$Ucidata.ovs) {
                  Curry._1(setUciGroupOvs, (function (param) {
                          return UciGroupOvs$Ucidata.empty;
                        }));
                }
                Curry._1(clearError, undefined);
                return Curry._1(resetValidation, undefined);
              })), setError);
    
  };
  return React.createElement(Section$Ucidata.make, {
              children: React.createElement(Collapsible$Ucidata.make, {
                    iconPosition: /* Right */0,
                    children: React.createElement(Validation$Ucidata.make, {
                          resetDependency: match$3[0],
                          render: (function (validation) {
                              var tmp = {};
                              if (error !== undefined) {
                                tmp.error = Caml_option.valFromOption(error);
                              }
                              return React.createElement("div", {
                                          className: "space-y-6 mt-4 pt-4 border-t"
                                        }, React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Layout$Ucidata.make, {
                                              style: /* Grid2Cols */0,
                                              className: "flex-auto mt-4",
                                              children: null
                                            }, React.createElement(Input_Text$Ucidata.make, {
                                                  id: "uci-name",
                                                  label: React.createElement(Text$Ucidata.make, {
                                                        children: "name"
                                                      }),
                                                  value: newUciGroup.name,
                                                  validate: Validation_Rules$Ucidata.$$String.required("Name is mandatory.", undefined),
                                                  onChange: (function (name) {
                                                      return Curry._1(setNewUciGroup, (function (uciGroup) {
                                                                    return {
                                                                            id: uciGroup.id,
                                                                            name: name,
                                                                            cssf_code: uciGroup.cssf_code,
                                                                            aifm: uciGroup.aifm,
                                                                            central_admin: uciGroup.central_admin,
                                                                            ucis: uciGroup.ucis
                                                                          };
                                                                  }));
                                                    }),
                                                  placeholder: "Uci Name"
                                                }), React.createElement(Input_Text$Ucidata.make, {
                                                  id: "cssf-label",
                                                  label: React.createElement(Text$Ucidata.make, {
                                                        children: "CSSF Code"
                                                      }),
                                                  value: newUciGroup.cssf_code,
                                                  validate: Validation_Rules$Ucidata.$$String.required("CSSF Code is mandatory.", undefined),
                                                  onChange: (function (cssf_code) {
                                                      return Curry._1(setNewUciGroup, (function (uciGroup) {
                                                                    return {
                                                                            id: uciGroup.id,
                                                                            name: uciGroup.name,
                                                                            cssf_code: cssf_code,
                                                                            aifm: uciGroup.aifm,
                                                                            central_admin: uciGroup.central_admin,
                                                                            ucis: uciGroup.ucis
                                                                          };
                                                                  }));
                                                    }),
                                                  placeholder: "12345678"
                                                }), React.createElement(IfOvs$Ucidata.make, {
                                                  children: (function (param) {
                                                      return React.createElement(React.Fragment, undefined, React.createElement(Input_Text$Ucidata.make, {
                                                                      id: "uci-aifm",
                                                                      label: React.createElement(Text$Ucidata.make, {
                                                                            children: "AIFM"
                                                                          }),
                                                                      value: uciGroupOvs.aifm,
                                                                      validate: Validation_Rules$Ucidata.$$String.required("AIFM is mandatory.", undefined),
                                                                      onChange: (function (aifm) {
                                                                          return Curry._1(setUciGroupOvs, (function (ovs) {
                                                                                        return {
                                                                                                aifm: aifm,
                                                                                                central_admin: ovs.central_admin,
                                                                                                uci_group_id: ovs.uci_group_id
                                                                                              };
                                                                                      }));
                                                                        }),
                                                                      placeholder: "AIFM"
                                                                    }), React.createElement(Input_Text$Ucidata.make, {
                                                                      id: "uci-central-admin",
                                                                      label: React.createElement(Text$Ucidata.make, {
                                                                            children: "Central Admin"
                                                                          }),
                                                                      value: uciGroupOvs.central_admin,
                                                                      validate: Validation_Rules$Ucidata.$$String.required("Central Admin is mandatory.", undefined),
                                                                      onChange: (function (central_admin) {
                                                                          return Curry._1(setUciGroupOvs, (function (ovs) {
                                                                                        return {
                                                                                                aifm: ovs.aifm,
                                                                                                central_admin: central_admin,
                                                                                                uci_group_id: ovs.uci_group_id
                                                                                              };
                                                                                      }));
                                                                        }),
                                                                      placeholder: "Central Admin"
                                                                    }));
                                                    })
                                                })), React.createElement("div", undefined, React.createElement(Button$Ucidata.make, {
                                                  style: /* Primary */0,
                                                  disabled: Validation_Context$Ucidata.invalid(validation),
                                                  className: "md:w-auto w-full",
                                                  onClick: createUciGroup,
                                                  children: React.createElement(Icon$Ucidata.make, {
                                                        text: "Create",
                                                        icon: ProRegularSvgIcons.faPlus
                                                      })
                                                })));
                            })
                        }),
                    render: (function (param) {
                        return React.createElement(Font_Div$Ucidata.make, {
                                    style: /* Subtitle */2,
                                    children: React.createElement(Icon$Ucidata.make, {
                                          text: "Create Uci",
                                          icon: ProLightSvgIcons.faPlus,
                                          className: "mr-2"
                                        })
                                  });
                      })
                  })
            });
}

var make = React.memo(Config_CreateUci);

export {
  make ,
  
}
/* make Not a pure module */
