// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as If$Ucidata from "../conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../Icon.bs.js";
import * as OptionMap$Ucidata from "../conditionals/OptionMap.bs.js";
import ReactDatePicker from "react-date-picker";
import * as Validation$Ucidata from "../validation/Validation.bs.js";
import * as Validation_Errors$Ucidata from "../validation/Validation_Errors.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Input_Calendar(Props) {
  var id = Props.id;
  var label = Props.label;
  var sizeOpt = Props.size;
  var validate = Props.validate;
  var validateDependency = Props.validateDependency;
  var showValidationErrorsOpt = Props.showValidationErrors;
  var clearableOpt = Props.clearable;
  var noBordersOpt = Props.noBorders;
  var autoFocus = Props.autoFocus;
  var calendarAriaLabel = Props.calendarAriaLabel;
  var calendarClassName = Props.calendarClassName;
  var className = Props.className;
  var clearAriaLabel = Props.clearAriaLabel;
  var closeCalendar = Props.closeCalendar;
  var dayAriaLabel = Props.dayAriaLabel;
  var dayPlaceholder = Props.dayPlaceholder;
  var disabled = Props.disabled;
  var disableCalendar = Props.disableCalendar;
  var format = Props.format;
  var isOpen = Props.isOpen;
  var locale = Props.locale;
  var maxDate = Props.maxDate;
  var maxDetail = Props.maxDetail;
  var minDate = Props.minDate;
  var minDetail = Props.minDetail;
  var monthAriaLabel = Props.monthAriaLabel;
  var monthPlaceholder = Props.monthPlaceholder;
  var name = Props.name;
  var nativeInputAriaLabel = Props.nativeInputAriaLabel;
  var onCalendarClose = Props.onCalendarClose;
  var onCalendarOpen = Props.onCalendarOpen;
  var onChange = Props.onChange;
  var required = Props.required;
  var returnValue = Props.returnValue;
  var showLeadingZeros = Props.showLeadingZeros;
  var value = Props.value;
  var yearAriaLabel = Props.yearAriaLabel;
  var yearPlaceholder = Props.yearPlaceholder;
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var showValidationErrors = showValidationErrorsOpt !== undefined ? showValidationErrorsOpt : true;
  var clearable = clearableOpt !== undefined ? clearableOpt : true;
  var noBorders = noBordersOpt !== undefined ? noBordersOpt : false;
  var match = Validation$Ucidata.useValidation(id, value, validate, validateDependency, undefined);
  var errors = match.errors;
  var dirty = match.dirty;
  var isDisabled = Belt_Option.getWithDefault(disabled, false);
  var labelCn = Cn.$plus("block uppercase tracking-wide text-gray-500 text-xs font-medium absolute -mt-6 w-full", Cn.on("text-red-500", dirty && errors.length !== 0));
  var tmp;
  switch (size) {
    case /* Small */0 :
        tmp = "h-8 px-2";
        break;
    case /* Normal */1 :
        tmp = "h-11 px-4";
        break;
    case /* Tiny */2 :
        tmp = "px-1";
        break;
    
  }
  var inputCn = Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("flex items-center justify-center appearance-none block w-full leading-tight", Cn.on("border-gray-300 border rounded ", !noBorders)), Cn.on("text-gray-500", !isDisabled)), Cn.on("cursor-not-allowed bg-gray-100 text-gray-500", isDisabled)), Cn.on("border-red-500", dirty && errors.length !== 0)), tmp);
  var tmp$1 = {
    calendarIcon: React.createElement(Icon$Ucidata.make, {
          icon: ProRegularSvgIcons.faCalendar,
          className: "hover:text-black"
        }),
    className: inputCn,
    clearIcon: clearable ? React.createElement(Icon$Ucidata.make, {
            icon: ProSolidSvgIcons.faTimes,
            className: "hover:text-black"
          }) : null,
    onChange: onChange,
    value: value
  };
  if (autoFocus !== undefined) {
    tmp$1.autoFocus = autoFocus;
  }
  if (calendarAriaLabel !== undefined) {
    tmp$1.calendarAriaLabel = calendarAriaLabel;
  }
  if (calendarClassName !== undefined) {
    tmp$1.calendarClassName = calendarClassName;
  }
  if (clearAriaLabel !== undefined) {
    tmp$1.clearAriaLabel = clearAriaLabel;
  }
  if (closeCalendar !== undefined) {
    tmp$1.closeCalendar = closeCalendar;
  }
  if (dayAriaLabel !== undefined) {
    tmp$1.dayAriaLabel = dayAriaLabel;
  }
  if (dayPlaceholder !== undefined) {
    tmp$1.dayPlaceholder = dayPlaceholder;
  }
  if (disabled !== undefined) {
    tmp$1.disabled = disabled;
  }
  if (disableCalendar !== undefined) {
    tmp$1.disableCalendar = disableCalendar;
  }
  if (format !== undefined) {
    tmp$1.format = format;
  }
  if (isOpen !== undefined) {
    tmp$1.isOpen = isOpen;
  }
  if (locale !== undefined) {
    tmp$1.locale = locale;
  }
  if (maxDate !== undefined) {
    tmp$1.maxDate = Caml_option.valFromOption(maxDate);
  }
  if (maxDetail !== undefined) {
    tmp$1.maxDetail = maxDetail;
  }
  if (minDate !== undefined) {
    tmp$1.minDate = Caml_option.valFromOption(minDate);
  }
  if (minDetail !== undefined) {
    tmp$1.minDetail = minDetail;
  }
  if (monthAriaLabel !== undefined) {
    tmp$1.monthAriaLabel = monthAriaLabel;
  }
  if (monthPlaceholder !== undefined) {
    tmp$1.monthPlaceholder = monthPlaceholder;
  }
  if (name !== undefined) {
    tmp$1.name = name;
  }
  if (nativeInputAriaLabel !== undefined) {
    tmp$1.nativeInputAriaLabel = nativeInputAriaLabel;
  }
  if (onCalendarClose !== undefined) {
    tmp$1.onCalendarClose = Caml_option.valFromOption(onCalendarClose);
  }
  if (onCalendarOpen !== undefined) {
    tmp$1.onCalendarOpen = Caml_option.valFromOption(onCalendarOpen);
  }
  if (required !== undefined) {
    tmp$1.required = required;
  }
  if (returnValue !== undefined) {
    tmp$1.returnValue = returnValue;
  }
  if (showLeadingZeros !== undefined) {
    tmp$1.showLeadingZeros = showLeadingZeros;
  }
  if (yearAriaLabel !== undefined) {
    tmp$1.yearAriaLabel = yearAriaLabel;
  }
  if (yearPlaceholder !== undefined) {
    tmp$1.yearPlaceholder = yearPlaceholder;
  }
  return React.createElement("div", {
              className: Cn.$plus(Cn.$plus("relative", Cn.onSome("pt-6", label)), Cn.take(className)),
              id: id
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: label,
                  render: (function (label) {
                      return React.createElement("label", {
                                  className: labelCn,
                                  htmlFor: id
                                }, label);
                    })
                }), React.createElement(ReactDatePicker, tmp$1), React.createElement(If$Ucidata.make, {
                  condition: showValidationErrors,
                  children: React.createElement(Validation_Errors$Ucidata.make, {
                        errors: errors,
                        dirty: dirty
                      })
                }));
}

var make = Input_Calendar;

export {
  make ,
  
}
/* react Not a pure module */
