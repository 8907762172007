// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getErrorMessage(error) {
  var defaultMessage = function (error) {
    return Belt_Option.mapWithDefault(error.response, "Somethng went wrong…", (function (res) {
                  return res.statusText;
                }));
  };
  var formatMessage = function (message) {
    try {
      return Belt_Option.mapWithDefault(Js_json.decodeObject(JSON.parse(message)), message, (function (json) {
                    var details = Belt_Option.flatMap(Js_dict.get(json, "details"), Js_json.decodeString);
                    var msg = Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(json, "message"), Js_json.decodeString), message);
                    return Belt_Option.getWithDefault(details, msg);
                  }));
    }
    catch (exn){
      return message;
    }
  };
  return $$String.capitalize_ascii(Belt_Option.getWithDefault(Belt_Option.flatMap(Belt_Option.map(error.message, formatMessage), (function (msg) {
                        if (msg.length !== 0) {
                          return msg;
                        }
                        
                      })), defaultMessage(error)));
}

function component(f) {
  return f;
}

function cloneDict(d) {
  return Object.assign({}, d);
}

function stripTrailingSlash(path) {
  var pathLength = path.length;
  if (pathLength > 1 && path.endsWith("/")) {
    return $$String.sub(path, 0, pathLength - 1 | 0);
  } else {
    return path;
  }
}

function setStyle(element, property, value) {
  element.style[property] = value;
  
}

var GenType = {};

var Int = {};

function mapWithDefault2(o1, o2, $$default, fn) {
  if (o1 !== undefined && o2 !== undefined) {
    return Curry._2(fn, Caml_option.valFromOption(o1), Caml_option.valFromOption(o2));
  } else {
    return $$default;
  }
}

function map2(o1, o2, fn) {
  return mapWithDefault2(o1, o2, undefined, fn);
}

function fromBool($$boolean, some) {
  if ($$boolean) {
    return Caml_option.some(some);
  }
  
}

var $$Option = {
  mapWithDefault2: mapWithDefault2,
  map2: map2,
  fromBool: fromBool
};

var units = [
  "byte(s)",
  "Kb",
  "Mb",
  "Gb",
  "Tb"
];

function getFileSize(_unitsOpt, _size) {
  while(true) {
    var unitsOpt = _unitsOpt;
    var size = _size;
    var units$1 = unitsOpt !== undefined ? unitsOpt : units;
    if (size < 1000) {
      return [
              size.toFixed(2),
              units$1[0]
            ];
    }
    _size = size / 1000;
    _unitsOpt = units$1.slice(1);
    continue ;
  };
}

var downloadStringData = ((filename, data, mimetype) => {
    const exportedBlob = new Blob([data], { type: mimetype })
    const anchor = document.createElement('a')
    anchor.style = 'display: none'
    anchor.setAttribute('download', filename)
    anchor.setAttribute('target', '_blank')
    document.body.appendChild(anchor)
    anchor.href = URL.createObjectURL(exportedBlob)
    anchor.click()
    URL.revokeObjectURL(exportedBlob)
    document.body.removeChild(anchor)
  });

var ovs = OVS_ACTIV;

var cash = CASH_MONITOR_ACTIV;

export {
  getErrorMessage ,
  component ,
  cloneDict ,
  stripTrailingSlash ,
  setStyle ,
  GenType ,
  Int ,
  $$Option ,
  units ,
  getFileSize ,
  downloadStringData ,
  ovs ,
  cash ,
  
}
/* ovs Not a pure module */
