// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function Input_Toggle(Props) {
  var checked = Props.checked;
  var setChecked = Props.setChecked;
  var className = Props.className;
  var name = Props.name;
  var sizeOpt = Props.size;
  var disabled = Props.disabled;
  var size = sizeOpt !== undefined ? sizeOpt : /* Small */2;
  var checkedValue = Belt_Option.getWithDefault(checked, false);
  var disabled$1 = Belt_Option.getWithDefault(disabled, false);
  var outerCss;
  switch (size) {
    case /* Large */0 :
        outerCss = "w-16 h-8";
        break;
    case /* Regular */1 :
        outerCss = "w-10 h-5";
        break;
    case /* Small */2 :
        outerCss = "w-8 h-4";
        break;
    case /* Tiny */3 :
        outerCss = "w-6 h-3";
        break;
    
  }
  var innerCss;
  switch (size) {
    case /* Large */0 :
        innerCss = Cn.$plus(Cn.$plus("ml-2 h-4 w-4", Cn.on("translate-x-8", Caml_obj.caml_equal(checked, true))), Cn.on("translate-x-4", checked === undefined));
        break;
    case /* Regular */1 :
        innerCss = Cn.$plus(Cn.$plus("ml-1.5 h-3 w-3", Cn.on("translate-x-4", Caml_obj.caml_equal(checked, true))), Cn.on("translate-x-2", checked === undefined));
        break;
    case /* Small */2 :
        innerCss = Cn.$plus(Cn.$plus("ml-1 h-2 w-2", Cn.on("translate-x-4", Caml_obj.caml_equal(checked, true))), Cn.on("translate-x-2", checked === undefined));
        break;
    case /* Tiny */3 :
        innerCss = Cn.$plus(Cn.$plus("ml-1 h-1.5 w-1.5", Cn.on("translate-x-2.5", Caml_obj.caml_equal(checked, true))), Cn.on("translate-x-1.5", checked === undefined));
        break;
    
  }
  var toggle = function (param) {
    Belt_Option.map(setChecked, (function (setChecked) {
            return Curry._1(setChecked, !checkedValue);
          }));
    
  };
  var tmp = {
    className: "opacity-0 w-0 h-0",
    tabIndex: -1,
    checked: checkedValue,
    disabled: disabled$1,
    type: "checkbox",
    onChange: toggle
  };
  if (name !== undefined) {
    tmp.name = Caml_option.valFromOption(name);
  }
  return React.createElement("label", {
              className: Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("relative inline-flex items-center transition-colors duration-500 rounded-full", checked !== undefined ? (
                                      checked ? (
                                          disabled$1 ? "bg-green-200" : "bg-green-400"
                                        ) : (
                                          disabled$1 ? "bg-red-200" : "bg-red-300"
                                        )
                                    ) : (
                                      disabled$1 ? "bg-gray-200" : "bg-gray-400"
                                    )), Cn.on("cursor-not-allowed outline-none", disabled$1)), Cn.on("cursor-pointer", !disabled$1)), outerCss), Cn.take(className)),
              role: "checkbox",
              tabIndex: 0,
              onKeyDown: (function (e) {
                  if (e.key === " ") {
                    return toggle(undefined);
                  }
                  
                })
            }, React.createElement("input", tmp), React.createElement("span", {
                  className: Cn.$plus("absolute rounded-full left-0 bg-white transform transition-transform duration-500", innerCss)
                }));
}

var make = Input_Toggle;

export {
  make ,
  
}
/* react Not a pure module */
