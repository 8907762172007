// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Render$Ucidata from "../../../Render.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as ArrayMap$Ucidata from "../../../core/conditionals/ArrayMap.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as Uploader$Ucidata from "../../../../store/Uploader.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as UserContext$Ucidata from "../../../UserContext.bs.js";
import * as React$1 from "hyperactiv/src/react";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";
import * as CompartimentHoldingStructure_DocList$Ucidata from "./CompartimentHoldingStructure_DocList.bs.js";

function CompartimentHoldingStructure_Documents$DocumentTypes(Props) {
  var item = Props.item;
  var codeDocStructType = Props.codeDocStructType;
  var fetchItems = Props.fetchItems;
  return React.createElement("div", {
              className: "space-y-4"
            }, Render$Ucidata.array(undefined, codeDocStructType, (function (datum, param) {
                    var tmp = {
                      codeDocStructType: datum,
                      item: item,
                      editable: true,
                      key: String(datum.id)
                    };
                    if (fetchItems !== undefined) {
                      tmp.onSuccessfulUpload = Caml_option.valFromOption(fetchItems);
                    }
                    return React.createElement("div", {
                                key: String(datum.id),
                                className: "space-y-4"
                              }, React.createElement(Font_Div$Ucidata.make, {
                                    style: /* Large */3,
                                    className: "bg-gray-600 text-gray-50 px-4 py-1 rounded",
                                    children: React.createElement(Text$Ucidata.make, {
                                          children: datum.name
                                        })
                                  }), React.createElement(CompartimentHoldingStructure_DocList$Ucidata.make, tmp));
                  })));
}

var make = React.memo(CompartimentHoldingStructure_Documents$DocumentTypes);

var DocumentTypes = {
  make: make
};

function CompartimentHoldingStructure_Documents$SectionDocument(Props) {
  var item = Props.item;
  var doc = Props.doc;
  var unmounted = React.useRef(false);
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci_struct_file/" + String(Belt_Option.getWithDefault(item.id, 0)) + "/" + String(doc.id));
  var uciDocFilesState = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setFiles = match$2[1];
  var files = match$2[0];
  var store = React$1.useStore();
  var itemId = String(Belt_Option.getWithDefault(item.id, 0));
  var docId = String(doc.id);
  var storedFiles = Uploader$Ucidata.getFiles(store, itemId, docId);
  React.useEffect((function () {
          return (function (param) {
                    unmounted.current = false;
                    
                  });
        }), []);
  var uploadedDocumentsRender;
  var exit = 0;
  if (typeof uciDocFilesState === "number") {
    exit = 1;
  } else {
    switch (uciDocFilesState.TAG | 0) {
      case /* Loading */0 :
          uploadedDocumentsRender = React.createElement("div", {
                className: "w-20 text-xl p-4"
              }, React.createElement(Loader$Ucidata.make, {
                    children: undefined
                  }));
          break;
      case /* Error */1 :
          uploadedDocumentsRender = React.createElement("div", {
                className: "p-4"
              }, React.createElement(DisplayServerError$Ucidata.make, {
                    error: uciDocFilesState._0,
                    mode: /* Light */0
                  }));
          break;
      case /* Data */2 :
          var files$1 = uciDocFilesState._0;
          if (files$1 !== undefined && files$1.length !== 0) {
            uploadedDocumentsRender = React.createElement("div", {
                  className: "space-y-4 p-4 text-sm"
                }, React.createElement(ArrayMap$Ucidata.make, {
                      array: files$1,
                      render: (function (file) {
                          var link = "/api/uci_doc_file/" + String(file.id);
                          var match = Utils$Ucidata.getFileSize(undefined, file.size);
                          var unit = match[1];
                          var fileSize = match[0];
                          return React.createElement(Collapsible$Ucidata.make, {
                                      iconClassName: "text-sm",
                                      shallow: true,
                                      children: React.createElement("div", {
                                            className: "py-4"
                                          }, React.createElement("iframe", {
                                                className: "w-full min-h-3/4",
                                                src: link
                                              })),
                                      render: (function (param) {
                                          return React.createElement("div", {
                                                      className: "space-x-4 flex items-center"
                                                    }, React.createElement(Link$Ucidata.make, {
                                                          href: link + "?download=true",
                                                          vanilla: true,
                                                          className: "transition-colors hover:text-gray-800 hover:bg-blue-300 hover:border-blue-300 text-sm border-2 rounded-lg w-8 h-8 p-1 inline-flex items-center justify-center",
                                                          unstyled: true,
                                                          onClick: (function (prim) {
                                                              prim.stopPropagation();
                                                              
                                                            }),
                                                          title: "Download",
                                                          children: React.createElement(Icon$Ucidata.make, {
                                                                icon: ProLightSvgIcons.faDownload
                                                              })
                                                        }), React.createElement(Text_Span$Ucidata.make, {
                                                          children: file.descn
                                                        }), React.createElement(Font$Ucidata.make, {
                                                          style: /* Italic */4,
                                                          children: React.createElement(Text_Span$Ucidata.make, {
                                                                children: "(" + fileSize + " " + unit + ")"
                                                              })
                                                        }));
                                        }),
                                      key: String(file.id)
                                    });
                        })
                    }));
          } else {
            exit = 1;
          }
          break;
      
    }
  }
  if (exit === 1) {
    uploadedDocumentsRender = React.createElement(Font_Div$Ucidata.make, {
          style: /* Italic */4,
          className: "p-4 text-sm",
          children: React.createElement(Text$Ucidata.make, {
                children: "No documents."
              })
        });
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "p-2 px-4 text-sm space-x-4"
                }, React.createElement(Text_Span$Ucidata.make, {
                      className: "font-normal",
                      children: doc.code_doc
                    })), React.createElement("div", undefined, uploadedDocumentsRender), React.createElement(If$Ucidata.make, {
                  condition: storedFiles.errored.length !== 0,
                  children: React.createElement(Island$Ucidata.make, {
                        style: /* Error */2,
                        icon: ProDuotoneSvgIcons.faBomb,
                        children: React.createElement(Text$Ucidata.make, {
                              children: "An error occured while trying to upload some of the documents. Please try again."
                            })
                      })
                }), React.createElement(If$Ucidata.make, {
                  condition: storedFiles.uploading.length !== 0,
                  children: React.createElement(Island$Ucidata.make, {
                        icon: ProDuotoneSvgIcons.faSpinnerThird,
                        iconSpin: true,
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Uploading documents…"
                            })
                      })
                }), React.createElement("div", {
                  className: "p-4 text-sm border-t space-y-4"
                }, React.createElement("div", {
                      className: "space-x-4 inline-flex items-center"
                    }, React.createElement(Button$Ucidata.make, {
                          size: /* Slim */3,
                          noPadding: true,
                          children: React.createElement("label", {
                                className: "block cursor-pointer space-x-2 py-1 px-2"
                              }, React.createElement(Icon$Ucidata.make, {
                                    text: "Add",
                                    icon: ProLightSvgIcons.faPlus
                                  }), React.createElement("input", {
                                    className: "hidden",
                                    multiple: true,
                                    type: "file",
                                    onChange: (function (e) {
                                        var target = e.target;
                                        var newFiles = Array.from(target.files);
                                        Curry._1(setFiles, (function (files) {
                                                return Belt_Array.concat(files, newFiles);
                                              }));
                                        target.value = "";
                                        
                                      })
                                  }))
                        }), React.createElement(If$Ucidata.make, {
                          condition: files.length !== 0,
                          children: React.createElement(Button$Ucidata.make, {
                                style: /* Primary */0,
                                size: /* Slim */3,
                                icon: ProSolidSvgIcons.faSave,
                                className: "space-x-2 py-1 px-2",
                                noPadding: true,
                                children: React.createElement(Text$Ucidata.make, {
                                      children: "Save"
                                    })
                              })
                        })), React.createElement(If$Ucidata.make, {
                      condition: files.length !== 0,
                      children: null
                    }, React.createElement(Font_Div$Ucidata.make, {
                          style: /* Italic */4,
                          children: React.createElement(Text$Ucidata.make, {
                                children: "By uploading you confirm that all documents are true copies or as defined in the OP Memorandum of the UCI"
                              })
                        }), React.createElement("div", {
                          className: "flex flex-wrap text-xs"
                        }, React.createElement(ArrayMapi$Ucidata.make, {
                              array: files,
                              render: (function (file, index) {
                                  var match = Utils$Ucidata.getFileSize(undefined, file.size);
                                  return React.createElement("div", {
                                              key: String(index),
                                              className: "border rounded p-1 px-2 mr-2 mt-1 space-x-2"
                                            }, React.createElement(Text_Span$Ucidata.make, {
                                                  children: file.name
                                                }), React.createElement(Font$Ucidata.make, {
                                                  style: /* Italic */4,
                                                  children: React.createElement(Text_Span$Ucidata.make, {
                                                        children: "(" + match[0] + " " + match[1] + ")"
                                                      })
                                                }), React.createElement(Button$Ucidata.make, {
                                                  style: /* Unstyled */4,
                                                  onClick: (function (param) {
                                                      return Curry._1(setFiles, (function (files) {
                                                                    return Belt_Array.keep(files, (function (f) {
                                                                                  return f !== file;
                                                                                }));
                                                                  }));
                                                    }),
                                                  children: React.createElement(Icon$Ucidata.make, {
                                                        icon: ProSolidSvgIcons.faTimes,
                                                        className: "text-red-400 hover:text-red-500"
                                                      })
                                                }));
                                })
                            })))));
}

var make$1 = React.memo(React$1.watch(CompartimentHoldingStructure_Documents$SectionDocument));

var SectionDocument = {
  make: make$1
};

function CompartimentHoldingStructure_Documents(Props) {
  var item = Props.item;
  var onClose = Props.onClose;
  var uci = Props.uci;
  var user = UserContext$Ucidata.useUser(undefined);
  var match = React.useState(function () {
        return item;
      });
  var item$1 = match[0];
  var match$1 = Client$Ucidata.useData(uci, undefined, undefined, Belt_Option.isSome(uci), undefined, undefined, undefined, "/uci/");
  var uci$1 = Client$Ucidata.data(undefined, match$1[0]);
  var codes = Codes$Ucidata.useCodes(undefined);
  if (uci$1 !== undefined && user !== undefined) {
    IfUciRole$Ucidata.check(undefined, user, uci$1, [
          /* Controller */1,
          /* BackOffice */2
        ]);
  } else {
    false;
  }
  var codeDocStructType = Belt_Option.mapWithDefault(codes, [], (function (codes) {
          return codes.codeDocStructType;
        }));
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: React.createElement(Layout$Ucidata.make, {
                        style: /* FlexBetween */3,
                        children: null
                      }, React.createElement(Font_Div$Ucidata.make, {
                            style: /* Title */1,
                            children: React.createElement(Icon$Ucidata.make, {
                                  text: item$1.name,
                                  icon: ProLightSvgIcons.faFileAlt,
                                  className: "mr-2"
                                })
                          }), React.createElement("div", {
                            className: "flex items-center justify-center space-x-4"
                          }, React.createElement(OptionMap$Ucidata.make, {
                                option: onClose,
                                render: (function (onClose) {
                                    return React.createElement(Button$Ucidata.make, {
                                                style: /* Regular */3,
                                                onClick: (function (param) {
                                                    return Curry._1(onClose, undefined);
                                                  }),
                                                children: React.createElement(Text$Ucidata.make, {
                                                      children: "Close"
                                                    })
                                              });
                                  })
                              })))
                }), React.createElement(make, {
                  item: item$1,
                  codeDocStructType: codeDocStructType
                }));
}

var make$2 = React.memo(CompartimentHoldingStructure_Documents);

var DocList;

export {
  DocList ,
  DocumentTypes ,
  SectionDocument ,
  make$2 as make,
  
}
/* make Not a pure module */
