// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Utils$Ucidata from "../../../Utils.bs.js";

function IfOvs(Props) {
  var children = Props.children;
  if (Utils$Ucidata.ovs) {
    return Curry._1(children, undefined);
  } else {
    return null;
  }
}

var make = IfOvs;

export {
  make ,
  
}
/* Utils-Ucidata Not a pure module */
