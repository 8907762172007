// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Table$Ucidata from "../../../core/Table.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as PopupMenu$Ucidata from "../../../core/PopupMenu.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var Table = Table$Ucidata.Make({});

function make(codes, events) {
  var sectionStatusColumns = Belt_Array.map(codes.codesSection, (function (section) {
          return {
                  label: section.name,
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(item.sections_status, (function (s) {
                                        return s.code_section_id === section.id;
                                      })), "", (function (sectionStatus) {
                                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDocStatus, (function (elt) {
                                                      return elt.id === sectionStatus.code_doc_status_id;
                                                    })), "", (function (elt) {
                                                  return elt.name;
                                                }));
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                };
        }));
  return Curry._1(Table.Column.make, [
                {
                  label: "Name",
                  extract: (function (item) {
                      return item.name;
                    }),
                  options: Curry._7(Table.Column.Options.make, true, true, (function (label, item) {
                          return React.createElement(Link$Ucidata.make, {
                                      href: "assets/compartiment/" + String(item.uci_id) + "/transactions",
                                      extraClass: "border-b pb-0.5",
                                      children: React.createElement(Text_Span$Ucidata.make, {
                                            children: label
                                          })
                                    });
                        }), undefined, undefined, undefined, undefined)
                },
                {
                  label: "",
                  extract: (function (param) {
                      return "";
                    }),
                  options: Curry._7(Table.Column.Options.make, true, undefined, (function (param, item) {
                          return React.createElement(PopupMenu$Ucidata.make, {
                                      render: (function (setShowMenu) {
                                          return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                          onClick: (function (param) {
                                                              Curry._1(events.onDocumentsClick, item);
                                                              return Curry._1(setShowMenu, (function (param) {
                                                                            return false;
                                                                          }));
                                                            }),
                                                          children: React.createElement(Icon$Ucidata.make, {
                                                                text: "Documents",
                                                                icon: ProRegularSvgIcons.faFileAlt,
                                                                fixedWidth: true
                                                              }),
                                                          key: "show-documents"
                                                        }), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                          onClick: (function (param) {
                                                              Curry._1(events.onShowPosition, item);
                                                              return Curry._1(setShowMenu, (function (param) {
                                                                            return false;
                                                                          }));
                                                            }),
                                                          children: React.createElement(Icon$Ucidata.make, {
                                                                text: "Show Position",
                                                                icon: ProRegularSvgIcons.faBalanceScale,
                                                                fixedWidth: true
                                                              }),
                                                          key: "show-position"
                                                        }));
                                        }),
                                      children: React.createElement(Button$Ucidata.make, {
                                            style: /* Unstyled */4,
                                            className: "h-8 px-2",
                                            children: React.createElement(Icon$Ucidata.make, {
                                                  icon: ProRegularSvgIcons.faEllipsisH,
                                                  className: "text-base"
                                                })
                                          })
                                    });
                        }), undefined, "", undefined, undefined)
                },
                {
                  label: "Cadaster Nb.",
                  extract: (function (item) {
                      return Belt_Option.getWithDefault(item.cadaster_nb, "N/A");
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Internal Ref.",
                  extract: (function (item) {
                      return item.internal_ref;
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Transaction",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesEvent, (function (elt) {
                                        return elt.id === item.code_event_id;
                                      })), "", (function (elt) {
                                    return elt.name;
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Tr. Type",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesType, (function (elt) {
                                        return elt.id === item.code_type_id;
                                      })), "", (function (elt) {
                                    return elt.name;
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Tr. Category",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesSubtype, (function (elt) {
                                        return elt.id === item.code_subtype_id;
                                      })), "", (function (elt) {
                                    return elt.name;
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Date",
                  extract: (function (item) {
                      return new Date(item.created_at).toISOString();
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Asset Type",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesAssetType, (function (elt) {
                                        return elt.id === item.code_asset_type_id;
                                      })), "", (function (elt) {
                                    return elt.name;
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Units",
                  extract: (function (item) {
                      return item.units.toLocaleString();
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Denom.",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                        return elt.id === item.code_denom_id;
                                      })), "", (function (elt) {
                                    return elt.name;
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Cash",
                  extract: (function (item) {
                      return item.price.toLocaleString();
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Curr.",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                        return elt.id === item.code_ccy_id;
                                      })), "", (function (elt) {
                                    return elt.name;
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Transm. Id",
                  extract: (function (item) {
                      return String(item.transm_id);
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                },
                {
                  label: "Valid. Id",
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(item.valid_id, "", (function (valid_id) {
                                    return String(valid_id);
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                }
              ].concat(sectionStatusColumns));
}

var Column;

export {
  Table ,
  Column ,
  make ,
  
}
/* Table Not a pure module */
