// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as If$Ucidata from "../conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OptionMap$Ucidata from "../conditionals/OptionMap.bs.js";
import * as Validation$Ucidata from "../validation/Validation.bs.js";
import * as Validation_Errors$Ucidata from "../validation/Validation_Errors.bs.js";

function Input_Textarea(Props) {
  var id = Props.id;
  var rows = Props.rows;
  var label = Props.label;
  var value = Props.value;
  var className = Props.className;
  var sizeOpt = Props.size;
  var validate = Props.validate;
  var validateDependency = Props.validateDependency;
  var showValidationErrorsOpt = Props.showValidationErrors;
  var onChange = Props.onChange;
  var type_Opt = Props.type_;
  var autoComplete = Props.autoComplete;
  var placeholder = Props.placeholder;
  var disabled = Props.disabled;
  var min = Props.min;
  var max = Props.max;
  var pattern = Props.pattern;
  var onFocus = Props.onFocus;
  var onBlur = Props.onBlur;
  var size = sizeOpt !== undefined ? sizeOpt : /* Normal */1;
  var showValidationErrors = showValidationErrorsOpt !== undefined ? showValidationErrorsOpt : true;
  var type_ = type_Opt !== undefined ? type_Opt : "text";
  var match = Validation$Ucidata.useValidation(id, value, validate, validateDependency, undefined);
  var errors = match.errors;
  var dirty = match.dirty;
  var isDisabled = Belt_Option.getWithDefault(disabled, false);
  var labelCn = Cn.$plus("block uppercase tracking-wide text-gray-500 text-xs font-medium absolute -mt-6 w-full", Cn.on("text-red-500", dirty && errors.length !== 0));
  var inputCn = Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus("appearance-none block w-full border-gray-300 border rounded leading-tight", Cn.on("text-gray-500", !isDisabled)), Cn.on("cursor-not-allowed bg-gray-100 text-gray-400", isDisabled)), Cn.on("border-red-500", dirty && errors.length !== 0)), size ? "h-11 px-4" : "h-8 px-2");
  var onChange$1 = Belt_Option.map(onChange, (function (fn, e) {
          return Curry._1(fn, e.target.value);
        }));
  var tmp = {
    className: inputCn,
    id: id,
    type: type_,
    value: value
  };
  if (autoComplete !== undefined) {
    tmp.autoComplete = Caml_option.valFromOption(autoComplete);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (max !== undefined) {
    tmp.max = Caml_option.valFromOption(max);
  }
  if (min !== undefined) {
    tmp.min = Caml_option.valFromOption(min);
  }
  if (pattern !== undefined) {
    tmp.pattern = Caml_option.valFromOption(pattern);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (rows !== undefined) {
    tmp.rows = Caml_option.valFromOption(rows);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onChange$1 !== undefined) {
    tmp.onChange = Caml_option.valFromOption(onChange$1);
  }
  return React.createElement("div", {
              className: Cn.$plus(Cn.$plus("relative", Cn.onSome("pt-6", label)), Cn.take(className))
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: label,
                  render: (function (label) {
                      return React.createElement("label", {
                                  className: labelCn,
                                  htmlFor: id
                                }, label);
                    })
                }), React.createElement("textarea", tmp), React.createElement(If$Ucidata.make, {
                  condition: showValidationErrors,
                  children: React.createElement(Validation_Errors$Ucidata.make, {
                        errors: errors,
                        dirty: dirty
                      })
                }));
}

var make = React.memo(Input_Textarea);

export {
  make ,
  
}
/* make Not a pure module */
