// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../core/loader/Loader.bs.js";
import * as Font_H1$Ucidata from "../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Oversight_UciGroupsList$Ucidata from "./Oversight_UciGroupsList.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Oversight(Props) {
  var pageWrapperOpt = Props.pageWrapper;
  var pageWrapper = pageWrapperOpt !== undefined ? pageWrapperOpt : true;
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_group?ovs_status=true");
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_oversight");
  var match$2 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_doc_ovs_status");
  var match$3 = Client$Ucidata.compose3(match[0], match$2[0], match$1[0]);
  var tmp;
  if (typeof match$3 === "number") {
    tmp = undefined;
  } else {
    switch (match$3.TAG | 0) {
      case /* Loading */0 :
          tmp = undefined;
          break;
      case /* Error */1 :
          tmp = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: match$3._0
                  }));
          break;
      case /* Data */2 :
          var match$4 = match$3._0;
          tmp = Caml_option.some(React.createElement(Oversight_UciGroupsList$Ucidata.make, {
                    uciGroups: match$4[0],
                    codeDocOvsStatus: match$4[1],
                    codeOversightState: match$4[2]
                  }));
          break;
      
    }
  }
  var contents = React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
            title: React.createElement(Icon$Ucidata.make, {
                  text: "Uci List",
                  icon: ProLightSvgIcons.faList,
                  className: "mr-2"
                }),
            children: React.createElement("div", {
                  className: "space-y-8"
                }, React.createElement(Loader$Ucidata.make, {
                      className: "text-4xl",
                      children: tmp
                    }))
          }));
  if (pageWrapper) {
    return React.createElement(Layout$Ucidata.make, {
                style: /* Page */4,
                children: null
              }, React.createElement(Section$Ucidata.make, {
                    unstyled: true,
                    children: React.createElement(Layout$Ucidata.make, {
                          style: /* FlexBetween */3,
                          children: null
                        }, React.createElement(Font_H1$Ucidata.make, {
                              style: /* Title */1,
                              children: React.createElement(Icon$Ucidata.make, {
                                    text: "Oversight",
                                    icon: ProRegularSvgIcons.faMonitorHeartRate,
                                    className: "mr-2"
                                  })
                            }), React.createElement("div", undefined, React.createElement(Link$Ucidata.make, {
                                  href: "/oversight_flat",
                                  children: React.createElement(Button$Ucidata.make, {
                                        style: /* Primary */0,
                                        icon: ProLightSvgIcons.faList,
                                        className: "mr-2 w-full md:w-auto",
                                        children: React.createElement(Text$Ucidata.make, {
                                              children: "Control list"
                                            })
                                      })
                                })))
                  }), contents);
  } else {
    return contents;
  }
}

var UciGroupsList;

var make = Oversight;

export {
  UciGroupsList ,
  make ,
  
}
/* react Not a pure module */
