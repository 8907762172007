// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Input_Toggle$Ucidata from "../../../core/input/Input_Toggle.bs.js";
import * as CodeFrequency$Ucidata from "../../../../models/CodeFrequency.bs.js";
import * as Input_Calendar$Ucidata from "../../../core/input/Input_Calendar.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Compartiment_Oversight(Props) {
  var uciOvsState = Props.uciOvsState;
  var dispatchUciOvs = Props.dispatchUciOvs;
  var isEditable = Props.isEditable;
  var cachingClient = Client$Ucidata.useCachingClient(undefined);
  var codeFrequencies = CodeFrequency$Ucidata.useCodeFrequencies(undefined);
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(cachingClient), undefined, false, "/code_oversight");
  var codesOversight = Client$Ucidata.data(undefined, match[0]);
  var sectionContent;
  var exit = 0;
  var uciOvs;
  var codeFrequencies$1;
  var codesOversight$1;
  var exit$1 = 0;
  if (typeof uciOvsState === "number") {
    exit = 1;
  } else {
    switch (uciOvsState.TAG | 0) {
      case /* Loading */0 :
          var uciOvs$1 = uciOvsState._0;
          if (uciOvs$1 !== undefined) {
            if (codeFrequencies !== undefined) {
              if (codesOversight !== undefined) {
                uciOvs = uciOvs$1;
                codeFrequencies$1 = codeFrequencies;
                codesOversight$1 = codesOversight;
                exit = 2;
              } else {
                exit$1 = 3;
              }
            } else if (codeFrequencies !== undefined) {
              exit$1 = 3;
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case /* Error */1 :
          if (codeFrequencies !== undefined) {
            exit$1 = 3;
          } else {
            exit = 1;
          }
          break;
      case /* Data */2 :
          if (codeFrequencies !== undefined) {
            if (codesOversight !== undefined) {
              uciOvs = uciOvsState._0;
              codeFrequencies$1 = codeFrequencies;
              codesOversight$1 = codesOversight;
              exit = 2;
            } else {
              exit$1 = 3;
            }
          } else if (codeFrequencies !== undefined) {
            exit$1 = 3;
          } else {
            exit = 1;
          }
          break;
      
    }
  }
  if (exit$1 === 3) {
    if (codesOversight !== undefined) {
      sectionContent = React.createElement(DisplayServerError$Ucidata.make, {
            error: uciOvsState._0
          });
    } else {
      exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        sectionContent = React.createElement(Loader$Ucidata.make, {
              className: "text-4xl"
            });
        break;
    case 2 :
        sectionContent = React.createElement(React.Fragment, undefined, React.createElement(Input_Calendar$Ucidata.make, {
                  id: "activ-date",
                  label: React.createElement(Text$Ucidata.make, {
                        children: "Activation Date"
                      }),
                  onChange: (function (date) {
                      return Curry._1(dispatchUciOvs, {
                                  TAG: /* SetActivationDate */1,
                                  _0: Belt_Option.map((date == null) ? undefined : Caml_option.some(date), (function (prim) {
                                          return prim.toISOString();
                                        }))
                                });
                    }),
                  value: Js_null_undefined.fromOption(Belt_Option.map(uciOvs.activ_date, (function (prim) {
                              return new Date(prim);
                            })))
                }), React.createElement(Font_Div$Ucidata.make, {
                  style: /* Large */3,
                  children: React.createElement(Text$Ucidata.make, {
                        children: "Frequencies"
                      })
                }), React.createElement(Layout$Ucidata.make, {
                  style: /* Grid2Cols */0,
                  children: null
                }, React.createElement(Input_Select$Ucidata.make, {
                      name: "nav-frequency",
                      options: codeFrequencies$1.nav,
                      onChange: (function (freq) {
                          return Curry._1(dispatchUciOvs, {
                                      TAG: /* SetNavFrequency */2,
                                      _0: freq
                                    });
                        }),
                      value: Belt_Array.getBy(codeFrequencies$1.nav, (function (freq) {
                              return Belt_Option.getWithDefault(uciOvs.nav_freq_id, 0) === freq.id;
                            })),
                      label: React.createElement(Text$Ucidata.make, {
                            children: "NAV frequency"
                          }),
                      isClearable: true,
                      isDisabled: !isEditable,
                      getOptionLabel: (function (freq) {
                          return freq.name;
                        }),
                      getOptionValue: (function (freq) {
                          return freq.id;
                        })
                    }), React.createElement(Input_Select$Ucidata.make, {
                      name: "proc-frequency",
                      options: codeFrequencies$1.proc,
                      onChange: (function (freq) {
                          return Curry._1(dispatchUciOvs, {
                                      TAG: /* SetProcFrequency */3,
                                      _0: freq
                                    });
                        }),
                      value: Belt_Array.getBy(codeFrequencies$1.proc, (function (freq) {
                              return Belt_Option.getWithDefault(uciOvs.proc_freq_id, 0) === freq.id;
                            })),
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Procedure Control frequency"
                          }),
                      isClearable: true,
                      isDisabled: !isEditable,
                      getOptionLabel: (function (freq) {
                          return freq.name;
                        }),
                      getOptionValue: (function (freq) {
                          return freq.id;
                        })
                    }), React.createElement(Input_Select$Ucidata.make, {
                      name: "inv-frequency",
                      options: codeFrequencies$1.restr,
                      onChange: (function (freq) {
                          return Curry._1(dispatchUciOvs, {
                                      TAG: /* SetResctrictionFrequency */4,
                                      _0: freq
                                    });
                        }),
                      value: Belt_Array.getBy(codeFrequencies$1.restr, (function (freq) {
                              return Belt_Option.getWithDefault(uciOvs.inv_restr_freq_id, 0) === freq.id;
                            })),
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Invest Restrictions Control Frequency"
                          }),
                      isClearable: true,
                      isDisabled: !isEditable,
                      getOptionLabel: (function (freq) {
                          return freq.name;
                        }),
                      getOptionValue: (function (freq) {
                          return freq.id;
                        })
                    }), React.createElement(Input_Select$Ucidata.make, {
                      name: "settle-frequency",
                      options: codeFrequencies$1.settle,
                      onChange: (function (freq) {
                          return Curry._1(dispatchUciOvs, {
                                      TAG: /* SetSettlementFrequency */5,
                                      _0: freq
                                    });
                        }),
                      value: Belt_Array.getBy(codeFrequencies$1.settle, (function (freq) {
                              return Belt_Option.getWithDefault(uciOvs.time_settle_freq_id, 0) === freq.id;
                            })),
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Timely Settlement Control Frequency"
                          }),
                      isClearable: true,
                      isDisabled: !isEditable,
                      getOptionLabel: (function (freq) {
                          return freq.name;
                        }),
                      getOptionValue: (function (freq) {
                          return freq.id;
                        })
                    })), React.createElement(Font_Div$Ucidata.make, {
                  style: /* Large */3,
                  children: React.createElement(Text$Ucidata.make, {
                        children: "Activation"
                      })
                }), React.createElement("div", {
                  className: "bg-white rounded divide-y px-4 border text-sm"
                }, React.createElement(ArrayMapi$Ucidata.make, {
                      array: codesOversight$1,
                      render: (function (codeOversight, index) {
                          var checked = Belt_Option.mapWithDefault(Belt_Array.getBy(uciOvs.uci_ovs_configs, (function (config) {
                                      return config.code_oversight_id === codeOversight.id;
                                    })), false, (function (config) {
                                  return config.is_active;
                                }));
                          return React.createElement("div", {
                                      key: String(index),
                                      className: "py-4"
                                    }, React.createElement("label", {
                                          className: "flex items-center cursor-pointer space-x-4"
                                        }, React.createElement(Input_Toggle$Ucidata.make, {
                                              checked: checked,
                                              setChecked: (function (checked) {
                                                  return Curry._1(dispatchUciOvs, {
                                                              TAG: /* SetOvsConfigItem */7,
                                                              _0: index,
                                                              _1: checked
                                                            });
                                                }),
                                              disabled: !isEditable
                                            }), React.createElement(Text_Span$Ucidata.make, {
                                              children: codeOversight.name
                                            })), React.createElement(If$Ucidata.make, {
                                          condition: checked && index === 1,
                                          children: React.createElement("label", {
                                                className: "flex items-center cursor-pointer space-x-4 mt-4"
                                              }, React.createElement(Input_Toggle$Ucidata.make, {
                                                    checked: uciOvs.is_ext_valuer,
                                                    setChecked: (function (checked) {
                                                        return Curry._1(dispatchUciOvs, {
                                                                    TAG: /* SetExternalValuer */6,
                                                                    _0: checked
                                                                  });
                                                      }),
                                                    disabled: !isEditable
                                                  }), React.createElement(Text_Span$Ucidata.make, {
                                                    children: "External Valuer"
                                                  }))
                                        }));
                        })
                    })));
        break;
    
  }
  return React.createElement(Section$Ucidata.make, {
              title: React.createElement(Text$Ucidata.make, {
                    children: "Oversight"
                  }),
              className: "space-y-8",
              icon: ProRegularSvgIcons.faMonitorHeartRate,
              children: sectionContent
            });
}

var make = Compartiment_Oversight;

export {
  make ,
  
}
/* react Not a pure module */
