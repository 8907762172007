// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var context = React.createContext(undefined);

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var Provider = {
  make: make,
  makeProps: makeProps
};

function useUser(param) {
  return React.useContext(context);
}

export {
  context ,
  Provider ,
  useUser ,
  
}
/* context Not a pure module */
