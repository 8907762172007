// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "./core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Link$Ucidata from "./core/router/Link.bs.js";
import * as Text$Ucidata from "./core/text/Text.bs.js";
import * as Router$Ucidata from "./core/router/Router.bs.js";
import * as Text_Span$Ucidata from "./core/text/Text_Span.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function subroutes(path) {
  var subroutes$1 = Belt_List.reverse(Belt_List.reduce(path, [
              /* [] */0,
              []
            ], (function (param, fragment) {
                var subroutes = param[0];
                var subpaths = Belt_Array.concat(param[1], [fragment]);
                var subroute = Router$Ucidata.match(Belt_List.fromArray(subpaths));
                if (Router$Ucidata.isDefault(subroute)) {
                  return [
                          subroutes,
                          subpaths
                        ];
                } else {
                  return [
                          {
                            hd: subroute,
                            tl: subroutes
                          },
                          subpaths
                        ];
                }
              }))[0]);
  if (subroutes$1 && Router$Ucidata.isDefault(subroutes$1.hd)) {
    return subroutes$1;
  } else {
    return {
            hd: Router$Ucidata.match(/* [] */0),
            tl: subroutes$1
          };
  }
}

function Breadcrumbs(Props) {
  var className = Props.className;
  var titleOpt = Props.title;
  var title = titleOpt !== undefined ? titleOpt : false;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var subroutes$1 = subroutes(url.path);
  var breadcrumbElements = Belt_List.mapWithIndex(subroutes$1, (function (index, subroute) {
          var label = Curry._1(subroute.label, url);
          var path = subroute.path;
          return React.createElement("span", {
                      key: String(index),
                      className: "mr-2 font-semibold"
                    }, index === (Belt_List.length(subroutes$1) - 1 | 0) ? React.createElement(Text_Span$Ucidata.make, {
                            children: label
                          }) : React.createElement(React.Fragment, undefined, React.createElement(Link$Ucidata.make, {
                                href: path,
                                className: "mr-2 underline",
                                children: React.createElement(Text$Ucidata.make, {
                                      children: label
                                    })
                              }), React.createElement(Text$Ucidata.make, {
                                children: ">"
                              })));
        }));
  var currentElement = Belt_Option.getExn(Belt_List.head(Belt_List.reverse(breadcrumbElements)));
  return React.createElement(React.Fragment, undefined, React.createElement(If$Ucidata.make, {
                  condition: title,
                  children: React.createElement("h1", {
                        className: "text-3xl font-bold leading-tight"
                      }, currentElement)
                }), React.createElement(If$Ucidata.make, {
                  condition: Belt_List.length(breadcrumbElements) > (
                    title ? 1 : 0
                  ),
                  children: React.createElement("div", {
                        className: Cn.$plus(Cn.$plus("flex items-center", Cn.on("mt-4", title)), Cn.take(className))
                      }, Belt_List.toArray(breadcrumbElements))
                }));
}

var make = React.memo(Breadcrumbs);

export {
  subroutes ,
  make ,
  
}
/* make Not a pure module */
