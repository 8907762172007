// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function upload(client, item, codeDocStructType, file) {
  return client.url("/uci_struct_docfile/" + String(Belt_Option.getWithDefault(item.id, 0)) + "/" + String(codeDocStructType.id)).formData({
                  file: file
                }, undefined).post(undefined).json();
}

export {
  upload ,
  
}
/* No side effect */
