// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as If$Ucidata from "../conditionals/If.bs.js";
import * as ArrayMapi$Ucidata from "../conditionals/ArrayMapi.bs.js";
import * as OptionMap$Ucidata from "../conditionals/OptionMap.bs.js";

function Validation_Errors(Props) {
  var errors = Props.errors;
  var dirty = Props.dirty;
  return React.createElement(If$Ucidata.make, {
              condition: dirty,
              children: React.createElement(ArrayMapi$Ucidata.make, {
                    array: errors,
                    render: (function (error, idx) {
                        return React.createElement("p", {
                                    key: String(idx),
                                    className: "text-red-500 text-xs italic mt-2"
                                  }, React.createElement(OptionMap$Ucidata.make, {
                                        option: error.message,
                                        render: (function (prim) {
                                            return prim;
                                          })
                                      }));
                      })
                  })
            });
}

var make = React.memo(Validation_Errors);

export {
  make ,
  
}
/* make Not a pure module */
