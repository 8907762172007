// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as ArrayMap$Ucidata from "../../../core/conditionals/ArrayMap.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as StateLoader$Ucidata from "../../../core/StateLoader.bs.js";
import * as UciStructDocFile$Ucidata from "../../../../models/UciStructDocFile.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";

function CompartimentHoldingStructure_DocList(Props) {
  var codeDocStructType = Props.codeDocStructType;
  var item = Props.item;
  var editable = Props.editable;
  var onSuccessfulUpload = Props.onSuccessfulUpload;
  var client = Client$Ucidata.useHttpClient(undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setUploading = match[1];
  var uploading = match[0];
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearError = match$1[2];
  var setError = match$1[1];
  var match$2 = Hooks$Ucidata.useFixedReference(undefined);
  var refetchDocuments = match$2[1];
  var match$3 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match$2[0]], undefined, "/uci_struct_docfile/" + String(Belt_Option.getWithDefault(item.id, 0)) + "/" + String(codeDocStructType.id));
  var match$4 = React.useState(function () {
        return [];
      });
  var setFiles = match$4[1];
  var files = match$4[0];
  var tmp = {
    mode: /* Light */0
  };
  var tmp$1 = match$1[0];
  if (tmp$1 !== undefined) {
    tmp.error = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              key: String(codeDocStructType.id),
              className: "bg-gray-50 rounded px-4 py-2 space-y-2 text-xs"
            }, React.createElement("div", {
                  className: "space-x-4 flex items-center"
                }, React.createElement(If$Ucidata.make, {
                      condition: editable,
                      children: null
                    }, React.createElement(Button$Ucidata.make, {
                          style: /* Secondary */2,
                          size: /* Slim */3,
                          disabled: uploading,
                          noPadding: true,
                          children: React.createElement("label", {
                                className: "block cursor-pointer space-x-2 px-2 py-0.5"
                              }, React.createElement(Icon$Ucidata.make, {
                                    text: "Add",
                                    icon: ProLightSvgIcons.faFileAlt
                                  }), React.createElement("input", {
                                    className: "hidden",
                                    multiple: true,
                                    type: "file",
                                    onChange: (function (e) {
                                        var target = e.target;
                                        var newFiles = Array.from(target.files);
                                        Curry._1(setFiles, (function (files) {
                                                return Belt_Array.concat(files, newFiles);
                                              }));
                                        target.value = "";
                                        
                                      })
                                  }))
                        }), React.createElement(If$Ucidata.make, {
                          condition: files.length !== 0,
                          children: React.createElement(Button$Ucidata.make, {
                                style: /* Primary */0,
                                size: /* Slim */3,
                                disabled: uploading,
                                icon: ProSolidSvgIcons.faSave,
                                onClick: (function (param) {
                                    Curry._1(clearError, undefined);
                                    Curry._1(setUploading, (function (param) {
                                            return true;
                                          }));
                                    var uploads = Belt_Array.map(files, (function (file) {
                                            return UciStructDocFile$Ucidata.upload(client, item, codeDocStructType, file);
                                          }));
                                    Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(Promise.all(uploads), (function (param) {
                                                        Curry._1(refetchDocuments, undefined);
                                                        return Belt_Option.forEach(onSuccessfulUpload, (function (fn) {
                                                                      return Curry._1(fn, undefined);
                                                                    }));
                                                      })), setError), (function (param) {
                                                return Curry._1(setUploading, (function (param) {
                                                              return false;
                                                            }));
                                              })));
                                    return Curry._1(setFiles, (function (param) {
                                                  return [];
                                                }));
                                  }),
                                children: React.createElement(Text$Ucidata.make, {
                                      children: "Save"
                                    })
                              })
                        }))), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(If$Ucidata.make, {
                  condition: uploading,
                  children: React.createElement(Island$Ucidata.make, {
                        children: React.createElement("div", {
                              className: "text-xs"
                            }, React.createElement(Icon$Ucidata.make, {
                                  text: "Uploading documents…",
                                  icon: ProDuotoneSvgIcons.faSpinner,
                                  pulse: true
                                }))
                      })
                }), React.createElement("div", {
                  className: "flex items-center space-x-2"
                }, React.createElement(StateLoader$Ucidata.make, {
                      className: "p-2 text-xl",
                      state: match$3[0],
                      render: (function (documents) {
                          return React.createElement(If$Ucidata.make, {
                                      condition: documents.length !== 0,
                                      renderElse: (function (documents) {
                                          return React.createElement(If$Ucidata.make, {
                                                      condition: files.length === 0,
                                                      children: React.createElement(Font$Ucidata.make, {
                                                            style: /* Italic */4,
                                                            children: React.createElement(Text$Ucidata.make, {
                                                                  children: "No uploaded documents."
                                                                })
                                                          })
                                                    });
                                        }),
                                      children: React.createElement("div", {
                                            className: "space-y-2 w-full"
                                          }, React.createElement(ArrayMap$Ucidata.make, {
                                                array: documents,
                                                render: (function ($$document) {
                                                    var link = "/api/uci_struct_docfile/" + String($$document.id);
                                                    var match = Utils$Ucidata.getFileSize(undefined, $$document.size);
                                                    var unit = match[1];
                                                    var fileSize = match[0];
                                                    return React.createElement(Collapsible$Ucidata.make, {
                                                                shallow: true,
                                                                children: React.createElement("div", {
                                                                      className: "py-4"
                                                                    }, React.createElement("iframe", {
                                                                          className: "w-full min-h-3/4",
                                                                          src: link
                                                                        })),
                                                                render: (function (param) {
                                                                    return React.createElement("div", {
                                                                                className: "space-x-2 flex items-center"
                                                                              }, React.createElement(Link$Ucidata.make, {
                                                                                    href: link + "?download=true",
                                                                                    vanilla: true,
                                                                                    className: "transition-colors hover:text-gray-800 hover:bg-blue-300 hover:border-blue-300 border rounded-lg w-8 h-8 inline-flex items-center justify-center",
                                                                                    unstyled: true,
                                                                                    onClick: (function (prim) {
                                                                                        prim.stopPropagation();
                                                                                        
                                                                                      }),
                                                                                    title: "Download",
                                                                                    children: React.createElement(Icon$Ucidata.make, {
                                                                                          icon: ProLightSvgIcons.faDownload
                                                                                        })
                                                                                  }), React.createElement(Text_Span$Ucidata.make, {
                                                                                    children: $$document.descn
                                                                                  }), React.createElement(Font$Ucidata.make, {
                                                                                    style: /* Italic */4,
                                                                                    children: React.createElement(Text_Span$Ucidata.make, {
                                                                                          children: "(" + fileSize + " " + unit + ")"
                                                                                        })
                                                                                  }));
                                                                  }),
                                                                key: String($$document.id)
                                                              });
                                                  })
                                              }))
                                    });
                        })
                    })), React.createElement(If$Ucidata.make, {
                  condition: files.length !== 0,
                  children: null
                }, React.createElement(Font_Div$Ucidata.make, {
                      style: /* Italic */4,
                      children: React.createElement(Text$Ucidata.make, {
                            children: "By uploading you confirm that all documents are true copies or as defined in the OP Memorandum of the UCI"
                          })
                    }), React.createElement("div", {
                      className: "flex flex-wrap"
                    }, React.createElement(ArrayMapi$Ucidata.make, {
                          array: files,
                          render: (function (file, index) {
                              var match = Utils$Ucidata.getFileSize(undefined, file.size);
                              return React.createElement("div", {
                                          key: String(index),
                                          className: "border rounded p-1 px-2 mr-2 mt-1 space-x-2"
                                        }, React.createElement(Text_Span$Ucidata.make, {
                                              children: file.name
                                            }), React.createElement(Font$Ucidata.make, {
                                              style: /* Italic */4,
                                              children: React.createElement(Text_Span$Ucidata.make, {
                                                    children: "(" + match[0] + " " + match[1] + ")"
                                                  })
                                            }), React.createElement(Button$Ucidata.make, {
                                              style: /* Unstyled */4,
                                              onClick: (function (param) {
                                                  return Curry._1(setFiles, (function (files) {
                                                                return Belt_Array.keep(files, (function (f) {
                                                                              return f !== file;
                                                                            }));
                                                              }));
                                                }),
                                              children: React.createElement(Icon$Ucidata.make, {
                                                    icon: ProSolidSvgIcons.faTimes,
                                                    className: "text-red-400 hover:text-red-500"
                                                  })
                                            }));
                            })
                        }))));
}

var make = React.memo(CompartimentHoldingStructure_DocList);

var Helpers;

export {
  Helpers ,
  make ,
  
}
/* make Not a pure module */
