// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Input_Select$Ucidata from "../../core/input/Input_Select.bs.js";
import * as Input_DateRange$Ucidata from "../../core/input/Input_DateRange.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

var exportFormats = [
  {
    label: "csv",
    value: "csv"
  },
  {
    label: "json",
    value: "json"
  }
];

function Assets_Export(Props) {
  var uciGroups = Props.uciGroups;
  var match = React.useState(function () {
        return [];
      });
  var setGroups = match[1];
  var groups = match[0];
  var match$1 = React.useState(function () {
        return null;
      });
  var setDateRange = match$1[1];
  var dateRange = match$1[0];
  var match$2 = React.useState(function () {
        return Belt_Array.getExn(exportFormats, 0);
      });
  var setExportFormat = match$2[1];
  var exportFormat = match$2[0];
  var searchParams = new URLSearchParams("");
  searchParams.append("download", "true");
  var groupQp = Belt_Array.map(groups, (function (group) {
            return Belt_Option.getExn(group.id);
          })).join(",");
  searchParams.append("uci_groups", groupQp);
  Js_null_undefined.bind(dateRange, (function (dateRange) {
          searchParams.append("date_from", dateRange[0].toISOString());
          searchParams.append("date_to", dateRange[1].toISOString());
          
        }));
  searchParams.append("format", exportFormat.value);
  var downloadUrl = "/api/report/uci_items?" + searchParams.toString();
  return React.createElement("div", {
              className: "md:border rounded md:p-8 space-y-8"
            }, React.createElement(Layout$Ucidata.make, {
                  style: /* Grid2Cols */0,
                  children: null
                }, React.createElement(Input_Select$Ucidata.make, {
                      name: "export-uci-groups",
                      options: uciGroups,
                      onChange: (function (groups) {
                          return Curry._1(setGroups, (function (param) {
                                        return groups;
                                      }));
                        }),
                      value: groups,
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Uci"
                          }),
                      className: "flex-1",
                      isMulti: true,
                      getOptionLabel: (function (uciGroup) {
                          return uciGroup.name;
                        }),
                      getOptionValue: (function (uciGroup) {
                          return uciGroup.id;
                        })
                    }), React.createElement(Input_DateRange$Ucidata.make, {
                      id: "export-date-range",
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Date Range"
                          }),
                      className: "flex-1",
                      format: "y-MM-dd",
                      onChange: (function (value) {
                          return Curry._1(setDateRange, (function (param) {
                                        return value;
                                      }));
                        }),
                      value: dateRange
                    })), React.createElement("div", {
                  className: "flex items-center space-x-4"
                }, React.createElement(Button$Ucidata.make, {
                      style: /* Primary */0,
                      noPadding: true,
                      children: React.createElement("a", {
                            className: "inline-block py-1.5 px-4",
                            href: downloadUrl,
                            rel: "noopener noreferrer",
                            target: "_blank"
                          }, React.createElement(Icon$Ucidata.make, {
                                text: "Download",
                                icon: ProSolidSvgIcons.faDownload
                              }))
                    }), React.createElement(Input_Select$Ucidata.make, {
                      name: "export-format",
                      options: exportFormats,
                      onChange: (function (format) {
                          return Curry._1(setExportFormat, (function (param) {
                                        return format;
                                      }));
                        }),
                      value: exportFormat,
                      size: /* Small */0,
                      placeholder: "Export Format"
                    })));
}

var make = React.memo(Assets_Export);

export {
  exportFormats ,
  make ,
  
}
/* make Not a pure module */
