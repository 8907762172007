// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function ArrayMap(Props) {
  var render = Props.render;
  var array = Props.array;
  return array.map(Curry.__1(render));
}

var make = ArrayMap;

export {
  make ,
  
}
/* No side effect */
