// Generated by ReScript, PLEASE EDIT WITH CARE


var OvsStatus = {};

var Uci = {
  OvsStatus: OvsStatus
};

var empty_ucis = [];

var empty = {
  id: undefined,
  name: "",
  cssf_code: "",
  aifm: undefined,
  central_admin: undefined,
  ucis: empty_ucis
};

export {
  Uci ,
  empty ,
  
}
/* No side effect */
