// Generated by ReScript, PLEASE EDIT WITH CARE


var Detailed = {};

function save(client, item) {
  return client.url("/uci_ovs_items/" + String(item.id)).put(item).json();
}

export {
  Detailed ,
  save ,
  
}
/* No side effect */
