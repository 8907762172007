// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Text_Span$Ucidata from "./core/text/Text_Span.bs.js";

import Logo from '../assets/logo_small.png'
;

var logoSrc = Logo;

function Footer(Props) {
  var className = Props.className;
  var logoClassName = Props.logoClassName;
  return React.createElement("footer", {
              className: Cn.$plus("px-8 text-3xs", Cn.take(className))
            }, React.createElement("div", {
                  className: "max-w-screen-2xl mx-auto flex items-center space-x-4 md:justify-start justify-center"
                }, React.createElement("div", {
                      className: "h-8 p-1"
                    }, React.createElement("img", {
                          className: Cn.$plus("h-full", Cn.take(logoClassName)),
                          src: logoSrc
                        })), React.createElement(Text_Span$Ucidata.make, {
                      children: "© 2021 Edgewood Technologies Europe LLP"
                    })));
}

var make = React.memo(Footer);

export {
  logoSrc ,
  make ,
  
}
/*  Not a pure module */
