// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as User$Ucidata from "../../../models/User.bs.js";
import * as Codes$Ucidata from "../../Codes.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Modal$Ucidata from "../../core/modals/Modal.bs.js";
import * as Table$Ucidata from "../../core/Table.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Wretch$Ucidata from "../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../core/conditionals/OptionMap.bs.js";
import * as PopupMenu$Ucidata from "../../core/PopupMenu.bs.js";
import * as StateLoader$Ucidata from "../../core/StateLoader.bs.js";
import * as Admin_UserDetails$Ucidata from "./Admin_UserDetails.bs.js";
import * as Admin_ChangePassword$Ucidata from "./Admin_ChangePassword.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var UserTable = Table$Ucidata.Make({});

function Admin(Props) {
  var client = Client$Ucidata.useClient(undefined);
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var fetchUsers = match[1];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/user");
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowUserModal = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowChangePasswordModal = match$3[1];
  var showChangePasswordModal = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setShowConfirmDeletionModal = match$4[1];
  var match$5 = React.useState(function () {
        
      });
  var setSelectedUser = match$5[1];
  var selectedUser = match$5[0];
  var codes = Codes$Ucidata.useCodes(undefined);
  var columns = React.useMemo((function () {
          return Curry._1(UserTable.Column.make, [
                      {
                        label: "Id",
                        extract: (function (user) {
                            return String(user.id);
                          }),
                        options: Curry._7(UserTable.Column.Options.make, undefined, true, undefined, undefined, "w-10 text-center border-r", undefined, undefined)
                      },
                      {
                        label: "",
                        extract: (function (param) {
                            return "";
                          }),
                        options: Curry._7(UserTable.Column.Options.make, undefined, undefined, (function (param, user) {
                                return React.createElement(PopupMenu$Ucidata.make, {
                                            render: (function (setShowMenu) {
                                                return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setSelectedUser, (function (param) {
                                                                            return user;
                                                                          }));
                                                                    Curry._1(setShowUserModal, (function (param) {
                                                                            return true;
                                                                          }));
                                                                    return Curry._1(setShowMenu, (function (param) {
                                                                                  return false;
                                                                                }));
                                                                  }),
                                                                children: React.createElement(Icon$Ucidata.make, {
                                                                      text: "Edit",
                                                                      icon: ProRegularSvgIcons.faEdit,
                                                                      fixedWidth: true
                                                                    }),
                                                                key: "edit-user"
                                                              }), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setSelectedUser, (function (param) {
                                                                            return user;
                                                                          }));
                                                                    Curry._1(setShowChangePasswordModal, (function (param) {
                                                                            return true;
                                                                          }));
                                                                    return Curry._1(setShowMenu, (function (param) {
                                                                                  return false;
                                                                                }));
                                                                  }),
                                                                children: React.createElement(Icon$Ucidata.make, {
                                                                      text: "Change Password",
                                                                      icon: ProSolidSvgIcons.faKey,
                                                                      fixedWidth: true
                                                                    }),
                                                                key: "change-user-password"
                                                              }), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                                onClick: (function (param) {
                                                                    Curry._1(setSelectedUser, (function (param) {
                                                                            return user;
                                                                          }));
                                                                    Curry._1(setShowConfirmDeletionModal, (function (param) {
                                                                            return true;
                                                                          }));
                                                                    return Curry._1(setShowMenu, (function (param) {
                                                                                  return false;
                                                                                }));
                                                                  }),
                                                                className: "text-red-500",
                                                                children: React.createElement(Icon$Ucidata.make, {
                                                                      text: "Delete",
                                                                      icon: ProRegularSvgIcons.faTrash,
                                                                      fixedWidth: true
                                                                    }),
                                                                key: "delete-user"
                                                              }));
                                              }),
                                            position: /* RightTopAlign */2,
                                            children: React.createElement(Button$Ucidata.make, {
                                                  style: /* Unstyled */4,
                                                  className: "h-8 px-2",
                                                  children: React.createElement(Icon$Ucidata.make, {
                                                        icon: ProRegularSvgIcons.faEllipsisH,
                                                        className: "text-base"
                                                      })
                                                })
                                          });
                              }), undefined, "w-10 border-r text-center", undefined, undefined)
                      },
                      {
                        label: "First Name",
                        extract: (function (user) {
                            return user.first_name;
                          }),
                        options: Curry._7(UserTable.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                      },
                      {
                        label: "Last Name",
                        extract: (function (user) {
                            return user.last_name;
                          }),
                        options: Curry._7(UserTable.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                      },
                      {
                        label: "Identifier",
                        extract: (function (user) {
                            return user.identifier;
                          }),
                        options: Curry._7(UserTable.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                      },
                      {
                        label: "Roles",
                        extract: (function (user) {
                            return Belt_Option.getWithDefault(Belt_Option.flatMap(user.user_roles, (function (roles) {
                                              return Belt_Option.map(codes, (function (codes) {
                                                            return Belt_Array.map(roles, (function (role) {
                                                                            return Belt_Option.map(Belt_Array.getBy(codes.codesRole, (function (param) {
                                                                                              return param.id === role;
                                                                                            })), (function (param) {
                                                                                          return param.name;
                                                                                        }));
                                                                          })).join(", ");
                                                          }));
                                            })), "");
                          }),
                        options: Curry._7(UserTable.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                      }
                    ]);
        }), [codes]);
  var tmp = {
    onSave: (function (param) {
        Curry._1(fetchUsers, undefined);
        return Curry._1(setShowUserModal, (function (param) {
                      return false;
                    }));
      }),
    onCancel: (function (param) {
        return Curry._1(setShowUserModal, (function (param) {
                      return false;
                    }));
      })
  };
  if (selectedUser !== undefined) {
    tmp.user = Caml_option.valFromOption(selectedUser);
  }
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: null
            }, React.createElement(Modal$Ucidata.make, {
                  isOpen: match$2[0],
                  setOpen: setShowUserModal,
                  closeOnOutsideClick: true,
                  closeOnEsc: true,
                  size: /* Large */1,
                  children: React.createElement(Admin_UserDetails$Ucidata.make, tmp)
                }), React.createElement(Modal$Ucidata.make, {
                  isOpen: match$4[0],
                  setOpen: setShowConfirmDeletionModal,
                  closeOnOutsideClick: true,
                  closeOnEsc: true,
                  size: /* Large */1,
                  children: React.createElement("div", {
                        className: "space-y-8"
                      }, React.createElement(Font_Div$Ucidata.make, {
                            style: /* Title */1,
                            children: React.createElement(Text$Ucidata.make, {
                                  children: "User Deletion"
                                })
                          }), React.createElement(Font_Div$Ucidata.make, {
                            style: /* Subtitle */2,
                            children: React.createElement(Icon$Ucidata.make, {
                                  text: "Please confirm that you wish to permanently delete this user.",
                                  icon: ProSolidSvgIcons.faExclamationTriangle,
                                  className: "text-yellow-500"
                                })
                          }), React.createElement("div", {
                            className: "space-x-4"
                          }, React.createElement(Button$Ucidata.make, {
                                onClick: (function (param) {
                                    return Curry._1(setShowConfirmDeletionModal, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: React.createElement(Text$Ucidata.make, {
                                      children: "Cancel"
                                    })
                              }), React.createElement(Button$Ucidata.make, {
                                style: /* PrimaryRed */1,
                                icon: ProRegularSvgIcons.faTrash,
                                onClick: (function (param) {
                                    return Belt_Option.mapWithDefault(selectedUser, undefined, (function (user) {
                                                  return Wretch$Ucidata.ignore(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(User$Ucidata.$$delete(client, user.id), (function (param) {
                                                                        Curry._1(fetchUsers, undefined);
                                                                        return Curry._1(setShowConfirmDeletionModal, (function (param) {
                                                                                      return false;
                                                                                    }));
                                                                      })), (function (param) {
                                                                    
                                                                  })));
                                                }));
                                  }),
                                children: React.createElement(Text$Ucidata.make, {
                                      children: "Delete"
                                    })
                              })))
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: selectedUser,
                  render: (function (param) {
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showChangePasswordModal,
                                  setOpen: setShowChangePasswordModal,
                                  closeOnOutsideClick: true,
                                  closeOnEsc: true,
                                  size: /* Large */1,
                                  children: React.createElement(Admin_ChangePassword$Ucidata.make, {
                                        userId: String(param.id),
                                        onCancel: (function (param) {
                                            return Curry._1(setShowChangePasswordModal, (function (param) {
                                                          return false;
                                                        }));
                                          }),
                                        onSave: (function (param) {
                                            return Curry._1(setShowChangePasswordModal, (function (param) {
                                                          return false;
                                                        }));
                                          })
                                      })
                                });
                    })
                }), React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: React.createElement(Font_Div$Ucidata.make, {
                        style: /* Title */1,
                        className: "mb-2",
                        children: React.createElement(Icon$Ucidata.make, {
                              text: "Admin",
                              icon: ProSolidSvgIcons.faUserCog
                            })
                      })
                }), React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Icon$Ucidata.make, {
                        text: "User List",
                        icon: ProLightSvgIcons.faUsers,
                        className: "mr-2"
                      }),
                  className: "space-y-4",
                  children: null
                }, React.createElement("div", undefined, React.createElement(Button$Ucidata.make, {
                          style: /* Primary */0,
                          size: /* Small */0,
                          icon: ProRegularSvgIcons.faPlus,
                          onClick: (function (param) {
                              Curry._1(setSelectedUser, (function (param) {
                                      
                                    }));
                              return Curry._1(setShowUserModal, (function (param) {
                                            return true;
                                          }));
                            }),
                          children: React.createElement(Text$Ucidata.make, {
                                children: "Create User"
                              })
                        })), React.createElement(StateLoader$Ucidata.make, {
                      className: "text-6xl",
                      state: match$1[0],
                      render: (function (users) {
                          return React.createElement(UserTable.make, {
                                      columns: columns,
                                      data: users,
                                      dataKey: (function (user) {
                                          return String(user.id);
                                        }),
                                      className: "table-auto w-full whitespace-nowrap border-separate rounded border text-xs text-left",
                                      overflowContainerClassName: "w-full",
                                      thClassName: "p-4 font-medium text-center",
                                      tdClassName: "p-4 border-r",
                                      headTrClassName: "bg-gray-100 uppercase",
                                      bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
                                      paginationLimit: 50
                                    });
                        })
                    })));
}

var make = React.memo(Admin);

var UserDetails;

var ChangeUserPassword;

var Column;

export {
  UserDetails ,
  ChangeUserPassword ,
  UserTable ,
  Column ,
  make ,
  
}
/* UserTable Not a pure module */
