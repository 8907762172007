// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Table$Ucidata from "../../../core/Table.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as PopupMenu$Ucidata from "../../../core/PopupMenu.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

var Table = Table$Ucidata.Make({});

function make(codes, events) {
  return Curry._1(Table.Column.make, Curry._1(Table.Column.make, [
                  {
                    label: "Bank Name",
                    extract: (function (account) {
                        return account.bank_name;
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "IBAN",
                    extract: (function (account) {
                        return account.iban;
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Currency",
                    extract: (function (account) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                          return elt.id === account.code_ccy_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "",
                    extract: (function (param) {
                        return "";
                      }),
                    options: Curry._7(Table.Column.Options.make, false, undefined, (function (param, item) {
                            return React.createElement(PopupMenu$Ucidata.make, {
                                        render: (function (setShowMenu) {
                                            return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                            onClick: (function (param) {
                                                                Curry._1(events.onEditAccountClick, item);
                                                                return Curry._1(setShowMenu, (function (param) {
                                                                              return false;
                                                                            }));
                                                              }),
                                                            children: React.createElement(Icon$Ucidata.make, {
                                                                  text: "Edit Account",
                                                                  icon: ProRegularSvgIcons.faEdit,
                                                                  fixedWidth: true
                                                                }),
                                                            key: "edit-account"
                                                          }), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                            onClick: (function (param) {
                                                                Curry._1(events.onDeleteAccountClick, item);
                                                                return Curry._1(setShowMenu, (function (param) {
                                                                              return false;
                                                                            }));
                                                              }),
                                                            children: React.createElement(Icon$Ucidata.make, {
                                                                  text: "Delete Account",
                                                                  icon: ProRegularSvgIcons.faTrash,
                                                                  fixedWidth: true
                                                                }),
                                                            key: "delete-account"
                                                          }));
                                          }),
                                        children: React.createElement(Button$Ucidata.make, {
                                              style: /* Unstyled */4,
                                              className: "h-8 px-2",
                                              children: React.createElement(Icon$Ucidata.make, {
                                                    icon: ProRegularSvgIcons.faEllipsisH,
                                                    className: "text-base"
                                                  })
                                            })
                                      });
                          }), undefined, undefined, undefined, undefined)
                  }
                ]));
}

var Column;

export {
  Table ,
  Column ,
  make ,
  
}
/* Table Not a pure module */
