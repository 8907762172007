// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Link$Ucidata from "../../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Modal$Ucidata from "../../../core/modals/Modal.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Unless$Ucidata from "../../../core/conditionals/Unless.bs.js";
import * as Font_H1$Ucidata from "../../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as UciItem$Ucidata from "../../../../models/UciItem.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as Uploader$Ucidata from "../../../../store/Uploader.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as PopupMenu$Ucidata from "../../../core/PopupMenu.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "../../../core/conditionals/IfUserRole.bs.js";
import * as Collapsible$Ucidata from "../../../core/Collapsible.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as UserContext$Ucidata from "../../../UserContext.bs.js";
import * as Input_Search$Ucidata from "../../../core/input/Input_Search.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Input_Toggle$Ucidata from "../../../core/input/Input_Toggle.bs.js";
import * as Input_DateRange$Ucidata from "../../../core/input/Input_DateRange.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as Assets_UciGroupsList$Ucidata from "../Assets_UciGroupsList.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";
import * as CompartimentTransactions_Columns$Ucidata from "./CompartimentTransactions_Columns.bs.js";
import * as CompartimentTransactions_History$Ucidata from "./CompartimentTransactions_History.bs.js";
import * as CompartimentTransactions_Documents$Ucidata from "./CompartimentTransactions_Documents.bs.js";
import * as CompartimentTransactions_ItemDetails$Ucidata from "./CompartimentTransactions_ItemDetails.bs.js";
import * as CompartimentTransactions_ItemPosition$Ucidata from "./CompartimentTransactions_ItemPosition.bs.js";

var defaultFilters_dateRange = null;

var defaultFilters = {
  status: undefined,
  name: "",
  validationStatus: undefined,
  dateRange: defaultFilters_dateRange
};

function CompartimentTransactions$TableWrapper(Props) {
  var columns = Props.columns;
  var items = Props.items;
  var codes = Props.codes;
  var user = Props.user;
  var uci = Props.uci;
  var positionId = Props.positionId;
  var itemType = Props.itemType;
  var setItemType = Props.setItemType;
  var filters = Props.filters;
  var setFilters = Props.setFilters;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var statusOptions = React.useMemo((function () {
          return Belt_Option.mapWithDefault(codes, [], (function (codes) {
                        return codes.codesStatus;
                      }));
        }), [codes]);
  var validationStatusOptions = React.useMemo((function () {
          return Belt_Option.mapWithDefault(codes, [], (function (codes) {
                        return Belt_Array.map(codes.codesDocStatus, (function (codeDocStatus) {
                                      return {
                                              id: codeDocStatus.id,
                                              name: $$String.capitalize_ascii(codeDocStatus.name)
                                            };
                                    }));
                      }));
        }), [codes]);
  React.useEffect((function () {
          if (user !== undefined && uci !== undefined && IfUciRole$Ucidata.check([], user, uci, [/* Controller */1])) {
            var defaultValidationOption = Belt_Array.getBy(validationStatusOptions, (function (opt) {
                    return opt.id === 1;
                  }));
            Curry._1(setFilters, (function (filters) {
                    return {
                            status: filters.status,
                            name: filters.name,
                            validationStatus: defaultValidationOption,
                            dateRange: filters.dateRange
                          };
                  }));
          }
          
        }), [
        user,
        uci
      ]);
  var tmp = {
    name: "status-filter",
    options: statusOptions,
    onChange: (function (status) {
        return Curry._1(setFilters, (function (filters) {
                      return {
                              status: status,
                              name: filters.name,
                              validationStatus: filters.validationStatus,
                              dateRange: filters.dateRange
                            };
                    }));
      }),
    label: React.createElement(Text$Ucidata.make, {
          children: "Status"
        }),
    className: "pt-0",
    isClearable: true,
    getOptionLabel: (function (option) {
        return option.name;
      }),
    getOptionValue: (function (option) {
        return option.id;
      })
  };
  if (filters.status !== undefined) {
    tmp.value = Caml_option.valFromOption(filters.status);
  }
  var tmp$1 = {
    roles: [
      /* Manager */0,
      /* BackOffice */2
    ],
    children: React.createElement(Input_Select$Ucidata.make, tmp)
  };
  if (uci !== undefined) {
    tmp$1.uci = Caml_option.valFromOption(uci);
  }
  var tmp$2 = {
    name: "validation-status-filter",
    options: validationStatusOptions,
    onChange: (function (validationStatus) {
        return Curry._1(setFilters, (function (filters) {
                      return {
                              status: filters.status,
                              name: filters.name,
                              validationStatus: validationStatus,
                              dateRange: filters.dateRange
                            };
                    }));
      }),
    label: React.createElement(Text$Ucidata.make, {
          children: "Documents State"
        }),
    className: "pt-0",
    isClearable: true,
    getOptionLabel: (function (option) {
        return option.name;
      }),
    getOptionValue: (function (option) {
        return option.id;
      })
  };
  if (filters.validationStatus !== undefined) {
    tmp$2.value = Caml_option.valFromOption(filters.validationStatus);
  }
  var tmp$3 = {
    roles: [/* Controller */1],
    children: React.createElement(Input_Select$Ucidata.make, tmp$2)
  };
  if (uci !== undefined) {
    tmp$3.uci = Caml_option.valFromOption(uci);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Collapsible$Ucidata.make, {
                  openedClassName: "border-b pb-2",
                  containerClassName: "bg-white p-2 border",
                  children: React.createElement("div", {
                        className: "mt-2 md:mt-0 md:flex flex-wrap space-y-10 md:space-y-10 gap-10 py-2 px-4 text-sm items-end"
                      }, React.createElement("div", {
                            className: "flex flex-col items-center"
                          }, React.createElement(Font_Div$Ucidata.make, {
                                style: /* UppercaseLabel */6,
                                className: "text-xs mb-2",
                                children: React.createElement(Text$Ucidata.make, {
                                      children: "Commitments"
                                    })
                              }), React.createElement("div", {
                                className: "h-11 flex justify-center items-center"
                              }, React.createElement(Input_Toggle$Ucidata.make, {
                                    checked: itemType === "2",
                                    setChecked: (function (checked) {
                                        var newItemType = checked ? "2" : "1";
                                        var newQueryParams = new URLSearchParams(url.search);
                                        newQueryParams.set("itemType", newItemType);
                                        RescriptReactRouter.replace("?" + newQueryParams.toString());
                                        return Curry._1(setItemType, (function (param) {
                                                      return newItemType;
                                                    }));
                                      })
                                  }))), React.createElement(IfUciRole$Ucidata.make, tmp$1), React.createElement(Unless$Ucidata.make, {
                            condition: Belt_Option.isSome(positionId),
                            children: React.createElement(Input_Search$Ucidata.make, {
                                  id: "search-name",
                                  value: filters.name,
                                  label: "Name",
                                  onChange: (function (name) {
                                      return Curry._1(setFilters, (function (filters) {
                                                    return {
                                                            status: filters.status,
                                                            name: name,
                                                            validationStatus: filters.validationStatus,
                                                            dateRange: filters.dateRange
                                                          };
                                                  }));
                                    }),
                                  className: "pt-0",
                                  placeholder: "Item Name"
                                })
                          }), React.createElement(IfUciRole$Ucidata.make, tmp$3), React.createElement(Input_DateRange$Ucidata.make, {
                            id: "filter-date-range",
                            label: React.createElement(Text$Ucidata.make, {
                                  children: "Date Range"
                                }),
                            className: "pt-0",
                            format: "y-MM-dd",
                            onChange: (function (value) {
                                return Curry._1(setFilters, (function (filters) {
                                              return {
                                                      status: filters.status,
                                                      name: filters.name,
                                                      validationStatus: filters.validationStatus,
                                                      dateRange: value
                                                    };
                                            }));
                              }),
                            value: filters.dateRange
                          })),
                  render: (function (param) {
                      return React.createElement(Font_Div$Ucidata.make, {
                                  style: /* Uppercase */5,
                                  className: "text-sm",
                                  children: React.createElement(Icon$Ucidata.make, {
                                        text: "Filters",
                                        icon: ProRegularSvgIcons.faFilter,
                                        className: "ml-2"
                                      })
                                });
                    })
                }), React.createElement(CompartimentTransactions_Columns$Ucidata.Table.make, {
                  columns: columns,
                  data: items,
                  dataKey: (function (item) {
                      return String(item.id);
                    }),
                  className: "table-auto whitespace-nowrap border-separate rounded border text-xs text-center",
                  tableExtraClass: "rounded-l-none",
                  fixedTableExtraClass: "border-r-8 rounded-r-none",
                  containerClassName: "border-r rounded border-dashed",
                  thClassName: "py-4 px-2 font-medium",
                  tdClassName: "py-4 px-2",
                  headTrClassName: "bg-gray-100 uppercase",
                  bodyTrClassName: "hover:bg-blue-50 transition-colors duration-200 bg-white",
                  paginationLimit: 20
                }));
}

var make = React.memo(CompartimentTransactions$TableWrapper);

var TableWrapper = {
  make: make
};

function CompartimentTransactions(Props) {
  var uciId = Props.uciId;
  var user = UserContext$Ucidata.useUser(undefined);
  var client = Client$Ucidata.useClient(undefined);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        return defaultFilters;
      });
  var filters = match[0];
  var queryParams = new URLSearchParams(url.search);
  var positionId = queryParams.get("positionId");
  var positionId$1 = (positionId == null) ? undefined : Caml_option.some(positionId);
  var match$1 = React.useState(function () {
        return Belt_Option.getWithDefault(Caml_option.nullable_to_opt(queryParams.get("itemType")), "1");
      });
  var itemType = match$1[0];
  var match$2 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci/" + uciId);
  var match$3 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_group");
  var match$4 = Hooks$Ucidata.useFixedReference(undefined);
  var fetchItems = match$4[1];
  var searchParams = new URLSearchParams("");
  searchParams.append("type", itemType);
  Belt_Option.forEach(filters.validationStatus, (function (validationStatus) {
          searchParams.append("validation_status", String(validationStatus.id));
          
        }));
  var searchParams$1 = searchParams.toString();
  var match$5 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match$4[0]], undefined, Belt_Option.mapWithDefault(positionId$1, "/uci/" + uciId + "/uci_item?" + searchParams$1, (function (positionId) {
              return "/positions/" + positionId + "/uci_items?" + searchParams$1;
            })));
  var setItems = match$5[1];
  var itemsState = match$5[0];
  var position = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, Belt_Option.isNone(positionId$1), undefined, undefined, undefined, "/uci_item/" + Belt_Option.getWithDefault(positionId$1, "") + "/position")[0]);
  var codes = Codes$Ucidata.useCodes(undefined);
  var uci = Client$Ucidata.data(undefined, match$2[0]);
  var uciGroups = Client$Ucidata.data(undefined, match$3[0]);
  var match$6 = React.useState(function () {
        return false;
      });
  var setShowItemDetailsModal = match$6[1];
  var match$7 = React.useState(function () {
        return false;
      });
  var setShowDocumentsModal = match$7[1];
  var showDocumentsModal = match$7[0];
  var match$8 = React.useState(function () {
        return false;
      });
  var setShowPositionModal = match$8[1];
  var showPositionModal = match$8[0];
  var match$9 = React.useState(function () {
        return false;
      });
  var setShowHistoryModal = match$9[1];
  var showHistoryModal = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setSelectedItem = match$10[1];
  var selectedItem = match$10[0];
  React.useEffect((function () {
          var unmounted = {
            contents: false
          };
          var reloadItem = function (item) {
            return Curry._1(setItems, (function (items) {
                          return Belt_Option.map(items, (function (items) {
                                        return Belt_Array.map(items, (function (elt) {
                                                      if (elt.id === item.id) {
                                                        return item;
                                                      } else {
                                                        return elt;
                                                      }
                                                    }));
                                      }));
                        }));
          };
          var listener = function (itemId, _docId, _fileState) {
            UciItem$Ucidata.$$fetch(client, itemId).then(function (item) {
                    return Promise.resolve(!unmounted.contents ? reloadItem(item) : undefined);
                  }).catch(function (param) {
                  return Promise.resolve(undefined);
                });
            
          };
          Uploader$Ucidata.onFileUpload(listener);
          return (function (param) {
                    unmounted.contents = true;
                    return Uploader$Ucidata.removeFileUpload(listener);
                  });
        }), []);
  var columns = React.useMemo((function () {
          return Belt_Option.map(codes, (function (codes) {
                        return CompartimentTransactions_Columns$Ucidata.make(codes, uci, user, {
                                    onDocumentsClick: (function (item) {
                                        Curry._1(setShowDocumentsModal, (function (param) {
                                                return true;
                                              }));
                                        return Curry._1(setSelectedItem, (function (param) {
                                                      return item;
                                                    }));
                                      }),
                                    onEditItemClick: (function (item) {
                                        Curry._1(setShowItemDetailsModal, (function (param) {
                                                return true;
                                              }));
                                        return Curry._1(setSelectedItem, (function (param) {
                                                      return item;
                                                    }));
                                      }),
                                    onShowPosition: (function (item) {
                                        Curry._1(setShowPositionModal, (function (param) {
                                                return true;
                                              }));
                                        return Curry._1(setSelectedItem, (function (param) {
                                                      return item;
                                                    }));
                                      }),
                                    onShowHistory: (function (item) {
                                        Curry._1(setShowHistoryModal, (function (param) {
                                                return true;
                                              }));
                                        return Curry._1(setSelectedItem, (function (param) {
                                                      return item;
                                                    }));
                                      })
                                  });
                      }));
        }), [
        codes,
        uci,
        user
      ]);
  var filteredItems = React.useMemo((function () {
          return Belt_Option.map(Client$Ucidata.data(undefined, itemsState), (function (items) {
                        return Belt_Array.keep(items, (function (item) {
                                      if (Belt_Option.mapWithDefault(filters.status, true, (function (status) {
                                                return Belt_Option.getWithDefault(item.status_id, 0) === status.id;
                                              })) && (
                                          filters.name.length !== 0 ? item.name.toLocaleLowerCase().includes(filters.name) : true
                                        )) {
                                        return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(filters.dateRange), true, (function (dateRange) {
                                                      var itemDate = new Date(item.created_at);
                                                      if (Caml_obj.caml_greaterequal(itemDate, dateRange[0])) {
                                                        return Caml_obj.caml_lessthan(itemDate, dateRange[1]);
                                                      } else {
                                                        return false;
                                                      }
                                                    }));
                                      } else {
                                        return false;
                                      }
                                    }));
                      }));
        }), [
        itemsState,
        filters
      ]);
  var innerContent;
  var exit = 0;
  var columns$1;
  if (typeof itemsState === "number") {
    innerContent = Caml_option.some(React.createElement(Text$Ucidata.make, {
              children: "No items."
            }));
  } else {
    switch (itemsState.TAG | 0) {
      case /* Loading */0 :
          if (itemsState._0 !== undefined && columns !== undefined) {
            columns$1 = columns;
            exit = 1;
          } else {
            innerContent = undefined;
          }
          break;
      case /* Error */1 :
          innerContent = Caml_option.some(React.createElement(DisplayServerError$Ucidata.make, {
                    error: itemsState._0
                  }));
          break;
      case /* Data */2 :
          if (columns !== undefined) {
            columns$1 = columns;
            exit = 1;
          } else {
            innerContent = undefined;
          }
          break;
      
    }
  }
  if (exit === 1) {
    innerContent = Caml_option.some(React.createElement(make, {
              columns: columns$1,
              items: Belt_Option.getExn(filteredItems),
              codes: codes,
              user: user,
              uci: uci,
              positionId: positionId$1,
              itemType: itemType,
              setItemType: match$1[1],
              filters: filters,
              setFilters: match[1]
            }));
  }
  var tmp = {
    uciId: Belt_Option.getExn(Belt_Int.fromString(uciId)),
    onCancel: (function (param) {
        return Curry._1(setShowItemDetailsModal, (function (param) {
                      return false;
                    }));
      }),
    onSave: (function (param) {
        Curry._1(fetchItems, undefined);
        return Curry._1(setShowItemDetailsModal, (function (param) {
                      return false;
                    }));
      }),
    itemType: itemType
  };
  var tmp$1 = Belt_Option.map(selectedItem, UciItem$Ucidata.Detailed.toItem);
  if (tmp$1 !== undefined) {
    tmp.item = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    role: /* Manager */0,
    children: React.createElement(Button$Ucidata.make, {
          style: /* Primary */0,
          className: "text-base w-full md:w-auto",
          onClick: (function (param) {
              Curry._1(setSelectedItem, (function (param) {
                      
                    }));
              return Curry._1(setShowItemDetailsModal, (function (param) {
                            return true;
                          }));
            }),
          children: React.createElement(Icon$Ucidata.make, {
                text: "New Item",
                icon: ProRegularSvgIcons.faPlus
              })
        })
  };
  if (uci !== undefined) {
    tmp$2.uci = Caml_option.valFromOption(uci);
  }
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: null
            }, React.createElement(Modal$Ucidata.make, {
                  isOpen: match$6[0],
                  setOpen: setShowItemDetailsModal,
                  closeOnOutsideClick: true,
                  closeOnEsc: true,
                  size: /* Large */1,
                  children: React.createElement(CompartimentTransactions_ItemDetails$Ucidata.make, tmp)
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: selectedItem,
                  render: (function (item) {
                      var tmp = {
                        item: item,
                        onClose: (function (param) {
                            return Curry._1(setShowDocumentsModal, (function (param) {
                                          return false;
                                        }));
                          }),
                        onSave: (function (param) {
                            return Curry._1(fetchItems, undefined);
                          })
                      };
                      if (uci !== undefined) {
                        tmp.uci = Caml_option.valFromOption(uci);
                      }
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showDocumentsModal,
                                  setOpen: setShowDocumentsModal,
                                  closeOnEsc: true,
                                  size: /* Fullscreen */0,
                                  children: React.createElement(CompartimentTransactions_Documents$Ucidata.make, tmp)
                                });
                    })
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: selectedItem,
                  render: (function (item) {
                      var tmp = {
                        itemId: String(item.id),
                        onClose: (function (param) {
                            return Curry._1(setShowPositionModal, (function (param) {
                                          return false;
                                        }));
                          }),
                        onOperation: (function (param) {
                            Curry._1(fetchItems, undefined);
                            return Curry._1(setShowPositionModal, (function (param) {
                                          return false;
                                        }));
                          })
                      };
                      if (uci !== undefined) {
                        tmp.uci = Caml_option.valFromOption(uci);
                      }
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showPositionModal,
                                  setOpen: setShowPositionModal,
                                  closeOnOutsideClick: true,
                                  closeOnEsc: true,
                                  size: /* Fullscreen */0,
                                  children: React.createElement(CompartimentTransactions_ItemPosition$Ucidata.make, tmp)
                                });
                    })
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: selectedItem,
                  render: (function (item) {
                      return React.createElement(Modal$Ucidata.make, {
                                  isOpen: showHistoryModal,
                                  setOpen: setShowHistoryModal,
                                  closeOnOutsideClick: true,
                                  closeOnEsc: true,
                                  size: /* Fullscreen */0,
                                  children: React.createElement(CompartimentTransactions_History$Ucidata.make, {
                                        item: item,
                                        uciId: uciId,
                                        onClose: (function (param) {
                                            return Curry._1(setShowHistoryModal, (function (param) {
                                                          return false;
                                                        }));
                                          })
                                      })
                                });
                    })
                }), React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: null
                }, React.createElement(Font_H1$Ucidata.make, {
                      style: /* Title */1,
                      children: null
                    }, React.createElement(Icon$Ucidata.make, {
                          icon: ProRegularSvgIcons.faExchange,
                          className: "mr-4"
                        }), React.createElement(Text_Span$Ucidata.make, {
                          className: "mr-4",
                          children: "Transactions"
                        }), React.createElement(Font_Div$Ucidata.make, {
                          style: /* Subtitle */2,
                          className: "flex-auto space-x-4",
                          children: React.createElement(Unless$Ucidata.make, {
                                condition: Belt_Option.isSome(positionId$1),
                                renderElse: (function (param) {
                                    return React.createElement(OptionMap$Ucidata.make, {
                                                option: position,
                                                render: (function (position) {
                                                    return React.createElement(Text$Ucidata.make, {
                                                                children: position.name
                                                              });
                                                  })
                                              });
                                  }),
                                children: React.createElement(OptionMap$Ucidata.make, {
                                      option: uci,
                                      render: (function (uci) {
                                          var textElt = React.createElement(Text$Ucidata.make, {
                                                children: uci.comp_name
                                              });
                                          var linkElt = React.createElement(Link$Ucidata.make, {
                                                href: "/config/compartiment/" + String(Belt_Option.getWithDefault(uci.id, 0)),
                                                children: textElt
                                              });
                                          return React.createElement(React.Fragment, undefined, React.createElement(IfUserRole$Ucidata.make, {
                                                          roles: [
                                                            /* AccountAdmin */1,
                                                            /* BackOffice */3
                                                          ],
                                                          renderElse: (function (param) {
                                                              return textElt;
                                                            }),
                                                          children: React.createElement(IfUciRole$Ucidata.make, {
                                                                uci: uci,
                                                                role: /* BackOffice */2,
                                                                renderElse: (function (param) {
                                                                    return textElt;
                                                                  }),
                                                                children: linkElt
                                                              })
                                                        }));
                                        })
                                    })
                              })
                        }), React.createElement(OptionMap$Ucidata.make, {
                          option: uciGroups,
                          render: (function (uciGroups) {
                              return React.createElement(PopupMenu$Ucidata.make, {
                                          render: (function (param) {
                                              return React.createElement(Section$Ucidata.make, {
                                                          className: "text-base space-y-4 max-h-3/4 overflow-y-scroll",
                                                          children: React.createElement(Assets_UciGroupsList$Ucidata.make, {
                                                                uciGroups: uciGroups
                                                              })
                                                        });
                                            }),
                                          children: React.createElement(Button$Ucidata.make, {
                                                style: /* Unstyled */4,
                                                children: React.createElement(Icon$Ucidata.make, {
                                                      icon: ProRegularSvgIcons.faListAlt
                                                    })
                                              })
                                        });
                            })
                        })), React.createElement("div", {
                      className: "space-y-2 italic text-sm font-normal"
                    }, React.createElement(OptionMap$Ucidata.make, {
                          option: positionId$1,
                          render: (function (positionId) {
                              return React.createElement("div", undefined, React.createElement(Link$Ucidata.make, {
                                              href: "/assets/compartiment/" + uciId + "/transactions",
                                              children: React.createElement(Icon$Ucidata.make, {
                                                    containerClassName: "flex items-center",
                                                    text: "Compartiment Transactions",
                                                    icon: ProRegularSvgIcons.faArrowLeft,
                                                    fixedWidth: true
                                                  })
                                            }));
                            })
                        }), React.createElement("div", undefined, React.createElement(Link$Ucidata.make, {
                              href: "/assets/compartiment/" + uciId + "/positions",
                              children: React.createElement(Icon$Ucidata.make, {
                                    containerClassName: "flex items-center",
                                    text: "Compartiment Positions",
                                    icon: ProRegularSvgIcons.faLink,
                                    fixedWidth: true
                                  })
                            })))), React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Layout$Ucidata.make, {
                        style: /* FlexBetween */3,
                        children: null
                      }, React.createElement("div", {
                            className: "text-center md:text-left"
                          }, React.createElement(Icon$Ucidata.make, {
                                text: "History",
                                icon: ProSolidSvgIcons.faList,
                                className: "mr-2"
                              })), React.createElement("div", {
                            className: "flex items-center space-x-4"
                          }, React.createElement(IfUciRole$Ucidata.make, tmp$2), React.createElement(PopupMenu$Ucidata.make, {
                                render: (function (setShowMenu) {
                                    return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                    onClick: (function (param) {
                                                        var items = Belt_Option.getWithDefault(filteredItems, []);
                                                        return Belt_Option.forEach(columns, (function (columns) {
                                                                      var csv = Curry._3(CompartimentTransactions_Columns$Ucidata.Table.csvExport, undefined, columns, items);
                                                                      return Utils$Ucidata.downloadStringData(Belt_Option.mapWithDefault(uci, "", (function (param) {
                                                                                        return param.uci_name;
                                                                                      })) + "_transactions_" + new Date().toISOString() + ".csv", csv, "text/csv");
                                                                    }));
                                                      }),
                                                    children: React.createElement(Icon$Ucidata.make, {
                                                          text: "Export to CSV",
                                                          icon: ProRegularSvgIcons.faFileExport,
                                                          fixedWidth: true
                                                        }),
                                                    key: "csv-export"
                                                  }));
                                  }),
                                children: React.createElement(Button$Ucidata.make, {
                                      style: /* Unstyled */4,
                                      className: "px-2",
                                      children: React.createElement(Icon$Ucidata.make, {
                                            icon: ProSolidSvgIcons.faEllipsisV,
                                            className: "text-base"
                                          })
                                    })
                              }))),
                  children: React.createElement("div", {
                        className: "space-y-4"
                      }, React.createElement(Loader$Ucidata.make, {
                            className: "text-4xl",
                            children: innerContent
                          }))
                }));
}

var make$1 = React.memo(CompartimentTransactions);

var Columns;

var ItemDetails;

var Documents;

var ItemPosition;

var $$History;

export {
  Columns ,
  ItemDetails ,
  Documents ,
  ItemPosition ,
  $$History ,
  defaultFilters ,
  TableWrapper ,
  make$1 as make,
  
}
/* make Not a pure module */
