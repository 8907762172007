// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var context = React.createContext({
      validation: {},
      setValidation: (function (param, param$1) {
          
        })
    });

function invalid(context) {
  return Belt_Array.some(Js_dict.values(context), (function (element) {
                return element.errors.length !== 0;
              }));
}

function dirty(context) {
  return Belt_Array.some(Js_dict.values(context), (function (element) {
                return element.dirty;
              }));
}

export {
  context ,
  invalid ,
  dirty ,
  
}
/* context Not a pure module */
