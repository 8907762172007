// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as IfUserRole$Ucidata from "./IfUserRole.bs.js";
import * as UserContext$Ucidata from "../../UserContext.bs.js";

function getUciRoleCode(role) {
  return role + 1 | 0;
}

function check(bypassWhenUserRolesOpt, user, uci, roles) {
  var bypassWhenUserRoles = bypassWhenUserRolesOpt !== undefined ? bypassWhenUserRolesOpt : [/* AccountAdmin */1];
  if (Belt_Array.some(uci.uci_user_roles, (function (user_role) {
            return user_role.user_id === user.id ? Belt_Array.some(roles, (function (role) {
                            return user_role.code_uci_role_id === (role + 1 | 0);
                          })) : false;
          }))) {
    return true;
  } else {
    return IfUserRole$Ucidata.check(user, bypassWhenUserRoles);
  }
}

function IfUciRole(Props) {
  var uciId = Props.uciId;
  var uci = Props.uci;
  var role = Props.role;
  var rolesOpt = Props.roles;
  var bypassWhenUserRolesOpt = Props.bypassWhenUserRoles;
  var renderElse = Props.renderElse;
  var children = Props.children;
  var roles = rolesOpt !== undefined ? rolesOpt : [];
  var bypassWhenUserRoles = bypassWhenUserRolesOpt !== undefined ? bypassWhenUserRolesOpt : [/* AccountAdmin */1];
  var user = UserContext$Ucidata.useUser(undefined);
  var match = Client$Ucidata.useData(uci, undefined, undefined, Belt_Option.isSome(uci) || Belt_Option.isNone(uciId), undefined, undefined, undefined, "/uci/" + String(Belt_Option.getWithDefault(uciId, 0)));
  var uci$1 = Belt_Option.isNone(uci) ? Client$Ucidata.data(true, match[0]) : uci;
  var roles$1 = Belt_Array.concat(Belt_Option.mapWithDefault(role, [], (function (role) {
              return [role];
            })), roles);
  var authorized = user !== undefined && uci$1 !== undefined ? check(bypassWhenUserRoles, user, uci$1, roles$1) : false;
  if (authorized) {
    return children;
  } else {
    return Curry._1(Belt_Option.getWithDefault(renderElse, (function (param) {
                      return null;
                    })), undefined);
  }
}

var make = React.memo(IfUciRole);

export {
  getUciRoleCode ,
  check ,
  make ,
  
}
/* make Not a pure module */
