// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";

function IfLazy(Props) {
  var condition = Props.condition;
  var children = Props.children;
  if (condition) {
    return CamlinternalLazy.force(children);
  } else {
    return null;
  }
}

var make = IfLazy;

export {
  make ,
  
}
/* No side effect */
