// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text_Component$Ucidata from "./Text_Component.bs.js";

function Text_Span(Props) {
  var className = Props.className;
  var children = Props.children;
  var tmp = {
    element: "span",
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement(Text_Component$Ucidata.make, tmp);
}

var make = React.memo(Text_Span);

export {
  make ,
  
}
/* make Not a pure module */
