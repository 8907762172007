// Generated by ReScript, PLEASE EDIT WITH CARE


function toUciItem(position, codeEvent) {
  return {
          id: undefined,
          uci_item_group_id: position.id,
          uci_id: position.uci_id,
          name: position.name,
          cadaster_nb: position.cadaster_nb,
          internal_ref: position.internal_ref,
          units: position.units,
          price: position.price,
          code_type_id: position.code_type_id,
          code_event_id: codeEvent,
          code_subtype_id: position.code_subtype_id,
          code_asset_type_id: position.code_asset_type_id,
          code_denom_id: position.code_denom_id,
          code_ccy_id: position.code_ccy_id,
          code_uci_item_type_id: position.code_uci_item_type_id,
          status_id: undefined,
          holding_structure: undefined,
          status_hs: undefined,
          notes: undefined
        };
}

function toPos(detailed) {
  return {
          id: detailed.id,
          uci_id: detailed.uci_id,
          name: detailed.name,
          cadaster_nb: detailed.cadaster_nb,
          internal_ref: detailed.internal_ref,
          units: detailed.units,
          opn_comm_units: detailed.opn_comm_units,
          initial_comm: detailed.initial_comm,
          price: detailed.price,
          code_type_id: detailed.code_type_id,
          code_subtype_id: detailed.code_subtype_id,
          code_asset_type_id: detailed.code_asset_type_id,
          code_denom_id: detailed.code_denom_id,
          code_ccy_id: detailed.code_ccy_id,
          code_uci_item_type_id: detailed.code_uci_item_type_id,
          holding_structure: detailed.holding_structure,
          status_hs: detailed.status_hs
        };
}

var UciGroup = {};

var Uci = {};

var Detailed = {
  toPos: toPos,
  UciGroup: UciGroup,
  Uci: Uci
};

function $$fetch(client, id) {
  return client.url("/uci_item/" + id + "/position").get().json();
}

export {
  toUciItem ,
  Detailed ,
  $$fetch ,
  
}
/* No side effect */
