// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Text$Ucidata from "./text/Text.bs.js";
import * as Island$Ucidata from "./Island.bs.js";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";

function StateLoader(Props) {
  var className = Props.className;
  var iconClassName = Props.iconClassName;
  var staleOpt = Props.stale;
  var state = Props.state;
  var render = Props.render;
  var renderNoDataOpt = Props.renderNoData;
  var stale = staleOpt !== undefined ? staleOpt : false;
  var renderNoData = renderNoDataOpt !== undefined ? renderNoDataOpt : (function (param) {
        return React.createElement(Island$Ucidata.make, {
                    children: React.createElement(Text$Ucidata.make, {
                          children: "No data to display."
                        })
                  });
      });
  if (typeof state === "number") {
    return Curry._1(renderNoData, undefined);
  }
  switch (state.TAG | 0) {
    case /* Loading */0 :
        var data = state._0;
        if (data !== undefined && stale) {
          return Curry._1(render, Caml_option.valFromOption(data));
        }
        var tmp = {
          icon: ProDuotoneSvgIcons.faSpinner,
          pulse: true
        };
        if (iconClassName !== undefined) {
          tmp.className = iconClassName;
        }
        return React.createElement("div", {
                    className: Cn.$plus("flex items-center justify-center", Cn.take(className))
                  }, React.createElement(Icon$Ucidata.make, tmp));
    case /* Error */1 :
        var data$1 = state._1;
        if (data$1 !== undefined && stale) {
          return Curry._1(render, Caml_option.valFromOption(data$1));
        }
        console.error(state._0);
        return React.createElement(Island$Ucidata.make, {
                    style: /* Error */2,
                    title: "Error(s)",
                    icon: ProDuotoneSvgIcons.faBomb,
                    children: React.createElement(Text$Ucidata.make, {
                          children: "An error occured while trying to fetch data. Please try again."
                        })
                  });
    case /* Data */2 :
        return Curry._1(render, state._0);
    
  }
}

var make = StateLoader;

export {
  make ,
  
}
/* react Not a pure module */
