// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Modal$Ucidata from "../../core/modals/Modal.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Render$Ucidata from "../../Render.bs.js";
import * as Wretch$Ucidata from "../../../libs/Wretch.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as OptionMap$Ucidata from "../../core/conditionals/OptionMap.bs.js";
import * as UciOvsItem$Ucidata from "../../../models/UciOvsItem.bs.js";
import * as Collapsible$Ucidata from "../../core/Collapsible.bs.js";
import * as Input_Select$Ucidata from "../../core/input/Input_Select.bs.js";
import * as Input_Calendar$Ucidata from "../../core/input/Input_Calendar.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Oversight_Documents$Ucidata from "./Oversight_Documents.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as CompartimentTransactions_History$Ucidata from "../assets/transactions/CompartimentTransactions_History.bs.js";

function Oversight_Item(Props) {
  var item = Props.item;
  var subtype = Props.subtype;
  var codeDocOvsStatus = Props.codeDocOvsStatus;
  var editable = Props.editable;
  var setItems = Props.setItems;
  var fetchItems = Props.fetchItems;
  var client = Client$Ucidata.useHttpClient(undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveError = match$1[2];
  var setSaveError = match$1[1];
  var itemStatus = Belt_Array.getBy(codeDocOvsStatus, (function (status) {
          return status.id === item.status_id;
        }));
  var title = React.useMemo((function () {
          return Belt_Option.mapWithDefault(itemStatus, "", (function (status) {
                        return status.name;
                      }));
        }), [
        codeDocOvsStatus,
        item
      ]);
  var setItem = function (cb) {
    return Curry._1(setItems, (function (items) {
                  return Belt_Option.map(items, (function (items) {
                                return Belt_Array.map(items, (function (it) {
                                              if (item.id === it.id) {
                                                return Curry._1(cb, it);
                                              } else {
                                                return it;
                                              }
                                            }));
                              }));
                }));
  };
  var match$2 = item.status_id;
  var colors;
  switch (match$2) {
    case 1 :
        colors = "bg-blue-400 text-white";
        break;
    case 2 :
        colors = "bg-green-400 text-black";
        break;
    case 3 :
        colors = "bg-yellow-400 text-black";
        break;
    case 4 :
    case 5 :
        colors = "bg-red-400 text-black";
        break;
    default:
      colors = undefined;
  }
  var uciItemId = Belt_Option.getWithDefault(item.note, "");
  var uciId = String(item.uci_id);
  var checkNote = uciItemId !== "";
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowHistoryModal = match$3[1];
  var showHistoryModal = match$3[0];
  var match$4 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_item/" + uciItemId);
  var uciItem = Client$Ucidata.data(undefined, match$4[0]);
  var tmp = {
    mode: /* Light */0
  };
  var tmp$1 = match$1[0];
  if (tmp$1 !== undefined) {
    tmp.error = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement("div", {
              className: "space-y-4 p-4"
            }, React.createElement("div", {
                  key: String(item.id),
                  className: "flex items-center space-x-8"
                }, React.createElement(OptionMap$Ucidata.make, {
                      option: colors,
                      render: (function (colors) {
                          return React.createElement("div", {
                                      className: colors + " capitalize text-xs py-0.5 px-2 inline-flex items-center justify-center rounded italic",
                                      title: title
                                    }, React.createElement(Text$Ucidata.make, {
                                          children: title
                                        }));
                        })
                    }), React.createElement("div", {
                      className: "flex items-baseline"
                    }, React.createElement(Font_Div$Ucidata.make, {
                          style: /* UppercaseLabel */6,
                          className: "text-xs",
                          children: React.createElement(Text$Ucidata.make, {
                                children: "Date:"
                              })
                        }), React.createElement(Input_Calendar$Ucidata.make, {
                          id: "next-control",
                          size: /* Tiny */2,
                          noBorders: true,
                          disabled: !editable,
                          onChange: (function (date) {
                              return setItem(function (it) {
                                          return {
                                                  code_oversight_id: it.code_oversight_id,
                                                  code_oversight_subtype_id: it.code_oversight_subtype_id,
                                                  id: it.id,
                                                  next_control: Belt_Option.map((date == null) ? undefined : Caml_option.some(date), (function (prim) {
                                                          return prim.toISOString();
                                                        })),
                                                  controlled_on: it.controlled_on,
                                                  controlled_by_id: it.controlled_by_id,
                                                  status_id: it.status_id,
                                                  uci_id: it.uci_id,
                                                  note: it.note
                                                };
                                        });
                            }),
                          value: Js_null_undefined.fromOption(Belt_Option.map(item.next_control, (function (prim) {
                                      return new Date(prim);
                                    })))
                        }), React.createElement(If$Ucidata.make, {
                          condition: editable,
                          children: React.createElement("div", {
                                className: "flex items-baseline space-x-2 ml-4"
                              }, React.createElement(Font_Div$Ucidata.make, {
                                    style: /* UppercaseLabel */6,
                                    className: "text-xs",
                                    children: React.createElement(Text$Ucidata.make, {
                                          children: "Status:"
                                        })
                                  }), React.createElement(Input_Select$Ucidata.make, {
                                    name: "item-status-" + String(item.id),
                                    options: codeDocOvsStatus,
                                    onChange: (function (status) {
                                        return setItem(function (it) {
                                                    return {
                                                            code_oversight_id: it.code_oversight_id,
                                                            code_oversight_subtype_id: it.code_oversight_subtype_id,
                                                            id: it.id,
                                                            next_control: it.next_control,
                                                            controlled_on: it.controlled_on,
                                                            controlled_by_id: it.controlled_by_id,
                                                            status_id: status.id,
                                                            uci_id: it.uci_id,
                                                            note: it.note
                                                          };
                                                  });
                                      }),
                                    value: itemStatus,
                                    isMulti: false,
                                    getOptionLabel: (function (status) {
                                        return status.name;
                                      }),
                                    getOptionValue: (function (status) {
                                        return status.id;
                                      }),
                                    size: /* Tiny */2
                                  }))
                        })), React.createElement(If$Ucidata.make, {
                      condition: editable,
                      children: React.createElement(Button$Ucidata.make, {
                            style: /* Secondary */2,
                            size: /* Slim */3,
                            disabled: match[0],
                            icon: ProSolidSvgIcons.faSave,
                            onClick: (function (param) {
                                Curry._1(setSaving, (function (param) {
                                        return true;
                                      }));
                                Curry._1(clearSaveError, undefined);
                                return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(UciOvsItem$Ucidata.save(client, item), (function (_item) {
                                                          return Belt_Option.forEach(fetchItems, (function (fn) {
                                                                        return Curry._1(fn, undefined);
                                                                      }));
                                                        })), setSaveError), (function (param) {
                                                  return Curry._1(setSaving, (function (param) {
                                                                return false;
                                                              }));
                                                })));
                              }),
                            children: React.createElement(Text$Ucidata.make, {
                                  children: "Save"
                                })
                          })
                    }), React.createElement("div", {
                      className: "flex items-baseline"
                    }, React.createElement(OptionMap$Ucidata.make, {
                          option: uciItem,
                          render: (function (item) {
                              return React.createElement(Modal$Ucidata.make, {
                                          isOpen: showHistoryModal,
                                          setOpen: setShowHistoryModal,
                                          closeOnOutsideClick: true,
                                          closeOnEsc: true,
                                          size: /* Fullscreen */0,
                                          children: React.createElement(CompartimentTransactions_History$Ucidata.make, {
                                                item: item,
                                                uciId: uciId,
                                                onClose: (function (param) {
                                                    return Curry._1(setShowHistoryModal, (function (param) {
                                                                  return false;
                                                                }));
                                                  })
                                              })
                                        });
                            })
                        }), React.createElement(If$Ucidata.make, {
                          condition: checkNote,
                          children: React.createElement(Button$Ucidata.make, {
                                style: /* Secondary */2,
                                size: /* Slim */3,
                                onClick: (function (param) {
                                    return Curry._1(setShowHistoryModal, (function (param) {
                                                  return true;
                                                }));
                                  }),
                                children: React.createElement(Icon$Ucidata.make, {
                                      text: "Trx",
                                      icon: ProLightSvgIcons.faLink
                                    })
                              })
                        }))), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Collapsible$Ucidata.make, {
                  className: "text-xs",
                  iconCentered: false,
                  shallow: true,
                  children: React.createElement("div", {
                        className: "p-4 space-y-2"
                      }, Render$Ucidata.array(undefined, subtype.docs, (function (codeOversightDoc, param) {
                              var tmp = {
                                codeOversightDoc: codeOversightDoc,
                                item: item,
                                editable: editable,
                                key: String(codeOversightDoc.id)
                              };
                              if (fetchItems !== undefined) {
                                tmp.onSuccessfulUpload = Caml_option.valFromOption(fetchItems);
                              }
                              return React.createElement(Oversight_Documents$Ucidata.make, tmp);
                            }))),
                  render: (function (param) {
                      return React.createElement(Text$Ucidata.make, {
                                  children: "Documents"
                                });
                    })
                }));
}

var make = React.memo(Oversight_Item);

var Helpers;

var Documents;

var $$History;

export {
  Helpers ,
  Documents ,
  $$History ,
  make ,
  
}
/* make Not a pure module */
