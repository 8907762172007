// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Home$Ucidata from "../../pages/home/Home.bs.js";
import * as Admin$Ucidata from "../../pages/admin/Admin.bs.js";
import * as Utils$Ucidata from "../../../Utils.bs.js";
import * as Assets$Ucidata from "../../pages/assets/Assets.bs.js";
import * as Config$Ucidata from "../../pages/config/Config.bs.js";
import * as Oversight$Ucidata from "../../pages/oversight/Oversight.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Compartiment$Ucidata from "../../pages/config/compartiment/Compartiment.bs.js";
import * as OversightFlat$Ucidata from "../../pages/oversight/OversightFlat.bs.js";
import * as ChangePassword$Ucidata from "../../pages/ChangePassword.bs.js";
import * as CompartimentOversight$Ucidata from "../../pages/oversight/CompartimentOversight.bs.js";
import * as CompartimentPositions$Ucidata from "../../pages/assets/positions/CompartimentPositions.bs.js";
import * as CompartimentTransactions$Ucidata from "../../pages/assets/transactions/CompartimentTransactions.bs.js";
import * as CompartimentHoldingStructure$Ucidata from "../../pages/assets/holding_structure/CompartimentHoldingStructure.bs.js";

function default_label(param) {
  return "Home";
}

var default_component = React.createElement(Home$Ucidata.make, {});

var $$default = {
  label: default_label,
  component: default_component
};

function isDefault(route) {
  return route.component === default_component;
}

function match(path) {
  var url = "/" + Belt_List.toArray(path).join("/");
  var pathless;
  if (path) {
    switch (path.hd) {
      case "admin" :
          pathless = path.tl ? $$default : ({
                label: (function (param) {
                    return "Admin";
                  }),
                component: React.createElement(Admin$Ucidata.make, {})
              });
          break;
      case "assets" :
          var match$1 = path.tl;
          if (match$1) {
            if (match$1.hd === "compartiment") {
              var match$2 = match$1.tl;
              if (match$2) {
                var match$3 = match$2.tl;
                if (match$3) {
                  var uciItemId = match$3.hd;
                  var uciId = match$2.hd;
                  var exit = 0;
                  switch (uciItemId) {
                    case "positions" :
                        if (match$3.tl) {
                          exit = 1;
                        } else {
                          pathless = {
                            label: (function (param) {
                                return "Positions";
                              }),
                            component: React.createElement(CompartimentPositions$Ucidata.make, {
                                  uciId: uciId
                                })
                          };
                        }
                        break;
                    case "transactions" :
                        if (match$3.tl) {
                          exit = 1;
                        } else {
                          pathless = {
                            label: (function (param) {
                                return "Transactions History";
                              }),
                            component: React.createElement(CompartimentTransactions$Ucidata.make, {
                                  uciId: uciId
                                })
                          };
                        }
                        break;
                    default:
                      exit = 1;
                  }
                  if (exit === 1) {
                    var match$4 = match$3.tl;
                    pathless = match$4 && match$4.hd === "holding" && !match$4.tl ? ({
                          label: (function (param) {
                              return "Holding Structure";
                            }),
                          component: React.createElement(CompartimentHoldingStructure$Ucidata.make, {
                                uciId: uciId,
                                uciItemId: uciItemId
                              })
                        }) : $$default;
                  }
                  
                } else {
                  pathless = $$default;
                }
              } else {
                pathless = $$default;
              }
            } else {
              pathless = $$default;
            }
          } else {
            pathless = {
              label: (function (param) {
                  return "Assets";
                }),
              component: React.createElement(Assets$Ucidata.make, {})
            };
          }
          break;
      case "config" :
          var match$5 = path.tl;
          if (match$5) {
            if (match$5.hd === "compartiment") {
              var match$6 = match$5.tl;
              if (match$6) {
                if (match$6.tl) {
                  pathless = $$default;
                } else {
                  var uciId$1 = match$6.hd;
                  pathless = {
                    label: (function (param) {
                        return uciId$1;
                      }),
                    component: React.createElement(Compartiment$Ucidata.make, {
                          uciId: uciId$1
                        })
                  };
                }
              } else {
                pathless = {
                  label: (function (param) {
                      return "Compartiment";
                    }),
                  component: React.createElement(Compartiment$Ucidata.make, {})
                };
              }
            } else {
              pathless = $$default;
            }
          } else {
            pathless = {
              label: (function (param) {
                  return "Configuration";
                }),
              component: React.createElement(Config$Ucidata.make, {})
            };
          }
          break;
      case "oversight" :
          var match$7 = path.tl;
          if (match$7) {
            if (match$7.hd === "compartiment") {
              var match$8 = match$7.tl;
              if (match$8 && !(match$8.tl || !Utils$Ucidata.ovs)) {
                var uciId$2 = match$8.hd;
                pathless = {
                  label: (function (param) {
                      return "Compartiment " + uciId$2;
                    }),
                  component: React.createElement(CompartimentOversight$Ucidata.make, {
                        uciId: uciId$2
                      })
                };
              } else {
                pathless = $$default;
              }
            } else {
              pathless = $$default;
            }
          } else {
            pathless = Utils$Ucidata.ovs ? ({
                  label: (function (param) {
                      return "Oversight";
                    }),
                  component: React.createElement(Oversight$Ucidata.make, {})
                }) : $$default;
          }
          break;
      case "oversight_flat" :
          pathless = path.tl || !Utils$Ucidata.ovs ? $$default : ({
                label: (function (param) {
                    return "Oversight Control List";
                  }),
                component: React.createElement(OversightFlat$Ucidata.make, {})
              });
          break;
      case "password" :
          pathless = path.tl ? $$default : ({
                label: (function (param) {
                    return "Password";
                  }),
                component: React.createElement(ChangePassword$Ucidata.make, {})
              });
          break;
      default:
        pathless = $$default;
    }
  } else {
    pathless = $$default;
  }
  return {
          path: url,
          label: pathless.label,
          component: pathless.component
        };
}

function Router(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  return match(url.path).component;
}

var make = React.memo(Router);

export {
  $$default ,
  $$default as default,
  isDefault ,
  match ,
  make ,
  
}
/* default Not a pure module */
