// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Button$Ucidata from "../../core/Button.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Wretch$Ucidata from "../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Input_Text$Ucidata from "../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../core/validation/Validation.bs.js";
import * as Validation_Rules$Ucidata from "../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../core/validation/Validation_Context.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Admin_ChangePassword(Props) {
  var userId = Props.userId;
  var onCancel = Props.onCancel;
  var onSave = Props.onSave;
  var client = Client$Ucidata.useClient(undefined);
  var match = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveError = match[2];
  var setSaveError = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSaving = match$1[1];
  var saving = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setPwd = match$2[1];
  var pwd = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setPwdConfirmation = match$3[1];
  var pwdConfirmation = match$3[0];
  var updatePassword = function (param) {
    Curry._1(clearSaveError, undefined);
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(client.url("/user/" + userId + "/password").post({
                              password: pwd,
                              password_confirmation: pwdConfirmation
                            }).res(function (param) {
                            
                          }), setSaveError), (function (param) {
                      return Curry._1(setSaving, (function (param) {
                                    return false;
                                  }));
                    })));
  };
  var tmp = {};
  var tmp$1 = match[0];
  if (tmp$1 !== undefined) {
    tmp.error = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                  title: React.createElement(Text$Ucidata.make, {
                        children: "Change User Password"
                      }),
                  unstyled: true,
                  className: "space-y-8",
                  icon: ProSolidSvgIcons.faKey,
                  children: null
                }, React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Validation$Ucidata.make, {
                      render: (function (validation) {
                          var partial_arg = [Validation_Rules$Ucidata.$$String.required("Please enter the new password.", undefined)];
                          var partial_arg$1 = [
                            Validation_Rules$Ucidata.$$String.required("Please confirm the new password.", undefined),
                            Validation_Rules$Ucidata.$$String.equal(pwd, "The confirmation does not match the password.")
                          ];
                          return React.createElement("div", {
                                      className: "space-y-4"
                                    }, React.createElement(Layout$Ucidata.make, {
                                          style: /* Grid2Cols */0,
                                          children: null
                                        }, React.createElement(Input_Text$Ucidata.make, {
                                              id: "pwd",
                                              label: React.createElement(Text$Ucidata.make, {
                                                    children: "New Password"
                                                  }),
                                              value: pwd,
                                              validate: (function (param) {
                                                  return Validation_Rules$Ucidata.compose(partial_arg, param);
                                                }),
                                              onChange: (function (value) {
                                                  return Curry._1(setPwd, (function (param) {
                                                                return value;
                                                              }));
                                                }),
                                              type_: "password",
                                              placeholder: "*********"
                                            }), React.createElement(Input_Text$Ucidata.make, {
                                              id: "pwd-confirmation",
                                              label: React.createElement(Text$Ucidata.make, {
                                                    children: "Confirm Password"
                                                  }),
                                              value: pwdConfirmation,
                                              validate: (function (param) {
                                                  return Validation_Rules$Ucidata.compose(partial_arg$1, param);
                                                }),
                                              validateDependency: pwd,
                                              onChange: (function (value) {
                                                  return Curry._1(setPwdConfirmation, (function (param) {
                                                                return value;
                                                              }));
                                                }),
                                              type_: "password",
                                              placeholder: "*********"
                                            })), React.createElement("div", {
                                          className: "space-x-4"
                                        }, React.createElement(Button$Ucidata.make, {
                                              style: /* Primary */0,
                                              disabled: Validation_Context$Ucidata.invalid(validation),
                                              spinner: saving,
                                              onClick: (function (param) {
                                                  updatePassword(undefined);
                                                  return Curry._1(onSave, undefined);
                                                }),
                                              children: React.createElement(Text$Ucidata.make, {
                                                    children: "Save"
                                                  })
                                            }), React.createElement(Button$Ucidata.make, {
                                              onClick: (function (param) {
                                                  return Curry._1(onCancel, undefined);
                                                }),
                                              children: React.createElement(Text$Ucidata.make, {
                                                    children: "Cancel"
                                                  })
                                            })));
                        })
                    })));
}

var make = Admin_ChangePassword;

export {
  make ,
  
}
/* react Not a pure module */
