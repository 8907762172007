// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../core/font/Font.bs.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Text$Ucidata from "../../core/text/Text.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Font_H1$Ucidata from "../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as IfUciRole$Ucidata from "../../core/conditionals/IfUciRole.bs.js";
import * as IfUserRole$Ucidata from "../../core/conditionals/IfUserRole.bs.js";
import * as StateLoader$Ucidata from "../../core/StateLoader.bs.js";
import * as Oversight_Categories$Ucidata from "./Oversight_Categories.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function CompartimentOversight(Props) {
  var uciId = Props.uciId;
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci/" + uciId);
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "/uci_ovs/" + uciId);
  var match$2 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_oversight");
  var match$3 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_oversight_subtype");
  var match$4 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_oversight_doc");
  var match$5 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_doc_ovs_status");
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: React.createElement(StateLoader$Ucidata.make, {
                    className: "text-6xl",
                    state: Client$Ucidata.compose6(match[0], match$1[0], match$2[0], match$3[0], match$4[0], match$5[0]),
                    render: (function (param) {
                        var uci = param[0];
                        var textElt = React.createElement(Text$Ucidata.make, {
                              children: uci.comp_name
                            });
                        var linkElt = React.createElement(Link$Ucidata.make, {
                              href: "/config/compartiment/" + String(Belt_Option.getWithDefault(uci.id, 0)),
                              children: textElt
                            });
                        return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                        unstyled: true,
                                        children: React.createElement("div", {
                                              className: "flex items-baseline space-x-4"
                                            }, React.createElement(Font_H1$Ucidata.make, {
                                                  style: /* Title */1,
                                                  children: null
                                                }, React.createElement(Icon$Ucidata.make, {
                                                      icon: ProRegularSvgIcons.faMonitorHeartRate,
                                                      className: "mr-4 self-center"
                                                    }), React.createElement(React.Fragment, undefined, React.createElement(IfUserRole$Ucidata.make, {
                                                          roles: [
                                                            /* AccountAdmin */1,
                                                            /* BackOffice */3
                                                          ],
                                                          renderElse: (function (param) {
                                                              return textElt;
                                                            }),
                                                          children: React.createElement(IfUciRole$Ucidata.make, {
                                                                uci: uci,
                                                                role: /* BackOffice */2,
                                                                renderElse: (function (param) {
                                                                    return textElt;
                                                                  }),
                                                                children: linkElt
                                                              })
                                                        }))), React.createElement(Font$Ucidata.make, {
                                                  style: /* Subtitle */2,
                                                  children: React.createElement(Text$Ucidata.make, {
                                                        children: uci.uci_name
                                                      })
                                                }))
                                      }), React.createElement(Section$Ucidata.make, {
                                        title: React.createElement(Text$Ucidata.make, {
                                              children: "Reviews"
                                            }),
                                        icon: ProRegularSvgIcons.faGlasses,
                                        children: React.createElement(Oversight_Categories$Ucidata.make, {
                                              uciOvs: param[1],
                                              codesOversight: param[2],
                                              codesOversightSubtype: param[3],
                                              codeOversightDoc: param[4],
                                              codeDocOvsStatus: param[5]
                                            })
                                      }));
                      })
                  })
            });
}

var Categories;

var make = CompartimentOversight;

export {
  Categories ,
  make ,
  
}
/* react Not a pure module */
