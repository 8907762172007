// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Text$Ucidata from "./text/Text.bs.js";
import * as IfLazy$Ucidata from "./conditionals/IfLazy.bs.js";
import * as ArrayMap$Ucidata from "./conditionals/ArrayMap.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Text_Span$Ucidata from "./text/Text_Span.bs.js";
import * as Pagination$Ucidata from "./Pagination.bs.js";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Make(T) {
  var make = function (fixedOpt, sortableOpt, renderOpt, thClassNameOpt, tdClassNameOpt, ignoreOpt, param) {
    var fixed = fixedOpt !== undefined ? fixedOpt : false;
    var sortable = sortableOpt !== undefined ? sortableOpt : false;
    var render = renderOpt !== undefined ? renderOpt : (function (txt, param) {
          return React.createElement(Text$Ucidata.make, {
                      children: txt
                    });
        });
    var thClassName = thClassNameOpt !== undefined ? Caml_option.valFromOption(thClassNameOpt) : undefined;
    var tdClassName = tdClassNameOpt !== undefined ? Caml_option.valFromOption(tdClassNameOpt) : undefined;
    var ignore = ignoreOpt !== undefined ? ignoreOpt : (function (param) {
          return false;
        });
    return {
            fixed: fixed,
            sortable: sortable,
            render: render,
            thClassName: thClassName,
            tdClassName: tdClassName,
            ignore: ignore
          };
  };
  var empty = make(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var Options = {
    make: make,
    empty: empty
  };
  var make$1 = function (columns) {
    return columns;
  };
  var Column = {
    Options: Options,
    make: make$1
  };
  var reverseOrder = function (order) {
    if (order) {
      return /* Ascending */0;
    } else {
      return /* Descending */1;
    }
  };
  var Sort = {
    reverseOrder: reverseOrder,
    defaultOrder: /* Ascending */0
  };
  var Table$Make = function (Props) {
    var columns = Props.columns;
    var data = Props.data;
    var dataKey = Props.dataKey;
    var className = Props.className;
    var overflowContainerClassName = Props.overflowContainerClassName;
    var tableExtraClass = Props.tableExtraClass;
    var fixedTableExtraClass = Props.fixedTableExtraClass;
    var containerClassName = Props.containerClassName;
    var thClassName = Props.thClassName;
    var tdClassName = Props.tdClassName;
    var headTrClassName = Props.headTrClassName;
    var bodyTrClassName = Props.bodyTrClassName;
    var paginationLimit = Props.paginationLimit;
    var match = React.useState(function () {
          
        });
    var setSortBy = match[1];
    var sortBy = match[0];
    var match$1 = React.useState(function () {
          return 0;
        });
    var setOffset = match$1[1];
    var offset = match$1[0];
    var tableHeadRef = React.useRef(null);
    var data$1 = React.useMemo((function () {
            return Belt_Option.mapWithDefault(sortBy, data, (function (param) {
                          var order = param[1];
                          var column = param[0];
                          return data.slice(0).sort(function (a, b) {
                                      if (order) {
                                        return $$String.compare(Curry._1(column.extract, a), Curry._1(column.extract, b));
                                      } else {
                                        return $$String.compare(Curry._1(column.extract, b), Curry._1(column.extract, a));
                                      }
                                    });
                        }));
          }), [
          data,
          sortBy
        ]);
    var match$2 = React.useMemo((function () {
            return Belt_Array.reduce(columns, [
                        [],
                        []
                      ], (function (acc, column) {
                          if (!Curry._1(column.options.ignore, data$1)) {
                            if (column.options.fixed) {
                              acc[0].push(column);
                            } else {
                              acc[1].push(column);
                            }
                          }
                          return acc;
                        }));
          }), [columns]);
    var normalColumns = match$2[1];
    var fixedColumns = match$2[0];
    var finalData = React.useMemo((function () {
            if (paginationLimit !== undefined) {
              return Belt_Array.slice(data$1, offset, paginationLimit);
            } else {
              return data$1;
            }
          }), [
          offset,
          data$1,
          paginationLimit
        ]);
    var renderTableHeader = function (columns) {
      var tmp = {};
      if (headTrClassName !== undefined) {
        tmp.className = Caml_option.valFromOption(headTrClassName);
      }
      return React.createElement("thead", {
                  ref: tableHeadRef
                }, React.createElement("tr", tmp, React.createElement(ArrayMap$Ucidata.make, {
                          array: columns,
                          render: (function (column) {
                              var thClassName$1 = Belt_Option.isSome(column.options.thClassName) ? column.options.thClassName : thClassName;
                              if (column.options.sortable) {
                                var isActive = Belt_Option.getWithDefault(Belt_Option.map(sortBy, (function (sortBy) {
                                            return sortBy[0] === column;
                                          })), false);
                                return React.createElement("th", {
                                            key: column.label,
                                            className: Cn.$plus(Cn.$plus("cursor-pointer justify-center space-x-2", Cn.take(thClassName$1)), Cn.on("text-blue-900 font-semibold", isActive)),
                                            onClick: (function (param) {
                                                return Curry._1(setSortBy, (function (previousState) {
                                                              var updatedOrder = previousState !== undefined && !(previousState[0] === column && previousState[1]) ? /* Descending */1 : /* Ascending */0;
                                                              return [
                                                                      column,
                                                                      updatedOrder
                                                                    ];
                                                            }));
                                              })
                                          }, React.createElement(Text_Span$Ucidata.make, {
                                                children: column.label
                                              }), React.createElement(IfLazy$Ucidata.make, {
                                                condition: isActive,
                                                children: {
                                                  LAZY_DONE: false,
                                                  VAL: (function () {
                                                      var match = Belt_Option.getExn(sortBy)[1];
                                                      var icon = match ? ProRegularSvgIcons.faSortAlphaDown : ProRegularSvgIcons.faSortAlphaUp;
                                                      return React.createElement(Icon$Ucidata.make, {
                                                                  icon: icon
                                                                });
                                                    })
                                                }
                                              }));
                              }
                              var tmp = {
                                key: column.label
                              };
                              if (thClassName$1 !== undefined) {
                                tmp.className = Caml_option.valFromOption(thClassName$1);
                              }
                              return React.createElement("th", tmp, React.createElement(Text$Ucidata.make, {
                                              children: column.label
                                            }));
                            })
                        })));
    };
    var renderTableBody = function (columns) {
      return React.createElement("tbody", {
                  className: "bg-white"
                }, React.createElement(ArrayMap$Ucidata.make, {
                      array: finalData,
                      render: (function (datum) {
                          var tmp = {
                            key: Curry._1(dataKey, datum)
                          };
                          if (bodyTrClassName !== undefined) {
                            tmp.className = Caml_option.valFromOption(bodyTrClassName);
                          }
                          return React.createElement("tr", tmp, React.createElement(ArrayMap$Ucidata.make, {
                                          array: columns,
                                          render: (function (column) {
                                              var tdClassName$1 = Belt_Option.isSome(column.options.tdClassName) ? column.options.tdClassName : tdClassName;
                                              var tmp = {
                                                key: column.label,
                                                title: column.label
                                              };
                                              if (tdClassName$1 !== undefined) {
                                                tmp.className = Caml_option.valFromOption(tdClassName$1);
                                              }
                                              return React.createElement("td", tmp, Curry._2(column.options.render, Curry._1(column.extract, datum), datum));
                                            })
                                        }));
                        })
                    }));
    };
    var leftTable = React.createElement(If$Ucidata.make, {
          condition: fixedColumns.length !== 0,
          children: React.createElement("table", {
                className: Cn.$plus(Cn.take(className), Cn.take(fixedTableExtraClass)),
                style: {
                  borderSpacing: "0"
                }
              }, renderTableHeader(fixedColumns), renderTableBody(fixedColumns))
        });
    var rightTable = React.createElement(If$Ucidata.make, {
          condition: normalColumns.length !== 0,
          children: React.createElement("table", {
                className: Cn.$plus(Cn.take(className), Cn.take(tableExtraClass)),
                style: {
                  borderSpacing: "0"
                }
              }, renderTableHeader(normalColumns), renderTableBody(normalColumns))
        });
    var tables = React.createElement("div", {
          className: Cn.$plus("flex items-start", Cn.take(containerClassName))
        }, leftTable, React.createElement("div", {
              className: Cn.$plus("overflow-y-scroll", Cn.take(overflowContainerClassName))
            }, rightTable));
    if (paginationLimit !== undefined) {
      return React.createElement("div", {
                  className: "space-y-4"
                }, tables, React.createElement(Pagination$Ucidata.make, {
                      offset: offset,
                      limit: paginationLimit,
                      total: data$1.length,
                      onChange: (function (offset) {
                          Js_null_undefined.bind(tableHeadRef.current, (function (ref) {
                                  ref.scrollIntoView();
                                  
                                }));
                          return Curry._1(setOffset, (function (param) {
                                        return offset;
                                      }));
                        })
                    }));
    } else {
      return tables;
    }
  };
  var make$2 = React.memo(Table$Make);
  var csvExport = function (separatorOpt, columns, data) {
    var separator = separatorOpt !== undefined ? separatorOpt : ",";
    var csvRef = {
      contents: ""
    };
    var headers = Belt_Array.map(columns, (function (param) {
              return "\"" + param.label + "\"";
            })).join(separator);
    csvRef.contents = csvRef.contents + headers + "\n";
    Belt_Array.forEach(data, (function (datum) {
            var line = Belt_Array.map(columns, (function (column) {
                      return "\"" + Curry._1(column.extract, datum) + "\"";
                    })).join(separator);
            csvRef.contents = csvRef.contents + line + "\n";
            
          }));
    return csvRef.contents;
  };
  return {
          Column: Column,
          Sort: Sort,
          make: make$2,
          csvExport: csvExport
        };
}

function make(fixedOpt, sortableOpt, renderOpt, thClassNameOpt, tdClassNameOpt, ignoreOpt, param) {
  var fixed = fixedOpt !== undefined ? fixedOpt : false;
  var sortable = sortableOpt !== undefined ? sortableOpt : false;
  var render = renderOpt !== undefined ? renderOpt : (function (txt, param) {
        return React.createElement(Text$Ucidata.make, {
                    children: txt
                  });
      });
  var thClassName = thClassNameOpt !== undefined ? Caml_option.valFromOption(thClassNameOpt) : undefined;
  var tdClassName = tdClassNameOpt !== undefined ? Caml_option.valFromOption(tdClassNameOpt) : undefined;
  var ignore = ignoreOpt !== undefined ? ignoreOpt : (function (param) {
        return false;
      });
  return {
          fixed: fixed,
          sortable: sortable,
          render: render,
          thClassName: thClassName,
          tdClassName: tdClassName,
          ignore: ignore
        };
}

var empty = make(undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Options = {
  make: make,
  empty: empty
};

function make$1(columns) {
  return columns;
}

var Column = {
  Options: Options,
  make: make$1
};

function reverseOrder(order) {
  if (order) {
    return /* Ascending */0;
  } else {
    return /* Descending */1;
  }
}

var Sort = {
  reverseOrder: reverseOrder,
  defaultOrder: /* Ascending */0
};

function Table$Make(Props) {
  var columns = Props.columns;
  var data = Props.data;
  var dataKey = Props.dataKey;
  var className = Props.className;
  var overflowContainerClassName = Props.overflowContainerClassName;
  var tableExtraClass = Props.tableExtraClass;
  var fixedTableExtraClass = Props.fixedTableExtraClass;
  var containerClassName = Props.containerClassName;
  var thClassName = Props.thClassName;
  var tdClassName = Props.tdClassName;
  var headTrClassName = Props.headTrClassName;
  var bodyTrClassName = Props.bodyTrClassName;
  var paginationLimit = Props.paginationLimit;
  var match = React.useState(function () {
        
      });
  var setSortBy = match[1];
  var sortBy = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setOffset = match$1[1];
  var offset = match$1[0];
  var tableHeadRef = React.useRef(null);
  var data$1 = React.useMemo((function () {
          return Belt_Option.mapWithDefault(sortBy, data, (function (param) {
                        var order = param[1];
                        var column = param[0];
                        return data.slice(0).sort(function (a, b) {
                                    if (order) {
                                      return $$String.compare(Curry._1(column.extract, a), Curry._1(column.extract, b));
                                    } else {
                                      return $$String.compare(Curry._1(column.extract, b), Curry._1(column.extract, a));
                                    }
                                  });
                      }));
        }), [
        data,
        sortBy
      ]);
  var match$2 = React.useMemo((function () {
          return Belt_Array.reduce(columns, [
                      [],
                      []
                    ], (function (acc, column) {
                        if (!Curry._1(column.options.ignore, data$1)) {
                          if (column.options.fixed) {
                            acc[0].push(column);
                          } else {
                            acc[1].push(column);
                          }
                        }
                        return acc;
                      }));
        }), [columns]);
  var normalColumns = match$2[1];
  var fixedColumns = match$2[0];
  var finalData = React.useMemo((function () {
          if (paginationLimit !== undefined) {
            return Belt_Array.slice(data$1, offset, paginationLimit);
          } else {
            return data$1;
          }
        }), [
        offset,
        data$1,
        paginationLimit
      ]);
  var renderTableHeader = function (columns) {
    var tmp = {};
    if (headTrClassName !== undefined) {
      tmp.className = Caml_option.valFromOption(headTrClassName);
    }
    return React.createElement("thead", {
                ref: tableHeadRef
              }, React.createElement("tr", tmp, React.createElement(ArrayMap$Ucidata.make, {
                        array: columns,
                        render: (function (column) {
                            var thClassName$1 = Belt_Option.isSome(column.options.thClassName) ? column.options.thClassName : thClassName;
                            if (column.options.sortable) {
                              var isActive = Belt_Option.getWithDefault(Belt_Option.map(sortBy, (function (sortBy) {
                                          return sortBy[0] === column;
                                        })), false);
                              return React.createElement("th", {
                                          key: column.label,
                                          className: Cn.$plus(Cn.$plus("cursor-pointer justify-center space-x-2", Cn.take(thClassName$1)), Cn.on("text-blue-900 font-semibold", isActive)),
                                          onClick: (function (param) {
                                              return Curry._1(setSortBy, (function (previousState) {
                                                            var updatedOrder = previousState !== undefined && !(previousState[0] === column && previousState[1]) ? /* Descending */1 : /* Ascending */0;
                                                            return [
                                                                    column,
                                                                    updatedOrder
                                                                  ];
                                                          }));
                                            })
                                        }, React.createElement(Text_Span$Ucidata.make, {
                                              children: column.label
                                            }), React.createElement(IfLazy$Ucidata.make, {
                                              condition: isActive,
                                              children: {
                                                LAZY_DONE: false,
                                                VAL: (function () {
                                                    var match = Belt_Option.getExn(sortBy)[1];
                                                    var icon = match ? ProRegularSvgIcons.faSortAlphaDown : ProRegularSvgIcons.faSortAlphaUp;
                                                    return React.createElement(Icon$Ucidata.make, {
                                                                icon: icon
                                                              });
                                                  })
                                              }
                                            }));
                            }
                            var tmp = {
                              key: column.label
                            };
                            if (thClassName$1 !== undefined) {
                              tmp.className = Caml_option.valFromOption(thClassName$1);
                            }
                            return React.createElement("th", tmp, React.createElement(Text$Ucidata.make, {
                                            children: column.label
                                          }));
                          })
                      })));
  };
  var renderTableBody = function (columns) {
    return React.createElement("tbody", {
                className: "bg-white"
              }, React.createElement(ArrayMap$Ucidata.make, {
                    array: finalData,
                    render: (function (datum) {
                        var tmp = {
                          key: Curry._1(dataKey, datum)
                        };
                        if (bodyTrClassName !== undefined) {
                          tmp.className = Caml_option.valFromOption(bodyTrClassName);
                        }
                        return React.createElement("tr", tmp, React.createElement(ArrayMap$Ucidata.make, {
                                        array: columns,
                                        render: (function (column) {
                                            var tdClassName$1 = Belt_Option.isSome(column.options.tdClassName) ? column.options.tdClassName : tdClassName;
                                            var tmp = {
                                              key: column.label,
                                              title: column.label
                                            };
                                            if (tdClassName$1 !== undefined) {
                                              tmp.className = Caml_option.valFromOption(tdClassName$1);
                                            }
                                            return React.createElement("td", tmp, Curry._2(column.options.render, Curry._1(column.extract, datum), datum));
                                          })
                                      }));
                      })
                  }));
  };
  var leftTable = React.createElement(If$Ucidata.make, {
        condition: fixedColumns.length !== 0,
        children: React.createElement("table", {
              className: Cn.$plus(Cn.take(className), Cn.take(fixedTableExtraClass)),
              style: {
                borderSpacing: "0"
              }
            }, renderTableHeader(fixedColumns), renderTableBody(fixedColumns))
      });
  var rightTable = React.createElement(If$Ucidata.make, {
        condition: normalColumns.length !== 0,
        children: React.createElement("table", {
              className: Cn.$plus(Cn.take(className), Cn.take(tableExtraClass)),
              style: {
                borderSpacing: "0"
              }
            }, renderTableHeader(normalColumns), renderTableBody(normalColumns))
      });
  var tables = React.createElement("div", {
        className: Cn.$plus("flex items-start", Cn.take(containerClassName))
      }, leftTable, React.createElement("div", {
            className: Cn.$plus("overflow-y-scroll", Cn.take(overflowContainerClassName))
          }, rightTable));
  if (paginationLimit !== undefined) {
    return React.createElement("div", {
                className: "space-y-4"
              }, tables, React.createElement(Pagination$Ucidata.make, {
                    offset: offset,
                    limit: paginationLimit,
                    total: data$1.length,
                    onChange: (function (offset) {
                        Js_null_undefined.bind(tableHeadRef.current, (function (ref) {
                                ref.scrollIntoView();
                                
                              }));
                        return Curry._1(setOffset, (function (param) {
                                      return offset;
                                    }));
                      })
                  }));
  } else {
    return tables;
  }
}

var make$2 = React.memo(Table$Make);

function csvExport(separatorOpt, columns, data) {
  var separator = separatorOpt !== undefined ? separatorOpt : ",";
  var csvRef = {
    contents: ""
  };
  var headers = Belt_Array.map(columns, (function (param) {
            return "\"" + param.label + "\"";
          })).join(separator);
  csvRef.contents = csvRef.contents + headers + "\n";
  Belt_Array.forEach(data, (function (datum) {
          var line = Belt_Array.map(columns, (function (column) {
                    return "\"" + Curry._1(column.extract, datum) + "\"";
                  })).join(separator);
          csvRef.contents = csvRef.contents + line + "\n";
          
        }));
  return csvRef.contents;
}

var AbstractTable = {
  Column: Column,
  Sort: Sort,
  make: make$2,
  csvExport: csvExport
};

var $$default = make$2;

var makeColumns = make$1;

var emptyOptions = empty;

export {
  Make ,
  AbstractTable ,
  $$default ,
  $$default as default,
  makeColumns ,
  emptyOptions ,
  
}
/* empty Not a pure module */
