// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Input_Text$Ucidata from "../../../core/input/Input_Text.bs.js";
import * as Input_Search$Ucidata from "../../../core/input/Input_Search.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Validation_Rules$Ucidata from "../../../core/validation/Validation_Rules.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";

var emptyArray = [];

function Compartiment_Details(Props) {
  var uci = Props.uci;
  var dispatchUci = Props.dispatchUci;
  var documents = Props.documents;
  var codeTypes = Props.codeTypes;
  var isEditable = Props.isEditable;
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var httpScopedCache = Client$Ucidata.useScopedCachingClient(undefined);
  var match = React.useState(function () {
        return [];
      });
  var setUciGroups = match[1];
  var uciGroups = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setUciGroupsLoading = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var setUciGroupSearch = match$2[1];
  var uciGroupSearch = match$2[0];
  var match$3 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_ccy");
  var codesCCY = Belt_Option.getWithDefault(Client$Ucidata.data(undefined, match$3[0]), []);
  var uciTypeOptions = React.useMemo((function () {
          if (codeTypes !== undefined) {
            return Belt_Array.map(codeTypes, (function (codeType) {
                          return {
                                  value: codeType.id,
                                  label: codeType.name
                                };
                        }));
          } else {
            return [];
          }
        }), [codeTypes]);
  var selectedUciTypeOptions = React.useMemo((function () {
          var selection = Belt_Array.keep(uciTypeOptions, (function (opt) {
                  return Belt_Array.getBy(uci.types, (function (typ) {
                                return typ === opt.value;
                              })) !== undefined;
                }));
          if (selection.length === 0) {
            return emptyArray;
          } else {
            return selection;
          }
        }), [
        uciTypeOptions,
        uci.types
      ]);
  Hooks$Ucidata.useDebouncedEffect((function (param) {
          if (uciGroupSearch.length !== 0) {
            return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(httpScopedCache.url("/uci_group/pattern/" + uciGroupSearch).get().json(setUciGroups), (function (param) {
                                  return Curry._1(setUciGroups, (function (param) {
                                                return [];
                                              }));
                                })), (function (param) {
                              return Curry._1(setUciGroupsLoading, (function (param) {
                                            return false;
                                          }));
                            })));
          }
          
        }), 500, [uciGroupSearch]);
  var tmp = {
    title: React.createElement(Text$Ucidata.make, {
          children: "Details"
        }),
    className: "space-y-8",
    icon: ProLightSvgIcons.faPen,
    children: null
  };
  var tmp$1 = Utils$Ucidata.$$Option.fromBool(isEditable, "Pick an existing Uci that will contain this compartiment and enter the compartiment details.");
  if (tmp$1 !== undefined) {
    tmp.description = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Section$Ucidata.make, tmp, React.createElement("div", {
                  className: "space-y-4 border-b pb-8"
                }, React.createElement(If$Ucidata.make, {
                      condition: isEditable,
                      children: React.createElement("div", undefined, React.createElement(Input_Search$Ucidata.make, {
                                id: "uci-search",
                                value: uciGroupSearch,
                                label: "Find Uci",
                                onChange: (function (value) {
                                    Curry._1(setUciGroupsLoading, (function (param) {
                                            return true;
                                          }));
                                    return Curry._1(setUciGroupSearch, (function (param) {
                                                  return value;
                                                }));
                                  }),
                                loading: match$1[0],
                                menuElements: (function (closeMenu) {
                                    return Belt_Array.map(uciGroups, (function (uciGroup) {
                                                  return React.createElement(Input_Search$Ucidata.$$Element.make, {
                                                              onClick: (function (param) {
                                                                  Curry._1(setUciGroupSearch, (function (param) {
                                                                          return "";
                                                                        }));
                                                                  Curry._1(dispatchUci, {
                                                                        TAG: /* SetUciGroup */7,
                                                                        _0: uciGroup.name,
                                                                        _1: uciGroup.cssf_code
                                                                      });
                                                                  return Curry._1(closeMenu, undefined);
                                                                }),
                                                              children: React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
                                                                        children: uciGroup.name
                                                                      }), React.createElement(Font$Ucidata.make, {
                                                                        style: /* Italic */4,
                                                                        children: React.createElement(Text$Ucidata.make, {
                                                                              children: " (" + uciGroup.cssf_code + ")"
                                                                            })
                                                                      })),
                                                              key: String(Belt_Option.getExn(uciGroup.id))
                                                            });
                                                }));
                                  }),
                                placeholder: "Search by name or cssf code"
                              }))
                    }), React.createElement(Layout$Ucidata.make, {
                      style: /* Grid2Cols */0,
                      children: null
                    }, React.createElement(Input_Text$Ucidata.make, {
                          id: "uci-name",
                          label: React.createElement(Text$Ucidata.make, {
                                children: "name"
                              }),
                          value: uci.uci_name,
                          validate: Validation_Rules$Ucidata.$$String.required("Name is mandatory.", undefined),
                          placeholder: "Uci Name",
                          disabled: true
                        }), React.createElement(Input_Text$Ucidata.make, {
                          id: "cssf-label",
                          label: React.createElement(Text$Ucidata.make, {
                                children: "CSSF Code"
                              }),
                          value: uci.cssf_code,
                          validate: Validation_Rules$Ucidata.$$String.required("CSSF Code is mandatory.", undefined),
                          placeholder: "12345678",
                          disabled: true
                        }))), React.createElement("div", {
                  className: "space-y-4"
                }, React.createElement(Layout$Ucidata.make, {
                      style: /* Grid2Cols */0,
                      children: null
                    }, React.createElement(Input_Text$Ucidata.make, {
                          id: "compartiment-name",
                          label: React.createElement(Text$Ucidata.make, {
                                children: "Compartiment"
                              }),
                          value: uci.comp_name,
                          validate: Validation_Rules$Ucidata.$$String.required("Compartiment name is mandatory.", undefined),
                          onChange: (function (value) {
                              return Curry._1(dispatchUci, {
                                          TAG: /* SetName */4,
                                          _0: value
                                        });
                            }),
                          placeholder: "Compartiment Name",
                          disabled: !isEditable
                        }), React.createElement(Input_Text$Ucidata.make, {
                          id: "account-label",
                          label: React.createElement(Text$Ucidata.make, {
                                children: "Account Number"
                              }),
                          value: uci.account_nb,
                          validate: Validation_Rules$Ucidata.$$String.required("Account Number is mandatory.", undefined),
                          onChange: (function (value) {
                              return Curry._1(dispatchUci, {
                                          TAG: /* SetAccountNb */2,
                                          _0: value
                                        });
                            }),
                          placeholder: "12345678",
                          disabled: !isEditable
                        })), React.createElement(Input_Select$Ucidata.make, {
                      name: "capital-ccy",
                      options: codesCCY,
                      onChange: (function (value) {
                          return Curry._1(dispatchUci, {
                                      TAG: /* SetCCY */9,
                                      _0: Belt_Option.map(value, (function (param) {
                                              return param.id;
                                            }))
                                    });
                        }),
                      value: Belt_Array.getBy(codesCCY, (function (elt) {
                              return elt.id === Belt_Option.getWithDefault(uci.capital_ccy_id, 0);
                            })),
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Capital Currency"
                          }),
                      isClearable: false,
                      isDisabled: !isEditable,
                      getOptionLabel: (function (param) {
                          return param.name;
                        }),
                      getOptionValue: (function (param) {
                          return param.id;
                        }),
                      placeholder: "Currency"
                    }), React.createElement(Input_Select$Ucidata.make, {
                      name: "Type(s)",
                      options: uciTypeOptions,
                      onChange: (function (uciTypes) {
                          var types = Belt_Option.mapWithDefault(uciTypes, [], (function (uciTypes) {
                                  return Belt_Array.map(uciTypes, (function (param) {
                                                return param.value;
                                              }));
                                }));
                          return Curry._1(dispatchUci, {
                                      TAG: /* SetTypes */5,
                                      _0: types,
                                      _1: documents
                                    });
                        }),
                      value: selectedUciTypeOptions,
                      label: React.createElement(Text$Ucidata.make, {
                            children: "Type(s)"
                          }),
                      validate: Curry._2(Validation_Rules$Ucidata.$$Array.Optional.notEmpty, undefined, undefined),
                      isMulti: true,
                      isSearchable: true,
                      isDisabled: !isEditable
                    })));
}

var make = React.memo(Compartiment_Details);

export {
  emptyArray ,
  make ,
  
}
/* make Not a pure module */
