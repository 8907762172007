// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$Ucidata from "../Utils.bs.js";
import * as Client$Ucidata from "../Client.bs.js";

var uploader = {
  contents: Promise.resolve(undefined)
};

function uploadFile(itemId, docId, file) {
  return Client$Ucidata.base.url("/uci_doc_file/" + itemId + "/" + docId).formData({
                  file: file
                }, undefined).post(undefined).json();
}

var listeners = {
  fileUpload: []
};

function onFileUpload(listener) {
  listeners.fileUpload.push(listener);
  
}

function removeFileUpload(listener) {
  listeners.fileUpload = Belt_Array.keep(listeners.fileUpload, (function (l) {
          return l !== listener;
        }));
  
}

function chainFile(store, itemId, docId, fileState) {
  var uploads = store.uploads;
  var files = Belt_Option.flatMap(Js_dict.get(uploads.data, itemId), (function (dict) {
          return Js_dict.get(dict, docId);
        }));
  var fileState$1 = Belt_Option.flatMap(files, (function (files) {
          return Belt_Array.getBy(files, (function (f) {
                        return f.file === fileState.file;
                      }));
        }));
  var okToUpload = Belt_Option.mapWithDefault(fileState$1, false, (function (fileState) {
          return fileState.state === /* Pending */0;
        }));
  var nestedPromise;
  if (okToUpload) {
    var listener = function (e) {
      e.preventDefault();
      e.returnValue = "";
      
    };
    window.addEventListener("beforeunload", listener);
    var fileState$2 = Belt_Option.getExn(fileState$1);
    uploads.working = true;
    fileState$2.state = /* Uploading */1;
    Belt_Option.forEach(files, (function (files) {
            return Belt_Array.forEach(files, (function (file) {
                          var match = file.state;
                          if (typeof match === "number" || !(match.TAG === /* Error */0 && match._1)) {
                            return ;
                          } else {
                            file.state = {
                              TAG: /* Error */0,
                              _0: match._0,
                              _1: false
                            };
                            return ;
                          }
                        }));
          }));
    nestedPromise = uploadFile(itemId, docId, fileState$2.file).then(function (docFile) {
              return Promise.resolve((fileState$2.state = {
                            TAG: /* Success */1,
                            _0: docFile
                          }, undefined));
            }).catch(function (exn) {
            return Promise.resolve((fileState$2.state = {
                          TAG: /* Error */0,
                          _0: exn,
                          _1: true
                        }, undefined));
          }).then(function (param) {
          return Promise.resolve((Belt_Array.forEach(listeners.fileUpload, (function (listener) {
                              return Curry._3(listener, itemId, docId, fileState$2);
                            })), window.removeEventListener("beforeunload", listener), uploads.working = false, undefined));
        });
  } else {
    nestedPromise = Promise.resolve(undefined);
  }
  uploader.contents = uploader.contents.then(function (param) {
        return nestedPromise;
      });
  return nestedPromise;
}

function uploadFiles(store, itemId, docId, files) {
  var uploads = store.uploads;
  var docDict = Belt_Option.getWithDefault(Js_dict.get(uploads.data, itemId), {});
  store.uploads.data[itemId] = docDict;
  var filesArray = Belt_Option.getWithDefault(Js_dict.get(docDict, docId), []);
  var updatedFilesArray = Belt_Array.map(files, (function (f) {
            return {
                    state: /* Pending */0,
                    file: f
                  };
          })).concat(filesArray);
  docDict[docId] = updatedFilesArray;
  uploads.data = Utils$Ucidata.cloneDict(uploads.data);
  return Belt_Array.map(updatedFilesArray, (function (file) {
                return chainFile(store, itemId, docId, file);
              }));
}

function getFiles(store, itemId, docId) {
  return Belt_Array.reduce(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(store.uploads.data, itemId), (function (dict) {
                        return Js_dict.get(dict, docId);
                      })), []), {
              errored: [],
              hiddenErrored: [],
              successful: [],
              pending: [],
              uploading: []
            }, (function (acc, elt) {
                var match = elt.state;
                var tmp;
                tmp = typeof match === "number" ? (
                    match === /* Pending */0 ? acc.pending : acc.uploading
                  ) : (
                    match.TAG === /* Error */0 ? (
                        match._1 ? acc.errored : acc.hiddenErrored
                      ) : acc.successful
                  );
                tmp.push(elt);
                return acc;
              }));
}

export {
  uploader ,
  uploadFile ,
  listeners ,
  onFileUpload ,
  removeFileUpload ,
  chainFile ,
  uploadFiles ,
  getFiles ,
  
}
/* uploader Not a pure module */
