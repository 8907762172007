// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as If$Ucidata from "./conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icon$Ucidata from "./Icon.bs.js";
import * as Text$Ucidata from "./text/Text.bs.js";
import * as Button$Ucidata from "./Button.bs.js";
import * as OptionMap$Ucidata from "./conditionals/OptionMap.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Island(Props) {
  var className = Props.className;
  var styleOpt = Props.style;
  var title = Props.title;
  var icon = Props.icon;
  var iconSpin = Props.iconSpin;
  var closeable = Props.closeable;
  var children = Props.children;
  var style = styleOpt !== undefined ? styleOpt : /* Info */0;
  var outerStyle;
  switch (style) {
    case /* Info */0 :
        outerStyle = "border-blue-400 bg-blue-50 text-blue-400";
        break;
    case /* Warning */1 :
        outerStyle = "border-yellow-400 bg-yellow-50 text-yellow-400";
        break;
    case /* Error */2 :
        outerStyle = "border-red-400 bg-red-50 text-red-400";
        break;
    
  }
  var titleStyle;
  switch (style) {
    case /* Info */0 :
        titleStyle = "bg-blue-400 text-blue-50";
        break;
    case /* Warning */1 :
        titleStyle = "bg-yellow-400 text-yellow-50";
        break;
    case /* Error */2 :
        titleStyle = "bg-red-400 text-red-50";
        break;
    
  }
  var outerCss = Cn.$plus(Cn.$plus("border relative", outerStyle), Cn.take(className));
  var titleCss = Cn.$plus("flex items-center p-2 font-normal", titleStyle);
  var hasTitle = Belt_Option.isSome(title);
  return React.createElement("div", {
              className: outerCss
            }, React.createElement(OptionMap$Ucidata.make, {
                  option: closeable,
                  render: (function (closeable) {
                      return React.createElement(Button$Ucidata.make, {
                                  style: /* Unstyled */4,
                                  className: Cn.$plus("absolute right-2 top-1", hasTitle ? titleStyle : outerStyle),
                                  onClick: (function (param) {
                                      return Curry._1(closeable, undefined);
                                    }),
                                  children: React.createElement(Icon$Ucidata.make, {
                                        icon: ProSolidSvgIcons.faTimes
                                      })
                                });
                    })
                }), React.createElement(OptionMap$Ucidata.make, {
                  option: title,
                  render: (function (title) {
                      return React.createElement("div", {
                                  className: titleCss
                                }, React.createElement(OptionMap$Ucidata.make, {
                                      option: icon,
                                      render: (function (icon) {
                                          return React.createElement(Icon$Ucidata.make, {
                                                      icon: icon,
                                                      className: "mr-2"
                                                    });
                                        })
                                    }), React.createElement(Text$Ucidata.make, {
                                      children: title
                                    }));
                    })
                }), React.createElement("div", {
                  className: "p-2 text-sm"
                }, React.createElement(If$Ucidata.make, {
                      condition: !hasTitle,
                      children: React.createElement(OptionMap$Ucidata.make, {
                            option: icon,
                            render: (function (icon) {
                                var tmp = {
                                  icon: icon
                                };
                                if (iconSpin !== undefined) {
                                  tmp.spin = iconSpin;
                                }
                                return React.createElement("span", {
                                            className: Cn.append(titleStyle, "-ml-2 -mt-2 p-2 mr-2 float-left")
                                          }, React.createElement(Icon$Ucidata.make, tmp));
                              })
                          })
                    }), React.createElement("span", undefined, children)));
}

var make = React.memo(Island);

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
