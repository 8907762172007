// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDom from "react-dom";
import * as Main$Ucidata from "./components/Main.bs.js";

import './index.css'
;

var root = document.querySelector("#root");

if (!(root == null)) {
  ReactDom.render(React.createElement(React.StrictMode, {
            children: React.createElement(Main$Ucidata.make, {})
          }), root);
}

export {
  
}
/*  Not a pure module */
