// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as UciStruct$Ucidata from "../../../../models/UciStruct.bs.js";
import * as Input_Text$Ucidata from "../../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../../core/validation/Validation.bs.js";
import * as Input_Number$Ucidata from "../../../core/input/Input_Number.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Validation_Rules$Ucidata from "../../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../../core/validation/Validation_Context.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function CompartimentHoldingStructureItem(Props) {
  var uciItemId = Props.uciItemId;
  var item = Props.item;
  var onCancel = Props.onCancel;
  var onSave = Props.onSave;
  var client = Client$Ucidata.useClient(undefined);
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(item, UciStruct$Ucidata.empty(uciItemId));
      });
  var setItem = match[1];
  var item$1 = match[0];
  var editionMode = item$1.id !== undefined;
  var codes = Codes$Ucidata.useCodes(undefined);
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveError = match$1[2];
  var setSaveError = match$1[1];
  var saveError = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSaving = match$2[1];
  var saving = match$2[0];
  var saveItem = function (param) {
    Curry._1(clearSaveError, undefined);
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(UciStruct$Ucidata.save(client, item$1), (function (item) {
                              return Belt_Option.forEach(onSave, (function (onSave) {
                                            return Curry._1(onSave, item);
                                          }));
                            })), setSaveError), (function (param) {
                      return Curry._1(setSaving, (function (param) {
                                    return false;
                                  }));
                    })));
  };
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Loader$Ucidata.make, {
                  className: "text-6xl my-10",
                  children: Belt_Option.map(codes, (function (codes) {
                          return React.createElement(Validation$Ucidata.make, {
                                      render: (function (validation) {
                                          var tmp = {};
                                          if (saveError !== undefined) {
                                            tmp.error = Caml_option.valFromOption(saveError);
                                          }
                                          var tmp$1 = {
                                            name: "code-nature",
                                            options: codes.codesNature,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_id: item.uci_item_id,
                                                                                    name: item.name,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    cost: item.cost,
                                                                                    holdings: item.holdings,
                                                                                    code_nature_id: option.id,
                                                                                    code_country_id: item.code_country_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_level_id: item.code_level_id
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Nature"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (nature) {
                                                return nature.name;
                                              }),
                                            getOptionValue: (function (nature) {
                                                return nature.id;
                                              })
                                          };
                                          var tmp$2 = Belt_Array.getBy(codes.codesNature, (function (elt) {
                                                  return elt.id === item$1.code_nature_id;
                                                }));
                                          if (tmp$2 !== undefined) {
                                            tmp$1.value = Caml_option.valFromOption(tmp$2);
                                          }
                                          var tmp$3 = {
                                            name: "code-country",
                                            options: codes.codesCountry,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_id: item.uci_item_id,
                                                                                    name: item.name,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    cost: item.cost,
                                                                                    holdings: item.holdings,
                                                                                    code_nature_id: item.code_nature_id,
                                                                                    code_country_id: option.id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_level_id: item.code_level_id
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Country"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (country) {
                                                return country.name;
                                              }),
                                            getOptionValue: (function (country) {
                                                return country.id;
                                              })
                                          };
                                          var tmp$4 = Belt_Array.getBy(codes.codesCountry, (function (elt) {
                                                  return elt.id === item$1.code_country_id;
                                                }));
                                          if (tmp$4 !== undefined) {
                                            tmp$3.value = Caml_option.valFromOption(tmp$4);
                                          }
                                          var tmp$5 = {
                                            name: "code-denom",
                                            options: codes.codesDenom,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_id: item.uci_item_id,
                                                                                    name: item.name,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    cost: item.cost,
                                                                                    holdings: item.holdings,
                                                                                    code_nature_id: item.code_nature_id,
                                                                                    code_country_id: item.code_country_id,
                                                                                    code_denom_id: option.id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_level_id: item.code_level_id
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Denomination"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (denom) {
                                                return denom.name;
                                              }),
                                            getOptionValue: (function (denom) {
                                                return denom.id;
                                              })
                                          };
                                          var tmp$6 = Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                                  return elt.id === item$1.code_denom_id;
                                                }));
                                          if (tmp$6 !== undefined) {
                                            tmp$5.value = Caml_option.valFromOption(tmp$6);
                                          }
                                          var tmp$7 = {
                                            name: "code-currency",
                                            options: codes.codesCcy,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_id: item.uci_item_id,
                                                                                    name: item.name,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    cost: item.cost,
                                                                                    holdings: item.holdings,
                                                                                    code_nature_id: item.code_nature_id,
                                                                                    code_country_id: item.code_country_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: option.id,
                                                                                    code_level_id: item.code_level_id
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Currency"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (ccy) {
                                                return ccy.name;
                                              }),
                                            getOptionValue: (function (ccy) {
                                                return ccy.id;
                                              })
                                          };
                                          var tmp$8 = Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                                  return elt.id === item$1.code_ccy_id;
                                                }));
                                          if (tmp$8 !== undefined) {
                                            tmp$7.value = Caml_option.valFromOption(tmp$8);
                                          }
                                          var tmp$9 = {
                                            name: "code-level",
                                            options: codes.codesLevel,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setItem, (function (item) {
                                                                            return {
                                                                                    id: item.id,
                                                                                    uci_item_id: item.uci_item_id,
                                                                                    name: item.name,
                                                                                    internal_ref: item.internal_ref,
                                                                                    units: item.units,
                                                                                    cost: item.cost,
                                                                                    holdings: item.holdings,
                                                                                    code_nature_id: item.code_nature_id,
                                                                                    code_country_id: item.code_country_id,
                                                                                    code_denom_id: item.code_denom_id,
                                                                                    code_ccy_id: item.code_ccy_id,
                                                                                    code_level_id: option.id
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Level"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (level) {
                                                return level.name;
                                              }),
                                            getOptionValue: (function (level) {
                                                return level.id;
                                              })
                                          };
                                          var tmp$10 = Belt_Array.getBy(codes.codesLevel, (function (elt) {
                                                  return elt.id === item$1.code_level_id;
                                                }));
                                          if (tmp$10 !== undefined) {
                                            tmp$9.value = Caml_option.valFromOption(tmp$10);
                                          }
                                          return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                                          unstyled: true,
                                                          children: React.createElement(Layout$Ucidata.make, {
                                                                style: /* FlexBetween */3,
                                                                children: null
                                                              }, React.createElement(Font_Div$Ucidata.make, {
                                                                    style: /* Title */1,
                                                                    children: editionMode ? React.createElement(Icon$Ucidata.make, {
                                                                            text: item$1.name,
                                                                            icon: ProSolidSvgIcons.faEdit,
                                                                            className: "mr-2"
                                                                          }) : React.createElement(Icon$Ucidata.make, {
                                                                            text: "Create Holding Structure Item",
                                                                            icon: ProLightSvgIcons.faPlus,
                                                                            className: "mr-2"
                                                                          })
                                                                  }), React.createElement("div", {
                                                                    className: "flex items-center justify-center space-x-4"
                                                                  }, React.createElement(OptionMap$Ucidata.make, {
                                                                        option: onCancel,
                                                                        render: (function (onCancel) {
                                                                            return React.createElement(Button$Ucidata.make, {
                                                                                        onClick: (function (param) {
                                                                                            return Curry._1(onCancel, undefined);
                                                                                          }),
                                                                                        children: React.createElement(Text$Ucidata.make, {
                                                                                              children: "Cancel"
                                                                                            })
                                                                                      });
                                                                          })
                                                                      }), React.createElement(Button$Ucidata.make, {
                                                                        style: /* Primary */0,
                                                                        disabled: Validation_Context$Ucidata.invalid(validation),
                                                                        icon: ProRegularSvgIcons.faSave,
                                                                        spinner: saving,
                                                                        onClick: saveItem,
                                                                        children: editionMode ? React.createElement(Text$Ucidata.make, {
                                                                                children: "Update"
                                                                              }) : React.createElement(Text$Ucidata.make, {
                                                                                children: "Create"
                                                                              })
                                                                      })))
                                                        }), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Section$Ucidata.make, {
                                                          children: React.createElement("div", {
                                                                className: "space-y-4"
                                                              }, React.createElement(Layout$Ucidata.make, {
                                                                    style: /* Grid2Cols */0,
                                                                    children: null
                                                                  }, React.createElement(Input_Text$Ucidata.make, {
                                                                        id: "name",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Name"
                                                                            }),
                                                                        value: item$1.name,
                                                                        validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                        onChange: (function (name) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_id: item.uci_item_id,
                                                                                                  name: name,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: item.units,
                                                                                                  cost: item.cost,
                                                                                                  holdings: item.holdings,
                                                                                                  code_nature_id: item.code_nature_id,
                                                                                                  code_country_id: item.code_country_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_level_id: item.code_level_id
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Name"
                                                                      }), React.createElement(Input_Text$Ucidata.make, {
                                                                        id: "internal-ref",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Reference"
                                                                            }),
                                                                        value: item$1.internal_ref,
                                                                        validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                        onChange: (function (internal_ref) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_id: item.uci_item_id,
                                                                                                  name: item.name,
                                                                                                  internal_ref: internal_ref,
                                                                                                  units: item.units,
                                                                                                  cost: item.cost,
                                                                                                  holdings: item.holdings,
                                                                                                  code_nature_id: item.code_nature_id,
                                                                                                  code_country_id: item.code_country_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_level_id: item.code_level_id
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Internal Reference"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$1), React.createElement(Input_Select$Ucidata.make, tmp$3)), React.createElement(Layout$Ucidata.make, {
                                                                    style: /* Grid2Cols */0,
                                                                    children: null
                                                                  }, React.createElement(Input_Number$Ucidata.make, {
                                                                        id: "units",
                                                                        value: item$1.units,
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Units"
                                                                            }),
                                                                        onChange: (function (units) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_id: item.uci_item_id,
                                                                                                  name: item.name,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: units,
                                                                                                  cost: item.cost,
                                                                                                  holdings: item.holdings,
                                                                                                  code_nature_id: item.code_nature_id,
                                                                                                  code_country_id: item.code_country_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_level_id: item.code_level_id
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Units"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$5), React.createElement(Input_Number$Ucidata.make, {
                                                                        id: "total-cost",
                                                                        value: item$1.cost,
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Cost"
                                                                            }),
                                                                        validate: Validation_Rules$Ucidata.Int.min(undefined, 1),
                                                                        onChange: (function (price) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_id: item.uci_item_id,
                                                                                                  name: item.name,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: item.units,
                                                                                                  cost: price,
                                                                                                  holdings: item.holdings,
                                                                                                  code_nature_id: item.code_nature_id,
                                                                                                  code_country_id: item.code_country_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_level_id: item.code_level_id
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Cost"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$7), React.createElement(Input_Number$Ucidata.make, {
                                                                        id: "holding",
                                                                        value: item$1.holdings,
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Holding%"
                                                                            }),
                                                                        validate: Validation_Rules$Ucidata.Int.min(undefined, 1),
                                                                        onChange: (function (holdings) {
                                                                            return Curry._1(setItem, (function (item) {
                                                                                          return {
                                                                                                  id: item.id,
                                                                                                  uci_item_id: item.uci_item_id,
                                                                                                  name: item.name,
                                                                                                  internal_ref: item.internal_ref,
                                                                                                  units: item.units,
                                                                                                  cost: item.cost,
                                                                                                  holdings: holdings,
                                                                                                  code_nature_id: item.code_nature_id,
                                                                                                  code_country_id: item.code_country_id,
                                                                                                  code_denom_id: item.code_denom_id,
                                                                                                  code_ccy_id: item.code_ccy_id,
                                                                                                  code_level_id: item.code_level_id
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Holding%"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$9)))
                                                        }));
                                        })
                                    });
                        }))
                }));
}

var make = React.memo(CompartimentHoldingStructureItem);

var realEstateId = 1;

export {
  realEstateId ,
  make ,
  
}
/* make Not a pure module */
