// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Render$Ucidata from "../Render.bs.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

function Transition(Props) {
  var show = Props.show;
  var transitionIn = Props.transitionIn;
  var transitionOut = Props.transitionOut;
  var className = Props.className;
  var children = Props.children;
  var match = React.useState(function () {
        return show;
      });
  var setVisible = match[1];
  var ref = React.useRef(null);
  React.useEffect((function () {
          if (show) {
            Curry._1(setVisible, (function (param) {
                    return true;
                  }));
          }
          
        }), [show]);
  React.useLayoutEffect(function () {
        Belt_Option.forEach(transitionIn, (function (transitionIn) {
                Js_null_undefined.bind(ref.current, (function (ref) {
                        requestAnimationFrame(function (param) {
                              var classList = ref.classList;
                              var mounted = document.contains(ref);
                              var transitionIn$1 = Belt_Array.map(transitionIn.split(/\s+/), Belt_Option.getExn);
                              var applyTransition = mounted && classList.contains("__transition") && Belt_Array.some(transitionIn$1, (function (cssClass) {
                                      return !classList.contains(cssClass);
                                    }));
                              if (applyTransition) {
                                requestAnimationFrame(function (param) {
                                      Caml_splice_call.spliceObjApply(classList, "add", [transitionIn$1]);
                                      return Belt_Option.forEach(transitionOut, (function (transitionOut) {
                                                    Caml_splice_call.spliceObjApply(classList, "remove", [transitionOut.split(" ")]);
                                                    
                                                  }));
                                    });
                                return ;
                              }
                              
                            });
                        
                      }));
                
              }));
        
      });
  var onTransitionEnd = function (e) {
    var target = e.target;
    var currentTarget = e.currentTarget;
    if (target === currentTarget && !show) {
      return Curry._1(setVisible, (function (param) {
                    return false;
                  }));
    }
    
  };
  return Render$Ucidata.on(match[0], React.createElement("div", {
                  ref: ref,
                  className: Cn.$plus(Cn.$plus(Cn.on("__transition", show), Cn.take(transitionOut)), Cn.take(className)),
                  onTransitionEnd: onTransitionEnd
                }, children));
}

var make = Transition;

export {
  make ,
  
}
/* react Not a pure module */
