// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client$Ucidata from "../Client.bs.js";

function useCodes(param) {
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_type")[0]);
  var match$1 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_event")[0]);
  var match$2 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_subtype")[0]);
  var match$3 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_asset_type")[0]);
  var match$4 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_denom")[0]);
  var match$5 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_ccy")[0]);
  var match$6 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_doc_status")[0]);
  var match$7 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_status")[0]);
  var match$8 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_section")[0]);
  var match$9 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_role")[0]);
  var match$10 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_nature")[0]);
  var match$11 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_country")[0]);
  var match$12 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_level")[0]);
  var match$13 = Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_doc_struct_type")[0]);
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && match$4 !== undefined && match$5 !== undefined && match$6 !== undefined && match$7 !== undefined && match$8 !== undefined && match$9 !== undefined && match$10 !== undefined && match$11 !== undefined && match$12 !== undefined && match$13 !== undefined) {
    return {
            codesType: Caml_option.valFromOption(match),
            codesEvent: Caml_option.valFromOption(match$1),
            codesSubtype: Caml_option.valFromOption(match$2),
            codesAssetType: Caml_option.valFromOption(match$3),
            codesDenom: Caml_option.valFromOption(match$4),
            codesCcy: Caml_option.valFromOption(match$5),
            codesDocStatus: Caml_option.valFromOption(match$6),
            codesStatus: Caml_option.valFromOption(match$7),
            codesSection: Caml_option.valFromOption(match$8),
            codesRole: Caml_option.valFromOption(match$9),
            codesNature: Caml_option.valFromOption(match$10),
            codesCountry: Caml_option.valFromOption(match$11),
            codesLevel: Caml_option.valFromOption(match$12),
            codeDocStructType: Caml_option.valFromOption(match$13)
          };
  }
  
}

export {
  useCodes ,
  
}
/* Client-Ucidata Not a pure module */
