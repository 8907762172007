// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as Input_Search$Ucidata from "../../../core/input/Input_Search.bs.js";
import * as Compartiment_Users_Role$Ucidata from "./Compartiment_Users_Role.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";

function Compartiment_Users(Props) {
  var uci = Props.uci;
  var dispatchUci = Props.dispatchUci;
  var isEditable = Props.isEditable;
  var httpScopedCache = Client$Ucidata.useScopedCachingClient(undefined);
  var match = React.useState(function () {
        return "";
      });
  var setUserSearchPattern = match[1];
  var userSearchPattern = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setUserlist = match$1[1];
  var userlist = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setUserlistLoading = match$2[1];
  Hooks$Ucidata.useDebouncedEffect((function (param) {
          if (userSearchPattern.length !== 0) {
            return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(httpScopedCache.url("/user/pattern/" + userSearchPattern).get().json(setUserlist), (function (param) {
                                  return Curry._1(setUserlist, (function (param) {
                                                return [];
                                              }));
                                })), (function (param) {
                              return Curry._1(setUserlistLoading, (function (param) {
                                            return false;
                                          }));
                            })));
          }
          
        }), 500, [userSearchPattern]);
  var tmp = {
    title: React.createElement(Text$Ucidata.make, {
          children: "Users"
        }),
    className: "space-y-8",
    icon: ProLightSvgIcons.faUsers,
    children: null
  };
  var tmp$1 = Utils$Ucidata.$$Option.fromBool(isEditable, "Add one or more users to review and process the compartiment.");
  if (tmp$1 !== undefined) {
    tmp.description = Caml_option.valFromOption(tmp$1);
  }
  var match$3 = uci.uci_user_roles.length;
  return React.createElement(Section$Ucidata.make, tmp, React.createElement(If$Ucidata.make, {
                  condition: isEditable,
                  children: React.createElement("div", {
                        className: "relative mb-4"
                      }, React.createElement(Input_Search$Ucidata.make, {
                            id: "search-users",
                            value: userSearchPattern,
                            onChange: (function (value) {
                                Curry._1(setUserSearchPattern, (function (param) {
                                        return value;
                                      }));
                                return Curry._1(setUserlistLoading, (function (param) {
                                              return true;
                                            }));
                              }),
                            loading: match$2[0],
                            menuElements: (function (closeMenu) {
                                return Belt_Array.map(userlist, (function (user) {
                                              return React.createElement(Input_Search$Ucidata.$$Element.make, {
                                                          onClick: (function (param) {
                                                              Curry._1(dispatchUci, {
                                                                    TAG: /* AddUser */0,
                                                                    _0: user
                                                                  });
                                                              return Curry._1(closeMenu, undefined);
                                                            }),
                                                          children: React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
                                                                    children: user.first_name + " " + user.last_name
                                                                  }), React.createElement(Font$Ucidata.make, {
                                                                    style: /* Italic */4,
                                                                    children: React.createElement(Text$Ucidata.make, {
                                                                          children: " (" + user.email + ")"
                                                                        })
                                                                  })),
                                                          key: String(user.id)
                                                        });
                                            }));
                              }),
                            placeholder: "Search by name or email"
                          }))
                }), React.createElement("div", {
                  className: "md:space-y-1 space-y-4"
                }, match$3 !== 0 ? React.createElement(ArrayMapi$Ucidata.make, {
                        array: uci.uci_user_roles,
                        render: (function (userRole, i) {
                            return React.createElement(Compartiment_Users_Role$Ucidata.make, {
                                        userId: userRole.user_id,
                                        codeUciRoleId: userRole.code_uci_role_id,
                                        dispatchUci: dispatchUci,
                                        isEditable: isEditable,
                                        key: String(i)
                                      });
                          })
                      }) : React.createElement(Island$Ucidata.make, {
                        style: /* Info */0,
                        icon: ProSolidSvgIcons.faInfoCircle,
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Please assign at least one user."
                            })
                      })));
}

var make = React.memo(Compartiment_Users);

var UserRole;

export {
  UserRole ,
  make ,
  
}
/* make Not a pure module */
