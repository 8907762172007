// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Codes$Ucidata from "../../../Codes.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../../core/font/Font_Div.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Input_Text$Ucidata from "../../../core/input/Input_Text.bs.js";
import * as Validation$Ucidata from "../../../core/validation/Validation.bs.js";
import * as CashAccount$Ucidata from "../../../../models/CashAccount.bs.js";
import * as Input_Select$Ucidata from "../../../core/input/Input_Select.bs.js";
import * as Validation_Rules$Ucidata from "../../../core/validation/Validation_Rules.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../../core/validation/Validation_Context.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function CompartimentCashAccounts_ItemDetails(Props) {
  var uciId = Props.uciId;
  var account = Props.account;
  var onCancel = Props.onCancel;
  var onSave = Props.onSave;
  var client = Client$Ucidata.useClient(undefined);
  var match = React.useState(function () {
        return Belt_Option.getWithDefault(account, CashAccount$Ucidata.empty(uciId));
      });
  var setAccount = match[1];
  var account$1 = match[0];
  var editionMode = account$1.id !== undefined;
  var codes = Codes$Ucidata.useCodes(undefined);
  var match$1 = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveError = match$1[2];
  var setSaveError = match$1[1];
  var saveError = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSaving = match$2[1];
  var saving = match$2[0];
  var saveAccount = function (param) {
    Curry._1(clearSaveError, undefined);
    Curry._1(setSaving, (function (param) {
            return true;
          }));
    return Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(CashAccount$Ucidata.save(client, account$1), (function (account) {
                              return Belt_Option.forEach(onSave, (function (onSave) {
                                            return Curry._1(onSave, account);
                                          }));
                            })), setSaveError), (function (param) {
                      return Curry._1(setSaving, (function (param) {
                                    return false;
                                  }));
                    })));
  };
  return React.createElement("div", {
              className: "space-y-8"
            }, React.createElement(Loader$Ucidata.make, {
                  className: "text-6xl my-10",
                  children: Belt_Option.map(codes, (function (codes) {
                          return React.createElement(Validation$Ucidata.make, {
                                      render: (function (validation) {
                                          var tmp = {};
                                          if (saveError !== undefined) {
                                            tmp.error = Caml_option.valFromOption(saveError);
                                          }
                                          var tmp$1 = {
                                            name: "currency",
                                            options: codes.codesCcy,
                                            onChange: (function (option) {
                                                return Belt_Option.forEach(option, (function (option) {
                                                              return Curry._1(setAccount, (function (account) {
                                                                            return {
                                                                                    id: account.id,
                                                                                    uci_id: account.uci_id,
                                                                                    bank_name: account.bank_name,
                                                                                    iban: account.iban,
                                                                                    balance: account.balance,
                                                                                    code_ccy_id: option.id,
                                                                                    code_monitor_freq: account.code_monitor_freq,
                                                                                    code_toggle: account.code_toggle,
                                                                                    code_monitor_acc: account.code_monitor_acc
                                                                                  };
                                                                          }));
                                                            }));
                                              }),
                                            label: React.createElement(Text$Ucidata.make, {
                                                  children: "Currency"
                                                }),
                                            validate: Validation_Rules$Ucidata.$$Option.required(undefined, undefined),
                                            getOptionLabel: (function (ccy) {
                                                return ccy.name;
                                              }),
                                            getOptionValue: (function (ccy) {
                                                return ccy.id;
                                              })
                                          };
                                          var tmp$2 = Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                                  return elt.id === account$1.code_ccy_id;
                                                }));
                                          if (tmp$2 !== undefined) {
                                            tmp$1.value = Caml_option.valFromOption(tmp$2);
                                          }
                                          return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                                          unstyled: true,
                                                          children: React.createElement(Layout$Ucidata.make, {
                                                                style: /* FlexBetween */3,
                                                                children: null
                                                              }, React.createElement(Font_Div$Ucidata.make, {
                                                                    style: /* Title */1,
                                                                    children: editionMode ? React.createElement(Icon$Ucidata.make, {
                                                                            text: account$1.bank_name,
                                                                            icon: ProSolidSvgIcons.faEdit,
                                                                            className: "mr-2"
                                                                          }) : React.createElement(Icon$Ucidata.make, {
                                                                            text: "Create Cash Account",
                                                                            icon: ProLightSvgIcons.faPlus,
                                                                            className: "mr-2"
                                                                          })
                                                                  }), React.createElement("div", {
                                                                    className: "flex items-center justify-center space-x-4"
                                                                  }, React.createElement(OptionMap$Ucidata.make, {
                                                                        option: onCancel,
                                                                        render: (function (onCancel) {
                                                                            return React.createElement(Button$Ucidata.make, {
                                                                                        onClick: (function (param) {
                                                                                            return Curry._1(onCancel, undefined);
                                                                                          }),
                                                                                        children: React.createElement(Text$Ucidata.make, {
                                                                                              children: "Cancel"
                                                                                            })
                                                                                      });
                                                                          })
                                                                      }), React.createElement(Button$Ucidata.make, {
                                                                        style: /* Primary */0,
                                                                        disabled: Validation_Context$Ucidata.invalid(validation),
                                                                        icon: ProRegularSvgIcons.faSave,
                                                                        spinner: saving,
                                                                        onClick: saveAccount,
                                                                        children: editionMode ? React.createElement(Text$Ucidata.make, {
                                                                                children: "Update"
                                                                              }) : React.createElement(Text$Ucidata.make, {
                                                                                children: "Create"
                                                                              })
                                                                      })))
                                                        }), React.createElement(DisplayServerError$Ucidata.make, tmp), React.createElement(Section$Ucidata.make, {
                                                          children: React.createElement("div", {
                                                                className: "space-y-4"
                                                              }, React.createElement(Layout$Ucidata.make, {
                                                                    style: /* Grid2Cols */0,
                                                                    children: null
                                                                  }, React.createElement(Input_Text$Ucidata.make, {
                                                                        id: "bank-name",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "Bank Name"
                                                                            }),
                                                                        value: account$1.bank_name,
                                                                        validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                        onChange: (function (name) {
                                                                            return Curry._1(setAccount, (function (account) {
                                                                                          return {
                                                                                                  id: account.id,
                                                                                                  uci_id: account.uci_id,
                                                                                                  bank_name: name,
                                                                                                  iban: account.iban,
                                                                                                  balance: account.balance,
                                                                                                  code_ccy_id: account.code_ccy_id,
                                                                                                  code_monitor_freq: account.code_monitor_freq,
                                                                                                  code_toggle: account.code_toggle,
                                                                                                  code_monitor_acc: account.code_monitor_acc
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "Bank Name"
                                                                      }), React.createElement(Input_Text$Ucidata.make, {
                                                                        id: "iban",
                                                                        label: React.createElement(Text$Ucidata.make, {
                                                                              children: "IBAN"
                                                                            }),
                                                                        value: account$1.iban,
                                                                        validate: Validation_Rules$Ucidata.$$String.required(undefined, undefined),
                                                                        onChange: (function (iban) {
                                                                            return Curry._1(setAccount, (function (account) {
                                                                                          return {
                                                                                                  id: account.id,
                                                                                                  uci_id: account.uci_id,
                                                                                                  bank_name: account.bank_name,
                                                                                                  iban: iban,
                                                                                                  balance: account.balance,
                                                                                                  code_ccy_id: account.code_ccy_id,
                                                                                                  code_monitor_freq: account.code_monitor_freq,
                                                                                                  code_toggle: account.code_toggle,
                                                                                                  code_monitor_acc: account.code_monitor_acc
                                                                                                };
                                                                                        }));
                                                                          }),
                                                                        placeholder: "IBAN"
                                                                      }), React.createElement(Input_Select$Ucidata.make, tmp$1)))
                                                        }));
                                        })
                                    });
                        }))
                }));
}

var make = React.memo(CompartimentCashAccounts_ItemDetails);

export {
  make ,
  
}
/* make Not a pure module */
