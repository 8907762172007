// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Table$Ucidata from "../../../core/Table.bs.js";
import * as IfUciRole$Ucidata from "../../../core/conditionals/IfUciRole.bs.js";
import * as OptionMap$Ucidata from "../../../core/conditionals/OptionMap.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";

var Table = Table$Ucidata.Make({});

function make(codes, uci, user) {
  var extraColumns = user !== undefined && uci !== undefined ? IfUciRole$Ucidata.check(undefined, user, uci, [/* BackOffice */2]) : false;
  var sectionStatusColumns = Belt_Array.map(codes.codesSection, (function (section) {
          return {
                  label: section.name,
                  extract: (function (item) {
                      return Belt_Option.mapWithDefault(Belt_Array.getBy(item.sections_status, (function (s) {
                                        return s.code_section_id === section.id;
                                      })), "", (function (sectionStatus) {
                                    return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDocStatus, (function (elt) {
                                                      return elt.id === sectionStatus.code_doc_status_id;
                                                    })), "", (function (elt) {
                                                  return elt.name;
                                                }));
                                  }));
                    }),
                  options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                };
        }));
  return Curry._1(Table.Column.make, [].concat([
                  {
                    label: "Last Update",
                    extract: (function (item) {
                        return new Date(item.last_review_date).toISOString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, (function (param) {
                            return !extraColumns;
                          }), undefined)
                  },
                  {
                    label: "Updated by",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(item.edited_by_identifier, "", (function (edited_by_identifier) {
                                      return edited_by_identifier;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, (function (param) {
                            return !extraColumns;
                          }), undefined)
                  }
                ], [
                  {
                    label: "Name",
                    extract: (function (item) {
                        return item.name;
                      }),
                    options: Curry._7(Table.Column.Options.make, true, true, (function (label, param) {
                            return React.createElement(Text_Span$Ucidata.make, {
                                        className: "mx-2",
                                        children: label
                                      });
                          }), undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Status",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(item.status_id, "0", (function (prim) {
                                      return String(prim);
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, true, true, (function (value, param) {
                            var id = Belt_Option.getExn(Belt_Int.fromString(value));
                            var title = Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesStatus, (function (status) {
                                        return status.id === id;
                                      })), "", (function (status) {
                                    return status.name;
                                  }));
                            var markup;
                            switch (id) {
                              case 1 :
                                  markup = [
                                    "bg-green-400",
                                    React.createElement(Icon$Ucidata.make, {
                                          icon: ProDuotoneSvgIcons.faCheckDouble
                                        })
                                  ];
                                  break;
                              case 2 :
                                  markup = [
                                    "bg-yellow-400",
                                    React.createElement(Icon$Ucidata.make, {
                                          icon: ProSolidSvgIcons.faQuestion
                                        })
                                  ];
                                  break;
                              case 3 :
                                  markup = [
                                    "bg-red-400",
                                    React.createElement(Icon$Ucidata.make, {
                                          icon: ProSolidSvgIcons.faExclamation
                                        })
                                  ];
                                  break;
                              default:
                                markup = undefined;
                            }
                            return React.createElement(OptionMap$Ucidata.make, {
                                        option: markup,
                                        render: (function (param) {
                                            return React.createElement("div", {
                                                        className: param[0] + " w-6 h-6 inline-flex items-center justify-center rounded",
                                                        title: title
                                                      }, param[1]);
                                          })
                                      });
                          }), undefined, "text-white", (function (param) {
                            return !extraColumns;
                          }), undefined)
                  },
                  {
                    label: "Cadaster Nb.",
                    extract: (function (item) {
                        return Belt_Option.getWithDefault(item.cadaster_nb, "N/A");
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Internal Ref.",
                    extract: (function (item) {
                        return item.internal_ref;
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Transaction",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesEvent, (function (elt) {
                                          return elt.id === item.code_event_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Tr. Type",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesType, (function (elt) {
                                          return elt.id === item.code_type_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Tr. Category",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesSubtype, (function (elt) {
                                          return elt.id === item.code_subtype_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Date",
                    extract: (function (item) {
                        return new Date(item.created_at).toISOString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Asset Type",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesAssetType, (function (elt) {
                                          return elt.id === item.code_asset_type_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Units",
                    extract: (function (item) {
                        return item.units.toLocaleString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Denom.",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesDenom, (function (elt) {
                                          return elt.id === item.code_denom_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Cash",
                    extract: (function (item) {
                        return item.price.toLocaleString();
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Curr.",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(Belt_Array.getBy(codes.codesCcy, (function (elt) {
                                          return elt.id === item.code_ccy_id;
                                        })), "", (function (elt) {
                                      return elt.name;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Transm. Id",
                    extract: (function (item) {
                        return item.transm_identifier;
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  },
                  {
                    label: "Valid. Id",
                    extract: (function (item) {
                        return Belt_Option.mapWithDefault(item.valid_identifier, "", (function (valid_identifier) {
                                      return valid_identifier;
                                    }));
                      }),
                    options: Curry._7(Table.Column.Options.make, undefined, true, undefined, undefined, undefined, undefined, undefined)
                  }
                ], sectionStatusColumns));
}

var Column;

export {
  Table ,
  Column ,
  make ,
  
}
/* Table Not a pure module */
