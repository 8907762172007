// Generated by ReScript, PLEASE EDIT WITH CARE


function empty(param) {
  return {
          id: 0,
          first_name: "",
          last_name: "",
          identifier: "",
          email: "",
          user_roles: undefined
        };
}

function me(client) {
  return client.url("/me").get().json();
}

function signup(client, user, password) {
  return client.url("/sign_ups").post({
                email: user.email,
                password: password,
                password_confirmation: password,
                first_name: user.first_name,
                last_name: user.last_name,
                identifier: user.identifier
              }).json();
}

function update(client, user) {
  return client.url("/user/" + String(user.id)).put(user).json();
}

function set_roles(client, userId, roles) {
  return client.url("/user/set_roles").post({
                user_id: userId,
                roles: roles
              }).json();
}

function $$delete(client, userId) {
  return client.url("/user/" + String(userId)).delete().res();
}

export {
  empty ,
  me ,
  signup ,
  update ,
  set_roles ,
  $$delete ,
  
}
/* No side effect */
