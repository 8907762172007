// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "./core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "./core/Icon.bs.js";
import * as Link$Ucidata from "./core/router/Link.bs.js";
import * as Text$Ucidata from "./core/text/Text.bs.js";
import * as IfOvs$Ucidata from "./core/conditionals/IfOvs.bs.js";
import * as Button$Ucidata from "./core/Button.bs.js";
import * as Client$Ucidata from "../Client.bs.js";
import * as Footer$Ucidata from "./Footer.bs.js";
import * as Loader$Ucidata from "./core/loader/Loader.bs.js";
import * as Render$Ucidata from "./Render.bs.js";
import * as ArrayMap$Ucidata from "./core/conditionals/ArrayMap.bs.js";
import * as OptionMap$Ucidata from "./core/conditionals/OptionMap.bs.js";
import * as PopupMenu$Ucidata from "./core/PopupMenu.bs.js";
import * as Text_Span$Ucidata from "./core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "./core/conditionals/IfUserRole.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as UserContext$Ucidata from "./UserContext.bs.js";
import * as React$1 from "hyperactiv/src/react";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function PageShell$Navigation$Element(Props) {
  var href = Props.href;
  var children = Props.children;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var currentPath = "/" + Belt_List.toArray(url.path).join("/");
  var active = href === currentPath;
  return React.createElement(Link$Ucidata.make, {
              href: href,
              className: active ? "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium",
              children: children
            });
}

var $$Element = {
  make: PageShell$Navigation$Element
};

function PageShell$Navigation(Props) {
  return React.createElement("div", {
              className: "ml-10 flex items-baseline space-x-4"
            }, React.createElement(IfUserRole$Ucidata.make, {
                  roles: [
                    /* Controller */5,
                    /* Manager */4,
                    /* AccountAdmin */1,
                    /* HeadBackOffice */2,
                    /* BackOffice */3
                  ],
                  children: React.createElement(PageShell$Navigation$Element, {
                        href: "/assets",
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Assets"
                            })
                      })
                }), React.createElement(IfUserRole$Ucidata.make, {
                  roles: [
                    /* AccountAdmin */1,
                    /* BackOffice */3
                  ],
                  children: React.createElement(PageShell$Navigation$Element, {
                        href: "/config",
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Configuration"
                            })
                      })
                }), React.createElement(IfOvs$Ucidata.make, {
                  children: (function (param) {
                      return React.createElement(IfUserRole$Ucidata.make, {
                                  roles: [
                                    /* Oversight */6,
                                    /* BackOffice */3,
                                    /* AccountAdmin */1
                                  ],
                                  children: React.createElement(PageShell$Navigation$Element, {
                                        href: "/oversight",
                                        children: React.createElement(Text$Ucidata.make, {
                                              children: "Oversight"
                                            })
                                      })
                                });
                    })
                }), React.createElement(IfUserRole$Ucidata.make, {
                  roles: [/* SysAdmin */0],
                  children: React.createElement(PageShell$Navigation$Element, {
                        href: "/admin",
                        children: React.createElement(Text$Ucidata.make, {
                              children: "Admin"
                            })
                      })
                }));
}

var Navigation = {
  $$Element: $$Element,
  make: PageShell$Navigation
};

function PageShell(Props) {
  var header = Props.header;
  var logout = Props.logout;
  var children = Props.children;
  var user = UserContext$Ucidata.useUser(undefined);
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var store = React$1.useStore();
  var match = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_role");
  var codeRoles = Client$Ucidata.data(undefined, match[0]);
  var userRoles = user !== undefined && codeRoles !== undefined ? Belt_Option.map(user.user_roles, (function (userRoles) {
            return Belt_Array.map(userRoles, (function (role) {
                          return Belt_Array.getBy(codeRoles, (function (r) {
                                        return r.id === role;
                                      }));
                        }));
          })) : undefined;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex flex-col bg-gray-400 min-h-screen"
                }, React.createElement("nav", {
                      className: "bg-gray-800"
                    }, React.createElement("div", {
                          className: "max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8"
                        }, React.createElement("div", {
                              className: "flex items-center justify-between h-16"
                            }, React.createElement("div", {
                                  className: "flex items-center"
                                }, React.createElement("div", {
                                      className: "flex-shrink-0"
                                    }, React.createElement(Link$Ucidata.make, {
                                          href: "/",
                                          className: "text-4xl",
                                          unstyled: true,
                                          children: React.createElement(Icon$Ucidata.make, {
                                                icon: ProRegularSvgIcons.faHomeLgAlt,
                                                className: "p-2 rounded bg-blue-400 hover:text-gray-50"
                                              })
                                        })), React.createElement("div", undefined, React.createElement(PageShell$Navigation, {}))), React.createElement("div", {
                                  className: "flex items-center space-x-4"
                                }, React.createElement(If$Ucidata.make, {
                                      condition: store.uploads.working,
                                      children: React.createElement("span", {
                                            className: "fa-layers fa-fw text-white text-3xl",
                                            title: "Uploads in progress…"
                                          }, React.createElement(Icon$Ucidata.make, {
                                                icon: ProRegularSvgIcons.faCloud
                                              }), React.createElement(Icon$Ucidata.make, {
                                                icon: ProSolidSvgIcons.faSync,
                                                spin: true,
                                                transform: "shrink-8"
                                              }))
                                    }), React.createElement(PopupMenu$Ucidata.make, {
                                      render: (function (param) {
                                          return React.createElement(React.Fragment, undefined, React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                          className: "text-white bg-gray-800 font-semibold",
                                                          children: React.createElement(Loader$Ucidata.make, {
                                                                children: Belt_Option.map(user, (function (user) {
                                                                        return React.createElement(Text$Ucidata.make, {
                                                                                    children: user.identifier + " - " + user.email
                                                                                  });
                                                                      }))
                                                              }),
                                                          key: "user description"
                                                        }), React.createElement(OptionMap$Ucidata.make, {
                                                          option: userRoles,
                                                          render: (function (userRoles) {
                                                              return React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                                          className: "text-white bg-gray-700",
                                                                          children: null
                                                                        }, React.createElement("div", {
                                                                              className: "font-semibold mb-1 text-xs"
                                                                            }, React.createElement(Text$Ucidata.make, {
                                                                                  children: "Role(s):"
                                                                                })), React.createElement("ul", {
                                                                              className: "list-disc list-inside text-xs text-gray-300"
                                                                            }, React.createElement(ArrayMap$Ucidata.make, {
                                                                                  array: userRoles,
                                                                                  render: (function (role) {
                                                                                      return Render$Ucidata.map(role, (function (role) {
                                                                                                    return React.createElement("li", {
                                                                                                                key: String(role.id)
                                                                                                              }, React.createElement(Text$Ucidata.make, {
                                                                                                                    children: role.name
                                                                                                                  }));
                                                                                                  }));
                                                                                    })
                                                                                })));
                                                            }),
                                                          key: "user roles"
                                                        }), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                          onClick: (function (param) {
                                                              return RescriptReactRouter.push("/password");
                                                            }),
                                                          className: "flex items-center space-x-2",
                                                          children: null,
                                                          key: "change-password"
                                                        }, React.createElement(Icon$Ucidata.make, {
                                                              icon: ProSolidSvgIcons.faKey
                                                            }), React.createElement(Text_Span$Ucidata.make, {
                                                              className: "px-2",
                                                              children: "Change Password"
                                                            })), React.createElement(PopupMenu$Ucidata.MenuElement.make, {
                                                          onClick: (function (param) {
                                                              Curry._1(logout, undefined);
                                                              
                                                            }),
                                                          className: "flex items-center space-x-2",
                                                          children: null,
                                                          key: "signout"
                                                        }, React.createElement(Icon$Ucidata.make, {
                                                              icon: ProRegularSvgIcons.faSignOut
                                                            }), React.createElement(Text_Span$Ucidata.make, {
                                                              className: "px-2",
                                                              children: "Sign Out"
                                                            })));
                                        }),
                                      className: "border border-white",
                                      children: React.createElement(Button$Ucidata.make, {
                                            style: /* Unstyled */4,
                                            className: "flex items-center justify-center w-8 h-8 rounded-full overflow-hidden text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white",
                                            children: React.createElement(Icon$Ucidata.make, {
                                                  icon: ProSolidSvgIcons.faUser,
                                                  className: "text-3xl"
                                                })
                                          })
                                    }))))), React.createElement("header", {
                      className: "bg-gray-700 text-white shadow pb-28"
                    }, React.createElement("div", {
                          className: "max-w-screen-2xl mx-auto py-6 px-4 sm:px-6 lg:px-8"
                        }, header)), React.createElement("main", {
                      className: "flex-auto sm:p-4 sm:pt-0 md:p-6 md:pt-0 lg:p-8 lg:pt-0 -mt-28"
                    }, children), React.createElement(Footer$Ucidata.make, {
                      className: "text-black bg-gray-50",
                      logoClassName: "p-1"
                    })));
}

var make = React.memo(React$1.watch(PageShell));

export {
  Navigation ,
  make ,
  
}
/* make Not a pure module */
