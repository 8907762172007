// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Validation_Defs$Ucidata from "./Validation_Defs.bs.js";

function compose(rules, value) {
  return Belt_Array.reduce(rules, undefined, (function (acc, validate) {
                return Validation_Defs$Ucidata.flatten(acc, Curry._1(validate, value));
              }));
}

function validate(condition, message, value) {
  if (!Curry._1(condition, value)) {
    return Validation_Defs$Ucidata.errorFromMessage(message);
  }
  
}

function notEmpty(messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "You must select at least one element.";
  return function (param) {
    return validate((function (value) {
                  return value.length !== 0;
                }), message, param);
  };
}

function notEmpty$1(messageOpt, param, value) {
  var message = messageOpt !== undefined ? messageOpt : "You must select at least one element.";
  return notEmpty(message, undefined)(Belt_Option.getWithDefault(value, []));
}

var Optional = {
  notEmpty: notEmpty$1
};

function notEmpty$2(messageOpt, param, value) {
  var message = messageOpt !== undefined ? messageOpt : "You must select at least one element.";
  return notEmpty$1(message, undefined, (value == null) ? undefined : Caml_option.some(value));
}

var Nullable = {
  notEmpty: notEmpty$2
};

var $$Array = {
  notEmpty: notEmpty,
  Optional: Optional,
  Nullable: Nullable
};

function notEmpty$3(messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "You must select at least one element.";
  return function (param) {
    return validate((function (value) {
                  if (value == null) {
                    return false;
                  } else {
                    return value.length !== 0;
                  }
                }), message, param);
  };
}

var NullableArray = {
  notEmpty: notEmpty$3
};

function equal(value, messageOpt) {
  var message = messageOpt !== undefined ? messageOpt : "Field is invalid.";
  return function (param) {
    return validate((function (v) {
                  return v === value;
                }), message, param);
  };
}

function required(messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Field is required.";
  return function (param) {
    return validate((function (value) {
                  return value.length !== 0;
                }), message, param);
  };
}

function minLength(nb, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "This field should contain at least " + String(nb) + " characters.";
  return function (param) {
    return validate((function (value) {
                  return value.length >= nb;
                }), message, param);
  };
}

function maxLength(nb, messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "This field should contain at most " + String(nb) + " characters.";
  return function (param) {
    return validate((function (value) {
                  return value.length <= nb;
                }), message, param);
  };
}

function regex(reg, message) {
  return function (param) {
    return validate((function (value) {
                  return reg.test(value);
                }), message, param);
  };
}

function numbersOnly(messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Only numbers are allowed.";
  return regex(/^[0-9]*$/, message);
}

function email(messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "This does not look like a valid email address.";
  return regex(/^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message);
}

var $$String$1 = {
  equal: equal,
  required: required,
  minLength: minLength,
  maxLength: maxLength,
  regex: regex,
  numbersOnly: numbersOnly,
  email: email
};

function min(message, nb) {
  var partial_arg = Belt_Option.getWithDefault(message, "Minimum amount is " + String(nb) + ".");
  return function (param) {
    return validate((function (value) {
                  return value >= nb;
                }), partial_arg, param);
  };
}

function max(message, nb) {
  var partial_arg = Belt_Option.getWithDefault(message, "Maximum amount is " + String(nb) + ".");
  return function (param) {
    return validate((function (value) {
                  return value <= nb;
                }), partial_arg, param);
  };
}

var Int = {
  min: min,
  max: max
};

function required$1(messageOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "Field is required.";
  return function (param) {
    return validate((function (value) {
                  return value !== undefined;
                }), message, param);
  };
}

function numbersOnly$1(messageOpt, opt) {
  var message = messageOpt !== undefined ? messageOpt : "Only numbers are allowed.";
  return Belt_Option.flatMap(opt, regex(/^[0-9]*$/, message));
}

var $$Option = {
  required: required$1,
  numbersOnly: numbersOnly$1
};

export {
  compose ,
  validate ,
  $$Array ,
  NullableArray ,
  $$String$1 as $$String,
  Int ,
  $$Option ,
  
}
/* No side effect */
