// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_option from "rescript/lib/es6/js_option.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Icon$Ucidata from "../../core/Icon.bs.js";
import * as Link$Ucidata from "../../core/router/Link.bs.js";
import * as Hooks$Ucidata from "../../Hooks.bs.js";
import * as Client$Ucidata from "../../../Client.bs.js";
import * as Layout$Ucidata from "../../core/Layout.bs.js";
import * as Font_H1$Ucidata from "../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../core/Section.bs.js";
import * as Font_Div$Ucidata from "../../core/font/Font_Div.bs.js";
import * as IfUserRole$Ucidata from "../../core/conditionals/IfUserRole.bs.js";
import * as Config_CreateUci$Ucidata from "./Config_CreateUci.bs.js";
import * as Config_UciGroupList$Ucidata from "./Config_UciGroupList.bs.js";
import * as ProLightSvgIcons from "@fortawesome/pro-light-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Config(Props) {
  var match = Hooks$Ucidata.useFixedReference(undefined);
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, undefined, [match[0]], undefined, "/uci_group");
  var setUciGroups = match$1[1];
  var setUciGroup = React.useCallback((function (uciGroup) {
          return Curry._1(setUciGroups, (function (param) {
                        return Js_option.map((function (groups) {
                                      return Belt_Array.map(groups, (function (group) {
                                                    if (Caml_obj.caml_equal(group.id, uciGroup.id)) {
                                                      return uciGroup;
                                                    } else {
                                                      return group;
                                                    }
                                                  }));
                                    }), param);
                      }));
        }), [setUciGroups]);
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: null
            }, React.createElement(Section$Ucidata.make, {
                  unstyled: true,
                  children: React.createElement(Font_H1$Ucidata.make, {
                        style: /* Title */1,
                        children: React.createElement(Icon$Ucidata.make, {
                              text: "Configuration",
                              icon: ProRegularSvgIcons.faTools,
                              className: "mr-2"
                            })
                      })
                }), React.createElement("div", {
                  className: "space-y-8"
                }, React.createElement(IfUserRole$Ucidata.make, {
                      role: /* AccountAdmin */1,
                      children: null
                    }, React.createElement(Config_CreateUci$Ucidata.make, {
                          refetchGroups: match[1]
                        }), React.createElement(Section$Ucidata.make, {
                          children: React.createElement(Font_Div$Ucidata.make, {
                                style: /* Subtitle */2,
                                children: React.createElement(Link$Ucidata.make, {
                                      href: "/config/compartiment",
                                      className: "full-w",
                                      unstyled: true,
                                      children: React.createElement(Icon$Ucidata.make, {
                                            text: "Create Compartiment",
                                            icon: ProLightSvgIcons.faPlus,
                                            className: "mr-2"
                                          })
                                    })
                              })
                        })), React.createElement(Config_UciGroupList$Ucidata.make, {
                      uciGroupsState: match$1[0],
                      setUciGroup: setUciGroup
                    })));
}

var CreateUci;

var UciGroupList;

var make = Config;

export {
  CreateUci ,
  UciGroupList ,
  make ,
  
}
/* react Not a pure module */
