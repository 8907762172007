// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client$Ucidata from "../Client.bs.js";

var frequencies = [
  "nav",
  "proc",
  "restr",
  "settle"
];

function useCodeFrequencies(param) {
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Belt_Array.map(frequencies, (function (freq) {
          return Client$Ucidata.data(undefined, Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_frequency?type=" + freq)[0]);
        }));
  if (match.length !== 4) {
    return ;
  }
  var nav = match[0];
  if (nav === undefined) {
    return ;
  }
  var proc = match[1];
  if (proc === undefined) {
    return ;
  }
  var restr = match[2];
  if (restr === undefined) {
    return ;
  }
  var settle = match[3];
  if (settle !== undefined) {
    return {
            nav: nav,
            proc: proc,
            restr: restr,
            settle: settle
          };
  }
  
}

export {
  frequencies ,
  useCodeFrequencies ,
  
}
/* Client-Ucidata Not a pure module */
