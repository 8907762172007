// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as If$Ucidata from "../../../core/conditionals/If.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Uci$Ucidata from "../../../../models/Uci.bs.js";
import * as Font$Ucidata from "../../../core/font/Font.bs.js";
import * as Icon$Ucidata from "../../../core/Icon.bs.js";
import * as Text$Ucidata from "../../../core/text/Text.bs.js";
import * as Hooks$Ucidata from "../../../Hooks.bs.js";
import * as IfOvs$Ucidata from "../../../core/conditionals/IfOvs.bs.js";
import * as Utils$Ucidata from "../../../../Utils.bs.js";
import * as Button$Ucidata from "../../../core/Button.bs.js";
import * as Client$Ucidata from "../../../../Client.bs.js";
import * as IfCash$Ucidata from "../../../core/conditionals/IfCash.bs.js";
import * as Island$Ucidata from "../../../core/Island.bs.js";
import * as Layout$Ucidata from "../../../core/Layout.bs.js";
import * as Loader$Ucidata from "../../../core/loader/Loader.bs.js";
import * as UciOvs$Ucidata from "../../../../models/UciOvs.bs.js";
import * as Wretch$Ucidata from "../../../../libs/Wretch.bs.js";
import * as Font_H1$Ucidata from "../../../core/font/Font_H1.bs.js";
import * as Section$Ucidata from "../../../core/Section.bs.js";
import * as ArrayMapi$Ucidata from "../../../core/conditionals/ArrayMapi.bs.js";
import * as Text_Span$Ucidata from "../../../core/text/Text_Span.bs.js";
import * as IfUserRole$Ucidata from "../../../core/conditionals/IfUserRole.bs.js";
import * as Validation$Ucidata from "../../../core/validation/Validation.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as UserContext$Ucidata from "../../../UserContext.bs.js";
import * as Compartiment_Cash$Ucidata from "./Compartiment_Cash.bs.js";
import * as Compartiment_Users$Ucidata from "./Compartiment_Users.bs.js";
import * as DisplayServerError$Ucidata from "../../../core/DisplayServerError.bs.js";
import * as Validation_Context$Ucidata from "../../../core/validation/Validation_Context.bs.js";
import * as Compartiment_Details$Ucidata from "./Compartiment_Details.bs.js";
import * as Compartiment_Documents$Ucidata from "./Compartiment_Documents.bs.js";
import * as Compartiment_Oversight$Ucidata from "./Compartiment_Oversight.bs.js";
import * as ProSolidSvgIcons from "@fortawesome/pro-solid-svg-icons";
import * as ProRegularSvgIcons from "@fortawesome/pro-regular-svg-icons";

function Compartiment(Props) {
  var uciId = Props.uciId;
  var user = UserContext$Ucidata.useUser(undefined);
  var client = Client$Ucidata.useClient(undefined);
  var httpCache = Client$Ucidata.useCachingClient(undefined);
  var match = Client$Ucidata.useDataReducer(Uci$Ucidata.Reducer.reducer, Uci$Ucidata.Reducer.setData, Uci$Ucidata.empty, undefined, undefined, Belt_Option.isNone(uciId), undefined, undefined, undefined, "/uci/" + Belt_Option.getWithDefault(uciId, ""));
  var dispatchUci = match[1];
  var uciState = match[0];
  var match$1 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_type");
  var codeTypesState = match$1[0];
  var match$2 = Client$Ucidata.useData(undefined, undefined, undefined, undefined, Caml_option.some(httpCache), undefined, false, "/code_type_doc");
  var documentsState = match$2[0];
  var uciOvsData;
  if (Utils$Ucidata.ovs) {
    var match$3 = Client$Ucidata.useDataReducer(UciOvs$Ucidata.Reducer.reducer, UciOvs$Ucidata.Reducer.setData, UciOvs$Ucidata.empty, undefined, undefined, Belt_Option.isNone(uciId), Caml_option.some(client.catcher(404, (function (_err, _w) {
                    return UciOvs$Ucidata.empty;
                  }))), undefined, undefined, "/uci_ovs/" + Belt_Option.getWithDefault(uciId, ""));
    uciOvsData = [
      match$3[0],
      match$3[1]
    ];
  } else {
    uciOvsData = undefined;
  }
  React.useEffect((function () {
          if (uciId === undefined) {
            Curry._1(dispatchUci, {
                  TAG: /* SetUci */6,
                  _0: Uci$Ucidata.empty
                });
          }
          
        }), [uciId]);
  var match$4 = Hooks$Ucidata.useErrorState(undefined);
  var clearSaveUciError = match$4[2];
  var setSaveUciError = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setSavingUci = match$5[1];
  var savingUci = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setUciHasBeenUpdated = match$6[1];
  var uciHasBeenUpdated = match$6[0];
  var isEditable = Belt_Option.mapWithDefault(user, false, (function (user) {
          return IfUserRole$Ucidata.check(user, [/* AccountAdmin */1]);
        }));
  var uci = Client$Ucidata.data(undefined, uciState);
  var documents = Client$Ucidata.data(undefined, documentsState);
  var codeTypes = Client$Ucidata.data(undefined, codeTypesState);
  var errors = Belt_Array.concat(Belt_Array.reduce([
            match$4[0],
            Client$Ucidata.error(uciState)
          ], [], (function (acc, opt) {
              Belt_Option.map(opt, (function (exn) {
                      return acc.push(exn);
                    }));
              return acc;
            })), Client$Ucidata.errors3(uciState, codeTypesState, documentsState));
  var saveUciOvs = function (updatedUci) {
    if (!Utils$Ucidata.ovs) {
      return Promise.resolve(undefined);
    }
    var match = Belt_Option.getExn(uciOvsData);
    var dispatchUciOvs = match[1];
    return Belt_Option.mapWithDefault(Client$Ucidata.data(undefined, match[0]), Promise.resolve(undefined), (function (uciOvs) {
                  return client.url("/uci_ovs/" + String(Belt_Option.getExn(updatedUci.id))).put(uciOvs).json(function (updatedUciOvs) {
                              return Curry._1(dispatchUciOvs, {
                                          TAG: /* SetUciOvs */0,
                                          _0: updatedUciOvs
                                        });
                            });
                }));
  };
  var saveUci = function (param) {
    return Belt_Option.map(uci, (function (uci) {
                  Curry._1(clearSaveUciError, undefined);
                  Curry._1(setSavingUci, (function (param) {
                          return true;
                        }));
                  Curry._1(setUciHasBeenUpdated, (function (param) {
                          return false;
                        }));
                  var match = uci.id;
                  var request = match !== undefined ? (function (param) {
                        return Wretch$Ucidata.$$then(Uci$Ucidata.update(client, uci), (function (updatedUci) {
                                      Curry._1(dispatchUci, {
                                            TAG: /* SetUci */6,
                                            _0: updatedUci
                                          });
                                      return saveUciOvs(updatedUci);
                                    }));
                      }) : (function (param) {
                        return Wretch$Ucidata.$$then(Uci$Ucidata.create(client, uci), (function (createdUci) {
                                      return Wretch$Ucidata.$$then(saveUciOvs(createdUci), (function (param) {
                                                    return RescriptReactRouter.push("/config/compartiment/" + String(Belt_Option.getExn(createdUci.id)));
                                                  }));
                                    }));
                      });
                  return Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(Curry._1(request, undefined), (function (param) {
                                        return Curry._1(setUciHasBeenUpdated, (function (param) {
                                                      return true;
                                                    }));
                                      })), setSaveUciError), (function (param) {
                                return Curry._1(setSavingUci, (function (param) {
                                              return false;
                                            }));
                              }));
                }));
  };
  var tmp;
  if (typeof uciState === "number") {
    tmp = React.createElement(DisplayServerError$Ucidata.make, {});
  } else {
    switch (uciState.TAG | 0) {
      case /* Loading */0 :
          tmp = React.createElement(Loader$Ucidata.make, {
                className: "text-6xl p-10"
              });
          break;
      case /* Error */1 :
          tmp = React.createElement(DisplayServerError$Ucidata.make, {
                error: uciState._0
              });
          break;
      case /* Data */2 :
          var uci$1 = uciState._0;
          tmp = React.createElement(Validation$Ucidata.make, {
                resetDependency: uciId,
                render: (function (validation) {
                    var disabled = Validation_Context$Ucidata.invalid(validation) || uci$1.uci_user_roles.length === 0 || savingUci;
                    var title = disabled ? "Validation failed." : undefined;
                    var content = Belt_Option.mapWithDefault(uciId, "Create", (function (param) {
                            return "Update";
                          }));
                    var tmp = {
                      style: /* Primary */0,
                      disabled: disabled,
                      icon: ProRegularSvgIcons.faSave,
                      spinner: savingUci,
                      className: "mr-2 w-full md:w-auto",
                      onClick: (function (param) {
                          saveUci(undefined);
                          
                        }),
                      children: React.createElement(Text$Ucidata.make, {
                            children: content
                          })
                    };
                    if (title !== undefined) {
                      tmp.title = Caml_option.valFromOption(title);
                    }
                    return React.createElement(React.Fragment, undefined, React.createElement(Section$Ucidata.make, {
                                    unstyled: true,
                                    children: React.createElement(Layout$Ucidata.make, {
                                          style: /* FlexBetween */3,
                                          children: null
                                        }, React.createElement(Font_H1$Ucidata.make, {
                                              style: /* Title */1,
                                              children: null
                                            }, React.createElement(Icon$Ucidata.make, {
                                                  icon: uci$1.id !== undefined ? ProRegularSvgIcons.faEdit : ProRegularSvgIcons.faPlusCircle,
                                                  className: "mr-4"
                                                }), Belt_Option.mapWithDefault(uci$1.id, React.createElement(Text$Ucidata.make, {
                                                      children: "New Compartiment"
                                                    }), (function (param) {
                                                    return React.createElement(React.Fragment, undefined, React.createElement(Text_Span$Ucidata.make, {
                                                                    className: "mr-4",
                                                                    children: uci$1.comp_name
                                                                  }), React.createElement(Font$Ucidata.make, {
                                                                    style: /* Subtitle */2,
                                                                    children: React.createElement(Text$Ucidata.make, {
                                                                          children: uci$1.uci_name
                                                                        })
                                                                  }));
                                                  }))), React.createElement(If$Ucidata.make, {
                                              condition: isEditable,
                                              children: React.createElement("div", undefined, React.createElement(Button$Ucidata.make, tmp))
                                            }))
                                  }), React.createElement(If$Ucidata.make, {
                                    condition: errors.length !== 0,
                                    children: React.createElement(Section$Ucidata.make, {
                                          unstyled: true,
                                          children: React.createElement(Island$Ucidata.make, {
                                                style: /* Error */2,
                                                title: "Error(s)",
                                                icon: ProSolidSvgIcons.faBomb,
                                                children: React.createElement(ArrayMapi$Ucidata.make, {
                                                      array: errors,
                                                      render: (function (error, key) {
                                                          return React.createElement("div", {
                                                                      key: String(key)
                                                                    }, React.createElement(Text$Ucidata.make, {
                                                                          children: Utils$Ucidata.getErrorMessage(error)
                                                                        }));
                                                        })
                                                    })
                                              })
                                        })
                                  }), React.createElement(If$Ucidata.make, {
                                    condition: uciHasBeenUpdated,
                                    children: React.createElement(Section$Ucidata.make, {
                                          unstyled: true,
                                          children: React.createElement(Island$Ucidata.make, {
                                                title: "Success",
                                                icon: ProSolidSvgIcons.faCheck,
                                                closeable: (function (param) {
                                                    return Curry._1(setUciHasBeenUpdated, (function (param) {
                                                                  return false;
                                                                }));
                                                  }),
                                                children: React.createElement(Text$Ucidata.make, {
                                                      children: "Compartiment has been updated successfully."
                                                    })
                                              })
                                        })
                                  }), React.createElement(Compartiment_Details$Ucidata.make, {
                                    uci: uci$1,
                                    dispatchUci: dispatchUci,
                                    documents: documents,
                                    codeTypes: codeTypes,
                                    isEditable: isEditable
                                  }), React.createElement(Compartiment_Users$Ucidata.make, {
                                    uci: uci$1,
                                    dispatchUci: dispatchUci,
                                    isEditable: isEditable
                                  }), React.createElement(Compartiment_Documents$Ucidata.make, {
                                    uci: uci$1,
                                    dispatchUci: dispatchUci,
                                    isEditable: isEditable
                                  }), React.createElement(IfOvs$Ucidata.make, {
                                    children: (function (param) {
                                        var match = Belt_Option.getExn(uciOvsData);
                                        return React.createElement(Compartiment_Oversight$Ucidata.make, {
                                                    uciOvsState: match[0],
                                                    dispatchUciOvs: match[1],
                                                    isEditable: isEditable
                                                  });
                                      })
                                  }), React.createElement(IfCash$Ucidata.make, {
                                    children: (function (param) {
                                        return React.createElement(Compartiment_Cash$Ucidata.make, {
                                                    uci: uci$1,
                                                    isEditable: isEditable
                                                  });
                                      })
                                  }));
                  })
              });
          break;
      
    }
  }
  return React.createElement(Layout$Ucidata.make, {
              style: /* Page */4,
              children: tmp
            });
}

var make = React.memo(Compartiment);

var CompartimentDetails;

var CompartimentUsers;

var CompartimentDocuments;

var CompartimentOversight;

var CompartimentCash;

export {
  CompartimentDetails ,
  CompartimentUsers ,
  CompartimentDocuments ,
  CompartimentOversight ,
  CompartimentCash ,
  make ,
  
}
/* make Not a pure module */
