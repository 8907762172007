// Generated by ReScript, PLEASE EDIT WITH CARE


var externalValuerSubtypeId = 5;

export {
  externalValuerSubtypeId ,
  
}
/* No side effect */
