// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Wretch from "wretch";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Hooks$Ucidata from "./components/Hooks.bs.js";
import * as Wretch$Ucidata from "./libs/Wretch.bs.js";
import * as WretchMiddlewares from "wretch-middlewares";

var retryMiddleware = WretchMiddlewares.retry({
      retryOnNetworkError: true,
      until: (function (response, networkError) {
          if (Belt_Option.mapWithDefault((networkError == null) ? undefined : Caml_option.some(networkError), false, (function (err) {
                    return err.name === "AbortError";
                  }))) {
            return true;
          } else {
            return Belt_Option.mapWithDefault((response == null) ? undefined : Caml_option.some(response), false, (function (r) {
                          if (r.ok) {
                            return true;
                          } else {
                            return r.status < 500;
                          }
                        }));
          }
        })
    });

var base = Wretch("/api").middlewares([
          WretchMiddlewares.dedupe(undefined),
          (function (next) {
              return function (url, options) {
                if (options.method === "GET") {
                  return Curry._1(retryMiddleware, next)(url, options);
                } else {
                  return next(url, options);
                }
              };
            })
        ]).defer(function (w, param, opts) {
        return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(opts.token), w, (function (token) {
                      return w.auth("Bearer " + token);
                    }));
      }).resolve(function (r, param) {
      return r.onAbort(function (param, param$1) {
                  
                });
    });

var cached = base.middlewares([
      WretchMiddlewares.throttlingCache({
            throttle: 3600000,
            clear: (function (param, opts) {
                return !(opts.clearCache == null);
              }),
            skip: (function (param, opts) {
                if (opts.clearCache == null) {
                  return !(opts.skipCache == null);
                } else {
                  return true;
                }
              })
          }),
      Wretch$Ucidata.Middleware.make(function (next) {
            return function (url, opts) {
              if (opts.mock) {
                return Promise.resolve(new Response());
              } else {
                return next(url, opts);
              }
            };
          })
    ], 1);

function defaultHook(w) {
  return w;
}

function data(staleOpt, state) {
  var stale = staleOpt !== undefined ? staleOpt : true;
  if (typeof state === "number") {
    return ;
  }
  switch (state.TAG | 0) {
    case /* Loading */0 :
        if (stale) {
          return state._0;
        } else {
          return ;
        }
    case /* Error */1 :
        if (stale) {
          return state._1;
        } else {
          return ;
        }
    case /* Data */2 :
        return Caml_option.some(state._0);
    
  }
}

function error(state) {
  if (typeof state === "number" || state.TAG !== /* Error */1) {
    return ;
  } else {
    return Caml_option.some(state._0);
  }
}

function composeErrors(a, acc) {
  return Belt_Option.mapWithDefault(error(a), acc, (function (e) {
                return Belt_Array.concat([e], acc);
              }));
}

function errors2(a, b) {
  return composeErrors(b, composeErrors(a, []));
}

function errors3(a, b, c) {
  return composeErrors(c, composeErrors(b, composeErrors(a, [])));
}

function useHttpClient(clientOpt, param) {
  if (clientOpt !== undefined) {
    return Caml_option.valFromOption(clientOpt);
  } else {
    return base;
  }
}

function useClient(param) {
  return useHttpClient(Caml_option.some(base), undefined);
}

function useCachingClient(param) {
  return useHttpClient(Caml_option.some(cached), undefined);
}

function useScopedCachingClient(param) {
  var cachingClient = useHttpClient(Caml_option.some(cached), undefined);
  return React.useState(function () {
                return cachingClient;
              })[0];
}

function useData(initialData, beforeRequestOpt, afterRequestOpt, skipOpt, clientOpt, dependenciesOpt, abortOnChangeOpt, url) {
  var beforeRequest = beforeRequestOpt !== undefined ? beforeRequestOpt : defaultHook;
  var afterRequest = afterRequestOpt !== undefined ? afterRequestOpt : defaultHook;
  var skip = skipOpt !== undefined ? skipOpt : false;
  var client = clientOpt !== undefined ? Caml_option.valFromOption(clientOpt) : base;
  var dependencies = dependenciesOpt !== undefined ? dependenciesOpt : [];
  var abortOnChange = abortOnChangeOpt !== undefined ? abortOnChangeOpt : true;
  var client$1 = useHttpClient(Caml_option.some(client), undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var match$1 = React.useState(function () {
        return initialData;
      });
  var setData = match$1[1];
  var data = match$1[0];
  var match$2 = Hooks$Ucidata.useErrorState(undefined);
  var clearError = match$2[2];
  var setError = match$2[1];
  var error = match$2[0];
  React.useEffect((function () {
          var controller = new AbortController();
          if (!skip) {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            var client$2 = client$1.url(url).signal(controller);
            Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Curry._1(afterRequest, Curry._1(beforeRequest, client$2).options({
                                      skipDedupe: abortOnChange
                                    }).get()).json(function (data) {
                              return Curry._1(setData, (function (param) {
                                            return Caml_option.some(data);
                                          }));
                            }), setError), (function (param) {
                        return Curry._1(setLoading, (function (param) {
                                      return false;
                                    }));
                      })));
          }
          return (function (param) {
                    Curry._1(clearError, undefined);
                    if (abortOnChange) {
                      controller.abort();
                      return ;
                    }
                    
                  });
        }), Belt_Array.concat([
            url,
            skip
          ], dependencies));
  var state = match[0] ? ({
        TAG: /* Loading */0,
        _0: data
      }) : (
      error !== undefined ? ({
            TAG: /* Error */1,
            _0: error,
            _1: data
          }) : (
          data !== undefined ? ({
                TAG: /* Data */2,
                _0: data
              }) : /* NoData */0
        )
    );
  return [
          state,
          setData
        ];
}

function useDataReducer(reducer, setData, initialData, beforeRequestOpt, afterRequestOpt, skipOpt, clientOpt, dependenciesOpt, abortOnChangeOpt, url) {
  var beforeRequest = beforeRequestOpt !== undefined ? beforeRequestOpt : defaultHook;
  var afterRequest = afterRequestOpt !== undefined ? afterRequestOpt : defaultHook;
  var skip = skipOpt !== undefined ? skipOpt : false;
  var client = clientOpt !== undefined ? Caml_option.valFromOption(clientOpt) : base;
  var dependencies = dependenciesOpt !== undefined ? dependenciesOpt : [];
  var abortOnChange = abortOnChangeOpt !== undefined ? abortOnChangeOpt : true;
  var client$1 = useHttpClient(Caml_option.some(client), undefined);
  var match = React.useState(function () {
        return false;
      });
  var setLoading = match[1];
  var match$1 = React.useReducer(Curry.__2(reducer), initialData);
  var dispatch = match$1[1];
  var data = match$1[0];
  var match$2 = Hooks$Ucidata.useErrorState(undefined);
  var clearError = match$2[2];
  var setError = match$2[1];
  var error = match$2[0];
  React.useEffect((function () {
          var controller = new AbortController();
          if (!skip) {
            Curry._1(setLoading, (function (param) {
                    return true;
                  }));
            var client$2 = client$1.url(url).signal(controller);
            Wretch$Ucidata.ignore(Wretch$Ucidata.$$then(Wretch$Ucidata.$$catch(Curry._1(afterRequest, Curry._1(beforeRequest, client$2).options({
                                      skipDedupe: abortOnChange
                                    }).get()).json(function (data) {
                              return Curry._2(setData, Caml_option.some(data), dispatch);
                            }), setError), (function (param) {
                        return Curry._1(setLoading, (function (param) {
                                      return false;
                                    }));
                      })));
          }
          return (function (param) {
                    Curry._1(clearError, undefined);
                    if (abortOnChange) {
                      controller.abort();
                      return ;
                    }
                    
                  });
        }), Belt_Array.concat([
            url,
            skip
          ], dependencies));
  var state = match[0] ? ({
        TAG: /* Loading */0,
        _0: data
      }) : (
      error !== undefined ? ({
            TAG: /* Error */1,
            _0: error,
            _1: data
          }) : (
          data !== undefined ? ({
                TAG: /* Data */2,
                _0: data
              }) : /* NoData */0
        )
    );
  return [
          state,
          dispatch
        ];
}

function useRequest(onSuccess, onError, request) {
  var match = React.useState(function () {
        return /* Idle */0;
      });
  var setState = match[1];
  var wrappedRequest = function (param) {
    Curry._1(setState, (function (param) {
            return /* Pending */1;
          }));
    return Wretch$Ucidata.$$catch(Wretch$Ucidata.$$then(Curry._1(request, undefined), (function (result) {
                      Curry._1(setState, (function (param) {
                              return /* Idle */0;
                            }));
                      return Belt_Option.forEach(onSuccess, (function (onSuccess) {
                                    return Curry._1(onSuccess, result);
                                  }));
                    })), (function (error) {
                  Curry._1(setState, (function (param) {
                          return /* Error */{
                                  _0: error
                                };
                        }));
                  return Belt_Option.forEach(onError, (function (onError) {
                                return Curry._1(onError, error);
                              }));
                }));
  };
  return [
          match[0],
          wrappedRequest
        ];
}

function compose2(s1, s2) {
  if (typeof s1 !== "number") {
    switch (s1.TAG | 0) {
      case /* Loading */0 :
          break;
      case /* Error */1 :
          return {
                  TAG: /* Error */1,
                  _0: s1._0,
                  _1: undefined
                };
      case /* Data */2 :
          if (typeof s2 === "number") {
            return /* NoData */0;
          }
          switch (s2.TAG | 0) {
            case /* Loading */0 :
            case /* Error */1 :
                break;
            case /* Data */2 :
                return {
                        TAG: /* Data */2,
                        _0: [
                          s1._0,
                          s2._0
                        ]
                      };
            
          }
          break;
      
    }
  }
  if (typeof s2 === "number") {
    if (typeof s1 === "number") {
      return /* NoData */0;
    } else {
      return {
              TAG: /* Loading */0,
              _0: undefined
            };
    }
  }
  switch (s2.TAG | 0) {
    case /* Loading */0 :
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
    case /* Error */1 :
        return {
                TAG: /* Error */1,
                _0: s2._0,
                _1: undefined
              };
    case /* Data */2 :
        if (typeof s1 === "number") {
          return /* NoData */0;
        } else {
          return {
                  TAG: /* Loading */0,
                  _0: undefined
                };
        }
    
  }
}

function compose3(s1, s2, s3) {
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (typeof s1 === "number") {
    exit$2 = 4;
  } else {
    switch (s1.TAG | 0) {
      case /* Loading */0 :
          exit$2 = 4;
          break;
      case /* Error */1 :
          return {
                  TAG: /* Error */1,
                  _0: s1._0,
                  _1: undefined
                };
      case /* Data */2 :
          if (typeof s2 === "number") {
            exit$1 = 3;
          } else {
            switch (s2.TAG | 0) {
              case /* Loading */0 :
                  exit$1 = 3;
                  break;
              case /* Error */1 :
                  exit$2 = 4;
                  break;
              case /* Data */2 :
                  if (typeof s3 === "number") {
                    return /* NoData */0;
                  }
                  switch (s3.TAG | 0) {
                    case /* Loading */0 :
                    case /* Error */1 :
                        exit$1 = 3;
                        break;
                    case /* Data */2 :
                        return {
                                TAG: /* Data */2,
                                _0: [
                                  s1._0,
                                  s2._0,
                                  s3._0
                                ]
                              };
                    
                  }
                  break;
              
            }
          }
          break;
      
    }
  }
  if (exit$2 === 4) {
    if (typeof s2 === "number") {
      exit$1 = 3;
    } else {
      if (s2.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s2._0,
                _1: undefined
              };
      }
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof s3 === "number") {
      exit = 2;
    } else {
      switch (s3.TAG | 0) {
        case /* Loading */0 :
            return {
                    TAG: /* Loading */0,
                    _0: undefined
                  };
        case /* Error */1 :
            return {
                    TAG: /* Error */1,
                    _0: s3._0,
                    _1: undefined
                  };
        case /* Data */2 :
            exit = 2;
            break;
        
      }
    }
  }
  if (exit === 2 && typeof s2 !== "number" && s2.TAG === /* Loading */0) {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
  if (typeof s1 === "number" || s1.TAG !== /* Loading */0) {
    return /* NoData */0;
  } else {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
}

function compose4(s1, s2, s3, s4) {
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  if (typeof s1 === "number") {
    exit$4 = 6;
  } else {
    switch (s1.TAG | 0) {
      case /* Loading */0 :
          exit$4 = 6;
          break;
      case /* Error */1 :
          return {
                  TAG: /* Error */1,
                  _0: s1._0,
                  _1: undefined
                };
      case /* Data */2 :
          if (typeof s2 === "number") {
            exit$3 = 5;
          } else {
            switch (s2.TAG | 0) {
              case /* Loading */0 :
                  exit$3 = 5;
                  break;
              case /* Error */1 :
                  exit$4 = 6;
                  break;
              case /* Data */2 :
                  if (typeof s3 === "number") {
                    exit$2 = 4;
                  } else {
                    switch (s3.TAG | 0) {
                      case /* Loading */0 :
                          exit$2 = 4;
                          break;
                      case /* Error */1 :
                          exit$3 = 5;
                          break;
                      case /* Data */2 :
                          if (typeof s4 === "number") {
                            return /* NoData */0;
                          }
                          switch (s4.TAG | 0) {
                            case /* Loading */0 :
                            case /* Error */1 :
                                exit$2 = 4;
                                break;
                            case /* Data */2 :
                                return {
                                        TAG: /* Data */2,
                                        _0: [
                                          s1._0,
                                          s2._0,
                                          s3._0,
                                          s4._0
                                        ]
                                      };
                            
                          }
                          break;
                      
                    }
                  }
                  break;
              
            }
          }
          break;
      
    }
  }
  if (exit$4 === 6) {
    if (typeof s2 === "number") {
      exit$3 = 5;
    } else {
      if (s2.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s2._0,
                _1: undefined
              };
      }
      exit$3 = 5;
    }
  }
  if (exit$3 === 5) {
    if (typeof s3 === "number") {
      exit$2 = 4;
    } else {
      if (s3.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s3._0,
                _1: undefined
              };
      }
      exit$2 = 4;
    }
  }
  if (exit$2 === 4) {
    if (typeof s4 === "number") {
      exit$1 = 3;
    } else {
      switch (s4.TAG | 0) {
        case /* Loading */0 :
            return {
                    TAG: /* Loading */0,
                    _0: undefined
                  };
        case /* Error */1 :
            return {
                    TAG: /* Error */1,
                    _0: s4._0,
                    _1: undefined
                  };
        case /* Data */2 :
            exit$1 = 3;
            break;
        
      }
    }
  }
  if (exit$1 === 3) {
    if (typeof s3 === "number") {
      exit = 2;
    } else {
      if (s3.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit = 2;
    }
  }
  if (exit === 2 && typeof s2 !== "number" && s2.TAG === /* Loading */0) {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
  if (typeof s1 === "number" || s1.TAG !== /* Loading */0) {
    return /* NoData */0;
  } else {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
}

function compose5(s1, s2, s3, s4, s5) {
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  var exit$5 = 0;
  var exit$6 = 0;
  if (typeof s1 === "number") {
    exit$6 = 8;
  } else {
    switch (s1.TAG | 0) {
      case /* Loading */0 :
          exit$6 = 8;
          break;
      case /* Error */1 :
          return {
                  TAG: /* Error */1,
                  _0: s1._0,
                  _1: undefined
                };
      case /* Data */2 :
          if (typeof s2 === "number") {
            exit$5 = 7;
          } else {
            switch (s2.TAG | 0) {
              case /* Loading */0 :
                  exit$5 = 7;
                  break;
              case /* Error */1 :
                  exit$6 = 8;
                  break;
              case /* Data */2 :
                  if (typeof s3 === "number") {
                    exit$4 = 6;
                  } else {
                    switch (s3.TAG | 0) {
                      case /* Loading */0 :
                          exit$4 = 6;
                          break;
                      case /* Error */1 :
                          exit$5 = 7;
                          break;
                      case /* Data */2 :
                          if (typeof s4 === "number") {
                            exit$3 = 5;
                          } else {
                            switch (s4.TAG | 0) {
                              case /* Loading */0 :
                                  exit$3 = 5;
                                  break;
                              case /* Error */1 :
                                  exit$4 = 6;
                                  break;
                              case /* Data */2 :
                                  if (typeof s5 === "number") {
                                    return /* NoData */0;
                                  }
                                  switch (s5.TAG | 0) {
                                    case /* Loading */0 :
                                    case /* Error */1 :
                                        exit$3 = 5;
                                        break;
                                    case /* Data */2 :
                                        return {
                                                TAG: /* Data */2,
                                                _0: [
                                                  s1._0,
                                                  s2._0,
                                                  s3._0,
                                                  s4._0,
                                                  s5._0
                                                ]
                                              };
                                    
                                  }
                                  break;
                              
                            }
                          }
                          break;
                      
                    }
                  }
                  break;
              
            }
          }
          break;
      
    }
  }
  if (exit$6 === 8) {
    if (typeof s2 === "number") {
      exit$5 = 7;
    } else {
      if (s2.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s2._0,
                _1: undefined
              };
      }
      exit$5 = 7;
    }
  }
  if (exit$5 === 7) {
    if (typeof s3 === "number") {
      exit$4 = 6;
    } else {
      if (s3.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s3._0,
                _1: undefined
              };
      }
      exit$4 = 6;
    }
  }
  if (exit$4 === 6) {
    if (typeof s4 === "number") {
      exit$3 = 5;
    } else {
      if (s4.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s4._0,
                _1: undefined
              };
      }
      exit$3 = 5;
    }
  }
  if (exit$3 === 5) {
    if (typeof s5 === "number") {
      exit$2 = 4;
    } else {
      switch (s5.TAG | 0) {
        case /* Loading */0 :
            return {
                    TAG: /* Loading */0,
                    _0: undefined
                  };
        case /* Error */1 :
            return {
                    TAG: /* Error */1,
                    _0: s5._0,
                    _1: undefined
                  };
        case /* Data */2 :
            exit$2 = 4;
            break;
        
      }
    }
  }
  if (exit$2 === 4) {
    if (typeof s4 === "number") {
      exit$1 = 3;
    } else {
      if (s4.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof s3 === "number") {
      exit = 2;
    } else {
      if (s3.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit = 2;
    }
  }
  if (exit === 2 && typeof s2 !== "number" && s2.TAG === /* Loading */0) {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
  if (typeof s1 === "number" || s1.TAG !== /* Loading */0) {
    return /* NoData */0;
  } else {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
}

function compose6(s1, s2, s3, s4, s5, s6) {
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  var exit$5 = 0;
  var exit$6 = 0;
  var exit$7 = 0;
  var exit$8 = 0;
  if (typeof s1 === "number") {
    exit$8 = 10;
  } else {
    switch (s1.TAG | 0) {
      case /* Loading */0 :
          exit$8 = 10;
          break;
      case /* Error */1 :
          return {
                  TAG: /* Error */1,
                  _0: s1._0,
                  _1: undefined
                };
      case /* Data */2 :
          if (typeof s2 === "number") {
            exit$7 = 9;
          } else {
            switch (s2.TAG | 0) {
              case /* Loading */0 :
                  exit$7 = 9;
                  break;
              case /* Error */1 :
                  exit$8 = 10;
                  break;
              case /* Data */2 :
                  if (typeof s3 === "number") {
                    exit$6 = 8;
                  } else {
                    switch (s3.TAG | 0) {
                      case /* Loading */0 :
                          exit$6 = 8;
                          break;
                      case /* Error */1 :
                          exit$7 = 9;
                          break;
                      case /* Data */2 :
                          if (typeof s4 === "number") {
                            exit$5 = 7;
                          } else {
                            switch (s4.TAG | 0) {
                              case /* Loading */0 :
                                  exit$5 = 7;
                                  break;
                              case /* Error */1 :
                                  exit$6 = 8;
                                  break;
                              case /* Data */2 :
                                  if (typeof s5 === "number") {
                                    exit$4 = 6;
                                  } else {
                                    switch (s5.TAG | 0) {
                                      case /* Loading */0 :
                                          exit$4 = 6;
                                          break;
                                      case /* Error */1 :
                                          exit$5 = 7;
                                          break;
                                      case /* Data */2 :
                                          if (typeof s6 === "number") {
                                            return /* NoData */0;
                                          }
                                          switch (s6.TAG | 0) {
                                            case /* Loading */0 :
                                            case /* Error */1 :
                                                exit$4 = 6;
                                                break;
                                            case /* Data */2 :
                                                return {
                                                        TAG: /* Data */2,
                                                        _0: [
                                                          s1._0,
                                                          s2._0,
                                                          s3._0,
                                                          s4._0,
                                                          s5._0,
                                                          s6._0
                                                        ]
                                                      };
                                            
                                          }
                                          break;
                                      
                                    }
                                  }
                                  break;
                              
                            }
                          }
                          break;
                      
                    }
                  }
                  break;
              
            }
          }
          break;
      
    }
  }
  if (exit$8 === 10) {
    if (typeof s2 === "number") {
      exit$7 = 9;
    } else {
      if (s2.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s2._0,
                _1: undefined
              };
      }
      exit$7 = 9;
    }
  }
  if (exit$7 === 9) {
    if (typeof s3 === "number") {
      exit$6 = 8;
    } else {
      if (s3.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s3._0,
                _1: undefined
              };
      }
      exit$6 = 8;
    }
  }
  if (exit$6 === 8) {
    if (typeof s4 === "number") {
      exit$5 = 7;
    } else {
      if (s4.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s4._0,
                _1: undefined
              };
      }
      exit$5 = 7;
    }
  }
  if (exit$5 === 7) {
    if (typeof s5 === "number") {
      exit$4 = 6;
    } else {
      if (s5.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s5._0,
                _1: undefined
              };
      }
      exit$4 = 6;
    }
  }
  if (exit$4 === 6) {
    if (typeof s6 === "number") {
      exit$3 = 5;
    } else {
      switch (s6.TAG | 0) {
        case /* Loading */0 :
            return {
                    TAG: /* Loading */0,
                    _0: undefined
                  };
        case /* Error */1 :
            return {
                    TAG: /* Error */1,
                    _0: s6._0,
                    _1: undefined
                  };
        case /* Data */2 :
            exit$3 = 5;
            break;
        
      }
    }
  }
  if (exit$3 === 5) {
    if (typeof s5 === "number") {
      exit$2 = 4;
    } else {
      if (s5.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit$2 = 4;
    }
  }
  if (exit$2 === 4) {
    if (typeof s4 === "number") {
      exit$1 = 3;
    } else {
      if (s4.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof s3 === "number") {
      exit = 2;
    } else {
      if (s3.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit = 2;
    }
  }
  if (exit === 2 && typeof s2 !== "number" && s2.TAG === /* Loading */0) {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
  if (typeof s1 === "number" || s1.TAG !== /* Loading */0) {
    return /* NoData */0;
  } else {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
}

function compose7(s1, s2, s3, s4, s5, s6, s7) {
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  var exit$5 = 0;
  var exit$6 = 0;
  var exit$7 = 0;
  var exit$8 = 0;
  var exit$9 = 0;
  var exit$10 = 0;
  if (typeof s1 === "number") {
    exit$10 = 12;
  } else {
    switch (s1.TAG | 0) {
      case /* Loading */0 :
          exit$10 = 12;
          break;
      case /* Error */1 :
          return {
                  TAG: /* Error */1,
                  _0: s1._0,
                  _1: undefined
                };
      case /* Data */2 :
          if (typeof s2 === "number") {
            exit$9 = 11;
          } else {
            switch (s2.TAG | 0) {
              case /* Loading */0 :
                  exit$9 = 11;
                  break;
              case /* Error */1 :
                  exit$10 = 12;
                  break;
              case /* Data */2 :
                  if (typeof s3 === "number") {
                    exit$8 = 10;
                  } else {
                    switch (s3.TAG | 0) {
                      case /* Loading */0 :
                          exit$8 = 10;
                          break;
                      case /* Error */1 :
                          exit$9 = 11;
                          break;
                      case /* Data */2 :
                          if (typeof s4 === "number") {
                            exit$7 = 9;
                          } else {
                            switch (s4.TAG | 0) {
                              case /* Loading */0 :
                                  exit$7 = 9;
                                  break;
                              case /* Error */1 :
                                  exit$8 = 10;
                                  break;
                              case /* Data */2 :
                                  if (typeof s5 === "number") {
                                    exit$6 = 8;
                                  } else {
                                    switch (s5.TAG | 0) {
                                      case /* Loading */0 :
                                          exit$6 = 8;
                                          break;
                                      case /* Error */1 :
                                          exit$7 = 9;
                                          break;
                                      case /* Data */2 :
                                          if (typeof s6 === "number") {
                                            exit$5 = 7;
                                          } else {
                                            switch (s6.TAG | 0) {
                                              case /* Loading */0 :
                                                  exit$5 = 7;
                                                  break;
                                              case /* Error */1 :
                                                  exit$6 = 8;
                                                  break;
                                              case /* Data */2 :
                                                  if (typeof s7 === "number") {
                                                    return /* NoData */0;
                                                  }
                                                  switch (s7.TAG | 0) {
                                                    case /* Loading */0 :
                                                    case /* Error */1 :
                                                        exit$5 = 7;
                                                        break;
                                                    case /* Data */2 :
                                                        return {
                                                                TAG: /* Data */2,
                                                                _0: [
                                                                  s1._0,
                                                                  s2._0,
                                                                  s3._0,
                                                                  s4._0,
                                                                  s5._0,
                                                                  s6._0,
                                                                  s7._0
                                                                ]
                                                              };
                                                    
                                                  }
                                                  break;
                                              
                                            }
                                          }
                                          break;
                                      
                                    }
                                  }
                                  break;
                              
                            }
                          }
                          break;
                      
                    }
                  }
                  break;
              
            }
          }
          break;
      
    }
  }
  if (exit$10 === 12) {
    if (typeof s2 === "number") {
      exit$9 = 11;
    } else {
      if (s2.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s2._0,
                _1: undefined
              };
      }
      exit$9 = 11;
    }
  }
  if (exit$9 === 11) {
    if (typeof s3 === "number") {
      exit$8 = 10;
    } else {
      if (s3.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s3._0,
                _1: undefined
              };
      }
      exit$8 = 10;
    }
  }
  if (exit$8 === 10) {
    if (typeof s4 === "number") {
      exit$7 = 9;
    } else {
      if (s4.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s4._0,
                _1: undefined
              };
      }
      exit$7 = 9;
    }
  }
  if (exit$7 === 9) {
    if (typeof s5 === "number") {
      exit$6 = 8;
    } else {
      if (s5.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s5._0,
                _1: undefined
              };
      }
      exit$6 = 8;
    }
  }
  if (exit$6 === 8) {
    if (typeof s6 === "number") {
      exit$5 = 7;
    } else {
      if (s6.TAG === /* Error */1) {
        return {
                TAG: /* Error */1,
                _0: s6._0,
                _1: undefined
              };
      }
      exit$5 = 7;
    }
  }
  if (exit$5 === 7) {
    if (typeof s7 === "number") {
      exit$4 = 6;
    } else {
      switch (s7.TAG | 0) {
        case /* Loading */0 :
            return {
                    TAG: /* Loading */0,
                    _0: undefined
                  };
        case /* Error */1 :
            return {
                    TAG: /* Error */1,
                    _0: s7._0,
                    _1: undefined
                  };
        case /* Data */2 :
            exit$4 = 6;
            break;
        
      }
    }
  }
  if (exit$4 === 6) {
    if (typeof s6 === "number") {
      exit$3 = 5;
    } else {
      if (s6.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit$3 = 5;
    }
  }
  if (exit$3 === 5) {
    if (typeof s5 === "number") {
      exit$2 = 4;
    } else {
      if (s5.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit$2 = 4;
    }
  }
  if (exit$2 === 4) {
    if (typeof s4 === "number") {
      exit$1 = 3;
    } else {
      if (s4.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof s3 === "number") {
      exit = 2;
    } else {
      if (s3.TAG === /* Loading */0) {
        return {
                TAG: /* Loading */0,
                _0: undefined
              };
      }
      exit = 2;
    }
  }
  if (exit === 2 && typeof s2 !== "number" && s2.TAG === /* Loading */0) {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
  if (typeof s1 === "number" || s1.TAG !== /* Loading */0) {
    return /* NoData */0;
  } else {
    return {
            TAG: /* Loading */0,
            _0: undefined
          };
  }
}

export {
  retryMiddleware ,
  base ,
  cached ,
  defaultHook ,
  data ,
  error ,
  composeErrors ,
  errors2 ,
  errors3 ,
  useHttpClient ,
  useClient ,
  useCachingClient ,
  useScopedCachingClient ,
  useData ,
  useDataReducer ,
  useRequest ,
  compose2 ,
  compose3 ,
  compose4 ,
  compose5 ,
  compose6 ,
  compose7 ,
  
}
/* retryMiddleware Not a pure module */
