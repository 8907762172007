// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Icon$Ucidata from "../Icon.bs.js";
import * as ProDuotoneSvgIcons from "@fortawesome/pro-duotone-svg-icons";

function Loader(Props) {
  var className = Props.className;
  var iconClassName = Props.iconClassName;
  var childrenOpt = Props.children;
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : undefined;
  if (children !== undefined) {
    return React.createElement(React.Fragment, undefined, Belt_Option.getExn(children));
  }
  var tmp = {
    icon: ProDuotoneSvgIcons.faSpinner,
    pulse: true
  };
  if (iconClassName !== undefined) {
    tmp.className = iconClassName;
  }
  return React.createElement("div", {
              className: Cn.$plus("flex items-center justify-center", Cn.take(className))
            }, React.createElement(Icon$Ucidata.make, tmp));
}

var make = React.memo(Loader);

var $$default = make;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* make Not a pure module */
